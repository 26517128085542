import React, { useState } from "react";
import backendUrl from './config';
import { useLanguage } from './languageContext';

function GetInTouch() {
    const { currentLanguage } = useLanguage();
    // send email
    // from, to, subject, text
    const [inputFrom, setInputFrom] = useState("");
    const [inputName, setInputName] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [inputSubject, setInputSubject] = useState("");
    const [inputText, setInputText] = useState("");
    const [responseMessage, setResponseMessage] = useState("");

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // send email
    // from, to, subject, text
    const handleInputChangeName = (e) => {
        setInputName(e.target.value);
    };
    const handleInputChangeFrom = (e) => {
        setInputFrom(e.target.value);
    };
    const handleInputChangePhone = (e) => {
        setInputPhone(e.target.value);
    };
    const handleInputChangeSubject = (e) => {
        setInputSubject(e.target.value);
    };
    const handleInputChangeText = (e) => {
        setInputText(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            from: inputFrom,
            name: inputName,
            phone: inputPhone,
            subject: inputSubject,
            text: inputText,
            itemId: "No ItemID, as request comes from about page",
            address: "No ItemID, as request comes from about page"
        };
        sendEmail(data)
    }

    async function sendEmail(data) {
        try {
            const response = await fetch(`${backendUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                const responseData = await response.json();
                setResponseMessage(responseData.message);
            } else {
                const errorData = await response.json();
                setResponseMessage(`Error: ${errorData.message}`);
            }
        } catch (error) {
            console.error("Error:", error);
            setResponseMessage("Error sending data to the server.");
        }
    };

    // end send email
    return (
        <div>
            {languageToUse === "ge" && (
                <div className="col-md-6 col-lg-12">
                    <article className="block-callboard">
                        <div className="block-callboard-body">
                            <h3 className="block-callboard-title">მოითხოვეთ კონსულტაცია</h3>
                            <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={{}}>
                                <div className="row row-20" id="contact" >
                                    <div className="col-12" id="sendEmailForm">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-name" type="text" name="name" placeholder="თქვენი სახელი" value={inputName} onChange={handleInputChangeName} data-constraints="@Required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-email" type="email" name="from" placeholder="თქვენი ელექტრონული ფოსტა" value={inputFrom} onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-phone" type="text" name="phone" placeholder="თქვენი ტელეფონის ნომერი" value={inputPhone} onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />
                                            {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="სათაური" value={inputSubject} onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />
                                            {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            {/* <label className="form-label" htmlFor="contact-message">Message</label> */}
                                            <textarea className="form-input" id="contact-message" name="text" placeholder="მესიჯი" value={inputText} onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="button button-block button-secondary" type="submit">მესიჯის გაგზავნა</button>
                                        <p style={{ color: 'blue', fontWeight: '600' }}>{responseMessage}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </article>
                </div>
            )}
            {languageToUse === "en" && (
                <div className="col-md-6 col-lg-12">
                    <article className="block-callboard">
                        <div className="block-callboard-body">
                            <h3 className="block-callboard-title">Request a Showing or cosultation</h3>
                            <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={{}}>
                                <div className="row row-20" id="contact" >
                                    <div className="col-12" id="sendEmailForm">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-name" type="text" name="name" autoComplete="name" placeholder="Your Name" onChange={handleInputChangeName} data-constraints="@Required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-email" type="email" name="from" autoComplete="email" placeholder="E-mail" onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-phone" type="text" name="phone" autoComplete="tel" placeholder="Phone" onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="Subject" onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="contact-message" name="text" placeholder="Message" onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="button button-block button-secondary" type="submit">Send message</button>
                                        <p>{responseMessage}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </article>
                </div>
            )}
            {languageToUse === "ru" && (
                <div className="col-md-6 col-lg-12">
                    <article className="block-callboard">
                        <div className="block-callboard-body">
                            <h3 className="block-callboard-title">Запросить показ или консультацию</h3>
                            <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={{}}>
                                <div className="row row-20" id="contact" >
                                    <div className="col-12" id="sendEmailForm">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-name" type="text" name="name" autoComplete="name" placeholder="Ваше имя" onChange={handleInputChangeName} data-constraints="@Required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-email" type="email" name="from" autoComplete="email" placeholder="Электронная почта" onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-phone" type="text" name="phone" autoComplete="tel" placeholder="Телефон" onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="Предмет" onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="contact-message" name="text" placeholder="Сообщение" onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="button button-block button-secondary" type="submit">Отправить</button>
                                        <p>{responseMessage}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </article>
                </div>
            )}
        </div>
    )
}

export default GetInTouch;