import React, { useState } from 'react';
import { useLanguage } from './languageContext';
import backendUrl from './config';
import { useAuth } from './authContext';

function SendEmailMainByUser(props) {
    const { userId } = useAuth();
    const { currentLanguage } = useLanguage();
    const [inputFrom, setInputFrom] = useState("");
    const [inputName, setInputName] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [inputSubject, setInputSubject] = useState("");
    const [inputText, setInputText] = useState("");
    const [responseMessageSendEmail, setResponseMessageSendEmail] = useState("");

    const [responseMessageShow, setResponseMessageShow] = useState(false);

    // Retrieve currentLanguage from sessionStorage
    const storedLanguage = sessionStorage.getItem('currentLanguage');

    // Use storedLanguage if available, otherwise use currentLanguage
    const languageToUse = storedLanguage || currentLanguage;

    // send email
    // from, to, subject, text
    const handleInputChangeName = (e) => {
        setInputName(e.target.value);
    };
    const handleInputChangeFrom = (e) => {
        setInputFrom(e.target.value);
    };
    const handleInputChangePhone = (e) => {
        setInputPhone(e.target.value);
    };
    const handleInputChangeSubject = (e) => {
        setInputSubject(e.target.value);
    };
    const handleInputChangeText = (e) => {
        setInputText(e.target.value);
    };

    async function sendEmail(data) {
        try {
            const response = await fetch(`${backendUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const responseData = await response.json();
                setResponseMessageSendEmail(responseData.message);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
                cleanContent();
            } else {
                const errorData = await response.json();
                setResponseMessageSendEmail(`Error: ${errorData.message}`);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            }
        } catch (error) {
            throw new Error("Error sending data to the server.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            from: inputFrom,
            name: inputName,
            phone: inputPhone,
            subject: inputSubject,
            text: inputText,
            itemId: props.itemId,
            address: props.address,
            product: props.product,
            activity: props.activity,
            user: userId
        };
        sendEmail(data)
    };

    function cleanContent() {
        setInputFrom("");
        setInputName("");
        setInputFrom("");
        setInputPhone("");
        setInputSubject("");
        setInputText("");
    };


    return (
        <div>
            {languageToUse === "ge" && (
                <article className="block-callboard">
                    <div className="block-callboard-body">
                        <h3 className="block-callboard-title">მოითხოვეთ კონსულტაცია ან საცხოვრებლის ნახვა</h3>
                        <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={`/item/${props.itemId}`}>
                            <div className="row row-20" id="contact" >
                                <div className="col-12" id="sendEmailForm">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-name" type="text" name="name" placeholder="თქვენი სახელი" value={inputName} onChange={handleInputChangeName} data-constraints="@Required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-email" type="email" name="from" placeholder="თქვენი ელექტრონული ფოსტა" value={inputFrom} onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-phone" type="text" name="phone" placeholder="თქვენი ტელეფონის ნომერი" value={inputPhone} onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />
                                        {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="სათაური" value={inputSubject} onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />
                                        {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        {/* <label className="form-label" htmlFor="contact-message">Message</label> */}
                                        <textarea className="form-input" id="contact-message" name="text" placeholder="მესიჯი" value={inputText} onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-block button-secondary" type="submit">მესიჯის გაგზავნა</button>
                                    {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow} style={{ color: 'blue', fontWeight: '600' }}>გამოხმაურება: {responseMessageSendEmail === "Email sent successfully" ? "გაიგზავნა წარმატებით" : "მესიჯი ვერ გაიგზავნა"}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </article>
            )}
            {languageToUse === "en" && (
                <article className="block-callboard">
                    <div className="block-callboard-body">
                        <h3 className="block-callboard-title">Request a visit or cosultation</h3>
                        <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={`/item/${props.itemId}`}>
                            <div className="row row-20" id="contact" >
                                <div className="col-12" id="sendEmailForm">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-name" type="text" name="name" placeholder="Your Name" value={inputName} onChange={handleInputChangeName} data-constraints="@Required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-email" type="email" name="from" placeholder="E-mail" value={inputFrom} onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-phone" type="text" name="phone" placeholder="Phone" value={inputPhone} onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />
                                        {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="Subject" value={inputSubject} onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />
                                        {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        {/* <label className="form-label" htmlFor="contact-message">Message</label> */}
                                        <textarea className="form-input" id="contact-message" name="text" placeholder="Message" value={inputText} onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-block button-secondary" type="submit">Send message</button>
                                    {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow} style={{ color: 'blue', fontWeight: '600' }}>Response: {responseMessageSendEmail}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </article>
            )}
            {languageToUse === "ru" && (
                <article className="block-callboard">
                    <div className="block-callboard-body">
                        <h3 className="block-callboard-title">Запросить визит или консультацию</h3>
                        <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={`/item/${props.itemId}`}>
                            <div className="row row-20" id="contact" >
                                <div className="col-12" id="sendEmailForm">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-name" type="text" name="name" placeholder="Ваше имя" value={inputName} onChange={handleInputChangeName} data-constraints="@Required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-email" type="email" name="from" placeholder="Электронная почта" value={inputFrom} onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-phone" type="text" name="phone" placeholder="Телефон" value={inputPhone} onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />
                                        {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="Предмет" value={inputSubject} onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />
                                        {/* <label className="form-label" htmlFor="contact-phone">Phone</label> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        {/* <label className="form-label" htmlFor="contact-message">Message</label> */}
                                        <textarea className="form-input" id="contact-message" name="text" placeholder="Сообщение" value={inputText} onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-block button-secondary" type="submit">Отправить</button>
                                    {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow} style={{ color: 'blue', fontWeight: '600' }}>Ответ: {responseMessageSendEmail}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </article>
            )}
        </div>
    );

}
export default SendEmailMainByUser;
