import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import Loader from "./loader";
import ChatBot from "./chatBot";
import FavorateButton from "./favorateButton";
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import backendUrl from './config';
import { useAuth } from './authContext';
import { useNavigate } from "react-router-dom";
import { useLanguage } from './languageContext';
import SendEmailMainByUser from "./sendEmaiMainByUser";
import ImgSlider from "./imgSlider";


function DemandDetailsPopUp({ onClick, listingId }) {
    const product = "demand";
    const itemId = listingId;
    const { currentLanguage } = useLanguage();
    const { userId } = useAuth();
    // const { itemId } = useParams();
    const [selectedDemand, setselectedDemand] = useState({});
    const [loading, setLoading] = useState(true);

    const [imagePaths, setImagePaths] = useState([]);

    // const [userId, setUserId] = useState("");
    const [responseMessageShow, setResponseMessageShow] = useState(false);

    // send email
    // from, to, subject, text
    const [responseMessageFetchPhotos, setResponseMessageFetchPhotos] = useState("");


    // Information toggle
    const [generalInformationAccordionOpened, setGeneralInformationAccordionOpened] = useState("true");
    const [vehicleInformationAccordionOpened, setVehicleInformationAccordionOpened] = useState("true");
    const [moreInformationAccordionOpened, setMoreInformationAccordionOpened] = useState("true");
    const [damagesAccordionOpened, setDamagesAccordionOpened] = useState("true");
    const [commentAccordionOpened, setCommentAccordionOpened] = useState("true");


    const { loggedIn } = useAuth();
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // deal summary 
    // let averageMortgageRate = 0.007;

    useEffect(() => {
        fetch(`${backendUrl}/item/${product}/${new Date().getTime()}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                setselectedDemand(responseData);
                setLoading(false);
                fetchPhotos(responseData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [loggedIn, navigate, itemId]);

    // fetch photos 
    const fetchPhotos = async (responseData) => {
        setImagePaths([]);
        // Use a Set to store unique URLs
        const uniqueImagePaths = new Set();
        // Receive and add each image URL to the imagePaths array
        const numberOfPhotos = responseData.imgs.length;
        for (let i = 0; i < numberOfPhotos; i++) {
            try {
                const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${i}`);
                if (response.ok) {
                    const contentType = response.headers.get('content-type');

                    if (contentType && contentType.includes('application/json')) {
                        const data = await response.json();
                        setResponseMessageFetchPhotos(data.message);
                    } else {
                        const blob = await response.blob();
                        const imgUrl = URL.createObjectURL(blob);

                        // Add the unique URL to the set
                        uniqueImagePaths.add(imgUrl);
                    }
                } else {
                    setResponseMessageFetchPhotos("Error fetching photos. Response failed.");
                }
            } catch (error) {
                console.error("Error fetching photos:", error);
                setResponseMessageFetchPhotos("Error fetching photos.");
            }
        }
        setImagePaths([...uniqueImagePaths]);
    };

    // fetching photo end


    if (loading) {
        return <Loader loading={loading} />;
    }

    const handleAccordionGeneralInformationToggle = () => {
        setGeneralInformationAccordionOpened(!generalInformationAccordionOpened);
    }

    const handleAccordionVehicleInformationToggle = () => {
        setVehicleInformationAccordionOpened(!vehicleInformationAccordionOpened);
    }

    const handleAccordionMoreDetalsToggle = () => {
        setMoreInformationAccordionOpened(!moreInformationAccordionOpened);
    }

    const handleAccordionDamagesToggle = () => {
        setDamagesAccordionOpened(!damagesAccordionOpened);
    }

    const handleAccordionCommentInformationToggle = () => {
        setCommentAccordionOpened(!commentAccordionOpened);
    }

    return (
        <div className="item-details-container container">
            {languageToUse === "ge" && (
                <div>
                    <section className="demandBackground context-dark" >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                            <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedDemand.address} {selectedDemand.city}</h3>
                        </div>
                    </section>
                    {/* <SubMenue product={product} /> */}
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{parseInt(selectedDemand.proposedPrice) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(parseInt(selectedDemand.proposedPrice)) : selectedDemand.proposedPrice}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list icon-size-small">
                                                    <li><img src="./images/location.png" alt="icon" /><span>{selectedDemand.city}</span></li>
                                                    <li><img src="./images/user-o.png" alt="icon" /><span>{selectedDemand.name}</span></li>
                                                    <li><img src="./images/mail-o.png" alt="icon" /><span>{selectedDemand.email}</span></li>
                                                    <li><img src="./images/b-call1.png" alt="icon" /><span>{selectedDemand.phone}</span></li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="layout">
                                                    <dl className="list-terms-inline text-margin">
                                                        <dt className="list-item-bold ">კატეგორია:</dt>
                                                        <dd hidden={selectedDemand.demandtype !== "lookingfor"}><span className=""></span><span>{selectedDemand.demandtype === "lookingfor" ? "ვეძებ" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "willbuy"}><span className=""></span><span>{selectedDemand.demandtype === "willbuy" ? "ვიყიდი" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "willrent"}><span className=""></span><span>{selectedDemand.demandtype === "willrent" ? "ვიქირავებ" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "other"}><span className=""></span><span>{selectedDemand.demandtype === "other" ? "სხვა" : ""}</span></dd>
                                                    </dl>
                                                </div>
                                                <div className="card-title "><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>{selectedDemand.subject}</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedDemand.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="card-body" style={{ display: "flex" }}>
                                            <div className="layout">
                                                <dl className="list-terms-inline text-margin">
                                                    <dt>ფასი:</dt>
                                                    <dd>{selectedDemand.askingPrice}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <article>

                                        </article>
                                    </div>
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    <div hidden={userId !== selectedDemand.user?._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editdemandlisting/${selectedDemand?._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    განცხადებაში ცვლილებების შეტანა
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "დარწმუნებული ხართ, რომ გსურთ ამ განცხადების წაშლა? როგორც კი ამას გააკეთებთ, ვერ აღადგენთ წაშლილ განცხადებას და გამოწერას. თუ განცხადება იყო ფასიანი დაკარგავთ მისი ერთთვიანი გამოწერის დარჩენილ ვადასაც. ეს არ შეეხება სხვა განცხადებებს."
                                                    )
                                                        ? navigate(`/deletedemand/${selectedDemand?._id}`)
                                                        : null
                                                }
                                            >
                                                განცხადების წაშლა
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    </div>
                                    {/* <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div> */}
                                    {/* <div className="block-group-item">
                                        <h3>მანქანის მდებარეობა რუქაზე</h3>
                                        <MapWrapper>
                                            <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedDemand} defaultCenter={selectedDemand}></Map>
                                        </MapWrapper>
                                    </div> */}
                                    {/* <div className="blog-post-solo-footer mt-20">
                                    </div> */}
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">სალომე ღურწკაია</h3>
                                                <p className="block-person-cite">კლიენტებთან ურთიერთობის ექსპერტი</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>5 წლის განმავლობაში დახმარება გაუწია უამრავ კლიენტს</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    დაუკავშირდით
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedDemand.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {languageToUse === "en" && (
                <div>
                    <section className="demandBackground context-dark" >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                            <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedDemand.address} {selectedDemand.city}</h3>
                        </div>
                    </section>
                    {/* <SubMenue product={product} /> */}
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{parseInt(selectedDemand.proposedPrice) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(parseInt(selectedDemand.proposedPrice)) : selectedDemand.proposedPrice}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list icon-size-small">
                                                    <li><img src="./images/location.png" alt="icon" /><span>{selectedDemand.city}</span></li>
                                                    <li><img src="./images/user-o.png" alt="icon" /><span>{selectedDemand.name}</span></li>
                                                    <li><img src="./images/mail-o.png" alt="icon" /><span>{selectedDemand.email}</span></li>
                                                    <li><img src="./images/b-call1.png" alt="icon" /><span>{selectedDemand.phone}</span></li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="layout">
                                                    <dl className="list-terms-inline text-margin">
                                                        <dt className="list-item-bold ">Type:</dt>
                                                        <dd hidden={selectedDemand.demandtype !== "lookingfor"}><span class=""></span><span>{selectedDemand.demandtype === "lookingfor" ? "I am looking for" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "willbuy"}><span class=""></span><span>{selectedDemand.demandtype === "willbuy" ? "Will buy" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "willrent"}><span class=""></span><span>{selectedDemand.demandtype === "willrent" ? "Will rent" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "other"}><span class=""></span><span>{selectedDemand.demandtype === "other" ? "Other" : ""}</span></dd>
                                                    </dl>
                                                </div>
                                                <div className="card-title "><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>{selectedDemand.subject}</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedDemand.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="card-body" style={{ display: "flex" }}>
                                            <div className="layout">
                                                <dl className="list-terms-inline text-margin">
                                                    <dt>Asking price:</dt>
                                                    <dd>{selectedDemand.askingPrice}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    <div hidden={userId !== selectedDemand.user?._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editdemandlisting/${selectedDemand?._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    Edit listing
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "Are you sure you want to remove this listing? Once you do this, you can't undo it. If you had a paid subscription for that listing, you'll lose it too."
                                                    )
                                                        ? navigate(`/deletedemand/${selectedDemand?._id}`)
                                                        : null
                                                }
                                            >
                                                Delete listing
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div> */}
                                    {/* <div className="block-group-item">
                                  <h3>Location on map</h3>
                                  <MapWrapper>
                                      <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedDemand} defaultCenter={selectedDemand}></Map>
                                  </MapWrapper>
                              </div> */}
                                    {/* <div className="blog-post-solo-footer mt-20">
                                    </div> */}
                                    <div>
                                        <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    </div>
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">Sally Gurtskaia</h3>
                                                <p className="block-person-cite">Customer success manager</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>Being a full time customer success manager for over 5 years has given Sally the opportunity to work with the most wonderful clients</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    Get in touch
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedDemand.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {languageToUse === "ru" && (
                <div>
                    <section className="demandBackground context-dark" >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                            <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedDemand.address} {selectedDemand.city}</h3>
                        </div>
                    </section>
                    {/* <SubMenue product={product} /> */}
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{parseInt(selectedDemand.proposedPrice) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(parseInt(selectedDemand.proposedPrice)) : selectedDemand.proposedPrice}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list icon-size-small">
                                                    <li><img src="./images/location.png" alt="icon" /><span>{selectedDemand.city}</span></li>
                                                    <li><img src="./images/user-o.png" alt="icon" /><span>{selectedDemand.name}</span></li>
                                                    <li><img src="./images/mail-o.png" alt="icon" /><span>{selectedDemand.email}</span></li>
                                                    <li><img src="./images/b-call1.png" alt="icon" /><span>{selectedDemand.phone}</span></li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="layout">
                                                    <dl className="list-terms-inline text-margin">
                                                        <dt className="list-item-bold ">Категория:</dt>
                                                        <dd hidden={selectedDemand.demandtype !== "lookingfor"}><span class=""></span><span>{selectedDemand.demandtype === "lookingfor" ? "Я ищу" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "willbuy"}><span class=""></span><span>{selectedDemand.demandtype === "willbuy" ? "Я куплю" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "willrent"}><span class=""></span><span>{selectedDemand.demandtype === "willrent" ? "Я сдам в аренду" : ""}</span></dd>
                                                        <dd hidden={selectedDemand.demandtype !== "other"}><span class=""></span><span>{selectedDemand.demandtype === "other" ? "Другие" : ""}</span></dd>
                                                    </dl>
                                                </div>
                                                <div className="card-title "><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>{selectedDemand.subject}</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedDemand.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="card-body" style={{ display: "flex" }}>
                                            <div className="layout">
                                                <dl className="list-terms-inline text-margin">
                                                    <dt>Цена:</dt>
                                                    <dd>{selectedDemand.askingPrice}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    <div hidden={userId !== selectedDemand.user?._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editdemandlisting/${selectedDemand?._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    Редактировать объявление
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "Вы уверены, что хотите удалить это объявление? Сделав это, вы не сможете отменить это. Если у вас была платная подписка на этот список, вы ее тоже потеряете."
                                                    )
                                                        ? navigate(`/deletedemand/${selectedDemand?._id}`)
                                                        : null
                                                }
                                            >
                                                Удалить объявление
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div> */}
                                    {/* <div className="block-group-item">
                                  <h3>Location on map</h3>
                                  <MapWrapper>
                                      <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedDemand} defaultCenter={selectedDemand}></Map>
                                  </MapWrapper>
                              </div> */}
                                    {/* <div className="blog-post-solo-footer mt-20">
                                    </div> */}
                                    <div>
                                        <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    </div>
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">Салли Гурцкая</h3>
                                                <p className="block-person-cite">Менеджер по работе с клиентами</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>Будучи менеджером по работе с клиентами на протяжении более 5 лет, Салли получила возможность работать с самыми замечательными клиентами.</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    Связаться
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedDemand.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DemandDetailsPopUp;