import React, { useState, useEffect } from 'react';
import ServicePoster from './servicePoster';
import ServiceDetailsPopUp from './serviceDetailsPopUp';
import backendUrl from './config';
import VisitCounts from './visitCounts';

const HomeServices = () => {
    const product = 'service';
    const [showItemDetails, setShowItemDetails] = useState(false);
    const [selectedListingId, setSelectedListingId] = useState(null);

    const handleItemClick = (listingId) => {
        setSelectedListingId(listingId);
        setShowItemDetails(true);
    };

    const handleItemClose = () => {
        setShowItemDetails(false);
    };

    useEffect(() => {
        // Function to fetch IP address
        const fetchIpAddress = async () => {
            try {
                const responseIp = await fetch('https://api.ipify.org?format=json');
                const data = await responseIp.json();
                const ipAddress = data.ip;

                // Send request to backend with IP address
                const responseBackend = await fetch(`${backendUrl}/visitcount/${product}`, {
                    method: 'POST',
                    body: JSON.stringify({ ipAddress }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });


                if (responseBackend.ok) {
                    const responseData = await responseBackend.json();
                } else {
                    throw new Error('Failed to fetch visit count');
                }
            } catch (error) {
                console.error('Error fetching visit count:', error);
            }
        };

        // Call fetchIpAddress function when component mounts
        fetchIpAddress();
    }, []);

    return (
        <div className="container home-container">
            <VisitCounts product={product} />
            <ServicePoster onItemClick={handleItemClick} listingId={selectedListingId} /> {/* Pass both onItemClick and listingId */}
            {showItemDetails && <ServiceDetailsPopUp listingId={selectedListingId} onClick={handleItemClose} />}
        </div>
    );
};

export default HomeServices;