import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import XMLParser from 'react-xml-parser';
import { useLanguage } from './languageContext';

const VehicleModelSelector = ({
    inputValueModel,
    handleInputChangeModel,
    externalValueModel,
    setExternalValueModel,
    selectedMake,
}) => {
    const { currentLanguage } = useLanguage();
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null); // State for selected model

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Reset model value to null when the make changes
        setSelectedModel(null);

        // Avoid unnecessary API calls when selectedMake is an empty string
        if (selectedMake && selectedMake !== 'Any') {
            const fetchModelsForMake = async (make) => {
                try {
                    const response = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/getmodelsformake/${make}?format=XML`);
                    const data = await response.text();
                    const parsedData = new XMLParser().parseFromString(data);
                    const modelsList = parsedData.getElementsByTagName('Model_Name').map(model => model.value);

                    setModels(modelsList);
                } catch (error) {
                    console.error(`Error fetching models for ${make}:`, error);
                    setModels([]); // Set models to an empty array in case of an error
                }
            };

            fetchModelsForMake(selectedMake);
        } else {
            setModels([]); // Reset models to an empty array when no make is selected
        }
    }, [selectedMake, setExternalValueModel]);

    const handleModelChange = (selectedOption) => {
        setSelectedModel(selectedOption);
        setExternalValueModel(selectedOption.value); // Update the external value with the selected model
    };
    return (
        <div className="">
            {languageToUse === "ge" && (
                <Select
                    className="form-input"
                    id="vehicleModel"
                    name="vehicleModel"
                    placeholder="Select model"
                    value={{ value: inputValueModel || externalValueModel, label: inputValueModel || externalValueModel || "შეარჩიეთ მოდელი" }}
                    options={models.map(model => ({ value: model, label: model }))}
                    onChange={(selectedOption) => {
                        handleInputChangeModel({ target: { value: selectedOption.value } });
                        setExternalValueModel(selectedOption.value);
                    }}
                    isSearchable // Enable search for arbitrary text input
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderStyle: 'none',
                            height: '24px',
                            minHeight: '24px',
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                    }}
                />
            )}
            {languageToUse === "en" && (
                <Select
                    className="form-input"
                    id="vehicleModel"
                    name="vehicleModel"
                    placeholder="Select model"
                    value={{ value: inputValueModel || externalValueModel, label: inputValueModel || externalValueModel || "Select model" }}
                    options={models.map(model => ({ value: model, label: model }))}
                    onChange={(selectedOption) => {
                        handleInputChangeModel({ target: { value: selectedOption.value } });
                        setExternalValueModel(selectedOption.value);
                    }}
                    isSearchable // Enable search for arbitrary text input
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderStyle: 'none',
                            height: '24px',
                            minHeight: '24px',
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                    }}
                />
            )}
            {languageToUse === "ru" && (
                <Select
                    className="form-input"
                    id="vehicleModel"
                    name="vehicleModel"
                    placeholder="Select model"
                    value={{ value: inputValueModel || externalValueModel, label: inputValueModel || externalValueModel || "Выберите модель" }}
                    options={models.map(model => ({ value: model, label: model }))}
                    onChange={(selectedOption) => {
                        handleInputChangeModel({ target: { value: selectedOption.value } });
                        setExternalValueModel(selectedOption.value);
                    }}
                    isSearchable // Enable search for arbitrary text input
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderStyle: 'none',
                            height: '24px',
                            minHeight: '24px',
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                    }}
                />
            )}
        </div>
    );
};

export default VehicleModelSelector;
