// TextAnalyzer.js

// Function to check if text contains the word
export const containsWord = (word, text) => {
    // Convert both the word and text to lowercase for case-insensitive comparison
    const lowerCaseWord = word.toLowerCase();
    const lowerCaseText = text.toLowerCase();

    // Check if the text contains the word
    return lowerCaseText.includes(lowerCaseWord);
};

