import React from 'react';

const Arrow = ({ className, style, onClick, direction }) => {
    const isNext = direction === 'next';
    const positionStyle = isNext ? { right: '20px' } : { left: '20px' };

    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                borderRadius: '50%',
                zIndex: '1',
                position: 'absolute',
                top: '50%', // Vertically center
                transform: direction === 'next' ? 'translateY(-50%)' : 'translate(-50%, -50%)', // Adjust vertical position and direction
                ...positionStyle, // Apply position style
                cursor: 'pointer',
                fontSize: '38px',
                fontWeight: 'bold',
                color: '#4b9ef1'
            }}
            onClick={onClick}
        >
            {isNext ? '>' : '<'}
        </div>
    );
};

export default Arrow;
