import React from "react";
import { Link } from "react-router-dom";

function PosterCard(props) {
    const cardTitle = props.cardTitle;
    const cardContent = props.cardContent;
    const cardLink = props.cardLink;
    const cardImg = props.cardImg;

    return (
        <div className="col-sm-6 wow fadeIn">
            <article className="box-minimal">
                <Link to={cardLink} className="nav-button" style={{ textDecoration: "none", color: "inherit", whiteSpace: 'inherit' }}>
                    <span className=""><img src={cardImg} alt="icon" style={{ width: '140px', height: '140px' }} /></span>
                    <h4 className="box-minimal-title">{cardTitle}</h4>
                    <div className="box-minimal-divider"></div>
                    <p>{cardContent}</p>
                </Link>
            </article>
        </div>
    )
}

export default PosterCard;