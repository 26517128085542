import React, { useState, useEffect } from "react";
import { useLanguage } from './languageContext';
import { containsWord } from './textAnylizer';


function SearchDemand(props) {
    const { currentLanguage } = useLanguage();
    const [inputValueDemandType, setInputValueDemandType] = useState("");
    const [inputValueWord, setInputValueWord] = useState("");

    const [showTooltip, setShowTooltip] = useState(false);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Call onFilterSubmit whenever there's a change in input values
        props.onFilterSubmit(getFilteredData());
    }, [
        inputValueDemandType,
        inputValueWord,
        props.listedDemands,
    ]);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };


    const handleInputDemandTypeChange = (e) => {
        setInputValueDemandType(e.target.value);
    };
    const handleInputValueChangeWord = (e) => {
        setInputValueWord(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFilter();
    };

    const setFilter = () => {
        props.onFilterSubmit(getFilteredData());
    };

    const getFilteredData = () => {
        const filterCriteria = {
            demandtype: inputValueDemandType,
            subject: inputValueWord,
            comment: inputValueWord,
        };

        return props.listedDemands.filter((item) => {
            for (const key in filterCriteria) {
                if (filterCriteria[key] === "Any" || filterCriteria[key] === "") {
                    continue;
                }
                let filterValue = filterCriteria[key]?.toString().toLowerCase(); // Convert filter value to lowercase
                const itemValue = item[key]?.toString().toLowerCase(); // Convert item value to lowercase
                const commnetValue = item["comment"]?.toString().toLowerCase();

                if (key === "demandtype" && filterValue !== itemValue) {
                    return false;
                }

                if (key === "subject") {
                    if (filterValue.startsWith('"') && filterValue.endsWith('"')) {
                        if (containsWord(filterValue.substring(1, filterValue.length - 1), itemValue)) {
                            return true; // If filter value found in the subject text, keep the item
                        } else if (containsWord(filterValue.substring(1, filterValue.length - 1), commnetValue)) {
                            return true; // If filter value found in the comment text, keep the item
                        } else {
                            return false;
                        }

                    } else {
                        filterValue = filterValue.replace(/[^\w\s"^\u10D0-\u10F9]/gu, '');
                        const words = filterValue.substring(0, filterValue.length).split(/\s+/);
                        // Check if any word from filterValue is present in itemValue
                        if (words.some(word => containsWord(word, itemValue))) {
                            return true; // If at least one word is found in itemValue, keep the item
                        } else if (words.some(word => containsWord(word, commnetValue))) {
                            return true; // If filter value found in the comment text, keep the item
                        } else {
                            return false; // If filter value not found in the comment text, filter out the item
                        }

                    }
                }


            }
            return true;
        });
    }

    const handleReset = () => {
        props.onFilterSubmit(props.listedDemands);
        setInputValueDemandType("");
        setInputValueWord("");
    };

    return <div>
        <div>
            <div className="divider-section">
            </div>
            {/* <section className="section section-xs bg-gray-12"> */}
            <section className="section section-xs bg-gray-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <div className="container">
                    <div className="row row-50">
                        <div className="col-lg-12">
                            {/* <article className="block-property bg-default"> */}
                            <article className="block-property bg-default" style={{ padding: '20px' }}>
                                <div className="layout-3">
                                    <h3 hidden={languageToUse !== "ge"}>მოძებნეთ სასურველი განცხადება</h3>
                                    <h3 hidden={languageToUse !== "en"}>Find posts on demands</h3>
                                    <h3 hidden={languageToUse !== "ru"}>Найти объявления по требованиям</h3>
                                    <div className="layout-3-item">
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}
                                    className="rd-mailform form-property mt-30"
                                    data-form-output="form-output-global"
                                    data-form-type="contact"
                                    method=""
                                    action=""
                                >
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            <select hidden={languageToUse !== "ge"} className="form-input select-filter" name="interior" value={inputValueDemandType} onChange={handleInputDemandTypeChange}>
                                                <option value="Any">აირჩიეთ მოთხოვნის კატეგორია</option>
                                                <option value="lookingfor">ვეძებ</option>
                                                <option value="willbuy">შევიძენ</option>
                                                <option value="willrent">ვიქირავებ</option>
                                                <option value="other">სხვა მოთხოვნები</option>
                                            </select>
                                            <select hidden={languageToUse !== "en"} className="form-input select-filter" name="interior" value={inputValueDemandType} onChange={handleInputDemandTypeChange}>
                                                <option value="Any">Select demand category</option>
                                                <option value="lookingfor">I am looking for</option>
                                                <option value="willbuy">I will buy</option>
                                                <option value="willrent">I will rent</option>
                                                <option value="other">Other</option>
                                            </select>
                                            <select hidden={languageToUse !== "ru"} className="form-input select-filter" name="interior" value={inputValueDemandType} onChange={handleInputDemandTypeChange}>
                                                <option value="Any">Выбрать категорию</option>
                                                <option value="lookingfor">Ищу</option>
                                                <option value="willbuy">Куплю</option>
                                                <option value="willrent">Сдам в аренду</option>
                                                <option value="other">Другие</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            {/* <input className="form-input" name="mileage" placeholder="სიტყვა განცხადებაში" value={inputValueWord} onChange={handleInputValueChangeWord}>
                                                </input> */}
                                            {languageToUse === "ge" && (
                                                <div className="tooltip-container">
                                                    <input
                                                        className="form-input"
                                                        name="mileage"
                                                        placeholder="სიტყვა განცხადებაში"
                                                        value={inputValueWord}
                                                        onChange={handleInputValueChangeWord}
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                    />
                                                    {showTooltip && <div className="tooltip-comment" style={{ fontSize: "9px", marginLeft: "6px" }}>გამოიყენეთ "" ზუსტი ფრაზის მოსაძებნად</div>}
                                                </div>
                                            )}
                                            {languageToUse === "en" && (
                                                <div className="tooltip-container">
                                                    <input
                                                        className="form-input"
                                                        name="mileage"
                                                        placeholder="A word in the post"
                                                        value={inputValueWord}
                                                        onChange={handleInputValueChangeWord}
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                    />
                                                    {showTooltip && <div className="tooltip-comment" style={{ fontSize: "9px", marginLeft: "6px" }}>Utilize "" for precise phrase search</div>}
                                                </div>
                                            )}
                                            {languageToUse === "ru" && (
                                                <div className="tooltip-container">
                                                    <input
                                                        className="form-input"
                                                        name="mileage"
                                                        placeholder="Слово в объявлении"
                                                        value={inputValueWord}
                                                        onChange={handleInputValueChangeWord}
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                    />
                                                    {showTooltip && <div className="tooltip-comment" style={{ fontSize: "9px", marginLeft: "6px" }}>Используйте "" для точного поиска по фразе.</div>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-wrap form-wrap-validation">
                                        <div className="">
                                            <button
                                                className="button button-md button-secondary-val form-input"
                                                type="button" onClick={handleReset}
                                            >
                                                <span hidden={languageToUse !== "ge"}>ფილტრის მოხსნა</span>
                                                <span hidden={languageToUse !== "en"}>Remove a filter</span>
                                                <span hidden={languageToUse !== "ru"}>Удалить фильтр</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
}
export default SearchDemand;