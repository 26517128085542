import backendUrl from './config';

var responseMessage = "";

async function sendEmail(from, name, phone, subject, text, itemId, address, product, activity) {
  const mailOptions = {
    from,
    name,
    phone,
    subject,
    text,
    itemId,
    address,
    product,
    activity
  };

  try {

    // Validate mandatory fields
    if (!product || !activity) {
      responseMessage = "Please fill in all mandatory fields and upload at least one file.";
      return; // Stop further execution
    }

    const response = await fetch(`${backendUrl}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mailOptions),
    });

    if (response.status === 200) {
      const responseData = await response.json();
      responseMessage = responseData.message;
    } else {
      const errorData = await response.json();
      responseMessage = `Error: ${errorData.message}`;
    }
  } catch (error) {
    responseMessage = "Error sending data to the server.";
  }
}

export { sendEmail };