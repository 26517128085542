import React, { useState, useEffect } from "react";
import GetInTouch from "./getInTouch";
import { useLocation } from 'react-router-dom';
import PopUpButton from "./popUpButton";
import TwoLastListings from "./twoLastListings";
import PhotoCarousel from "./photoCarusel";
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useLanguage } from './languageContext';
import ItemDetailsPopUp from "./itemDetalsPopUp";
import SubMenue from "./subMenue";

function About({ onItemClick, listingId }) {
    const { currentLanguage } = useLanguage();
    const [firstTabOurMIssion, setFirstTabOurMIssion] = useState("false");
    const [secondTabOurGoal, setSecondTabOurGoal] = useState("false");
    const [thirdTabAboutUs, setThirdTabAboutUs] = useState("true");


    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // handle ItemDetailsPopUp start

    const [showItemDetails, setShowItemDetails] = useState(false);
    const [selectedListingId, setSelectedListingId] = useState(null);

    const handleItemClick = (listingId) => {
        setSelectedListingId(listingId);
        setShowItemDetails(true);
    };

    // handle ItemDetailsPopUp end

    const handleItemClose = () => {
        setShowItemDetails(false);
    };

    const location = useLocation();
    // const { search } = location;

    useEffect(() => { refreshState(); }, [
    ]);

    function refreshState() {
        setFirstTabOurMIssion(false);
        setSecondTabOurGoal(false);
        setThirdTabAboutUs(true);
    }

    const handleFirstTabToggle = () => {
        if (!firstTabOurMIssion) {
            setFirstTabOurMIssion(!firstTabOurMIssion);
        }
        if (secondTabOurGoal) {
            setSecondTabOurGoal(!secondTabOurGoal);
        }
        if (thirdTabAboutUs) {
            setThirdTabAboutUs(!thirdTabAboutUs);
        }
    }

    const handleSecondTabToggle = () => {
        if (firstTabOurMIssion) {
            setFirstTabOurMIssion(!firstTabOurMIssion);
        }
        if (!secondTabOurGoal) {
            setSecondTabOurGoal(!secondTabOurGoal);
        }
        if (thirdTabAboutUs) {
            setThirdTabAboutUs(!thirdTabAboutUs);
        }
    }

    const handleThirdTabToggle = () => {
        if (firstTabOurMIssion) {
            setFirstTabOurMIssion(!firstTabOurMIssion);
        }
        if (secondTabOurGoal) {
            setSecondTabOurGoal(!secondTabOurGoal);
        }
        if (!thirdTabAboutUs) {
            setThirdTabAboutUs(!thirdTabAboutUs);
        }
    }



    return (<div>
        {languageToUse === "ge" && (
            <div className="container">
                {/* <!-- Breadcrumbs--> */}
                <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="59">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">ჩვენს შესახებ</h2>
                    </div>
                </section>
                <SubMenue product={"realestate"} />
                <div className="divider-section"></div>
                {/* <!-- Shortly About Us--> */}
                <section className="section section-md bg-default">
                    <div className="container">
                        <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                        <article className="box-info box-info-1">
                            {showItemDetails && <ItemDetailsPopUp listingId={selectedListingId} onClick={handleItemClose} />}
                        </article>
                        {/* <!-- Box Info--> */}
                        <article className="box-info box-info-1">
                            <div className="box-info-aside">
                                <TwoLastListings onItemClick={handleItemClick} listingId={selectedListingId} />
                            </div>
                            <div className="box-info-main context-dark">
                                <div className="box-info-main-inner">
                                    <h2 className="f1">უძრავი ქონების ბაზარზე 2018 წლიდან</h2>
                                    <div className="divider-small"></div>
                                    {/* <!-- Bootstrap tabs--> */}
                                    <div className="tabs-custom tabs-horizontal tabs-line" id="tabs-1">
                                        {/* <!-- Nav tabs--> */}
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item" onClick={handleFirstTabToggle} role="presentation">
                                                <button
                                                    className={`nav-link ${firstTabOurMIssion ? 'active' : ''}`}
                                                    style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                >
                                                    ჩვენი მისია
                                                </button>
                                            </li>
                                            <li className="nav-item" onClick={handleSecondTabToggle} role="presentation">
                                                <button
                                                    className={`nav-link ${secondTabOurGoal ? 'active' : ''}`}
                                                    style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                >
                                                    ჩვენი მიზანი
                                                </button>
                                            </li>
                                            <li className="nav-item" onClick={handleThirdTabToggle} role="presentation">
                                                <button
                                                    className={`nav-link ${thirdTabAboutUs ? 'active' : ''}`}
                                                    style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                >
                                                    ჩვენს შესახებ
                                                </button>
                                            </li>
                                        </ul>
                                        {/* <!-- Tab panes--> */}
                                        <div className="tab-content">
                                            <div className={`tab-pane fade ${firstTabOurMIssion ? 'active show' : ''}`} id="tabs-1-1">
                                                <p>ჩვენი ბიზნეს მისიაა საცხოვრებლის არჩევანის, ეკონომიკური სიცოცხლისუნარიანობისა და ხელმისაწვდომობის გაზრდა. </p>
                                                <p>ჩვენ ვცდილობთ ქართველი ემიგრანტებისთვის ხელმისაწვდომი გავხადოთ სასიცოცხლო მნიშვნელობის მქონე საცხოვრებელი და სამუშაო ადგილები.  </p>
                                                <p>ჩვენ გვჯერა, რომ ყველას უნდა ჰქონდეს წვდომა უძრავი ქონების ბაზარზე, მიუხედავად, სტატუსისა, ეკონომიკური მრგომარეობისა და სოციალური ინტეგრაციის ხარისხისა.</p>
                                            </div>
                                            <div className={`tab-pane fade ${secondTabOurGoal ? 'active show' : ''}`} id="tabs-1-2">
                                                <p>ჩვენი მიზანია ქართველ ემიგრანტებს დავეხმაროთ განათავსონ და მოძებნონ განცხადებები გასაქირავებელი საცხოვრებელი ფართის შესახებ, ასევე საცხოვრებლის ყიდვა-გაყიდვასა და იპოთეკური სესხით შეძენაზე.</p>
                                                <p>ჩვენი მიზანია დახმარება გაგიწიოთ დაიწყოთ ბიზნესი უძრავი ქონების სფეროში, ჩვენ დაგეხმარებით იქირაოთ, გასაქირავებლად მოაწყოთ და გააქირაოთ საცხოვრებელი ფართები. ჩვენ ამის დიდი გამოცდილება გვაქვს.</p>
                                                <p>ამისთვს შეიქმნა სრულიად ქართულ ენაზე ფუნქციონირებადი პლათფორმა, რომელიც გაქირავების გარდა მომხმარებლებს შესთავაზებს ყიდვა-გაყიდვისა და იპოთეკური სესხებით ვაჭრობის შესაძლებლობას.</p>
                                            </div>
                                            <div className={`tab-pane fade ${thirdTabAboutUs ? 'active show' : ''}`} id="tabs-1-3">
                                                <p>2018 წლიდან შევძელით 4000-ზე მეტი გასაქირავებელი ფართის მოწყობა და ბაზარზე გატანა. ჩვენი კლიენტები არიან პლათფომები, რომლებიც მოკლე და საშუალო ვადიან პირობებით აქირავებენ საცხოვრებელ ფართებს მსხვილ ქალქებში. </p>
                                                <p>ჩვენ ვაგროვებთ გამოცდილებას და წარმატებულ პროექტებს ვნერგავთ ქართველი მიგრანტებისთვის. ჩვენი პლატფორმა საშუალებას აძლევს ქართველ ემიგრანტებს სათანადოდ მოაწყონ და შეთავაზონ საცხოვრებლები თანამემამულეებს. </p>
                                                <p>ჩვენ ასევე გთავაზობთ უძრავ ქონებასთან დაკავშირებულ ყველა საველე სერვისებს: რემონტებს, ფართების გაქირავებისთვის უფრო მოსახერხებლად მოწყობას, ავეჯის შესყიდვა-მიწოდება-აწყობას, სამგანზომილებიანი ვირტუალური ტურების გადაღებას, გადაუდებელი შერემონტებისთვის სპეცილისტებით მომსახურებას, ახალი კლიენტების მიღებისთვის დასუფთავებას და სხვა სერვისებს. </p>
                                                <p>ჩვენ ვსწავლობთ თქვენს საჭიროებებს და ვეძებთ გზებს ეფექტურად მოვაგვაროთ ისინი, შესაბამისად თქვენი უკუკავშირი მნიშვნელოვნად გვეხმარება გავაუმჯობესოთ ასრებული და შევქმნათ ახალი სერვისები. </p>
                                                {/* <ul className="list-marked-1 cols-2">
                                                <li>Properties for Sale and Exchange</li>
                                                <li>Listing your assets</li>
                                                <li>Exclusive deals</li>
                                                <li>Your asset valuation</li>
                                            </ul> */}
                                            </div>
                                        </div>
                                        <ScrollLink
                                            className="button button-sm button-secondary"
                                            to="sendEmailFormAbout"
                                            smooth={true}
                                            duration={500}
                                        >
                                            გამოგვეხმაურეთ
                                        </ScrollLink>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                {/* <!-- Features--> */}
                <section className="section section-lg bg-default">
                    <div className="container">
                        <div className="row row-flex row-30 justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                {/* <!-- Box Modern--> */}
                                {/* <article className="box-modern"><span className="icon box-modern-icon fl-bigmug-line-big104"></span> */}
                                <article className="box-modern"><span><img src="./images/buy-home.png" alt="buy-home" className="icon-val-lg" /></span>
                                    <div className="box-modern-main">
                                        <h4 className="box-modern-title">უნიკალური შესაძლებლობები</h4>
                                        <p></p>
                                        <p>განავითარეთ თქვენი ბიზნესი ქართულენოვანი პლათფორმის გამოყენებით და შესთავაზეთ უფრო მეტი ფართები საცხოვრებლის მაძიებლებს.</p>
                                    </div>
                                </article>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                {/* <!-- Box Modern--> */}
                                <article className="box-modern"><span className="icon-val"><img src="./images/a-3.png" alt="a-3" className="icon-val-lg" /></span>
                                    <div className="box-modern-main">
                                        <h4 className="box-modern-title">ბინის მაძიებლებისთვის მოსახერხებელი ძიების სისტემა</h4>
                                        <p></p>
                                        <p>პლათფორმაზე ერთ ადგილზე თავმოყრილია სხვადასხვა არჩევანი, შეგიძლიათ მონიშნოთ სასურველი განცხადებები და თვალი ადევნოთ მათ, მიიღოთ კონსულტაციები, იქირაოთ და იცხოვროთ ან გადააქირაოთ საცხოვრებელი ფართები თქვენი გეგმების მიხედვით. </p>
                                    </div>
                                </article>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                {/* <!-- Box Modern--> */}
                                <article className="box-modern"><span><img src="./images/sale-min.png" alt="sale-min" className="icon-val-lg" /></span>
                                    <div className="box-modern-main">
                                        <h4 className="box-modern-title">დამატებითი სერვისები</h4>
                                        <p>თქვენი მოთხოვნის მიხედვით, ჩვენი საველე სპეცილისტები მზად არიან გასაქირავებლად მოგიწყობთ ფართები, ეს იქნება ოთახების გადაწყობა, ტიხრების გადატანა, ავეჯის შეძენა, მოტანა, აწყობა და შემდეგ გადაუდებელ შეკეთებებისთბის ვიზიტების გაკეთება. </p>
                                        <p>თქვენი მოთხოვნის მიხედვით ჩვენ გადავიღებთ სამ განზომილებიან ვირტუალურ მედიას, რომელიც საშუალებას მისცემს დამქირავებლებს დაათვალიერონ ბინები და ოთახები შორიდან, რაც მნიშვნელოვნად დაზოგავს გამქირავებლის და მქირავებლის დროს. </p>
                                        <p>ვირტუალური ტურის გადაღება დაჯდება $100, მისი შენახვა თვეში $4, როცა არ დაგჭირდებათ მედია, შეიძლება მისი დაპაუზება და შესაბამისად უსასყიდლოდ შენახვა მომავალი საჭიროებისთვის.</p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                <h2 className="heading-decoration-1"><span className="heading-inner">დაიწყეთ ან გააფართოვეთ უძრავი ქონების ბიზნესი ჩვენთან ერთად</span></h2>
                {/* <!-- Subscribe to Our Newsletter--> */}
                {/* <Subscribe /> */}
                {/* <!-- Partners--> */}
                <section className="section section-lg bg-default">
                    <div className="">
                        {/* <!-- Owl Carousel--> */}
                        <PhotoCarousel />
                    </div>
                </section>

                {/* this section for future use */}
                <article className="box-info box-info-1">
                    <div className="box-info-aside">
                        <div className="row row-30 row-flex">
                            <div className="col-sm-6 wow fadeIn">
                                {/* <!-- Box Minimal--> */}
                                {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-square160"></span> */}
                                <article className="box-minimal"><span><img src="./images/agent-bg.png" alt="sale-min" className="icon-val" /></span>
                                    <h4 className="box-minimal-title">ნაბიჯი 1: განცხადების ატვირთვა</h4>
                                    <div className="box-minimal-divider"></div>
                                    <p>დარეგისტრირდით ვებ გვერდზე და მოძებნეთ "განცხადების ატვირთვა" მენიუში "განცხადებების მართვა". თქვენ დაინახავთ ფორმას რომელიც უნდა შეავსოთ სრულიად და ზუსტად. ფორმაში მითითებულია თუ რა ინფორმაცია მოითხოვება.</p>
                                    <p>ველების უმრავლესობა სავალდებულოა. მისმართი აუცილებელია შეივსოს ზუსტად, რათა შესაძლებელი იყოს მისი მიგნება რუკაზე. არასწორი მისამართი გამოიწვევს დაინტერესებული კლიენტების დაბნეულობას, საბოლოოდ მათ შესაძლო დაკარგვას და თვენი ძვირფასი დროის მოცდენას.</p>
                                    <p>აუცილებელია ატვირთოთ ერთი სურათი მაინც. რეკომენდირებულია სამგანზომილბიანი ვირტუალური ტურის გადაღება მათთვის ვინც არაერთხელ გეგმავს ფართის გაქირავებას. სამგანზომილებიანი მედია საშუალებას იძლევა შორიდან მოხდეს ოთახის დეტალური დათვალიერება. </p>
                                    <p>იმ შემთხვევაში თუ ფართი საჭიროებს რემონტს ან ავეჯით მოწყობას, ჩვენ საველე გუნდს შეუძლია ეკონომიურ ფასებში და უსწრაფესად გაგიწიოეთ საჭირო მომსახურება.</p>
                                </article>
                            </div>
                            <div className="col-sm-6 wow fadeIn" data-wow-delay=".05s">
                                {/* <!-- Box Minimal--> */}
                                {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-cellphone55"></span> */}
                                <article className="box-minimal"><span><img src="./images/right-arrow-o.png" alt="sale-min" className="icon-val" /></span>
                                    <h4 className="box-minimal-title">ნაბიჯი 2: გამოაქვეყნეთ განცხადება</h4>
                                    <div className="box-minimal-divider"></div>
                                    <p>მას შემდეგ რაც ატვირთავთ განცხადებას ის გამოჩნდება თქვენს სამუშაო გვერდზე სადაც შეგიძლით შეხვიდეთ ნებისმიერ დროს. ამისთვის მოძებნეთ "ჩემი განცხადებები" "განცხადებების მართვის" მენიუში.</p>
                                    <p>გადახედეთ განცხადებას. თუ ის საჭიროებს გასწორებას მიმართეთ ჩვეს წარმომადგენელს. ამისათვის დააწკაპუნეთ თქვენი განცხადების ფოტოზე, რის შემდეგაც გადახვალთ თქვენი ფართის გვერდზე, იქ მარჯვენა მხარეს იპოვით "მოითხოვეთ კონსულტაცია ან საცხოვრებლის ნახვა"-ს და მოიწრეთ.</p>
                                    <p>მას შემდეგ რაც განცხადება მზად არის გამოსაქვეყნებლად დააჭირეთ ცისფერ ვირტულურ ღილაკს წარწერით "გამოქვეყნება". ის გადაგიყვანთ გვერდზე რომელიც უფასოდ გთავაზობთ გამოქვეყნებას ერთი თვით. დაეთანხმეთ და თქვენი განცხადება გამოქვეყნდება მთავარ გვერდზე, იქ სადაც ვებ გვერდის ყვლა მნახველი შეძლებს იპოვოს ის, მიუხედავად იმისა დარეგისტრირებულია მომხმარებელი თუ არა. </p>
                                </article>
                            </div>
                            <div className="col-sm-6 wow fadeIn">
                                {/* <!-- Box Minimal--> */}
                                {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-equalization3"></span> */}
                                <article className="box-minimal"><span><img src="./images/right-arrow-o.png" alt="sale-min" className="icon-val" /></span>
                                    <h4 className="box-minimal-title">ნაბიჯი 3: გამოქვეყნების შემდეგი ნაბიჯები</h4>
                                    <div className="box-minimal-divider"></div>
                                    <p>დაინტერსებული კლიენტი მოძებნის თქვენს განცხადებას და დაგიკავშირდებათ თქვენს მიერ მითითებულ ფოსტაზე ან ტელეფონის ნომერზე.</p>
                                    <p>ერთი თვის შემდეგ განცხადება გადავა პასიურ რეჟიმში, იმ შემთხვევაში თუ მისი განახლება არ მოხდება. განახლებისათვის თვენ უნდა შეხვიდეთ "ჩემ განცხადებებში" და დააწკაპუნოთ ღილაკზე "გამოქვეყნება". ეს გადაგიყვანთ გვერდზე რომელიც შემოგთავაზებთ თვეში 7.99 დოლარად განცხადების განახლებას. ეს თანხები საჭიროა რათა შევინახოთ ვებ გვერდი და დავფაროთ ხარჯები. მადლობა მხარდაჭერისთვის.</p>
                                    <p></p>
                                </article>
                            </div>
                            <div className="col-sm-6 wow fadeIn" data-wow-delay=".05s">
                                {/* <!-- Box Minimal--> */}
                                {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-two316"></span> */}
                                <article className="box-minimal"><span><img src="./images/circle.png" alt="sale-min" className="icon-val" /></span>
                                    <h4 className="box-minimal-title">ნაბიჯი 4: გაქირავების შემდგომი აქტივობები</h4>
                                    <div className="box-minimal-divider"></div>
                                    <p>შესაძლებელია გაქირავებულ ფართში წარმოიშვას რაიმე პრობლემა და ადგილზე თქვენი არ ყოფნის შემთხვევაში საჭირო გაგიხდეთ გარე დახმარების ძიება. ჩვენ შეგვიძლია მომენტალურად გავაგზავნოთ ჩვენი ოსტატი მისამართზე გადაუდებელი დახმარებისთვის. </p>
                                    <p>ამისთვის თქვენ შეგიძლიათ დაგვიკავშირდეთ ტელეფონით ან მოიწეროთ ელექტრონული ფოსტით. ჩვენი თანამშრომელი შექმნის დავალებას და ჩვენი ოსტატი გადაუდებელ შემთხვევაში იმ დღესვე მივა ადგილზე, სხვა შემთხვევაში თქვენთან შეთანხმებით დაინიშნება ვიზიტი. ოსტატის ანაზღაურება არის საათობობრივი. საშუალოდ ასეთი ვიზიტი ითხოვს 2 საათს. 1 საათი სერვისის ღირებულება არის $28. ჩვენი ოსტატი აღჭურვილია ვენით.</p>
                                    <p>თუ თქვენ გაგაჩნიათ ბევრი გასაქირავებელი ფართი, ჩვენ უფასოდ გაგიხსნით ანგარიშს ჩვენ პლათფორმაზე "Super Team" სადაც შეგეძლებათ თქვენ თვითონ შექმნათ დავალებები და აკონტროლოთ მათი შესრულება.</p>
                                </article>
                            </div>

                        </div>
                    </div>
                    <div id="sendEmailFormAbout">
                        <GetInTouch />
                    </div>
                </article>
                <section className="section section-lg bg-default">
                    <div className="container">
                    </div>
                </section>
                {/* <section className="section section-md bg-default">
                <div className="container">
                    <h2 className="heading-decoration-1"><span className="heading-inner">Our Agents</span></h2>
                    <div className="row row-30">
                        <div className="col-sm-6 col-lg-3">
                            <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                <img src="images/" alt="" width="540" height="460" />
                                <div className="block-agent-body">
                                    <h3 className="block-agent-title">Name</h3>
                                    <p>Certified Real Estate Broker</p>
                                </div>
                            </button>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                <img src="images/" alt="" width="540" height="460" />
                                <div className="block-agent-body">
                                    <h3 className="block-agent-title">Name</h3>
                                    <p>Certified Real Estate Broker</p>
                                </div>
                            </button>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                <img src="images/" alt="" width="540" height="460" />
                                <div className="block-agent-body">
                                    <h3 className="block-agent-title">Name</h3>
                                    <p>Certified Real Estate Broker</p>
                                </div>
                            </button>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                <img src="images/" alt="" width="540" height="460" />
                                <div className="block-agent-body">
                                    <h3 className="block-agent-title">Name</h3>
                                    <p>Certified Real Estate Broker</p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </section> */}
            </div>
        )}
        {languageToUse === "en" && (
            <div>
                <div className="container">

                    {/* <!-- Breadcrumbs--> */}
                    <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="59">
                        <div className="container">
                            <h2 className="breadcrumbs-custom-title">About Us</h2>
                        </div>
                    </section>
                    <SubMenue product={"realestate"} />
                    <div className="divider-section"></div>
                    {/* <!-- Shortly About Us--> */}
                    <section className="section section-md bg-default">
                        <div className="container">
                            <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                            <article className="box-info box-info-1">
                                {showItemDetails && <ItemDetailsPopUp listingId={selectedListingId} onClick={handleItemClose} />}
                            </article>
                            {/* <!-- Box Info--> */}
                            <article className="box-info box-info-1">
                                <div className="box-info-aside">
                                    <TwoLastListings onItemClick={handleItemClick} listingId={selectedListingId} />
                                </div>
                                <div className="box-info-main context-dark">
                                    <div className="box-info-main-inner">
                                        <h2 className="f1">Enhancing real estate investment feasibility since 2018</h2>
                                        <div className="divider-small"></div>
                                        {/* <!-- Bootstrap tabs--> */}
                                        <div className="tabs-custom tabs-horizontal tabs-line" id="tabs-1">
                                            {/* <!-- Nav tabs--> */}
                                            <ul className="nav nav-tabs">
                                                <li className="nav-item" onClick={handleFirstTabToggle} role="presentation">
                                                    <button
                                                        className={`nav-link ${firstTabOurMIssion ? 'active' : ''}`}
                                                        style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                    >
                                                        Our mission
                                                    </button>
                                                </li>
                                                <li className="nav-item" onClick={handleSecondTabToggle} role="presentation">
                                                    <button
                                                        className={`nav-link ${secondTabOurGoal ? 'active' : ''}`}
                                                        style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                    >
                                                        Our goal
                                                    </button>
                                                </li>
                                                <li className="nav-item" onClick={handleThirdTabToggle} role="presentation">
                                                    <button
                                                        className={`nav-link ${thirdTabAboutUs ? 'active' : ''}`}
                                                        style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                    >
                                                        About Us
                                                    </button>
                                                </li>
                                            </ul>
                                            {/* <!-- Tab panes--> */}
                                            <div className="tab-content">
                                                <div className={`tab-pane fade ${firstTabOurMIssion ? 'active show' : ''}`} id="tabs-1-1">
                                                    <p>Our business mission is to enhance the economic viability and affordability of real estate and housing options. </p>
                                                    <p>Our groundbreaking product represents a proprietary solution aimed at fostering a dynamic real estate sector. Our goal is to ensure the resilience of the market, making it worthwhile even during economic downturns, and resistant to fluctuations in interest rates and inflation. </p>
                                                    <p>We believe that everyone should have access to the market, irrespective of economic cycles and associated factors.</p>
                                                </div>
                                                <div className={`tab-pane fade ${secondTabOurGoal ? 'active show' : ''}`} id="tabs-1-2">
                                                    <p>Facilitating access to secure and economically sound transaction frameworks to enhance market appeal for both buyers and sellers. </p>
                                                    <p>A liberated free real estate marketplace catering to renters, sellers, tenants, and buyers. </p>
                                                    <p>Enhancing accessibility to services intertwined with this dynamic market. </p>
                                                    <p>New product - Recognizing mortgage loans as a valuable asset, establishing a marketplace, and facilitating trading opportunities.</p>
                                                    <p>Providing alternatives for sellers and buyers in situations where the perceived value of selling or buying property may not seem justifiable, despite the imperative to progress.</p>
                                                </div>
                                                <div className={`tab-pane fade ${thirdTabAboutUs ? 'active show' : ''}`} id="tabs-1-3">
                                                    <p>Since 2018, we have been able to arrange and market more than 4,000 rental spaces. Our clients are platforms that rent short- and medium-term residential premises in major cities. </p>
                                                    <p>We gather experience and implement successful projects in real estate market. Our platform and services allows those who has extra space or wants to invest in short, medium and long term rentals set up/upgrade living spaces and offer housing to those who are in need. </p>
                                                    <p>We also offer full stack field services related to real estate: renovations, making spaces more convenient for renting, purchase-delivery-assembly of furniture, shooting of virtual tours, services with specialists for emergency repairs, turnover cleanings and other services. </p>
                                                    <p>We study your needs and look for ways to effectively address them, so your feedback greatly helps us improve existing and create new services. </p>
                                                    {/* <ul className="list-marked-1 cols-2">
                                                        <li>Properties for Sale and Exchange</li>
                                                        <li>Listing your assets</li>
                                                        <li>Exclusive deals</li>
                                                        <li>Your asset valuation</li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                            <ScrollLink
                                                className="button button-sm button-secondary"
                                                to="sendEmailFormAbout"
                                                smooth={true}
                                                duration={500}
                                            >
                                                Get in Touch
                                            </ScrollLink>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </section>
                    {/* <!-- Features--> */}
                    <section className="section section-lg bg-default">
                        <div className="container">
                            <div className="row row-flex row-30 justify-content-center">
                                <div className="col-md-6 col-lg-4">
                                    {/* <!-- Box Modern--> */}
                                    {/* <article className="box-modern"><span className="icon box-modern-icon fl-bigmug-line-big104"></span> */}
                                    <article className="box-modern"><span><img src="./images/buy-home.png" alt="buy-home" className="icon-val-lg" /></span>
                                        <div className="box-modern-main">
                                            <h4 className="box-modern-title">Unique options</h4>
                                            <p>Expand your business by utilizing a platform that accommodates multiple languages. Offer spaces to a wider audience, including those who have recently relocated. We'll assist in background checks for a seamless rental experience.</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    {/* <!-- Box Modern--> */}
                                    <article className="box-modern"><span className="icon-val"><img src="./images/a-3.png" alt="a-3" className="icon-val-lg" /></span>
                                        <div className="box-modern-main">
                                            <h4 className="box-modern-title">An easy-to-use search system for those looking for apartments</h4>
                                            <p>Find various options all in one place on the platform. You can bookmark the announcements you like, stay updated on them, receive advice, rent a residential space, and live or sublet according to your plans. </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    {/* <!-- Box Modern--> */}
                                    <article className="box-modern"><span><img src="./images/sale-min.png" alt="sale-min" className="icon-val-lg" /></span>
                                        <div className="box-modern-main">
                                            <h4 className="box-modern-title">Additional services</h4>
                                            <p>Our field specialists are prepared to set up your rental space according to your preferences. This includes rearranging rooms, moving partitions, purchasing furniture, delivering, assembling, and addressing emergency repair needs as requested. </p>
                                            <p>Upon your request, we will create virtual tours, enabling tenants to remotely view apartments and rooms. This will greatly save both the landlord's and tenant's time.</p>
                                            <p>The virtual tour is priced at $100, with a $4 monthly fee for storage. If you don't currently need the media, you can pause it and store it for free to use in the future.</p>

                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </section>
                    <h2 className="heading-decoration-1"><span className="heading-inner">Let's make it</span></h2>
                    {/* <!-- Subscribe to Our Newsletter--> */}
                    {/* <Subscribe /> */}
                    {/* <!-- Partners--> */}
                    <section className="section section-lg bg-default">
                        <div className="">
                            {/* <!-- Owl Carousel--> */}
                            <PhotoCarousel />
                        </div>
                    </section>

                    {/* this section for future use */}
                    <article className="box-info box-info-1">
                        <div className="box-info-aside">
                            <div className="row row-30 row-flex">
                                <div className="col-sm-6 wow fadeIn">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-square160"></span> */}
                                    <article className="box-minimal"><span><img src="./images/agent-bg.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Step 1: Publish a Property and Consult with Our Team</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>Sign up on the website and find "Upload Listing" in the "Manage Listings" menu. You'll encounter a form that needs to be completed accurately, indicating the required information. </p>
                                        <p>Most fields are mandatory, and it's crucial to fill them in accurately to ensure visibility on the map. Providing an incorrect address could confuse potential customers, leading to potential loss and wasting months of valuable time.</p>
                                        <p>Make sure to upload at least one image. For those considering renting the space multiple times, it's advisable to opt for a three-dimensional virtual tour. This allows a detailed view of the room from a distance.</p>
                                        <p>If the space requires repairs or furnishings, our field team can offer these services at cost-effective prices and promptly.</p>
                                        <p>We engage in asset valuation to help determine a suitable asking price, ensuring alignment with your financial objectives.</p>
                                        <p>Additionally, we establish a secure deal structure for your convenience.</p>
                                    </article>
                                </div>
                                <div className="col-sm-6 wow fadeIn" data-wow-delay=".05s">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-cellphone55"></span> */}
                                    <article className="box-minimal"><span><img src="./images/right-arrow-o.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Step 2: Potential Renters Contact Our Agents or your Listing Agents</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>Once you upload your application, it will be visible on your "My Listings" page, accessible anytime. To find it, navigate to "My listings" in the "Manage listings" menu.</p>
                                        <p>Review the statement, and if corrections are needed, reach out to our representative. Click on the photo of your ad to go to your space's page. On the right side, locate "Request a consultation or view the apartment" and mark it.</p>
                                        <p>When the ad is ready for publication, click the blue virtual "Publish" button. You'll be directed to a page offering free publishing for one month. Accept, and your ad will appear on the home page, visible to average web visitors, whether registered or not.</p>
                                        <p>Our agents will execute a comprehensive marketing strategy to attract potential buyers, utilizing online listings, advertisements, and networking.</p>
                                        <p>Handle inquiries from potential buyers by providing necessary information and scheduling property viewings.</p>
                                        <p>Upon receiving interest from potential buyers, our team will collaborate with you to create a deal schedule.</p>
                                    </article>
                                </div>
                                <div className="col-sm-6 wow fadeIn">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-equalization3"></span> */}
                                    <article className="box-minimal"><span><img src="./images/right-arrow-o.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Step 3: Receive Information about Potential Renters and Finilize the Deal</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>A prospective client will find your ad and reach out to you using the email address or phone number you provided.</p>
                                        <p>After one month, the application will enter passive mode if not renewed. To update, visit "My Listings" and click the "Publish" button. You'll be directed to a page offering to renew your listing for $7.99 per month. These funds contribute to maintaining the website and covering expenses. We appreciate your support.</p>
                                        <p>Receive profiles of potential renters.</p>
                                        <p>Examine the deal schedule.</p>
                                        <p>Negotiate with potential renters to reach a mutually agreeable deal, considering price, terms, and conditions.</p>
                                        <p>Once satisfied, sign the finalized lease agreement</p>
                                        <p></p>
                                    </article>
                                </div>
                                <div className="col-sm-6 wow fadeIn" data-wow-delay=".05s">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-two316"></span> */}
                                    <article className="box-minimal"><span><img src="./images/circle.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Step 4: After Deal Activities</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>There might be issues in the rented space, and if you're not present, you may need external assistance. We can promptly dispatch our expert to the location for emergency help.</p>
                                        <p>To initiate this, reach out to us via phone or email. Our staff will generate a task, and in case of an emergency, our expert will arrive on the same day. Otherwise, a visit will be scheduled as per your agreement. The foreman is paid hourly, and typically, such visits take around 2 hours. The hourly service rate is $28, and our expert comes equipped.</p>
                                        <p>If you have multiple spaces for rent, we offer a complimentary account on our "Super Team" platform. Here, you can create tasks and monitor their progress independently.</p>
                                    </article>
                                </div>

                            </div>
                        </div>
                        <div id="sendEmailFormAbout">
                            <GetInTouch />
                        </div>
                    </article>
                    <section className="section section-lg bg-default">
                        <div className="container">
                        </div>
                    </section>
                    {/* <!-- Agents--> */}
                    {/* <section className="section section-md bg-default">
                        <div className="container">
                            <h2 className="heading-decoration-1"><span className="heading-inner">Our Agents</span></h2>
                            <div className="row row-30">
                                <div className="col-sm-6 col-lg-3">
                              
                                    <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                        <img src="images/" alt="" width="540" height="460" />
                                        <div className="block-agent-body">
                                            <h3 className="block-agent-title">Name</h3>
                                            <p>Certified Real Estate Broker</p>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                               
                                    <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                        <img src="images/" alt="" width="540" height="460" />
                                        <div className="block-agent-body">
                                            <h3 className="block-agent-title">Name</h3>
                                            <p>Certified Real Estate Broker</p>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                              
                                    <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                        <img src="images/" alt="" width="540" height="460" />
                                        <div className="block-agent-body">
                                            <h3 className="block-agent-title">Name</h3>
                                            <p>Certified Real Estate Broker</p>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                             
                                    <button className="block-agent" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                        <img src="images/" alt="" width="540" height="460" />
                                        <div className="block-agent-body">
                                            <h3 className="block-agent-title">Name</h3>
                                            <p>Certified Real Estate Broker</p>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>
            </div>
        )}
        {languageToUse === "ru" && (
            <div>
                <div className="container">

                    {/* <!-- Breadcrumbs--> */}
                    <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="59">
                        <div className="container">
                            <h2 className="breadcrumbs-custom-title">О нас</h2>
                        </div>
                    </section>
                    <SubMenue product={"realestate"} />
                    <div className="divider-section"></div>
                    {/* <!-- Shortly About Us--> */}
                    <section className="section section-md bg-default">
                        <div className="container">
                            <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                            <article className="box-info box-info-1">
                                {showItemDetails && <ItemDetailsPopUp listingId={selectedListingId} onClick={handleItemClose} />}
                            </article>
                            {/* <!-- Box Info--> */}
                            <article className="box-info box-info-1">
                                <div className="box-info-aside">
                                    <TwoLastListings onItemClick={handleItemClick} listingId={selectedListingId} />
                                </div>
                                <div className="box-info-main context-dark">
                                    <div className="box-info-main-inner">
                                        <h2 className="f1">Повышение рентабельности инвестиций в недвижимость с 2018 года</h2>
                                        <div className="divider-small"></div>
                                        {/* <!-- Bootstrap tabs--> */}
                                        <div className="tabs-custom tabs-horizontal tabs-line" id="tabs-1">
                                            {/* <!-- Nav tabs--> */}
                                            <ul className="nav nav-tabs">
                                                <li className="nav-item" onClick={handleFirstTabToggle} role="presentation">
                                                    <button
                                                        className={`nav-link ${firstTabOurMIssion ? 'active' : ''}`}
                                                        style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                    >
                                                        Наша миссия
                                                    </button>
                                                </li>
                                                <li className="nav-item" onClick={handleSecondTabToggle} role="presentation">
                                                    <button
                                                        className={`nav-link ${secondTabOurGoal ? 'active' : ''}`}
                                                        style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                    >
                                                        Наша цель
                                                    </button>
                                                </li>
                                                <li className="nav-item" onClick={handleThirdTabToggle} role="presentation">
                                                    <button
                                                        className={`nav-link ${thirdTabAboutUs ? 'active' : ''}`}
                                                        style={{ fontSize: '12px', fontWeight: '900', border: 'none', background: 'none', cursor: 'pointer' }}
                                                    >
                                                        О нас
                                                    </button>
                                                </li>
                                            </ul>
                                            {/* <!-- Tab panes--> */}
                                            <div className="tab-content">
                                                <div className={`tab-pane fade ${firstTabOurMIssion ? 'active show' : ''}`} id="tabs-1-1">
                                                    <p>Наша бизнес-миссия — повысить экономическую жизнеспособность и доступность недвижимости и жилья. </p>
                                                    <p>Наш новаторский продукт представляет собой запатентованное решение, направленное на развитие динамичного сектора недвижимости. Наша цель — обеспечить устойчивость рынка, сделав его рентабельным даже во время экономических спадов и устойчивым к колебаниям процентных ставок и инфляции. </p>
                                                    <p>Мы считаем, что каждый должен иметь доступ к рынку, независимо от экономических циклов и связанных с ними факторов.</p>
                                                </div>
                                                <div className={`tab-pane fade ${secondTabOurGoal ? 'active show' : ''}`} id="tabs-1-2">
                                                    <p>Облегчение доступа к безопасным и экономически обоснованным схемам транзакций для повышения рыночной привлекательности как для покупателей, так и для продавцов. </p>
                                                    <p>Свободный бесплатный рынок недвижимости, обслуживающий арендаторов, продавцов, арендаторов и покупателей. </p>
                                                    <p>Повышение доступности услуг, переплетенных с этим динамичным рынком. </p>
                                                    <p>Новый продукт – признание ипотечных кредитов ценным активом, создание рынка и расширение торговых возможностей.</p>
                                                    <p>Предоставление альтернатив продавцам и покупателям в ситуациях, когда предполагаемая ценность продажи или покупки недвижимости может показаться неоправданной, несмотря на необходимость прогресса.</p>
                                                </div>
                                                <div className={`tab-pane fade ${thirdTabAboutUs ? 'active show' : ''}`} id="tabs-1-3">
                                                    <p>С 2018 года нам удалось организовать и продать более 4000 арендных площадей. Наши клиенты — площадки, арендующие краткосрочную и среднесрочную жилую недвижимость в крупных городах. </p>
                                                    <p>Мы накапливаем опыт и реализуем успешные проекты на рынке недвижимости. Наша платформа и услуги позволяют тем, у кого есть дополнительное пространство или кто хочет инвестировать в краткосрочную, среднесрочную и долгосрочную аренду, обустроить/обновить жилые помещения и предложить жилье тем, кто в нем нуждается.</p>
                                                    <p>Также мы предлагаем полный комплекс выездных услуг, связанных с недвижимостью: ремонт, обустройство помещений для сдачи в аренду, покупка-доставка-сборка мебели, съемка виртуальных туров, услуги специалистов по срочному ремонту, товарная уборка и другие услуги.</p>
                                                    <p>Мы изучаем ваши потребности и ищем пути их эффективного удовлетворения, поэтому ваши отзывы во многом помогают нам улучшать существующие и создавать новые сервисы.</p>
                                                </div>
                                            </div>
                                            <ScrollLink
                                                className="button button-sm button-secondary"
                                                to="sendEmailFormAbout"
                                                smooth={true}
                                                duration={500}
                                            >
                                                Связаться
                                            </ScrollLink>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </section>
                    {/* <!-- Features--> */}
                    <section className="section section-lg bg-default">
                        <div className="container">
                            <div className="row row-flex row-30 justify-content-center">
                                <div className="col-md-6 col-lg-4">
                                    {/* <!-- Box Modern--> */}
                                    {/* <article className="box-modern"><span className="icon box-modern-icon fl-bigmug-line-big104"></span> */}
                                    <article className="box-modern"><span><img src="./images/buy-home.png" alt="buy-home" className="icon-val-lg" /></span>
                                        <div className="box-modern-main">
                                            <h4 className="box-modern-title">Уникальные возможности</h4>
                                            <p>Расширьте свой бизнес, используя платформу, поддерживающую несколько языков. Предлагайте помещения более широкой аудитории, включая тех, кто недавно переехал. Мы поможем с проверкой анкетных данных для обеспечения бесперебойной аренды.</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    {/* <!-- Box Modern--> */}
                                    <article className="box-modern"><span className="icon-val"><img src="./images/a-3.png" alt="a-3" className="icon-val-lg" /></span>
                                        <div className="box-modern-main">
                                            <h4 className="box-modern-title">Удобная система поиска для тех, кто ищет квартиру</h4>
                                            <p>Найдите различные варианты в одном месте на платформе. Вы можете добавлять понравившиеся объявления в закладки, быть в курсе их событий, получать консультации, арендовать жилое помещение, жить или сдавать в субаренду согласно своим планам</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    {/* <!-- Box Modern--> */}
                                    <article className="box-modern"><span><img src="./images/sale-min.png" alt="sale-min" className="icon-val-lg" /></span>
                                        <div className="box-modern-main">
                                            <h4 className="box-modern-title">Дополнительные услуги</h4>
                                            <p>Наши специалисты готовы обустроить арендуемое вами помещение в соответствии с вашими предпочтениями. Сюда входит перестановка помещений, перенос перегородок, закупка мебели, доставка, сборка и выполнение экстренного ремонта по запросу</p>
                                            <p>По вашему запросу мы создадим виртуальные туры, позволяющие арендаторам удаленно просматривать квартиры и комнаты. Это существенно сэкономит время как арендодателя, так и арендатора</p>
                                            <p>Стоимость виртуального тура составляет 100 долларов США, а за хранение взимается ежемесячная плата в размере 4 долларов США. Если вам в данный момент носитель не нужен, вы можете приостановить его и бесплатно сохранить для использования в будущем</p>

                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </section>
                    <h2 className="heading-decoration-1"><span className="heading-inner">Давайте сделаем это</span></h2>
                    <section className="section section-lg bg-default">
                        <div className="">
                            {/* <!-- Owl Carousel--> */}
                            <PhotoCarousel />
                        </div>
                    </section>

                    {/* this section for future use */}
                    <article className="box-info box-info-1">
                        <div className="box-info-aside">
                            <div className="row row-30 row-flex">
                                <div className="col-sm-6 wow fadeIn">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-square160"></span> */}
                                    <article className="box-minimal"><span><img src="./images/agent-bg.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Шаг 1. Опубликуйте объект недвижимости и проконсультируйтесь с нашей командой</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>Зарегистрируйтесь на сайте и в меню «Управление объявлениями» найдите «Загрузить объявление». Вы увидите форму, которую необходимо аккуратно заполнить, указав необходимую информацию. </p>
                                        <p>Большинство полей являются обязательными, поэтому очень важно правильно их заполнить, чтобы обеспечить видимость на карте. Предоставление неправильного адреса может сбить с толку потенциальных клиентов, что приведет к потенциальным потерям и потере месяцев драгоценного времени.</p>
                                        <p>Обязательно загрузите хотя бы одно изображение. Тем, кто рассматривает возможность многократной аренды помещения, рекомендуется выбрать трехмерный виртуальный тур. Это позволяет детально рассмотреть комнату на расстоянии.</p>
                                        <p>Если помещение требует ремонта или мебели, наша выездная команда может предложить эти услуги по экономически выгодным ценам и в кратчайшие сроки.</p>
                                        <p>Мы занимаемся оценкой активов, чтобы помочь определить подходящую запрашиваемую цену, гарантируя соответствие вашим финансовым целям.</p>
                                        <p>Кроме того, для вашего удобства мы создаем безопасную структуру сделки.</p>
                                    </article>
                                </div>
                                <div className="col-sm-6 wow fadeIn" data-wow-delay=".05s">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-cellphone55"></span> */}
                                    <article className="box-minimal"><span><img src="./images/right-arrow-o.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Шаг 2. Потенциальные арендаторы свяжитесь с нашими агентами или вашими агентами по листингу</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>Как только вы загрузите свою заявку, она будет видна на вашей странице «Мои объявления», доступной в любое время. Чтобы найти его, перейдите в раздел «Мои объявления» в меню «Управление объявлениями».</p>
                                        <p>Ознакомьтесь с заявлением и, если необходимы исправления, обратитесь к нашему представителю. Нажмите на фотографию вашего объявления, чтобы перейти на страницу вашего места. С правой стороны найдите «Заказать консультацию или посмотреть квартиру» и отметьте его.</p>
                                        <p>Когда объявление будет готово к публикации, нажмите синюю виртуальную кнопку «Опубликовать». Вы будете перенаправлены на страницу, предлагающую бесплатную публикацию в течение одного месяца. Примите, и ваше объявление появится на главной странице, видимое обычным посетителям сети, независимо от того, зарегистрированы они или нет.</p>
                                        <p>Наши агенты реализуют комплексную маркетинговую стратегию для привлечения потенциальных покупателей, используя онлайн-списки, рекламу и сети.</p>
                                        <p>Обрабатывайте запросы потенциальных покупателей, предоставляя необходимую информацию и планируя просмотры недвижимости.</p>
                                        <p>При получении интереса от потенциальных покупателей наша команда будет сотрудничать с вами для составления графика сделок.</p>
                                    </article>
                                </div>
                                <div className="col-sm-6 wow fadeIn">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-equalization3"></span> */}
                                    <article className="box-minimal"><span><img src="./images/right-arrow-o.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Шаг 3: Получите информацию о потенциальных арендаторах и завершите сделку</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>Потенциальный клиент найдет ваше объявление и свяжется с вами по указанному вами адресу электронной почты или номеру телефона.</p>
                                        <p>Если через месяц приложение не будет продлено, оно перейдет в пассивный режим. Чтобы обновить, посетите «Мои объявления» и нажмите кнопку «Опубликовать». Вы будете перенаправлены на страницу, предлагающую продлить ваше объявление за 7,99 долларов США в месяц. Эти средства используются для поддержания сайта и покрытия расходов. Мы ценим вашу поддержку.</p>
                                        <p>Получайте профили потенциальных арендаторов.</p>
                                        <p>Изучите детали сделки.</p>
                                        <p>Провести переговоры с потенциальными арендаторами для достижения взаимоприемлемой сделки, учитывая цену, сроки и условия.</p>
                                        <p>После удовлетворения подпишите окончательный договор аренды.</p>
                                        <p></p>
                                    </article>
                                </div>
                                <div className="col-sm-6 wow fadeIn" data-wow-delay=".05s">
                                    {/* <!-- Box Minimal--> */}
                                    {/* <article className="box-minimal"><span className="icon box-minimal-icon fl-bigmug-line-two316"></span> */}
                                    <article className="box-minimal"><span><img src="./images/circle.png" alt="sale-min" className="icon-val" /></span>
                                        <h4 className="box-minimal-title">Шаг 4: Действия после сделки</h4>
                                        <div className="box-minimal-divider"></div>
                                        <p>Могут возникнуть проблемы с арендованным помещением, и если вас нет рядом, вам может понадобиться посторонняя помощь. Мы можем оперативно отправить нашего специалиста на место для оказания экстренной помощи.</p>
                                        <p>Для этого свяжитесь с нами по телефону или электронной почте. Наши сотрудники сформируют задание, и в случае нештатной ситуации наш специалист приедет в тот же день. В противном случае визит будет запланирован по вашему соглашению. Бригадиру платят почасово, обычно такие визиты занимают около 2 часов. Почасовая ставка за обслуживание составляет 28 долларов США, наш специалист оснащен всем необходимым.</p>
                                        <p>Если вы арендуете несколько помещений, мы предлагаем бесплатную учетную запись на нашей платформе «Super Team». Здесь вы можете самостоятельно создавать задачи и следить за их ходом.</p>
                                    </article>
                                </div>

                            </div>
                        </div>
                        <div id="sendEmailFormAbout">
                            <GetInTouch />
                        </div>
                    </article>
                    <section className="section section-lg bg-default">
                        <div className="container">
                        </div>
                    </section>
                </div>
            </div>
        )}
    </div>
    )
}
export default About;