import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const [currentLanguage, setCurrentLanguage] = useState('ge');

    const switchLanguage = (language) => {
        setCurrentLanguage(language);
        sessionStorage.setItem('currentLanguage', language);
        window.location.reload();
        // if(language === 'ge'){
        // window.location.reload();
        // }
    };



    return (
        <LanguageContext.Provider value={{ currentLanguage, switchLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}

export function useLanguage() {
    return useContext(LanguageContext);
}
