import React, { useState, useEffect } from "react";
import BackToTopButton from "./backToTop";
import CookieConsentBanner from './cookieConsentBanner'
import Loader from "./loader";
import backendUrl from './config';
import { useLanguage } from './languageContext';
import { Link } from 'react-router-dom';

function Footer(props) {
    const product = "realestate";
    const { currentLanguage } = useLanguage();
    const [listingData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pathOfFirstProperty, setImagePathOfFirstProperty] = useState();
    const [pathOfSecondProperty, setImagePathOfSecondProperty] = useState();

    const [responseMessageFetch, setResponseMessageFetch] = useState("");

    // Retrieve currentLanguage from sessionStorage
    const storedLanguage = sessionStorage.getItem('currentLanguage');

    // Use storedLanguage if available, otherwise use currentLanguage
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Fetch data from the backend when the component mounts
        fetch(`${backendUrl}/home`)
            .then((response) => response.json())
            .then((responseData) => {
                setData(responseData);
                setLoading(false);
                fetchPhotos(responseData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);


    // fetch photos 
    const fetchPhotos = async (responseData) => {
        // Check if responseData contains at least two elements
        if (responseData.length < 2) {
            return;
        }
        const propertyOneId = responseData[responseData.length - 1]._id;
        const propertyTwoeId = responseData[responseData.length - 2]._id;

        // Use a Set to store unique URLs
        // Receive and add each image URL to the imagePaths array
        try {
            const response = await fetch(`${backendUrl}/api/photos/${product}/${propertyOneId}/${0}`);
            if (response.ok) {
                const contentType = response.headers.get('content-type');

                if (contentType && contentType.includes('application/json')) {
                    const data = await response.json();
                    setResponseMessageFetch(data.message);
                } else {
                    const blob = await response.blob();
                    const imgUrl = URL.createObjectURL(blob);

                    // Add the unique URL to the set
                    setImagePathOfFirstProperty(imgUrl);
                }
            } else {
                setResponseMessageFetch("Error fetching photos. Response failed.");
            }
        } catch (error) {
            console.error("Error fetching photos:", error);
            setResponseMessageFetch("Error fetching photos.");
        }
        try {
            const response = await fetch(`${backendUrl}/api/photos/${product}/${propertyTwoeId}/${0}`);
            if (response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    const data = await response.json();
                    setResponseMessageFetch(data.message);
                } else {
                    const blob = await response.blob();
                    const imgUrl = URL.createObjectURL(blob);

                    // Add the unique URL to the set
                    setImagePathOfSecondProperty(imgUrl);
                }
            } else {
                setResponseMessageFetch("Error fetching photos. Response failed.");
            }
        } catch (error) {
            console.error("Error fetching photos:", error);
            setResponseMessageFetch("Error fetching photos.");
        }
    };

    // fetching photo end

    // send email
    // from, to, subject, text
    const [inputFrom, setInputFrom] = useState("");
    const [responseMessageSendEmail, setResponseMessageSendEmail] = useState("");

    const [responseMessageShow, setResponseMessageShow] = useState(false);

    const handleInputChangeFrom = (e) => {
        setInputFrom(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            from: inputFrom,
            name: "",
            phone: "",
            subject: "Subscription",
            text: inputFrom + " subscribed for news letters ",
            itemId: "",
            address: "",
            product: "",
            activity: ""
        };

        try {
            const response = await fetch(`${backendUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                const responseData = await response.json();
                setResponseMessageSendEmail(responseData.message);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            } else {
                const errorData = await response.json();
                setResponseMessageSendEmail(`Error: ${errorData.message}`);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            }
        } catch (error) {
            console.error("Error:", error);
            setResponseMessageSendEmail("Error sending data to the server.");
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        }
    };

    // end send email
    // if (loading) {
    //     return <div>Loading...</div>;
    // };
    if (loading) {
        return <Loader loading={loading} />;
    }

    const openInNewWindowFirst = () => {
        sessionStorage.setItem('currentLanguage', languageToUse);
        const url = `/item/` + listingData[listingData.length - 1]._id;
        window.open(url);
    };

    const openInNewWindowSecond = () => {
        sessionStorage.setItem('currentLanguage', languageToUse);
        const url = `/item/` + listingData[listingData.length - 2]._id;
        window.open(url);
    };

    return <footer>
        {languageToUse === "ge" && (
            <section className="section section-md bg-gray-31 context-dark" style={{ marginTop: "30px" }}>
                <CookieConsentBanner />
                <div className="container">
                    <div className="row row-40 justify-content-lg-between">
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>უახლესი ფართები</h3>
                            {listingData.length > 0 && listingData[listingData.length - 1] && listingData[listingData.length - 1]._id && (
                                // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 1]._id}>
                                <a className="post-minimal" onClick={openInNewWindowFirst} style={{ cursor: 'pointer' }}>
                                    <div className="post-minimal-image">
                                        {pathOfFirstProperty ? (
                                            <img key={0}
                                                src={pathOfFirstProperty}
                                                alt={`Item ${0}`}
                                                width="161" height="136" />
                                        ) : (
                                            <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

                                        )}
                                    </div>
                                    <div className="post-minimal-body">
                                        <div className="post-minimal-title">
                                            <span>მისამართი: {listingData[listingData.length - 1].address},{listingData[listingData.length - 1].city},{listingData[listingData.length - 1].state}</span>
                                        </div>
                                        <div className="post-minimal-text">
                                            <span>ფასი: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 1].askingPrice)} </span>
                                        </div>
                                    </div>
                                </a>
                            )}
                            {listingData.length > 1 && listingData[listingData.length - 2] && listingData[listingData.length - 2]._id && (
                                // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 2]._id}>
                                <a className="post-minimal" onClick={openInNewWindowSecond} style={{ cursor: 'pointer' }}>
                                    <div className="post-minimal-image">
                                        {pathOfSecondProperty ? (
                                            <img key={1}
                                                src={pathOfSecondProperty}
                                                alt={`Item ${1}`}
                                                width="161" height="136" />
                                        ) : (
                                            <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

                                        )}

                                    </div>
                                    <div className="post-minimal-body">
                                        <div className="post-minimal-title">
                                            <span>მისამართი: {listingData[listingData.length - 2].address},{listingData[listingData.length - 2].city},{listingData[listingData.length - 2].state}</span>
                                        </div>
                                        <div className="post-minimal-text">
                                            <span>ფასი: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 2].askingPrice)} </span>
                                        </div>
                                    </div>
                                </a>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 col-bordered">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>დაგვიკავშირდით</h3>
                            <div className="link-with-icon heading-4 text-spacing-150 font-sec" data-item=".icon"><span className="icon icon-1 mdi mdi-phone"></span>1-718-374-2603</div>
                            <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-2 mdi mdi-email-outline"></span>info@superteamusa.com</div>
                            <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-3 mdi mdi-map-marker"></span>30 East 49th street<br /> New York, NY 10017</div>
                        </div>
                        <div className="col-lg-4">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>სიახლეების გამოწერა</h3>
                            <p className="rd-mailform-label">შეიყვანეთ თქვენი ელეფტრონული ფოსტა და მიიღეთ ინფორმაცია სიახლეების შესახებ</p>
                            <form onSubmit={handleSubmit} className="rd-form rd-mailform rd-form-inline-1" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="">
                                <div className="form-wrap">
                                    <input className="form-input" id="subscribe-form--email" type="email" name="email" autoComplete="email" placeholder='Your e-mail' data-constraints="@Email @Required" onChange={handleInputChangeFrom} />
                                    {/* <label className="form-label" htmlFor="subscribe-form--email">Your e-mail</label> */}
                                </div>
                                <div className="form-button">
                                    <button className="button button-primary button-square" type="submit">გამოწერა</button>
                                </div>
                            </form>
                            {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow}>გამოხმაურება: {responseMessageSendEmail}</p>}
                            {/* <ul className="list-inline-1">
                            <li><a className="icon fa-facebook" href="#"></a></li>
                            <li><a className="icon fa-twitter" href="#"></a></li>
                            <li><a className="icon fa-google-plus" href="#"></a></li>
                            <li><a className="icon fa-pinterest-p" href="#"></a></li>
                        </ul> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '22px' }}>
                                <Link to="/about" className="nav-button">
                                    <img src="./images/400PngdpiLogo.png" alt="Super Team logo" height="60px" width="120px" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
        {languageToUse === "en" && (
            <section className="section section-md bg-gray-31 context-dark" style={{ marginTop: "30px" }}>
                <CookieConsentBanner />
                <div className="container">
                    <div className="row row-40 justify-content-lg-between">
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Latest Properties</h3>
                            {listingData.length > 0 && listingData[listingData.length - 1] && listingData[listingData.length - 1]._id && (
                                // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 1]._id}>
                                <a className="post-minimal" onClick={openInNewWindowFirst} style={{ cursor: 'pointer' }}>
                                    <div className="post-minimal-image">
                                        {pathOfFirstProperty ? (
                                            <img key={0}
                                                src={pathOfFirstProperty}
                                                alt={`Item ${0}`}
                                                width="161" height="136" />
                                        ) : (
                                            <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

                                        )}
                                    </div>
                                    <div className="post-minimal-body">
                                        <div className="post-minimal-title">
                                            <span>Address {listingData[listingData.length - 1].address},{listingData[listingData.length - 1].city},{listingData[listingData.length - 1].state}</span>
                                        </div>
                                        <div className="post-minimal-text">
                                            <span>Price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 1].askingPrice)} </span>
                                        </div>
                                    </div>
                                </a>
                            )}
                            {listingData.length > 1 && listingData[listingData.length - 2] && listingData[listingData.length - 2]._id && (
                                // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 2]._id}>
                                <a className="post-minimal" onClick={openInNewWindowSecond} style={{ cursor: 'pointer' }}>
                                    <div className="post-minimal-image">
                                        {pathOfSecondProperty ? (
                                            <img key={1}
                                                src={pathOfSecondProperty}
                                                alt={`Item ${1}`}
                                                width="161" height="136" />
                                        ) : (
                                            <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

                                        )}

                                    </div>
                                    <div className="post-minimal-body">
                                        <div className="post-minimal-title">
                                            <span>Address {listingData[listingData.length - 2].address},{listingData[listingData.length - 2].city},{listingData[listingData.length - 2].state}</span>
                                        </div>
                                        <div className="post-minimal-text">
                                            <span>Price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 2].askingPrice)} </span>
                                        </div>
                                    </div>
                                </a>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 col-bordered">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Contact	Us</h3>
                            <div className="link-with-icon heading-4 text-spacing-150 font-sec" data-item=".icon"><span className="icon icon-1 mdi mdi-phone"></span>1-718-374-2603</div>
                            <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-2 mdi mdi-email-outline"></span>info@superteamusa.com</div>
                            <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-3 mdi mdi-map-marker"></span>30 East 49th street<br /> New York, NY 10017</div>
                        </div>
                        <div className="col-lg-4">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Newsletter Signup</h3>
                            <p className="rd-mailform-label">Enter your e-mail to get the latest news of Real Estate</p>
                            <form onSubmit={handleSubmit} className="rd-form rd-mailform rd-form-inline-1" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="">
                                <div className="form-wrap">
                                    <input className="form-input" id="subscribe-form--email" type="email" name="email" autoComplete="email" placeholder='Your e-mail' data-constraints="@Email @Required" onChange={handleInputChangeFrom} />
                                    {/* <label className="form-label" htmlFor="subscribe-form--email">Your e-mail</label> */}
                                </div>
                                <div className="form-button">
                                    <button className="button button-primary button-square" type="submit">Subscribe</button>
                                </div>
                            </form>
                            {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow}>Response: {responseMessageSendEmail}</p>}
                            {/* <ul className="list-inline-1">
                 <li><a className="icon fa-facebook" href="#"></a></li>
                 <li><a className="icon fa-twitter" href="#"></a></li>
                 <li><a className="icon fa-google-plus" href="#"></a></li>
                 <li><a className="icon fa-pinterest-p" href="#"></a></li>
             </ul> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '22px' }}>
                                <Link to="/about" className="nav-button">
                                    <img src="./images/400PngdpiLogo.png" alt="Super Team logo" height="60px" width="120px" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
        {languageToUse === "ru" && (
            <section className="section section-md bg-gray-31 context-dark" style={{ marginTop: "30px" }}>
                <CookieConsentBanner />
                <div className="container">
                    <div className="row row-40 justify-content-lg-between">
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Последние объявления</h3>
                            {listingData.length > 0 && listingData[listingData.length - 1] && listingData[listingData.length - 1]._id && (
                                // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 1]._id}>
                                <a className="post-minimal" onClick={openInNewWindowFirst} style={{ cursor: 'pointer' }}>
                                    <div className="post-minimal-image">
                                        {pathOfFirstProperty ? (
                                            <img key={0}
                                                src={pathOfFirstProperty}
                                                alt={`Item ${0}`}
                                                width="161" height="136" />
                                        ) : (
                                            <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

                                        )}
                                    </div>
                                    <div className="post-minimal-body">
                                        <div className="post-minimal-title">
                                            <span>Адрес {listingData[listingData.length - 1].address},{listingData[listingData.length - 1].city},{listingData[listingData.length - 1].state}</span>
                                        </div>
                                        <div className="post-minimal-text">
                                            <span>Цена {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 1].askingPrice)} </span>
                                        </div>
                                    </div>
                                </a>
                            )}
                            {listingData.length > 1 && listingData[listingData.length - 2] && listingData[listingData.length - 2]._id && (
                                // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 2]._id}>
                                <a className="post-minimal" onClick={openInNewWindowSecond} style={{ cursor: 'pointer' }}>
                                    <div className="post-minimal-image">
                                        {pathOfSecondProperty ? (
                                            <img key={1}
                                                src={pathOfSecondProperty}
                                                alt={`Item ${1}`}
                                                width="161" height="136" />
                                        ) : (
                                            <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

                                        )}

                                    </div>
                                    <div className="post-minimal-body">
                                        <div className="post-minimal-title">
                                            <span>Адрес {listingData[listingData.length - 2].address},{listingData[listingData.length - 2].city},{listingData[listingData.length - 2].state}</span>
                                        </div>
                                        <div className="post-minimal-text">
                                            <span>Цена {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 2].askingPrice)} </span>
                                        </div>
                                    </div>
                                </a>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 col-bordered">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Связаться с нами</h3>
                            <div className="link-with-icon heading-4 text-spacing-150 font-sec" data-item=".icon"><span className="icon icon-1 mdi mdi-phone"></span>1-718-374-2603</div>
                            <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-2 mdi mdi-email-outline"></span>info@superteamusa.com</div>
                            <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-3 mdi mdi-map-marker"></span>30 East 49th street<br /> New York, NY 10017</div>
                        </div>
                        <div className="col-lg-4">
                            <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Подписаться на новости</h3>
                            <p className="rd-mailform-label">Введите свой адрес электронной почты, чтобы получать последние новости</p>
                            <form onSubmit={handleSubmit} className="rd-form rd-mailform rd-form-inline-1" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="">
                                <div className="form-wrap">
                                    <input className="form-input" id="subscribe-form--email" type="email" name="email" autoComplete="email" placeholder='Your e-mail' data-constraints="@Email @Required" onChange={handleInputChangeFrom} />
                                    {/* <label className="form-label" htmlFor="subscribe-form--email">Your e-mail</label> */}
                                </div>
                                <div className="form-button">
                                    <button className="button button-primary button-square" type="submit">Подписаться</button>
                                </div>
                            </form>
                            {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow}>Ответ: {responseMessageSendEmail}</p>}
                            {/* <ul className="list-inline-1">
                 <li><a className="icon fa-facebook" href="#"></a></li>
                 <li><a className="icon fa-twitter" href="#"></a></li>
                 <li><a className="icon fa-google-plus" href="#"></a></li>
                 <li><a className="icon fa-pinterest-p" href="#"></a></li>
             </ul> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '22px' }}>
                                <Link to="/about" className="nav-button">
                                    <img src="./images/400PngdpiLogo.png" alt="Super Team logo" height="60px" width="120px" />
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )}
        <div className="container">
            <div className="float-end mb-1">
                <BackToTopButton />
            </div>
            <p>&copy; 2023 Copala Inc. All rights reserved.</p>
            {/* <ul className="list-unstyled d-flex">
                <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
            </ul> */}
        </div>
    </footer>
}

export default Footer;


// import React, { useState, useEffect } from "react";
// import BackToTopButton from "./backToTop";
// import CookieConsentBanner from './cookieConsentBanner'
// import Loader from "./loader";
// import backendUrl from './config';
// import { useLanguage } from './languageContext';

// function Footer({ onClick }) {
//     const product = "realestate";
//     const { currentLanguage } = useLanguage();
//     const [listingData, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [pathOfFirstProperty, setImagePathOfFirstProperty] = useState();
//     const [pathOfSecondProperty, setImagePathOfSecondProperty] = useState();

//     const [responseMessageFetch, setResponseMessageFetch] = useState("");

//     const handlePropertyClick = (listingId) => {
//         onClick(listingId); // Pass the listingId to onClick
//     };

//     useEffect(() => {
//         // Fetch data from the backend when the component mounts
//         fetch(`${backendUrl}/home`)
//             .then((response) => response.json())
//             .then((responseData) => {
//                 setData(responseData);
//                 setLoading(false);
//                 fetchPhotos(responseData);
//             })
//             .catch((error) => {
//                 console.error("Error fetching data:", error);
//                 setLoading(false);
//             });
//     }, []);


//     // fetch photos 
//     const fetchPhotos = async (responseData) => {
//         // Check if responseData contains at least two elements
//         if (responseData.length < 2) {
//             return;
//         }
//         const propertyOneId = responseData[responseData.length - 1]._id;
//         const propertyTwoeId = responseData[responseData.length - 2]._id;

//         // Use a Set to store unique URLs
//         // Receive and add each image URL to the imagePaths array
//         try {
//             const response = await fetch(`${backendUrl}/api/photos/${product}/${propertyOneId}/${0}`);
//             if (response.ok) {
//                 const contentType = response.headers.get('content-type');

//                 if (contentType && contentType.includes('application/json')) {
//                     const data = await response.json();
//                     setResponseMessageFetch(data.message);
//                 } else {
//                     const blob = await response.blob();
//                     const imgUrl = URL.createObjectURL(blob);

//                     // Add the unique URL to the set
//                     setImagePathOfFirstProperty(imgUrl);
//                 }
//             } else {
//                 setResponseMessageFetch("Error fetching photos. Response failed.");
//             }
//         } catch (error) {
//             console.error("Error fetching photos:", error);
//             setResponseMessageFetch("Error fetching photos.");
//         }
//         try {
//             const response = await fetch(`${backendUrl}/api/photos/${product}/${propertyTwoeId}/${0}`);
//             if (response.ok) {
//                 const contentType = response.headers.get('content-type');
//                 if (contentType && contentType.includes('application/json')) {
//                     const data = await response.json();
//                     setResponseMessageFetch(data.message);
//                 } else {
//                     const blob = await response.blob();
//                     const imgUrl = URL.createObjectURL(blob);

//                     // Add the unique URL to the set
//                     setImagePathOfSecondProperty(imgUrl);
//                 }
//             } else {
//                 setResponseMessageFetch("Error fetching photos. Response failed.");
//             }
//         } catch (error) {
//             console.error("Error fetching photos:", error);
//             setResponseMessageFetch("Error fetching photos.");
//         }
//     };

//     // fetching photo end

//     // send email
//     // from, to, subject, text
//     const [inputFrom, setInputFrom] = useState("");
//     const [responseMessageSendEmail, setResponseMessageSendEmail] = useState("");

//     const [responseMessageShow, setResponseMessageShow] = useState(false);

//     const handleInputChangeFrom = (e) => {
//         setInputFrom(e.target.value);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const data = {
//             from: inputFrom,
//             name: "",
//             phone: "",
//             subject: "Subscription",
//             text: inputFrom + " subscribed for news letters ",
//             itemId: "",
//             address: "",
//             product: "",
//             activity: ""
//         };

//         try {
//             const response = await fetch(`${backendUrl}/send-email`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(data),
//             });

//             if (response.status === 200) {
//                 const responseData = await response.json();
//                 setResponseMessageSendEmail(responseData.message);
//                 setResponseMessageShow(true);
//                 setTimeout(() => {
//                     setResponseMessageShow(false);
//                 }, 4000);
//             } else {
//                 const errorData = await response.json();
//                 setResponseMessageSendEmail(`Error: ${errorData.message}`);
//                 setResponseMessageShow(true);
//                 setTimeout(() => {
//                     setResponseMessageShow(false);
//                 }, 4000);
//             }
//         } catch (error) {
//             console.error("Error:", error);
//             setResponseMessageSendEmail("Error sending data to the server.");
//             setResponseMessageShow(true);
//             setTimeout(() => {
//                 setResponseMessageShow(false);
//             }, 4000);
//         }
//     };

//     // end send email
//     // if (loading) {
//     //     return <div>Loading...</div>;
//     // };
//     if (loading) {
//         return <Loader loading={loading} />;
//     }

//     return <footer>
//         {currentLanguage === "ge" && (
//             <section className="section section-md bg-gray-31 context-dark" style={{ marginTop: "30px" }}>
//                 <CookieConsentBanner />
//                 <div className="container">
//                     <div className="row row-40 justify-content-lg-between">
//                         <div className="col-md-6 col-lg-4 col-xl-3">
//                             <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>უახლესი ფართები</h3>
//                             {listingData.length > 0 && listingData[listingData.length - 1] && listingData[listingData.length - 1]._id && (
//                                 // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 1]._id}>
//                                 <div onClick={() => handlePropertyClick(listingData[listingData.length - 1]._id)}>
//                                     <div className="post-minimal-image">
//                                         {pathOfFirstProperty ? (
//                                             <img key={0}
//                                                 src={pathOfFirstProperty}
//                                                 alt={`Item ${0}`}
//                                                 width="161" height="136" />
//                                         ) : (
//                                             <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

//                                         )}
//                                     </div>
//                                     <div className="post-minimal-body">
//                                         <div className="post-minimal-title">
//                                             <span>მისამართი: {listingData[listingData.length - 1].address},{listingData[listingData.length - 1].city},{listingData[listingData.length - 1].state}</span>
//                                         </div>
//                                         <div className="post-minimal-text">
//                                             <span>ფასი: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 1].askingPrice)} </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 // {/* </a> */}
//                             )}
//                             {listingData.length > 1 && listingData[listingData.length - 2] && listingData[listingData.length - 2]._id && (
//                                 // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 2]._id}>
//                                 <div onClick={() => handlePropertyClick(listingData[listingData.length - 2]._id)}>
//                                     <div className="post-minimal-image">
//                                         {pathOfSecondProperty ? (
//                                             <img key={1}
//                                                 src={pathOfSecondProperty}
//                                                 alt={`Item ${1}`}
//                                                 width="161" height="136" />
//                                         ) : (
//                                             <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

//                                         )}

//                                     </div>
//                                     <div className="post-minimal-body">
//                                         <div className="post-minimal-title">
//                                             <span>მისამართი: {listingData[listingData.length - 2].address},{listingData[listingData.length - 2].city},{listingData[listingData.length - 2].state}</span>
//                                         </div>
//                                         <div className="post-minimal-text">
//                                             <span>ფასი: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 2].askingPrice)} </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 // </a>
//                             )}
//                         </div>
//                         <div className="col-md-6 col-lg-4 col-xl-3 col-bordered">
//                             <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>დაგვიკავშირდით</h3>
//                             <div className="link-with-icon heading-4 text-spacing-150 font-sec" data-item=".icon"><span className="icon icon-1 mdi mdi-phone"></span>1-718-374-2603</div>
//                             <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-2 mdi mdi-email-outline"></span>info@superteamusa.com</div>
//                             <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-3 mdi mdi-map-marker"></span>30 East 49th street<br /> New York, NY 10017</div>
//                         </div>
//                         <div className="col-lg-4">
//                             <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>სიახლეების გამოწერა</h3>
//                             <p className="rd-mailform-label">შეიყვანეთ თქვენი ელეფტრონული ფოსტა და მიიღეთ ინფორმაცია სიახლეების შესახებ</p>
//                             <form onSubmit={handleSubmit} className="rd-form rd-mailform rd-form-inline-1" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="">
//                                 <div className="form-wrap">
//                                     <input className="form-input" id="subscribe-form--email" type="email" name="email" autoComplete="email" placeholder='Your e-mail' data-constraints="@Email @Required" onChange={handleInputChangeFrom} />
//                                     {/* <label className="form-label" htmlFor="subscribe-form--email">Your e-mail</label> */}
//                                 </div>
//                                 <div className="form-button">
//                                     <button className="button button-primary button-square" type="submit">გამოწერა</button>
//                                 </div>
//                             </form>
//                             {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow}>გამოხმაურება: {responseMessageSendEmail}</p>}
//                             {/* <ul className="list-inline-1">
//                             <li><a className="icon fa-facebook" href="#"></a></li>
//                             <li><a className="icon fa-twitter" href="#"></a></li>
//                             <li><a className="icon fa-google-plus" href="#"></a></li>
//                             <li><a className="icon fa-pinterest-p" href="#"></a></li>
//                         </ul> */}
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         )}
//         {currentLanguage === "en" && (
//             <section className="section section-md bg-gray-31 context-dark" style={{ marginTop: "30px" }}>
//                 <CookieConsentBanner />
//                 <div className="container">
//                     <div className="row row-40 justify-content-lg-between">
//                         <div className="col-md-6 col-lg-4 col-xl-3">
//                             <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Latest Properties</h3>
//                             {listingData.length > 0 && listingData[listingData.length - 1] && listingData[listingData.length - 1]._id && (
//                                 // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 1]._id}>
//                                 <div onClick={() => handlePropertyClick(listingData[listingData.length - 1]._id)}>
//                                     <div className="post-minimal-image"  >
//                                         {pathOfFirstProperty ? (
//                                             <img key={0}
//                                                 src={pathOfFirstProperty}
//                                                 alt={`Item ${0}`}
//                                                 width="161" height="136" />
//                                         ) : (
//                                             <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

//                                         )}
//                                     </div>
//                                     <div className="post-minimal-body">
//                                         <div className="post-minimal-title">
//                                             <span>Address {listingData[listingData.length - 1].address},{listingData[listingData.length - 1].city},{listingData[listingData.length - 1].state}</span>
//                                         </div>
//                                         <div className="post-minimal-text">
//                                             <span>Price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 1].askingPrice)} </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 // </a>
//                             )}
//                             {listingData.length > 1 && listingData[listingData.length - 2] && listingData[listingData.length - 2]._id && (
//                                 // <a className="post-minimal" href={`/item/` + listingData[listingData.length - 2]._id}>
//                                 <div onClick={() => handlePropertyClick(listingData[listingData.length - 2]._id)}>
//                                     <div className="post-minimal-image" >
//                                         {pathOfSecondProperty ? (
//                                             <img key={1}
//                                                 src={pathOfSecondProperty}
//                                                 alt={`Item ${1}`}
//                                                 width="161" height="136" />
//                                         ) : (
//                                             <img src="./images/ser-r.png" alt="Default Item" style={{ width: '161', maxHeight: '136' }} />

//                                         )}

//                                     </div>
//                                     <div className="post-minimal-body">
//                                         <div className="post-minimal-title">
//                                             <span>Address {listingData[listingData.length - 2].address},{listingData[listingData.length - 2].city},{listingData[listingData.length - 2].state}</span>
//                                         </div>
//                                         <div className="post-minimal-text">
//                                             <span>Price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(listingData[listingData.length - 2].askingPrice)} </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 // </a>
//                             )}
//                         </div>
//                         <div className="col-md-6 col-lg-4 col-xl-3 col-bordered">
//                             <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Contact	Us</h3>
//                             <div className="link-with-icon heading-4 text-spacing-150 font-sec" data-item=".icon"><span className="icon icon-1 mdi mdi-phone"></span>1-718-374-2603</div>
//                             <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-2 mdi mdi-email-outline"></span>info@superteamusa.com</div>
//                             <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-3 mdi mdi-map-marker"></span>30 East 49th street<br /> New York, NY 10017</div>
//                         </div>
//                         <div className="col-lg-4">
//                             <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Newsletter Signup</h3>
//                             <p className="rd-mailform-label">Enter your e-mail to get the latest news of Real Estate</p>
//                             <form onSubmit={handleSubmit} className="rd-form rd-mailform rd-form-inline-1" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="">
//                                 <div className="form-wrap">
//                                     <input className="form-input" id="subscribe-form--email" type="email" name="email" autoComplete="email" placeholder='Your e-mail' data-constraints="@Email @Required" onChange={handleInputChangeFrom} />
//                                     {/* <label className="form-label" htmlFor="subscribe-form--email">Your e-mail</label> */}
//                                 </div>
//                                 <div className="form-button">
//                                     <button className="button button-primary button-square" type="submit">Subscribe</button>
//                                 </div>
//                             </form>
//                             {responseMessageSendEmail.trim() !== "" && <p hidden={!responseMessageShow}>Response: {responseMessageSendEmail}</p>}
//                             {/* <ul className="list-inline-1">
//                  <li><a className="icon fa-facebook" href="#"></a></li>
//                  <li><a className="icon fa-twitter" href="#"></a></li>
//                  <li><a className="icon fa-google-plus" href="#"></a></li>
//                  <li><a className="icon fa-pinterest-p" href="#"></a></li>
//              </ul> */}
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         )}
//         <div className="container">
//             <div className="float-end mb-1">
//                 <BackToTopButton />
//             </div>
//             <p>&copy; 2023 Copala Inc. All rights reserved.</p>
//             {/* <ul className="list-unstyled d-flex">
//                 <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
//                 <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
//                 <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
//             </ul> */}
//         </div>
//     </footer>
// }

// export default Footer;
// export { handlePropertyClick };







// import React, { useState, useEffect } from "react";
// import BackToTopButton from "./backToTop";

// function Footer() {

//     // load listings
//     const [responseData, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [pathOfFirstProperty, setImagePathOfFirstProperty] = useState();
//     const [pathOfSecondProperty, setImagePathOfSecondProperty] = useState();

//     useEffect(() => {
//         // Fetch data from the backend when the component mounts
//         fetch("/lastlistings")
//             .then((response) => response.json())
//             .then((responseData) => {
//                 setData(responseData);
//                 setLoading(false);
//                 fetchPhotos(responseData);
//             })
//             .catch((error) => {
//                 console.error("Error fetching data:", error);
//                 setLoading(false);
//             });
//     }, []);


//     // fetch photos
//     const fetchPhotos = async (responseData) => {

//         // Use a Set to store unique URLs
//         // Receive and add each image URL to the imagePaths array
//         try {
//             const responseOne = await fetch(`/api/photos/${responseData.firstItem.photoPaths}`);

//             if (responseOne.ok) {
//                 const contentType = responseOne.headers.get('content-type');

//                 if (contentType && contentType.includes('application/json')) {
//                     const data = await responseOne.json();
//                     setResponseMessage(data.message);
//                 } else {
//                     const blob = await responseOne.blob();
//                     const imgUrl = URL.createObjectURL(blob);

//                     // Add the unique URL to the set
//                     setImagePathOfFirstProperty(imgUrl);
//                 }
//             } else {
//                 setResponseMessage("Error fetching photos. Response failed.");
//             }
//         } catch (error) {
//             console.error("Error fetching photos:", error);
//             setResponseMessage("Error fetching photos.");
//         }
//         try {
//             const responseTwo = await fetch(`/api/photos/${responseData.secondItem.photoPaths}`);

//             if (responseTwo.ok) {
//                 const contentType = responseTwo.headers.get('content-type');

//                 if (contentType && contentType.includes('application/json')) {
//                     const data = await responseTwo.json();
//                     setResponseMessage(data.message);
//                 } else {
//                     const blob = await responseTwo.blob();
//                     const imgUrl = URL.createObjectURL(blob);

//                     // Add the unique URL to the set
//                     setImagePathOfSecondProperty(imgUrl);
//                 }
//             } else {
//                 setResponseMessage("Error fetching photos. Response failed.");
//             }
//         } catch (error) {
//             console.error("Error fetching photos:", error);
//             setResponseMessage("Error fetching photos.");
//         }
//     };

//     // fetching photo end

//     // send email
//     // from, to, subject, text
//     const [inputFrom, setInputFrom] = useState("");
//     const [responseMessage, setResponseMessage] = useState("");
//     const handleInputChangeFrom = (e) => {
//         setInputFrom(e.target.value);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const data = {
//             from: inputFrom,
//             name: "",
//             phone: "",
//             subject: "Sunscription",
//             text: inputFrom + " subscribed for news letters ",
//             itemId: "",
//             address: ""
//         };

//         try {
//             const response = await fetch(`/send-email`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(data),
//             });

//             if (response.status === 200) {
//                 const responseData = await response.json();
//                 setResponseMessage(responseData.message);
//                 // navigate("/mylistings");
//             } else {
//                 const errorData = await response.json(); // Assuming you return a JSON error message from the server
//                 setResponseMessage(`Error: ${errorData.message}`);
//             }
//         } catch (error) {
//             console.error("Error:", error);
//             setResponseMessage("Error sending data to the server.");
//         }
//     };

//     // end send email

//     return <footer>
//         <section className="section section-md bg-gray-31 context-dark">
//             <div className="container">
//                 <div className="row row-40 justify-content-lg-between">
//                     <div className="col-md-6 col-lg-4 col-xl-3">
//                         <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Latest Properties</h3>
//                         {responseData.firstItem && responseData.firstItem._id && (
//                             <a className="post-minimal" href={"/item/" + responseData.firstItem._id}>
//                                 <div className="post-minimal-image">
//                                     <img key={0}
//                                         src={pathOfFirstProperty}
//                                         alt={`Image ${0}`}
//                                         width="161" height="136" />
//                                 </div>
//                                 <div className="post-minimal-body">
//                                     <div className="post-minimal-title">
//                                         <span>Address {responseData.firstItem.address},{responseData.firstItem.city},{responseData.firstItem.state}</span>
//                                     </div>
//                                     <div className="post-minimal-text">
//                                         <span>Price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(responseData.firstItem.askingPrice)} </span>
//                                     </div>
//                                 </div>
//                             </a>
//                         )}
//                         {responseData.secondItem && responseData.secondItem._id && (
//                             <a className="post-minimal" href={"/item/" + responseData.secondItem._id}>
//                                 <div className="post-minimal-image">
//                                     <img key={1}
//                                         src={pathOfSecondProperty}
//                                         alt={`Image ${1}`}
//                                         width="161" height="136" />
//                                 </div>
//                                 <div className="post-minimal-body">
//                                     <div className="post-minimal-title">
//                                         <span>Address {responseData.secondItem.address},{responseData.secondItem.city},{responseData.secondItem.state}</span>
//                                     </div>
//                                     <div className="post-minimal-text">
//                                         <span>Price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(responseData.secondItem.askingPrice)} </span>
//                                     </div>
//                                 </div>
//                             </a>
//                         )}
//                     </div>
//                     <div className="col-md-6 col-lg-4 col-xl-3 col-bordered">
//                         <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Contact	Us</h3>
//                         <div className="link-with-icon heading-4 text-spacing-150 font-sec" data-item=".icon"><span className="icon icon-1 mdi mdi-phone"></span><a href="tel:#">1-718-374-2603</a></div>
//                         <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-2 mdi mdi-email-outline"></span><a href="mailto:#">info@superteamusa.com</a></div>
//                         <div className="link-with-icon text-spacing-100" data-item=".icon"><span className="icon icon-3 mdi mdi-map-marker"></span><a href="#">330 East 49th street<br /> New York, NY 10017</a></div>
//                     </div>
//                     <div className="col-lg-4">
//                         <h3 className="heading-square font-weight-sbold" data-item=".heading-square-item"><span className="heading-square-item"></span>Newsletter Signup</h3>
//                         <p className="rd-mailform-label">Enter your e-mail to get the latest news of Real Estate</p>
//                         <form onSubmit={handleSubmit} className="rd-form rd-mailform rd-form-inline-1" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="">
//                             <div className="form-wrap">
//                                 <input className="form-input" id="subscribe-form--email" type="email" name="email" autoComplete="email" placeholder='Your e-mail' data-constraints="@Email @Required" onChange={handleInputChangeFrom} />
//                                 {/* <label className="form-label" htmlFor="subscribe-form--email">Your e-mail</label> */}
//                             </div>
//                             <div className="form-button">
//                                 <button className="button button-primary button-square" type="submit">Subscribe</button>
//                             </div>
//                         </form>
//                         <p>{responseMessage}</p>
//                         {/* <ul className="list-inline-1">
//                             <li><a className="icon fa-facebook" href="#"></a></li>
//                             <li><a className="icon fa-twitter" href="#"></a></li>
//                             <li><a className="icon fa-google-plus" href="#"></a></li>
//                             <li><a className="icon fa-pinterest-p" href="#"></a></li>
//                         </ul> */}
//                     </div>
//                 </div>
//             </div>
//         </section>
//         <div className="container">
//             <div className="float-end mb-1">
//                 <BackToTopButton />
//             </div>
//             <p>&copy; 2023 Copala Inc. All rights reserved.</p>
//             <ul className="list-unstyled d-flex">
//                 <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
//                 <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
//                 <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
//             </ul>
//         </div>
//     </footer>
// }

// export default Footer;