import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Arrow from './arrow';

const ImgSlider = ({ imagePaths }) => {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Disable default arrows
    };

    return (
        <div className="slider-container" style={{ position: 'relative' }}>
            <Slider ref={sliderRef} {...settings}>
                {imagePaths && imagePaths.length > 0 ? (
                    imagePaths.map((image, index) => (
                        <div key={index}>
                            <img
                                src={image}
                                alt={`Item ${index}`}
                                width="763"
                                height="443"
                            />
                        </div>
                    ))
                ) : (
                    <div>
                        <img
                            src="./images/ser-r.png"
                            alt="Default Item"
                            style={{ width: '100%', maxHeight: '443px' }}
                        />
                    </div>
                )}
            </Slider>
            {/* Custom arrows */}
            <div className="custom-arrows">
                <Arrow direction="prev" onClick={() => sliderRef.current.slickPrev()} />
                <Arrow direction="next" onClick={() => sliderRef.current.slickNext()} />
            </div>
        </div>
    );
};

export default ImgSlider;

