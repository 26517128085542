
import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import Map from './googleMap';
import Loader from "./loader";
import ChatBot from "./chatBot";
import FavorateButton from "./favorateButton";
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import backendUrl from './config';
import { useAuth } from './authContext';
import { useNavigate } from "react-router-dom";
import { useLanguage } from './languageContext';
import MapWrapper from "./mapWrapper";
import SendEmailMainByUser from "./sendEmaiMainByUser";
import ImgSlider from "./imgSlider";


function ItemDetailsPopUp({ onClick, listingId }) {
    const product = "realestate";
    const itemId = listingId;
    const { loggedIn, userId } = useAuth();
    const { currentLanguage } = useLanguage();
    // const { itemId } = useParams();
    const [selectedObject, setSelectedObject] = useState({});
    const [loading, setLoading] = useState(true);
    const [inputPropertyMarketPrice, setInputPropertyMarketPrice] = useState();
    const [gainAtSale, setGainAtSale] = useState(0);
    const [averageMortgageRate, setAverageMortgageRate] = useState(0);
    const [averageMonthlyMortgageRate, setAverageMonthlyMortgageRate] = useState(0);
    const [imagePaths, setImagePaths] = useState([]);


    // send email
    const [responseMessageFetchPhotos, setresponseMessageFetchPhotos] = useState("");

    // Information toggle
    const [generalInformationAccordionOpened, setGeneralInformationAccordionOpened] = useState("true");
    const [rentInformationAccordionOpened, setRentInformationAccordionOpened] = useState("true");
    const [mortgageInformationAccordionOpened, setMortgageInformationAccordionOpened] = useState("true");
    const [moreInformationAccordionOpened, setMoreInformationAccordionOpened] = useState("true");
    const [featuresAccordionOpened, setFeaturesAccordionOpened] = useState("true");
    const [commentAccordionOpened, setCommentAccordionOpened] = useState("true");

    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // fetch average mortgage rate nationwide
        fetch(`${backendUrl}/mortgageRate`)
            .then(response => response.json())
            .then(data => {
                if (data.averageMortgageRate) {
                    setAverageMortgageRate(data.averageMortgageRate);
                    const monthlyInterestRateFourDigits = Math.round((parseFloat(data.averageMortgageRate) * 10000) / 12) / 1000000;
                    setAverageMonthlyMortgageRate(monthlyInterestRateFourDigits);
                } else {
                    console.error('No average mortgage rate available');
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Adjusting mannually mortgage rate
    const handleInputValueMortgageRate = (e) => {
        setAverageMortgageRate(e.target.value);
        // console.log("average mogthgage rate red in input", e.target.value)
        const monthlyInteresteRateFourDigits = Math.round((parseFloat(e.target.value) * 10000) / 12) / 1000000;
        setAverageMonthlyMortgageRate(monthlyInteresteRateFourDigits);
    }

    const [valueForAskingPrice, setValueForAskingPrice] = useState();
    const [valueForDownPaymentPercent, setValueDownPaymentPercent] = useState(20.00);
    const [valueForDownPayment, setValueDownPayment] = useState();
    const [totaPrincipleAndInterest, setTotaPrincipleAndInterest] = useState();
    const [propertyMonthlyTaxPayment, setPropertyMonthlyTaxPayment] = useState(0);
    const [monthlyHOAPayment, setMonthlyHOAPayment] = useState(0);
    const [inputValueMortgageInsurance, setInputValueMortgageInsurance] = useState(0);
    const [inputValueHomeInsurance, setInputValueHomeInsurance] = useState(0);

    const [isSubAccordionOpen, setSubAccordionOpen] = useState(false);

    const toggleSubAccordion = () => {
        setSubAccordionOpen(!isSubAccordionOpen);
    };

    // Calculate values based on selectedObject
    useEffect(() => {
        if (selectedObject) {
            // Calculate down payment based on percentage
            const downPayment = Math.round(valueForAskingPrice * (valueForDownPaymentPercent / 100));

            // Calculate total principle and interest
            let principle = valueForAskingPrice - valueForDownPayment;
            const totalPrincipleAndInterest = calculateTotaPrincipleAndInterest(principle, averageMonthlyMortgageRate, 30);

            // Update states with calculated values
            setValueDownPayment(downPayment);
            setTotaPrincipleAndInterest(totalPrincipleAndInterest);

            // Ensure propertyMonthlyTaxPayment is a valid number before updating state
            const declaredPropertyMonthlyTaxPayment = Math.round(selectedObject.propertyTax / 12);
            if (!isNaN(declaredPropertyMonthlyTaxPayment)) {
                setPropertyMonthlyTaxPayment(declaredPropertyMonthlyTaxPayment);
            }
            if (!isNaN(parseInt(selectedObject.monthlyHOApayment))) {
                setMonthlyHOAPayment(parseInt(selectedObject.monthlyHOApayment));
            }
        }
    }, [selectedObject, valueForDownPaymentPercent, valueForAskingPrice, valueForDownPayment, averageMonthlyMortgageRate, averageMortgageRate]);

    const handleInputValueChangeAskingPrice = (e) => {
        setValueForAskingPrice(e.target.value);
        let principle = valueForAskingPrice - valueForDownPayment;
        setTotaPrincipleAndInterest(calculateTotaPrincipleAndInterest(principle, averageMonthlyMortgageRate, 30));
    }
    const handleInputValueChangeDownPaymentPercent = (e) => {
        setValueDownPaymentPercent(e.target.value);
        let principle = valueForAskingPrice - valueForDownPayment;
        setTotaPrincipleAndInterest(calculateTotaPrincipleAndInterest(principle, averageMonthlyMortgageRate, 30));
    }
    const handleInputValueChangeDownPayment = (e) => {
        setValueDownPayment(e.target.value);
        let principle = valueForAskingPrice - valueForDownPayment;
        setTotaPrincipleAndInterest(calculateTotaPrincipleAndInterest(principle, averageMonthlyMortgageRate, 30));
    }

    const handleInputValuePropertyMonthlyTaxPayment = (e) => {
        const userInput = e.target.value;

        // Ensure that the input is a valid number or handle accordingly
        const inputAsNumber = parseFloat(userInput);

        // Check if input is a valid number or zero
        if (!isNaN(inputAsNumber) || inputAsNumber === 0) {
            setPropertyMonthlyTaxPayment(inputAsNumber);
        } else {
            // Handle invalid input, for example, set it to zero or display an error message
            setPropertyMonthlyTaxPayment(0);
        }
    };

    const handleInputValueMontlyHOAPayment = (e) => {
        const userInput = e.target.value;

        // Ensure that the input is a valid number or handle accordingly
        const inputAsNumber = parseFloat(userInput);

        if (!isNaN(inputAsNumber) || inputAsNumber === 0) {
            setMonthlyHOAPayment(inputAsNumber);
        } else {
            // Handle invalid input, for example, set it to zero or display an error message
            setMonthlyHOAPayment(0);
        }
    }

    const handleInputValueMortgageInsurance = (e) => {
        const userInput = e.target.value;

        // Ensure that the input is a valid number or handle accordingly
        const inputAsNumber = parseFloat(userInput);

        if (!isNaN(inputAsNumber) || inputAsNumber === 0) {
            setInputValueMortgageInsurance(inputAsNumber);
        } else {
            // Handle invalid input, for example, set it to zero or display an error message
            setInputValueMortgageInsurance(0);
        }
    }

    const handleInputValueHomeInsurance = (e) => {
        const userInput = e.target.value;

        // Ensure that the input is a valid number or handle accordingly
        const inputAsNumber = parseFloat(userInput);

        if (!isNaN(inputAsNumber) || inputAsNumber === 0) {
            setInputValueHomeInsurance(inputAsNumber);
        } else {
            // Handle invalid input, for example, set it to zero or display an error message
            setInputValueHomeInsurance(0);
        }
    }

    // deal summary 
    // let averageMortgageRate = 0.007;
    let discountedDebtObligation;
    let escrowDepost;
    let escrowDepreciationDifferential;
    let assetCost;
    let totalPayable;

    useEffect(() => {
        fetch(`${backendUrl}/item/${product}/${new Date().getTime()}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                setSelectedObject(responseData);
                setValueForAskingPrice(responseData.askingPrice);
                if (product === "realestate") {
                    let defaultDowPayment = Math.round(responseData.askingPrice * 0.2);
                    setValueDownPayment(defaultDowPayment);
                    let principle = valueForAskingPrice - valueForDownPayment;
                    setTotaPrincipleAndInterest(calculateTotaPrincipleAndInterest(principle, averageMonthlyMortgageRate, 30));
                }
                setLoading(false);
                fetchPhotos(responseData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [loggedIn, navigate, itemId]);


    // fetch photos 
    const fetchPhotos = async (responseData) => {
        setImagePaths([]);
        // Use a Set to store unique URLs
        const uniqueImagePaths = new Set();
        // Receive and add each image URL to the imagePaths array
        const numberOfPhotos = responseData.imgs.length;
        for (let i = 0; i < numberOfPhotos; i++) {
            try {
                const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${i}`);
                if (response.ok) {
                    const contentType = response.headers.get('content-type');

                    if (contentType && contentType.includes('application/json')) {
                        const data = await response.json();
                        setresponseMessageFetchPhotos(data.message);
                    } else {
                        const blob = await response.blob();
                        const imgUrl = URL.createObjectURL(blob);

                        // Add the unique URL to the set
                        uniqueImagePaths.add(imgUrl);
                    }
                } else {
                    setresponseMessageFetchPhotos("Error fetching photos. Response failed.");
                }
            } catch (error) {
                console.error("Error fetching photos:", error);
                setresponseMessageFetchPhotos("Error fetching photos.");
            }
        }
        setImagePaths([...uniqueImagePaths]);
    };

    // fetching photo end

    // features organizer start
    const featuresContainerRef = useRef(null);
    useEffect(() => {
        const featureItems = featuresContainerRef.current?.querySelectorAll('.list-sm li');

        if (featureItems) {
            const itemsPerColumn = 6;
            let currentRow;

            featureItems.forEach((item, index) => {
                const hiddenAttribute = item.getAttribute('hidden');

                if (hiddenAttribute && selectedObject) {
                    const featureKey = hiddenAttribute.replace('!selectedObject.', '');

                    // Check if the feature has a value of true
                    if (selectedObject[featureKey]) {
                        if (index % itemsPerColumn === 0) {
                            // Start a new row
                            currentRow = document.createElement('div');
                            currentRow.className = 'row';
                            featuresContainerRef.current.appendChild(currentRow);
                        }

                        // Create a column for each feature
                        const currentColumn = document.createElement('div');
                        currentColumn.className = 'col-6 col-md-3';
                        currentRow.appendChild(currentColumn);

                        // Append the feature to the column
                        currentColumn.appendChild(item.parentNode);
                    }
                }
            });
        }
    }, [selectedObject]);


    // feature organizer end


    if (loading) {
        return <Loader loading={loading} />;
    }

    // Calculator mortgage value
    let discountedDebtObligationNotChecked = Math.round(selectedObject.monthlyPayment * (1 - (1 / Math.pow((1 + averageMonthlyMortgageRate), (selectedObject.remaningAmortizationPeriod)))) / averageMonthlyMortgageRate);
    discountedDebtObligation = isNaN(discountedDebtObligationNotChecked) ? 0 : discountedDebtObligationNotChecked;
    escrowDepost = Math.round(selectedObject.monthlyPayment * 12);
    let escrowDepreciationDifferentialNotChecked = Math.round(escrowDepost * (1 / Math.pow((1 + averageMonthlyMortgageRate), selectedObject.remaningAmortizationPeriod)));
    escrowDepreciationDifferential = isNaN(escrowDepreciationDifferentialNotChecked) ? 0 : escrowDepreciationDifferentialNotChecked;
    assetCost = selectedObject.askingPrice + discountedDebtObligation + escrowDepreciationDifferential;
    totalPayable = selectedObject.askingPrice + escrowDepost;

    // Calculator monthly payment on mortgage purchase
    function calculateTotaPrincipleAndInterest(loanAmount, monthlyInterestRate, loanTermYears) {
        var r = monthlyInterestRate;
        var n = loanTermYears * 12;

        var monthlyPayment = loanAmount * (r * Math.pow((1 + r), n)) / (Math.pow((1 + r), n) - 1);

        return monthlyPayment;
    }


    // function calculateTotaPrincipleAndInterest() {
    //   return Math.round((valueForAskingPrice - valueForDownPayment) / (12 * 30) + (valueForAskingPrice - valueForDownPayment) * averageMonthlyMortgageRate / 2);
    // }



    const handleChangePropertyMarketPrice = (e) => {
        setInputPropertyMarketPrice(e.target.value);
    };

    const calculateGain = () => {
        const gainAtSale = parseInt(inputPropertyMarketPrice) - parseInt(assetCost);
        setGainAtSale(gainAtSale);
    };

    const handleAccordionGeneralInformationToggle = () => {
        setGeneralInformationAccordionOpened(!generalInformationAccordionOpened);
    }

    const handleAccordionRentInformationToggle = () => {
        setRentInformationAccordionOpened(!rentInformationAccordionOpened);
    }

    const handleAccordionMortgageInformationToggle = () => {
        setMortgageInformationAccordionOpened(!mortgageInformationAccordionOpened);
    }

    const handleAccordionMoreDetalsToggle = () => {
        setMoreInformationAccordionOpened(!moreInformationAccordionOpened);
    }

    const handleAccordionFeaturesToggle = () => {
        setFeaturesAccordionOpened(!featuresAccordionOpened);
    }

    const handleAccordionCommentInformationToggle = () => {
        setCommentAccordionOpened(!commentAccordionOpened);
    }

    return (
        <main>
            <div className="item-details-container container">
                {languageToUse === "ge" && (
                    <div>
                        <section className="" data-opacity="37">
                            <div className="container">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedObject.address} {selectedObject.city}</h3>
                                </div>
                            </div>
                        </section>
                        {/* <SubMenue product={product} /> */}
                        <div className="divider-section"></div>
                        <div className="section section-md bg-gray-12">
                            <div className="container">
                                <div className="row row-50">
                                    <div className="col-lg-7 col-xl-8">
                                        {/* <!-- Slick Carousel--> */}
                                        <div className="slick-slider-1">
                                            <div className="slick-slider-price">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</div>
                                            <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                                <ImgSlider imagePaths={imagePaths} />
                                            </div>
                                        </div>
                                        <div className="features-block">
                                            <div className="features-block-inner">
                                                <div className="features-block-item">
                                                    <ul className="features-block-list">
                                                        <li hidden={selectedObject.numberOfBathrooms !== 1}><img src="./images/icons/b-bat.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBathrooms} აბაზანა</span></li>
                                                        <li hidden={selectedObject.numberOfBathrooms === 1}><img src="./images/icons/b-bat.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBathrooms} აბაზანა</span></li>
                                                        <li hidden={selectedObject.numberOfBedrooms !== 0}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>სტუდიო </span></li>
                                                        <li hidden={selectedObject.numberOfBedrooms !== 1}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBedrooms} საძინებელი</span></li>
                                                        <li hidden={selectedObject.numberOfBedrooms === 0 || selectedObject.numberOfBedrooms === 1} aria-disabled={selectedObject.numberOfBedrooms === 1}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBedrooms} საძინებელი</span></li>
                                                        <li><img src="./images/icons/b-s.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.area}  კვ ფიტი</span></li>
                                                        <li><img src="./images/icons/b-g.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.garages} ავტ ფარეხი</span></li>
                                                    </ul>
                                                </div>
                                                <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                            </div>
                                        </div>

                                        <div>
                                            <p hidden={selectedObject.linkToVRT === ""}>
                                                <a className="link-text" href={selectedObject.linkToVRT} target="_blank" rel="noopener noreferrer">
                                                    დააწკაპუნეთ ვირტუალური ტურის ამ ბმულზე
                                                </a>
                                            </p>
                                            <hr className="line-below-header" hidden={selectedObject.linkToVRT === ""} />
                                        </div>

                                        <div hidden={selectedObject.comment === ""} className="card-group-custom card-group-corporate" id="accordion1" >
                                            {/* <!-- card--> */}
                                            <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                                <div className="card-header" id="accordion1-heading-1" >
                                                    <div className="card-title"><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>საცხოვრებლის მოკლე აღწერა</span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>
                                                <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                    <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                        <div className="layout">
                                                            <dl className="list-terms-inline text-margin">
                                                                <dd>{selectedObject.comment}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        {/* <!-- collapse--> */}
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            <article className={`card card-custom card-corporate ${generalInformationAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionGeneralInformationToggle} className={`card-link ${generalInformationAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={generalInformationAccordionOpened}><span>{selectedObject.address} {selectedObject.city} {selectedObject.state} </span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>
                                                <div className={`collapse ${generalInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                    <div className="card-body">
                                                        <div className="layout-1 text-margin">
                                                            <dl className="list-terms-inline">
                                                                <dt>მისამართი:</dt>
                                                                <dd>{selectedObject.address}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>ქალაქი:</dt>
                                                                <dd>{selectedObject.city}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>შტატი:</dt>
                                                                <dd>{selectedObject.state}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>ზიპ კოდი:</dt>
                                                                <dd>{selectedObject.zip}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>საცხოვრებლის სახეობა:</dt>
                                                                <dd>{selectedObject.propertytype === "House" ? "სახლი" : ""}</dd>
                                                                <dd>{selectedObject.propertytype === "Townhouse" ? "ბინა ქალაქურ სახლში" : ""}</dd>
                                                                <dd>{selectedObject.propertytype === "Condo" ? "ბინა კორპუსში (კონდო)" : ""}</dd>
                                                            </dl>
                                                            <dl hidden={selectedObject.dealtype === "forRent"} className="list-terms-inline">
                                                                <dt>Property condition:</dt>
                                                                <dd>{selectedObject.propertyCondition}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        {/* <!-- collapse--> */}
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            {selectedObject.dealtype === "forRent" && (
                                                <article className={`card card-custom card-corporate ${rentInformationAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionRentInformationToggle} className={`card-link ${rentInformationAccordionOpened ? '' : 'collapsed'}`}
                                                            role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={rentInformationAccordionOpened}><span>
                                                                ქირის ღირებულება {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>
                                                            <span>{selectedObject.rentChargePeriod === "monthly" ? " /თვეში" : ""}</span>
                                                            <span>{selectedObject.rentChargePeriod === "daily" ? " /დღეში" : ""}</span>
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>

                                                    <div className={`collapse ${rentInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body">
                                                            <div className="layout-1 text-margin">
                                                                <dl hidden={selectedObject.dealtype === "forRent"} className="list-terms-inline">
                                                                    <dt>აშენების წელი</dt>
                                                                    <dd>{selectedObject.yearOfBuild === '' ? 'ინფორმაცია არ მოიპოვება' : selectedObject.yearOfBuild} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>გასაქირავებელი ფართი:</dt>
                                                                    <dd>{selectedObject.rentalUnit === "entireProperty" ? "მთლიანი ბინა/სახლი" : ""} </dd>
                                                                    <dd>{selectedObject.rentalUnit === "room" ? "ოთახი" : ""} </dd>
                                                                    <dd>{selectedObject.rentalUnit === "bed" ? "ლოგინი" : ""} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>ქირავდება:</dt>
                                                                    <dd>{selectedObject.rentalUnitFurnished === "furnished" ? "ავეჯით" : ""} </dd>
                                                                    <dd>{selectedObject.rentalUnitFurnished === "notfurnished" ? "უავეჯოდ" : ""} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>განცხადების ავტორი:</dt>
                                                                    <dd>{selectedObject.name}</dd>, <dd> {selectedObject.email}</dd>, <dd> {selectedObject.phone}</dd>
                                                                </dl>
                                                                {selectedObject.listingby && selectedObject.listingby !== "" && (
                                                                    <dl className="list-terms-inline">
                                                                        <dt>განთავსებულია: </dt>
                                                                        <dd> {selectedObject.listingby === "owner" ? "მფლობელის მიერ" : "აგენტის მიერ"}</dd>
                                                                    </dl>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            )}
                                        </div>
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            {selectedObject.dealtype === "forSaleAsMortgageAsset" && (
                                                <article className={`card card-custom card-corporate ${mortgageInformationAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionMortgageInformationToggle} className={`card-link ${mortgageInformationAccordionOpened ? '' : 'collapsed'}`}
                                                            role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={mortgageInformationAccordionOpened}><span>
                                                                Asking price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    <div className={`collapse ${mortgageInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body">
                                                            <div className="layout-1 text-margin">
                                                                <dl className="list-terms-inline">
                                                                    <dt>Remaining principle:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.remaningPrinciple)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Remaining amortization period in months:</dt>
                                                                    <dd>{selectedObject.remaningAmortizationPeriod} months</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Interest rate:</dt>
                                                                    <dd>{selectedObject.interestRate} %</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Monthly payment:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.monthlyPayment)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Last purchase price:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.purchasePrice)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Original amortization period in years:</dt>
                                                                    <dd>{selectedObject.loanAmortizationPeriod} years</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Property tax:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.propertyTax)}</dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            )}
                                        </div>
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            {selectedObject.dealtype === "forSale" && (
                                                <article className={`card card-custom card-corporate ${moreInformationAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionMoreDetalsToggle} className={`card-link ${moreInformationAccordionOpened ? '' : 'collapsed'}`}
                                                            role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={moreInformationAccordionOpened}>
                                                            {selectedObject.dealtype !== "forSaleAsMortgageAsset" ? (<span>
                                                                Asking price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>) : (<span>More Details</span>)}
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    {/* need to decide what detals should be dsiplayed in thsi block */}
                                                    <div className={`collapse ${moreInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body">
                                                            <div className="layout-1 text-margin">
                                                                <dl className="list-terms-inline">
                                                                    <dt>Monthly HOA payment:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.monthlyHOApayment)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Year property was built:</dt>
                                                                    <dd>{selectedObject.yearOfBuild === '' ? 'information not provided' : selectedObject.yearOfBuild}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Property condition:</dt>
                                                                    <dd>{selectedObject.propertyCondition} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Last purchase price:</dt>
                                                                    <dd>{selectedObject.purchasePrice} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Property tax:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.propertyTax)}</dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            )}
                                        </div>
                                        {/* <!-- collapse--> */}
                                        <div className="card-group-custom card-group-corporate" id="accordion2" >
                                            {/* <!-- card--> */}
                                            <article className={`card card-custom card-corporate ${featuresAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionFeaturesToggle} className={`card-link ${featuresAccordionOpened ? '' : 'collapsed'}`} role="button" aria-controls="accordion1-collapse-1" aria-expanded={featuresAccordionOpened}><span>დამატებითი ინფორმაცია</span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>
                                                <div className={`collapse ${featuresAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" style={{ minHeight: "fit-content" }}>
                                                    <div className="col-12">
                                                        <div className="row row-10 features-container" ref={featuresContainerRef}>
                                                            <div className="">
                                                                <ul className="list-sm">
                                                                    <li hidden={!selectedObject.twostories}>
                                                                        <p>   2 სართულიანი </p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.basketballcourt}>
                                                                        <p>კალათბურთის მოედანი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.gym}>
                                                                        <p>სავარჯიშო დარბაზი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.fireplace}>
                                                                        <p>ბუხარი</p>
                                                                    </li>

                                                                    <li hidden={!selectedObject.sprinklers}>
                                                                        <p>სპრინკლერები</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.privatespace}>
                                                                        <p>დამატებითი ფართი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.balcony}>
                                                                        <p>ბალკონი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.laundry} >
                                                                        <p>ლონდრი</p>
                                                                    </li>

                                                                    <li hidden={!selectedObject.oceanview} >
                                                                        <p>ოკეანის ხედი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.centralheating}>
                                                                        <p>ცენტრალური გათბობა</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.lawn} >
                                                                        <p>მდელო</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.outdoorarea}>
                                                                        <p>ეზო</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.wifi}>
                                                                        <p>უკაბელო ინტერნეტი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.hometheater} >
                                                                        <p>სახლის თეატრი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.parkinglot}>
                                                                        <p>დასაპარკინგებელი ადგილი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.catsAndDogsAllowed}>
                                                                        <p>ძაღლები და კატები დაშვებულია</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.elevator}>
                                                                        <p>ლიფტი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.fulltimeDoorman}>
                                                                        <p>კარისკაცი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.bikeRoom}>
                                                                        <p>ველოსიპედის შესანახი</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.storageInBuilding}>
                                                                        <p>საკუჭნაო შენობაში</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.centralAir}>
                                                                        <p>ცენტრალური გათბობა</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.roofdeck}>
                                                                        <p>რუფდეკი</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        {/* <!-- collapse--> */}
                                        <div className="divider"></div>
                                        <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                        {/* <div className="col-12">
                    <button className="button button-sm button-secondary"><RouterLink to={`/editlisting/` + selectedObject._id} style={{ textDecoration: "none", color: "black" }}>განცხადებაში ცვლილებების შეტანა</RouterLink></button>
                  </div> */}
                                        <div hidden={userId !== selectedObject.user._id} className="row">
                                            <div className="col-6">
                                                <button className="button button-sm button-secondary">
                                                    <RouterLink to={`/editlisting/${selectedObject._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                        განცხადებაში ცვლილებების შეტანა
                                                    </RouterLink>
                                                </button>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <button
                                                    className="button button-sm button-secondary"
                                                    style={{ textDecoration: "none", color: "black" }}
                                                    onClick={() =>
                                                        window.confirm(
                                                            "დარწმუნებული ხართ, რომ გსურთ ამ განცხადების წაშლა? როგორც კი ამას გააკეთებთ, ვერ აღადგენთ წაშლილ განცხადებას და გამოწერას. თუ განცხადება იყო ფასიანი დაკარგავთ მისი ერთთვიანი გამოწერის დარჩენილ ვადასაც. ეს არ შეეხება სხვა განცხადებებს."
                                                        )
                                                            ? navigate(`/delete/${selectedObject._id}`)
                                                            : null
                                                    }
                                                >
                                                    განცხადების წაშლა
                                                </button>
                                            </div>
                                        </div>
                                        <div className="layout">
                                            <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                        </div>
                                        <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                        </div>
                                        <div className="block-group-item">
                                            <h3>საცხოვრებელი რუქაზე</h3>
                                            <MapWrapper>
                                                <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedObject} defaultCenter={selectedObject}></Map>
                                            </MapWrapper>
                                        </div>
                                        {/* <div className="blog-post-solo-footer mt-20">
                                        </div> */}
                                        {/* <div className="mt-35 mt-md-50 mt-lg-60">
                <article className="block-person">
                  <div className="block-person-left"><img src="images/agent-page-01-650x756.jpg" alt="" width="650" height="756" />
                  </div>
                  <div className="block-person-body">
                    <h3 className="block-person-title">John Peterson</h3>
                    <p className="block-person-cite">Real Estate Broker</p>
                    <ul className="block-person-list">
                      <li>
                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 098 890 7656</a></div>
                      </li>
                      <li>
                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">sam@superteamusa.com</a></div>
                      </li>
                    </ul>
                    <p>Being a full time real estate broker for over 15 years has given Sam the opportunity to work with the most wonderful clients.</p>
                    <Link
                      className="button button-primary"
                      to="sendEmailForm"
                      smooth={true}
                      duration={500}
                    >
                      Get in Touch
                    </Link>
                  </div>
                </article>
              </div> */}
                                        <div className="mt-35 mt-md-50 mt-lg-60">
                                            <article className="block-person">
                                                <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                                </div>
                                                <div className="block-person-body">
                                                    <h3 className="block-person-title">სალომე ღურწკაია</h3>
                                                    <p className="block-person-cite">კლიენტებთან ურთიერთობის ექსპერტი</p>
                                                    <ul className="block-person-list">
                                                        <li>
                                                            <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                        </li>
                                                        <li>
                                                            <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                        </li>
                                                        {/* <li>
                        <ul className="list-inline-1">
                          <li><a className="icon-fa-facebook"></a></li>
                          <li><a className="icon-fa-twitter" ></a></li>
                          <li><a className="icon-fa-google-plus" ></a></li>
                          <li><a className="icon-fa-pinterest-p"></a></li>
                        </ul>
                      </li> */}
                                                    </ul>
                                                    <p>5 წლის განმავლობაში დახმარება გაუწია უამრავ კლიენტს</p>
                                                    <Link
                                                        className="button button-primary"
                                                        to="sendEmailForm"
                                                        smooth={true}
                                                        duration={500}
                                                    >
                                                        დაუკავშირდით
                                                    </Link>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-4">
                                        <div className="row row-50">
                                            <div className="col-md-6 col-lg-12">
                                                {/* <article className="block-callboard">
                        <div className="block-callboard-body">
                          <h3 className="block-callboard-title">მოითხოვეთ კონსულტაცია ან საცხოვრებლის ნახვა</h3>
                          <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={`/item/${itemId}`}>
                            <div className="row row-20" id="contact" >
                              <div className="col-12" id="sendEmailForm">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-name" type="text" name="name" placeholder="თქვენი სახელი" value={inputName} onChange={handleInputChangeName} data-constraints="@Required" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-email" type="email" name="from" placeholder="თქვენი ელექტრონული ფოსტა" value={inputFrom} onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-phone" type="text" name="phone" placeholder="თქვენი ტელეფონის ნომერი" value={inputPhone} onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">

                                  <textarea className="form-input" id="contact-message" name="text" placeholder="მესიჯი" value={inputText} onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                </div>
                              </div>
                              <div className="col-12">
                                <button className="button button-block button-secondary" type="submit">მესიჯის გაგზავნა</button>
                                <p style={{ color: 'blue', fontWeight: '600' }}>{responseMessageSendEmail}</p>
                              </div>
                            </div>
                          </form>
                        </div>
                      </article> */}
                                                <SendEmailMainByUser
                                                    itemId={itemId}
                                                    address={selectedObject.address}
                                                    product="Listing Real Rstate"
                                                    activity="Inquiry"
                                                />
                                            </div>
                                            {selectedObject.dealtype === "forSaleAsMortgageAsset" && (
                                                <div className="col-md-6 col-lg-12">
                                                    <div className="block-info bg-default">
                                                        <h3>Deal financial summary</h3>
                                                        <div>
                                                            {/* <div className="form-wrap">
                          <label className="" htmlFor="average-mortgage-rate">Average Mortgage Rate - AMR %</label>
                          <input className="form-input" id="average-mortgage-rate" type="text" name="name" defaultValue={averageMortgageRate + "%"} data-constraints="@Required" onChange={handleInputValueMortgageRate} />
                          <p style={{ fontSize: '11px', fontWeight: '600' }}>Average interest rate by Federal Reserve Bank of St. Louis</p>
                        </div> */}
                                                            <div className="accordion" id="accordionCustomIR">
                                                                <div className="accordion-item">
                                                                    <h2 class="accordion-header">
                                                                        <button className="accordion-button collapsed"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseCustomeInterestRate"
                                                                            aria-expanded="false"
                                                                            aria-controls="collapseCustomeInterestRate"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Stop event propagation to prevent collapsing the parent accordion
                                                                            }}
                                                                        >
                                                                            <div className="form-wrap">
                                                                                <label htmlFor="average-mortgage-rate-monthlypayment" >30 year-fixed Aver. Iterest Rate</label>
                                                                                <input className="form-input" id="average-mortgage-rate-monthlypayment" type="text" name="averageInterestRate"
                                                                                    value={averageMortgageRate + "%"}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                    onChange={handleInputValueMortgageRate} />
                                                                                <p style={{ fontSize: '11px', fontWeight: '600' }}>Average interest rate by Federal Reserve Bank of St. Louis</p>
                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseCustomeInterestRate" class="accordion-collapse collapse" >
                                                                        <div className="accordion-body">
                                                                            <input className="form-input" id="average-mortgage-rate-monthlypayment" type="text" name="averageInterestRate"
                                                                                placeholder="Enter your value"
                                                                                onChange={handleInputValueMortgageRate}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="discounted-debt-obligation">Discounted Debt Obligation (USD)</label>
                                                                <input className="form-input" id="discounted-debt-obligation" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(discountedDebtObligation)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="escrow-deposit">Escrow deposit for one year obligation (USD)</label>
                                                                <input className="form-input" id="escrow-deposit" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(escrowDepost)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="contact-1-name">Escrow Depreciation Differential (USD)</label>
                                                                <input className="form-input" id="contact-1-name" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(escrowDepreciationDifferential)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="asset-cost">Asset cost (USD)</label>
                                                                <input className="form-input" id="asset-cost" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(assetCost)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="asset-cost">Total payable/value at purchase of the Asset (USD)</label>
                                                                <input className="form-input" id="asset-cost" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(totalPayable)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-divider "></div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="asset-cost">Find the anticipated gain (USD) from the transaction based the AMR %</label>
                                                                <input className="form-input" id="asset-cost" type="text" placeholder="Enter the property market price best estimation" name="name" onChange={handleChangePropertyMarketPrice} data-constraints="@Required" />
                                                            </div>
                                                            <ul className="form-wrap-list">
                                                                <li>Gain from the deal <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(gainAtSale)}</span>
                                                                </li>
                                                            </ul>
                                                            <div className="form-button">
                                                                <button className="button button-block button-secondary" type="button" onClick={calculateGain}>Calculate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedObject.dealtype === "forSale" && (
                                                <div className="col-md-6 col-lg-12">
                                                    <div className="block-info bg-default">
                                                        <h5>Monthly payment: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(((isNaN(totaPrincipleAndInterest) ? 0 : totaPrincipleAndInterest) + propertyMonthlyTaxPayment + monthlyHOAPayment + inputValueMortgageInsurance + inputValueHomeInsurance))}</h5>
                                                        <div>
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="total-principle-interest">Total principle & interest</label>
                                                                                <input className="form-input" id="total-principle-interest" type="text" name="total-principle-interest" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(isNaN(totaPrincipleAndInterest) ? Math.round((valueForAskingPrice - valueForDownPayment) / (12 * 30)) : totaPrincipleAndInterest)} data-constraints="@Required" />
                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="discounted-debt-obligation">Asking price (USD)</label>
                                                                                <input className="form-input" id="discounted-debt-obligation" type="text" name="name"
                                                                                    defaultValue={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(valueForAskingPrice)} data-constraints="@Required"
                                                                                    onChange={handleInputValueChangeAskingPrice} />
                                                                            </div>
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="escrow-deposit">Downpayment (USD)</label>
                                                                                <input className="form-input" id="escrow-deposit" type="text" name="name" defaultValue={valueForDownPaymentPercent + "%"} data-constraints="@Required" onChange={handleInputValueChangeDownPaymentPercent} />
                                                                                <p hidden={valueForDownPaymentPercent >= 20 ? true : false}>Mortgage insurance is normally required for down payments below 20%.</p>
                                                                                <input className="form-input" id="escrow-deposit" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(valueForDownPayment)} data-constraints="@Required"
                                                                                    onChange={handleInputValueChangeDownPayment}
                                                                                />
                                                                            </div>

                                                                            <div className="accordion-item">
                                                                                <h2 className="accordion-header">
                                                                                    <button
                                                                                        className={`accordion-button ${isSubAccordionOpen ? '' : 'collapsed'}`}
                                                                                        type="button"
                                                                                        onClick={toggleSubAccordion}
                                                                                    >
                                                                                        <div className="form-wrap">
                                                                                            <label htmlFor="average-mortgage-rate-monthlypayment">
                                                                                                30 year-fixed Aver. Iterest Rate
                                                                                            </label>
                                                                                            <input
                                                                                                className="form-input"
                                                                                                id="average-mortgage-rate-monthlypayment"
                                                                                                type="text"
                                                                                                name="averageInterestRate"
                                                                                                value={averageMortgageRate + "%"}
                                                                                                readOnly
                                                                                                data-constraints="@Required"
                                                                                                onChange={handleInputValueMortgageRate}
                                                                                            />
                                                                                            <p style={{ fontSize: '11px', fontWeight: '600' }}>
                                                                                                Average interest rate by Federal Reserve Bank of St. Louis
                                                                                            </p>
                                                                                        </div>
                                                                                    </button>
                                                                                </h2>
                                                                                <div
                                                                                    id="collapseOneSub"
                                                                                    className={`accordion-collapse collapse ${isSubAccordionOpen ? 'show' : ''}`}
                                                                                >
                                                                                    <div className="accordion-body">
                                                                                        <input
                                                                                            className="form-input"
                                                                                            id="average-mortgage-rate-monthlypayment"
                                                                                            type="text"
                                                                                            name="averageInterestRate"
                                                                                            placeholder="Enter your value"
                                                                                            onChange={handleInputValueMortgageRate}
                                                                                            data-constraints="@Required"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Property Tax</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-tax-payment"
                                                                                    type="text"
                                                                                    name="monthly-tax-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(propertyMonthlyTaxPayment)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />
                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-tax-payment"
                                                                                type="text"
                                                                                name="monthly-tax-payment"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={propertyMonthlyTaxPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(propertyMonthlyTaxPayment)}
                                                                                onChange={handleInputValuePropertyMonthlyTaxPayment}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Monthly HOA payment</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-HOA-payment"
                                                                                    type="text"
                                                                                    name="monthly-HOA-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />

                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-HOA-payment"
                                                                                type="text"
                                                                                name="monthly-HOA-payment"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                onChange={handleInputValueMontlyHOAPayment}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item" hidden={valueForDownPaymentPercent >= 20 ? true : false}>
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Mortgage insurance payment</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-mortgage-insurance-payment"
                                                                                    type="text"
                                                                                    name="monthly-mortgage-insurance-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(inputValueMortgageInsurance)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />

                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-mortgage-insurance-payment-custom"
                                                                                type="text"
                                                                                name="monthly-mortgage-insurance-payment-custom"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                onChange={handleInputValueMortgageInsurance}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Home insurance payment</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-home-insurance-payment"
                                                                                    type="text"
                                                                                    name="monthly-home-insurance-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(inputValueHomeInsurance)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />

                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-home-insurance-payment-custom"
                                                                                type="text"
                                                                                name="monthly-home-insurance-payment-custom"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                onChange={handleInputValueHomeInsurance}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-6 col-lg-12">
                                                <div className="block-info bg-default">
                                                    <div className="form-wrap chat-bot">
                                                        <div className="container"><ChatBot /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )}
                {languageToUse === "en" && (
                    <div>
                        <section className="" data-opacity="37">
                            <div className="container">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedObject.address} {selectedObject.city}</h3>
                                </div>
                            </div>
                        </section>
                        {/* <SubMenue product={product} /> */}
                        <div className="divider-section"></div>
                        <div className="section section-md bg-gray-12">
                            <div className="container">
                                <div className="row row-50">
                                    <div className="col-lg-7 col-xl-8">
                                        {/* <!-- Slick Carousel--> */}
                                        <div className="slick-slider-1">
                                            <div className="slick-slider-price">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</div>
                                            <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                                <ImgSlider imagePaths={imagePaths} />
                                            </div>
                                        </div>
                                        <div className="features-block">
                                            <div className="features-block-inner">
                                                <div className="features-block-item">
                                                    <ul className="features-block-list">
                                                        <li hidden={selectedObject.numberOfBathrooms !== 1}><img src="./images/icons/b-bat.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBathrooms} Bathroom</span></li>
                                                        <li hidden={selectedObject.numberOfBathrooms === 1}><img src="./images/icons/b-bat.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBathrooms} Bathrooms</span></li>
                                                        <li hidden={selectedObject.numberOfBedrooms !== 0}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>Studio </span></li>
                                                        <li hidden={selectedObject.numberOfBedrooms !== 1}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBedrooms} Bedroom</span></li>
                                                        <li hidden={selectedObject.numberOfBedrooms === 0 || selectedObject.numberOfBedrooms === 1} aria-disabled={selectedObject.numberOfBedrooms === 1}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBedrooms} Bedrooms</span></li>
                                                        <li><img src="./images/icons/b-s.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.area}  Sq Ft</span></li>
                                                        <li><img src="./images/icons/b-g.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.garages} Garage</span></li>
                                                    </ul>
                                                </div>
                                                <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} isFavoriteBySelection={false} /></div>
                                            </div>
                                        </div>
                                        <div>
                                            <p hidden={selectedObject.linkToVRT === ""}>
                                                <a className="link-text" href={selectedObject.linkToVRT} target="_blank" rel="noopener noreferrer">
                                                    Link to VRT
                                                </a>
                                            </p>
                                            <hr className="line-below-header" hidden={selectedObject.linkToVRT === ""} />
                                        </div>

                                        <div hidden={selectedObject.comment === ""} className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>Short description of the property</span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>
                                                <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                    <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                        <div className="layout">
                                                            <dl className="list-terms-inline text-margin">
                                                                <dd>{selectedObject.comment}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        {/* <!-- collapse--> */}
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            <article className={`card card-custom card-corporate ${generalInformationAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionGeneralInformationToggle} className={`card-link ${generalInformationAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={generalInformationAccordionOpened}><span>{selectedObject.address} {selectedObject.city} {selectedObject.state} </span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>
                                                <div className={`collapse ${generalInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                    <div className="card-body">
                                                        <div className="layout-1 text-margin">
                                                            <dl className="list-terms-inline">
                                                                <dt>Address:</dt>
                                                                <dd>{selectedObject.address}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>City:</dt>
                                                                <dd>{selectedObject.city}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>State/County:</dt>
                                                                <dd>{selectedObject.state}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Zip:</dt>
                                                                <dd>{selectedObject.zip}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Property type:</dt>
                                                                <dd>{selectedObject.propertytype}</dd>
                                                            </dl>
                                                            <dl hidden={selectedObject.dealtype === "forRent"} className="list-terms-inline">
                                                                <dt>Property condition:</dt>
                                                                <dd>{selectedObject.propertyCondition}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        {/* <!-- collapse--> */}
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            {selectedObject.dealtype === "forRent" && (
                                                <article className={`card card-custom card-corporate ${rentInformationAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionRentInformationToggle} className={`card-link ${rentInformationAccordionOpened ? '' : 'collapsed'}`}
                                                            role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={rentInformationAccordionOpened}><span>
                                                                Reant price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>
                                                            <span>{selectedObject.rentChargePeriod === "monthly" ? " /month" : ""}</span>
                                                            <span>{selectedObject.rentChargePeriod === "daily" ? " /day" : ""}</span>
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    <div className={`collapse ${rentInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body">
                                                            <div className="layout-1 text-margin">
                                                                <dl className="list-terms-inline">
                                                                    <dt>Year property was built:</dt>
                                                                    <dd>{selectedObject.yearOfBuild === '' ? 'information not provided' : selectedObject.yearOfBuild} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Rental unit:</dt>
                                                                    <dd>{selectedObject.rentalUnit} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Rental unit is:</dt>
                                                                    <dd>{selectedObject.rentalUnitFurnished} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Listed by:</dt>
                                                                    <dd>{selectedObject.name}</dd>, <dd> {selectedObject.email}</dd>, <dd> {selectedObject.phone}</dd>
                                                                </dl>
                                                                {selectedObject.listingby && selectedObject.listingby !== "" && (
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Listed by:</dt>
                                                                        <dd> {selectedObject.listingby === "owner" ? "Owner" : "Agent"}</dd>
                                                                    </dl>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            )}
                                        </div>
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            {selectedObject.dealtype === "forSaleAsMortgageAsset" && (
                                                <article className={`card card-custom card-corporate ${mortgageInformationAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionMortgageInformationToggle} className={`card-link ${mortgageInformationAccordionOpened ? '' : 'collapsed'}`}
                                                            role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={mortgageInformationAccordionOpened}><span>
                                                                Asking price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    <div className={`collapse ${mortgageInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body">
                                                            <div className="layout-1 text-margin">
                                                                <dl className="list-terms-inline">
                                                                    <dt>Remaining principle:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.remaningPrinciple)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Remaining amortization period in months:</dt>
                                                                    <dd>{selectedObject.remaningAmortizationPeriod} months</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Interest rate:</dt>
                                                                    <dd>{selectedObject.interestRate} %</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Monthly payment:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.monthlyPayment)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Last purchase price:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.purchasePrice)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Original amortization period in years:</dt>
                                                                    <dd>{selectedObject.loanAmortizationPeriod} years</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Property tax:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.propertyTax)}</dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            )}
                                        </div>
                                        <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            {selectedObject.dealtype === "forSale" && (
                                                <article className={`card card-custom card-corporate ${moreInformationAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionMoreDetalsToggle} className={`card-link ${moreInformationAccordionOpened ? '' : 'collapsed'}`}
                                                            role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={moreInformationAccordionOpened}>
                                                            {selectedObject.dealtype !== "forSaleAsMortgageAsset" ? (<span>
                                                                Asking price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>) : (<span>More Details</span>)}
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    {/* need to decide what detals should be dsiplayed in thsi block */}
                                                    <div className={`collapse ${moreInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body">
                                                            <div className="layout-1 text-margin">
                                                                <dl className="list-terms-inline">
                                                                    <dt>Monthly HOA payment:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.monthlyHOApayment)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Year property was built:</dt>
                                                                    <dd>{selectedObject.yearOfBuild === '' ? 'information not provided' : selectedObject.yearOfBuild}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Property condition:</dt>
                                                                    <dd>{selectedObject.propertyCondition} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Last purchase price:</dt>
                                                                    <dd>{selectedObject.purchasePrice} </dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Property tax:</dt>
                                                                    <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.propertyTax)}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Listed by:</dt>
                                                                    <dd>{selectedObject.name}</dd>, <dd> {selectedObject.email}</dd>, <dd> {selectedObject.phone}</dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            )}
                                        </div>
                                        {/* <!-- collapse--> */}
                                        <div className="card-group-custom card-group-corporate" id="accordion2" role="tablist" aria-multiselectable="false">
                                            {/* <!-- card--> */}
                                            <article className={`card card-custom card-corporate ${featuresAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionFeaturesToggle} className={`card-link ${featuresAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={featuresAccordionOpened}><span>Features</span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>
                                                <div className={`collapse ${featuresAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                    <div className="col-12">
                                                        <div className="row row-10 features-container" ref={featuresContainerRef}>
                                                            <div className="col-6 col-md-3">
                                                                <ul className="list-sm">
                                                                    <li hidden={!selectedObject.twostories}>
                                                                        <p>   2 Stories </p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.basketballcourt}>
                                                                        <p>Basketball Court</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.gym}>
                                                                        <p>Gym</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.fireplace}>
                                                                        <p>Fireplace</p>
                                                                    </li>

                                                                    <li hidden={!selectedObject.sprinklers}>
                                                                        <p>Sprinklers</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.privatespace}>
                                                                        <p>Private space</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.balcony}>
                                                                        <p>Balcony</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.laundry} >
                                                                        <p>Laundry</p>
                                                                    </li>

                                                                    <li hidden={!selectedObject.oceanview} >
                                                                        <p>Ocean view</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.centralheating}>
                                                                        <p>Central Heating</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.lawn} >
                                                                        <p>Lawn</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.outdoorarea}>
                                                                        <p>Outdoor area</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.wifi}>
                                                                        <p>Wi-Fi</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.hometheater} >
                                                                        <p>Home theater</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.parkinglot}>
                                                                        <p>Parking lot</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.catsAndDogsAllowed}>
                                                                        <p>Cats and dogs allowed</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.elevator}>
                                                                        <p>Elevator</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.fulltimeDoorman}>
                                                                        <p>Full time doorman</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.bikeRoom}>
                                                                        <p>Bike room</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.storageInBuilding}>
                                                                        <p>Storage building</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.centralAir}>
                                                                        <p>Central air</p>
                                                                    </li>
                                                                    <li hidden={!selectedObject.roofdeck}>
                                                                        <p>Roofdeck</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        {/* <!-- collapse--> */}
                                        <div className="divider"></div>
                                        <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                        <div hidden={userId !== selectedObject.user._id} className="row">
                                            <div className="col-6">
                                                <button className="button button-sm button-secondary">
                                                    <RouterLink to={`/editlisting/${selectedObject._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                        Edit listing
                                                    </RouterLink>
                                                </button>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <button
                                                    className="button button-sm button-secondary"
                                                    style={{ textDecoration: "none", color: "black" }}
                                                    onClick={() =>
                                                        window.confirm(
                                                            "Are you sure you want to remove this listing? Once you do this, you can't undo it. If you had a paid subscription for that listing, you'll lose it too."
                                                        )
                                                            ? navigate(`/delete/${selectedObject._id}`)
                                                            : null
                                                    }
                                                >
                                                    Delete listing
                                                </button>
                                            </div>
                                        </div>
                                        <div className="layout">
                                            <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                        </div>
                                        <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                        </div>
                                        <div className="block-group-item">
                                            <h3>Property location</h3>
                                            <MapWrapper>
                                                <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedObject} defaultCenter={selectedObject}></Map>
                                            </MapWrapper>
                                        </div>
                                        <div className="blog-post-solo-footer mt-20">
                                        </div>
                                        {/* <div className="mt-35 mt-md-50 mt-lg-60">
                    <article className="block-person">
                      <div className="block-person-left"><img src="images/agent-page-01-650x756.jpg" alt="" width="650" height="756" />
                      </div>
                      <div className="block-person-body">
                        <h3 className="block-person-title">John Peterson</h3>
                        <p className="block-person-cite">Real Estate Broker</p>
                        <ul className="block-person-list">
                          <li>
                            <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 098 890 7656</a></div>
                          </li>
                          <li>
                            <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">sam@superteamusa.com</a></div>
                          </li>

                        </ul>
                        <p>Being a full time real estate broker for over 15 years has given Sam the opportunity to work with the most wonderful clients.</p>
                        <Link
                          className="button button-primary"
                          to="sendEmailForm"
                          smooth={true}
                          duration={500}
                        >
                          Get in Touch
                        </Link>
                      </div>
                    </article>
                  </div> */}
                                        <div className="mt-35 mt-md-50 mt-lg-60">
                                            <article className="block-person">
                                                <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                                </div>
                                                <div className="block-person-body">
                                                    <h3 className="block-person-title">Sally Gurtskaia</h3>
                                                    <p className="block-person-cite">Customer success manager</p>
                                                    <ul className="block-person-list">
                                                        <li>
                                                            <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                        </li>
                                                        <li>
                                                            <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                        </li>
                                                        {/* <li>
                        <ul className="list-inline-1">
                          <li><a className="icon-fa-facebook"></a></li>
                          <li><a className="icon-fa-twitter" ></a></li>
                          <li><a className="icon-fa-google-plus" ></a></li>
                          <li><a className="icon-fa-pinterest-p"></a></li>
                        </ul>
                      </li> */}
                                                    </ul>
                                                    <p>Being a full time customer success manager for over 5 years has given Sally the opportunity to work with the most wonderful clients</p>
                                                    <Link
                                                        className="button button-primary"
                                                        to="sendEmailForm"
                                                        smooth={true}
                                                        duration={500}
                                                    >
                                                        Get in touch
                                                    </Link>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-4">
                                        <div className="row row-50">
                                            <div className="col-md-6 col-lg-12">
                                                {/* <article className="block-callboard">
                        <div className="block-callboard-body">
                          <h3 className="block-callboard-title">Request a visit or cosultation</h3>
                          <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={`/item/${itemId}`}>
                            <div className="row row-20" id="contact" >
                              <div className="col-12" id="sendEmailForm">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-name" type="text" name="name" placeholder="Your Name" value={inputName} onChange={handleInputChangeName} data-constraints="@Required" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-email" type="email" name="from" placeholder="E-mail" value={inputFrom} onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-phone" type="text" name="phone" placeholder="Phone" value={inputPhone} onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="Subject" value={inputSubject} onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">

                                  <textarea className="form-input" id="contact-message" name="text" placeholder="Message" value={inputText} onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                </div>
                              </div>
                              <div className="col-12">
                                <button className="button button-block button-secondary" type="submit">Send message</button>
                                <p style={{ color: 'blue', fontWeight: '600' }}>{responseMessageSendEmail}</p>
                              </div>
                            </div>
                          </form>
                        </div>
                      </article> */}
                                                <SendEmailMainByUser
                                                    itemId={itemId}
                                                    address={selectedObject.address}
                                                    product="Listing Real Rstate"
                                                    activity="Inquiry"
                                                />
                                            </div>
                                            {selectedObject.dealtype === "forSaleAsMortgageAsset" && (
                                                <div className="col-md-6 col-lg-12">
                                                    <div className="block-info bg-default">
                                                        <h3>Deal financial summary</h3>
                                                        <div>
                                                            {/* <div className="form-wrap">
                          <label className="" htmlFor="average-mortgage-rate">Average Mortgage Rate - AMR %</label>
                          <input className="form-input" id="average-mortgage-rate" type="text" name="name" defaultValue={averageMortgageRate + "%"} data-constraints="@Required" onChange={handleInputValueMortgageRate} />
                          <p style={{ fontSize: '11px', fontWeight: '600' }}>Average interest rate by Federal Reserve Bank of St. Louis</p>
                        </div> */}
                                                            <div className="accordion" id="accordionCustomIR">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseCustomeInterestRate"
                                                                            aria-expanded="false"
                                                                            aria-controls="collapseCustomeInterestRate"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Stop event propagation to prevent collapsing the parent accordion
                                                                            }}
                                                                        >
                                                                            <div className="form-wrap">
                                                                                <label htmlFor="average-mortgage-rate-monthlypayment" >30 year-fixed Aver. Iterest Rate</label>
                                                                                <input className="form-input" id="average-mortgage-rate-monthlypayment" type="text" name="averageInterestRate"
                                                                                    value={averageMortgageRate + "%"}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                    onChange={handleInputValueMortgageRate} />
                                                                                <p style={{ fontSize: '11px', fontWeight: '600' }}>Average interest rate by Federal Reserve Bank of St. Louis</p>
                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseCustomeInterestRate" className="accordion-collapse collapse" >
                                                                        <div className="accordion-body">
                                                                            <input className="form-input" id="average-mortgage-rate-monthlypayment" type="text" name="averageInterestRate"
                                                                                placeholder="Enter your value"
                                                                                onChange={handleInputValueMortgageRate}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="discounted-debt-obligation">Discounted Debt Obligation (USD)</label>
                                                                <input className="form-input" id="discounted-debt-obligation" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(discountedDebtObligation)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="escrow-deposit">Escrow deposit for one year obligation (USD)</label>
                                                                <input className="form-input" id="escrow-deposit" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(escrowDepost)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="contact-1-name">Escrow Depreciation Differential (USD)</label>
                                                                <input className="form-input" id="contact-1-name" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(escrowDepreciationDifferential)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="asset-cost">Asset cost (USD)</label>
                                                                <input className="form-input" id="asset-cost" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(assetCost)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="asset-cost">Total payable/value at purchase of the Asset (USD)</label>
                                                                <input className="form-input" id="asset-cost" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(totalPayable)} data-constraints="@Required" />
                                                            </div>
                                                            <div className="form-divider "></div>
                                                            <div className="form-wrap">
                                                                <label className="" htmlFor="asset-cost">Find the anticipated gain (USD) from the transaction based the AMR %</label>
                                                                <input className="form-input" id="asset-cost" type="text" placeholder="Enter the property market price best estimation" name="name" onChange={handleChangePropertyMarketPrice} data-constraints="@Required" />
                                                            </div>
                                                            <ul className="form-wrap-list">
                                                                <li>Gain from the deal <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(gainAtSale)}</span>
                                                                </li>
                                                            </ul>
                                                            <div className="form-button">
                                                                <button className="button button-block button-secondary" type="button" onClick={calculateGain}>Calculate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedObject.dealtype === "forSale" && (
                                                <div className="col-md-6 col-lg-12">
                                                    <div className="block-info bg-default">
                                                        <h5>Monthly payment: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(((isNaN(totaPrincipleAndInterest) ? 0 : totaPrincipleAndInterest) + propertyMonthlyTaxPayment + monthlyHOAPayment + inputValueMortgageInsurance + inputValueHomeInsurance))}</h5>
                                                        <div>
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="total-principle-interest">Total principle & interest</label>
                                                                                <input className="form-input" id="total-principle-interest" type="text" name="total-principle-interest" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(isNaN(totaPrincipleAndInterest) ? Math.round((valueForAskingPrice - valueForDownPayment) / (12 * 30)) : totaPrincipleAndInterest)} data-constraints="@Required" />
                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="discounted-debt-obligation">Asking price (USD)</label>
                                                                                <input className="form-input" id="discounted-debt-obligation" type="text" name="name"
                                                                                    defaultValue={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(valueForAskingPrice)} data-constraints="@Required"
                                                                                    onChange={handleInputValueChangeAskingPrice} />
                                                                            </div>
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="escrow-deposit">Downpayment (USD)</label>
                                                                                <input className="form-input" id="escrow-deposit" type="text" name="name" defaultValue={valueForDownPaymentPercent + "%"} data-constraints="@Required" onChange={handleInputValueChangeDownPaymentPercent} />
                                                                                <p hidden={valueForDownPaymentPercent >= 20 ? true : false}>Mortgage insurance is normally required for down payments below 20%.</p>
                                                                                <input className="form-input" id="escrow-deposit" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(valueForDownPayment)} data-constraints="@Required"
                                                                                    onChange={handleInputValueChangeDownPayment}
                                                                                />
                                                                            </div>

                                                                            <div className="accordion-item">
                                                                                <h2 className="accordion-header">
                                                                                    <button
                                                                                        className={`accordion-button ${isSubAccordionOpen ? '' : 'collapsed'}`}
                                                                                        type="button"
                                                                                        onClick={toggleSubAccordion}
                                                                                    >
                                                                                        <div className="form-wrap">
                                                                                            <label htmlFor="average-mortgage-rate-monthlypayment">
                                                                                                30 year-fixed Aver. Iterest Rate
                                                                                            </label>
                                                                                            <input
                                                                                                className="form-input"
                                                                                                id="average-mortgage-rate-monthlypayment"
                                                                                                type="text"
                                                                                                name="averageInterestRate"
                                                                                                value={averageMortgageRate + "%"}
                                                                                                readOnly
                                                                                                data-constraints="@Required"
                                                                                                onChange={handleInputValueMortgageRate}
                                                                                            />
                                                                                            <p style={{ fontSize: '11px', fontWeight: '600' }}>
                                                                                                Average interest rate by Federal Reserve Bank of St. Louis
                                                                                            </p>
                                                                                        </div>
                                                                                    </button>
                                                                                </h2>
                                                                                <div
                                                                                    id="collapseOneSub"
                                                                                    className={`accordion-collapse collapse ${isSubAccordionOpen ? 'show' : ''}`}
                                                                                >
                                                                                    <div className="accordion-body">
                                                                                        <input
                                                                                            className="form-input"
                                                                                            id="average-mortgage-rate-monthlypayment"
                                                                                            type="text"
                                                                                            name="averageInterestRate"
                                                                                            placeholder="Enter your value"
                                                                                            onChange={handleInputValueMortgageRate}
                                                                                            data-constraints="@Required"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Property Tax</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-tax-payment"
                                                                                    type="text"
                                                                                    name="monthly-tax-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(propertyMonthlyTaxPayment)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />
                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-tax-payment"
                                                                                type="text"
                                                                                name="monthly-tax-payment"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={propertyMonthlyTaxPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(propertyMonthlyTaxPayment)}
                                                                                onChange={handleInputValuePropertyMonthlyTaxPayment}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Monthly HOA payment</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-HOA-payment"
                                                                                    type="text"
                                                                                    name="monthly-HOA-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />

                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-HOA-payment"
                                                                                type="text"
                                                                                name="monthly-HOA-payment"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                onChange={handleInputValueMontlyHOAPayment}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item" hidden={valueForDownPaymentPercent >= 20 ? true : false}>
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Mortgage insurance payment</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-mortgage-insurance-payment"
                                                                                    type="text"
                                                                                    name="monthly-mortgage-insurance-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(inputValueMortgageInsurance)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />

                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-mortgage-insurance-payment-custom"
                                                                                type="text"
                                                                                name="monthly-mortgage-insurance-payment-custom"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                onChange={handleInputValueMortgageInsurance}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            <div className="form-wrap">
                                                                                <label className="" htmlFor="monthly-tax-payment">Home insurance payment</label>
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-home-insurance-payment"
                                                                                    type="text"
                                                                                    name="monthly-home-insurance-payment"
                                                                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(inputValueHomeInsurance)}
                                                                                    readOnly
                                                                                    data-constraints="@Required"
                                                                                />

                                                                            </div>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <input
                                                                                className="form-input"
                                                                                id="monthly-home-insurance-payment-custom"
                                                                                type="text"
                                                                                name="monthly-home-insurance-payment-custom"
                                                                                placeholder="Enter your value"
                                                                                // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                onChange={handleInputValueHomeInsurance}
                                                                                data-constraints="@Required"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-6 col-lg-12">
                                                <div className="block-info bg-default">
                                                    <div className="form-wrap chat-bot">
                                                        <div className="container"><ChatBot /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )}
                {
                    languageToUse === "ru" && (
                        <div>
                            <section className="" data-opacity="37">
                                <div className="container">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                        <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedObject.address} {selectedObject.city}</h3>
                                    </div>
                                </div>
                            </section>
                            {/* <SubMenue product={product} /> */}
                            <div className="divider-section"></div>
                            <div className="section section-md bg-gray-12">
                                <div className="container">
                                    <div className="row row-50">
                                        <div className="col-lg-7 col-xl-8">
                                            {/* <!-- Slick Carousel--> */}
                                            <div className="slick-slider-1">
                                                <div className="slick-slider-price">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</div>
                                                <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                                    {/* <Slider {...settings}>
                          {imagePaths && imagePaths.length > 0 ? (
                            imagePaths.map((image, index) => (
                              <img
                                key={index}
                                src={image}
                                alt={`Item ${index}`}
                                width="763" height="443"
                              />
                            ))
                          ) : (
                            <img src="./images/ser-r.png" alt="Default Item" style={{ width: '100%', maxHeight: '443px' }} />
                          )}
                        </Slider> */}
                                                    <ImgSlider imagePaths={imagePaths} />
                                                </div>
                                            </div>
                                            <div className="features-block">
                                                <div className="features-block-inner">
                                                    <div className="features-block-item">
                                                        <ul className="features-block-list">
                                                            <li hidden={selectedObject.numberOfBathrooms !== 1}><img src="./images/icons/b-bat.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBathrooms} Ванная</span></li>
                                                            <li hidden={selectedObject.numberOfBathrooms === 1}><img src="./images/icons/b-bat.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBathrooms} Ванные</span></li>
                                                            <li hidden={selectedObject.numberOfBedrooms !== 0}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>Студия </span></li>
                                                            <li hidden={selectedObject.numberOfBedrooms !== 1}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBedrooms} Спальня</span></li>
                                                            <li hidden={selectedObject.numberOfBedrooms === 0 || selectedObject.numberOfBedrooms === 1} aria-disabled={selectedObject.numberOfBedrooms === 1}><img src="./images/icons/b-bed.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.numberOfBedrooms} Спальни</span></li>
                                                            <li><img src="./images/icons/b-s.png" alt="" style={{ width: '24px', hsseight: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.area}  Sq Ft</span></li>
                                                            <li><img src="./images/icons/b-g.png" alt="" style={{ width: '24px', height: '24px' }} /><span style={{ marginLeft: '10px', fontWeight: '600' }}>{selectedObject.garages} Garage</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} isFavoriteBySelection={false} /></div>
                                                </div>
                                            </div>
                                            <div>
                                                <p hidden={selectedObject.linkToVRT === ""}>
                                                    <a className="link-text" href={selectedObject.linkToVRT} target="_blank" rel="noopener noreferrer">
                                                        Ссылка на видео
                                                    </a>
                                                </p>
                                                <hr className="line-below-header" hidden={selectedObject.linkToVRT === ""} />
                                            </div>

                                            <div hidden={selectedObject.comment === ""} className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                                {/* <!-- card--> */}
                                                <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>Краткое описание недвижимости</span>
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                            <div className="layout">
                                                                <dl className="list-terms-inline text-margin">
                                                                    <dd>{selectedObject.comment}</dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>

                                            {/* <!-- collapse--> */}
                                            <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                                {/* <!-- card--> */}
                                                <article className={`card card-custom card-corporate ${generalInformationAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionGeneralInformationToggle} className={`card-link ${generalInformationAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={generalInformationAccordionOpened}><span>{selectedObject.address} {selectedObject.city} {selectedObject.state} </span>
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    <div className={`collapse ${generalInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="card-body">
                                                            <div className="layout-1 text-margin">
                                                                <dl className="list-terms-inline">
                                                                    <dt>Адрес:</dt>
                                                                    <dd>{selectedObject.address}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Город:</dt>
                                                                    <dd>{selectedObject.city}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Штат/Страна:</dt>
                                                                    <dd>{selectedObject.state}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Почтовый индекс:</dt>
                                                                    <dd>{selectedObject.zip}</dd>
                                                                </dl>
                                                                <dl className="list-terms-inline">
                                                                    <dt>Тип недвижимости:</dt>
                                                                    <dd>{selectedObject.propertytype === "Дом" ? "дом" : ""}</dd>
                                                                    <dd>{selectedObject.propertytype === "Townhouse" ? "Таунхаус" : ""}</dd>
                                                                    <dd>{selectedObject.propertytype === "Condo" ? "Кондоминиум" : ""}</dd>
                                                                    <dd>{selectedObject.propertytype === "Coop" ? "Кооператив" : ""}</dd>
                                                                </dl>
                                                                <dl hidden={selectedObject.dealtype === "forRent"} className="list-terms-inline">
                                                                    <dt>Состояние недвижимости:</dt>
                                                                    <dd>{selectedObject.propertyCondition}</dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            {/* <!-- collapse--> */}
                                            <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                                {/* <!-- card--> */}
                                                {selectedObject.dealtype === "forRent" && (
                                                    <article className={`card card-custom card-corporate ${rentInformationAccordionOpened ? 'active' : ''}`}>
                                                        <div className="card-header" id="accordion1-heading-1" role="tab">
                                                            <div className="card-title"><a onClick={handleAccordionRentInformationToggle} className={`card-link ${rentInformationAccordionOpened ? '' : 'collapsed'}`}
                                                                role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={rentInformationAccordionOpened}><span>
                                                                    Стоимость аренды {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>
                                                                <span>{selectedObject.rentChargePeriod === "monthly" ? " /месяц" : ""}</span>
                                                                <span>{selectedObject.rentChargePeriod === "daily" ? " /день" : ""}</span>
                                                                <div className="card-arrow"></div></a></div>
                                                        </div>
                                                        <div className={`collapse ${rentInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                            <div className="card-body">
                                                                <div className="layout-1 text-margin">
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Год постройки:</dt>
                                                                        <dd>{selectedObject.yearOfBuild === '' ? 'Информация не предоставлена' : selectedObject.yearOfBuild} </dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Арендная единица:</dt>
                                                                        <dd>{selectedObject.rentalUnit === "entireProperty" ? "Квартира/дом целиком" : ""} </dd>
                                                                        <dd>{selectedObject.rentalUnit === "room" ? "Комната" : ""} </dd>
                                                                        <dd>{selectedObject.rentalUnit === "bed" ? "Кровать" : ""} </dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Сдаётся:</dt>
                                                                        <dd>{selectedObject.rentalUnitFurnished === "furnished" ? "с мебелью" : ""} </dd>
                                                                        <dd>{selectedObject.rentalUnitFurnished === "notfurnished" ? "Без мебели" : ""} </dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Автор:</dt>
                                                                        <dd>{selectedObject.name}</dd>, <dd> {selectedObject.email}</dd>, <dd> {selectedObject.phone}</dd>
                                                                    </dl>
                                                                    {selectedObject.listingby && selectedObject.listingby !== "" && (
                                                                        <dl className="list-terms-inline">
                                                                            <dt>Автор:</dt>
                                                                            <dd> {selectedObject.listingby === "owner" ? "Владелец" : "Агент"}</dd>
                                                                        </dl>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                )}
                                            </div>
                                            <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                                {/* <!-- card--> */}
                                                {selectedObject.dealtype === "forSaleAsMortgageAsset" && (
                                                    <article className={`card card-custom card-corporate ${mortgageInformationAccordionOpened ? 'active' : ''}`}>
                                                        <div className="card-header" id="accordion1-heading-1" role="tab">
                                                            <div className="card-title"><a onClick={handleAccordionMortgageInformationToggle} className={`card-link ${mortgageInformationAccordionOpened ? '' : 'collapsed'}`}
                                                                role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={mortgageInformationAccordionOpened}><span>
                                                                    Asking price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>
                                                                <div className="card-arrow"></div></a></div>
                                                        </div>
                                                        <div className={`collapse ${mortgageInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                            <div className="card-body">
                                                                <div className="layout-1 text-margin">
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Remaining principle:</dt>
                                                                        <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.remaningPrinciple)}</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Remaining amortization period in months:</dt>
                                                                        <dd>{selectedObject.remaningAmortizationPeriod} months</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Interest rate:</dt>
                                                                        <dd>{selectedObject.interestRate} %</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Monthly payment:</dt>
                                                                        <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.monthlyPayment)}</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Last purchase price:</dt>
                                                                        <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.purchasePrice)}</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Original amortization period in years:</dt>
                                                                        <dd>{selectedObject.loanAmortizationPeriod} years</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Property tax:</dt>
                                                                        <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.propertyTax)}</dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                )}
                                            </div>
                                            <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                                {/* <!-- card--> */}
                                                {selectedObject.dealtype === "forSale" && (
                                                    <article className={`card card-custom card-corporate ${moreInformationAccordionOpened ? 'active' : ''}`}>
                                                        <div className="card-header" id="accordion1-heading-1" role="tab">
                                                            <div className="card-title"><a onClick={handleAccordionMoreDetalsToggle} className={`card-link ${moreInformationAccordionOpened ? '' : 'collapsed'}`}
                                                                role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={moreInformationAccordionOpened}>
                                                                {selectedObject.dealtype !== "forSaleAsMortgageAsset" ? (<span>
                                                                    Asking price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.askingPrice)}</span>) : (<span>More Details</span>)}
                                                                <div className="card-arrow"></div></a></div>
                                                        </div>
                                                        {/* need to decide what detals should be dsiplayed in thsi block */}
                                                        <div className={`collapse ${moreInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                            <div className="card-body">
                                                                <div className="layout-1 text-margin">
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Monthly HOA payment:</dt>
                                                                        <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.monthlyHOApayment)}</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Year property was built:</dt>
                                                                        <dd>{selectedObject.yearOfBuild === '' ? 'Информация не предоставлена' : selectedObject.yearOfBuild}</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Property condition:</dt>
                                                                        <dd>{selectedObject.propertyCondition} </dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Last purchase price:</dt>
                                                                        <dd>{selectedObject.purchasePrice} </dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Property tax:</dt>
                                                                        <dd>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedObject.propertyTax)}</dd>
                                                                    </dl>
                                                                    <dl className="list-terms-inline">
                                                                        <dt>Listed by:</dt>
                                                                        <dd>{selectedObject.name}</dd>, <dd> {selectedObject.email}</dd>, <dd> {selectedObject.phone}</dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                )}
                                            </div>
                                            {/* <!-- collapse--> */}
                                            <div className="card-group-custom card-group-corporate" id="accordion2" role="tablist" aria-multiselectable="false">
                                                {/* <!-- card--> */}
                                                <article className={`card card-custom card-corporate ${featuresAccordionOpened ? 'active' : ''}`}>
                                                    <div className="card-header" id="accordion1-heading-1" role="tab">
                                                        <div className="card-title"><a onClick={handleAccordionFeaturesToggle} className={`card-link ${featuresAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={featuresAccordionOpened}><span>Особенности</span>
                                                            <div className="card-arrow"></div></a></div>
                                                    </div>
                                                    <div className={`collapse ${featuresAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                        <div className="col-12">
                                                            <div className="row row-10 features-container" ref={featuresContainerRef}>
                                                                <div className="col-6 col-md-3">
                                                                    <ul className="list-sm">
                                                                        <li hidden={!selectedObject.twostories}>
                                                                            <p>   2 этажа </p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.basketballcourt}>
                                                                            <p>Баскетбольная площадка</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.gym}>
                                                                            <p>Спортзал</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.fireplace}>
                                                                            <p>Камин</p>
                                                                        </li>

                                                                        <li hidden={!selectedObject.sprinklers}>
                                                                            <p>Спринклеры</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.privatespace}>
                                                                            <p>Личное пространство</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.balcony}>
                                                                            <p>Балкон</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.laundry} >
                                                                            <p>Прачечная</p>
                                                                        </li>

                                                                        <li hidden={!selectedObject.oceanview} >
                                                                            <p>Вид на океан</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.centralheating}>
                                                                            <p>Центральное отопление</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.lawn} >
                                                                            <p>Лужайка</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.outdoorarea}>
                                                                            <p>Открытая площадка</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.wifi}>
                                                                            <p>Wi-Fi</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.hometheater} >
                                                                            <p>Домашний театр</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.parkinglot}>
                                                                            <p>Парковка</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.catsAndDogsAllowed}>
                                                                            <p>Кошки и собаки разрешены</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.elevator}>
                                                                            <p>Лифт</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.fulltimeDoorman}>
                                                                            <p>Швейцар на полную занятость</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.bikeRoom}>
                                                                            <p>Велосипедная комната</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.storageInBuilding}>
                                                                            <p>Складское здание</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.centralAir}>
                                                                            <p>Центральная система кондиционирования</p>
                                                                        </li>
                                                                        <li hidden={!selectedObject.roofdeck}>
                                                                            <p>Руфдек</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            {/* <!-- collapse--> */}
                                            <div className="divider"></div>
                                            <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                            <div hidden={userId !== selectedObject.user._id} className="row">
                                                <div className="col-6">
                                                    <button className="button button-sm button-secondary">
                                                        <RouterLink to={`/editlisting/${selectedObject._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                            Редактировать объявление
                                                        </RouterLink>
                                                    </button>
                                                </div>
                                                <div className="col-6 d-flex justify-content-end">
                                                    <button
                                                        className="button button-sm button-secondary"
                                                        style={{ textDecoration: "none", color: "black" }}
                                                        onClick={() =>
                                                            window.confirm(
                                                                "Вы уверены, что хотите удалить это объявление? Сделав это, вы не сможете отменить это. Если у вас была платная подписка на это объявление, вы ее тоже потеряете."
                                                            )
                                                                ? navigate(`/delete/${selectedObject._id}`)
                                                                : null
                                                        }
                                                    >
                                                        Удалить объявление
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                            </div>
                                            <div className="block-group-item">
                                                <h3>Местоположение недвижимости</h3>
                                                <MapWrapper>
                                                    <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedObject} defaultCenter={selectedObject}></Map>
                                                </MapWrapper>
                                            </div>
                                            {/* <div className="mt-35 mt-md-50 mt-lg-60">
                    <article className="block-person">
                      <div className="block-person-left"><img src="images/agent-page-01-650x756.jpg" alt="" width="650" height="756" />
                      </div>
                      <div className="block-person-body">
                        <h3 className="block-person-title">John Peterson</h3>
                        <p className="block-person-cite">Real Estate Broker</p>
                        <ul className="block-person-list">
                          <li>
                            <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 098 890 7656</a></div>
                          </li>
                          <li>
                            <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">sam@superteamusa.com</a></div>
                          </li>

                        </ul>
                        <p>Being a full time real estate broker for over 15 years has given Sam the opportunity to work with the most wonderful clients.</p>
                        <Link
                          className="button button-primary"
                          to="sendEmailForm"
                          smooth={true}
                          duration={500}
                        >
                          Get in Touch
                        </Link>
                      </div>
                    </article>
                  </div> */}
                                            <div className="mt-35 mt-md-50 mt-lg-60">
                                                <article className="block-person">
                                                    <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                                    </div>
                                                    <div className="block-person-body">
                                                        <h3 className="block-person-title">Салли Гурцкая</h3>
                                                        <p className="block-person-cite">Менеджер по работе с клиентами</p>
                                                        <ul className="block-person-list">
                                                            <li>
                                                                <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                            </li>
                                                            <li>
                                                                <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                            </li>
                                                            {/* <li>
                        <ul className="list-inline-1">
                          <li><a className="icon-fa-facebook"></a></li>
                          <li><a className="icon-fa-twitter" ></a></li>
                          <li><a className="icon-fa-google-plus" ></a></li>
                          <li><a className="icon-fa-pinterest-p"></a></li>
                        </ul>
                      </li> */}
                                                        </ul>
                                                        <p>Будучи менеджером по работе с клиентами на протяжении более 5 лет, Салли получила возможность работать с самыми замечательными клиентами.</p>
                                                        <Link
                                                            className="button button-primary"
                                                            to="sendEmailForm"
                                                            smooth={true}
                                                            duration={500}
                                                        >
                                                            Связаться
                                                        </Link>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-4">
                                            <div className="row row-50">
                                                <div className="col-md-6 col-lg-12">
                                                    {/* <article className="block-callboard">
                        <div className="block-callboard-body">
                          <h3 className="block-callboard-title">Request a visit or cosultation</h3>
                          <form onSubmit={handleSubmit} className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action={`/item/${itemId}`}>
                            <div className="row row-20" id="contact" >
                              <div className="col-12" id="sendEmailForm">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-name" type="text" name="name" placeholder="Your Name" value={inputName} onChange={handleInputChangeName} data-constraints="@Required" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-email" type="email" name="from" placeholder="E-mail" value={inputFrom} onChange={handleInputChangeFrom} data-constraints="@Email @Required" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-phone" type="text" name="phone" placeholder="Phone" value={inputPhone} onChange={handleInputChangePhone} data-constraints="@PhoneNumber" />

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">
                                  <input className="form-input" id="contact-subject" type="text" name="subject" placeholder="Subject" value={inputSubject} onChange={handleInputChangeSubject} data-constraints="@PhoneNumber" />

                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-wrap">

                                  <textarea className="form-input" id="contact-message" name="text" placeholder="Message" value={inputText} onChange={handleInputChangeText} data-constraints="@Required"></textarea>
                                </div>
                              </div>
                              <div className="col-12">
                                <button className="button button-block button-secondary" type="submit">Send message</button>
                                <p style={{ color: 'blue', fontWeight: '600' }}>{responseMessageSendEmail}</p>
                              </div>
                            </div>
                          </form>
                        </div>
                      </article> */}
                                                    <SendEmailMainByUser
                                                        itemId={itemId}
                                                        address={selectedObject.address}
                                                        product="Listing Real Rstate"
                                                        activity="Inquiry"
                                                    />
                                                </div>
                                                {selectedObject.dealtype === "forSaleAsMortgageAsset" && (
                                                    <div className="col-md-6 col-lg-12">
                                                        <div className="block-info bg-default">
                                                            <h3>Deal financial summary</h3>
                                                            <div>
                                                                {/* <div className="form-wrap">
                          <label className="" htmlFor="average-mortgage-rate">Average Mortgage Rate - AMR %</label>
                          <input className="form-input" id="average-mortgage-rate" type="text" name="name" defaultValue={averageMortgageRate + "%"} data-constraints="@Required" onChange={handleInputValueMortgageRate} />
                          <p style={{ fontSize: '11px', fontWeight: '600' }}>Average interest rate by Federal Reserve Bank of St. Louis</p>
                        </div> */}
                                                                <div className="accordion" id="accordionCustomIR">
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header">
                                                                            <button className="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseCustomeInterestRate"
                                                                                aria-expanded="false"
                                                                                aria-controls="collapseCustomeInterestRate"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation(); // Stop event propagation to prevent collapsing the parent accordion
                                                                                }}
                                                                            >
                                                                                <div className="form-wrap">
                                                                                    <label htmlFor="average-mortgage-rate-monthlypayment" >30 year-fixed Aver. Iterest Rate</label>
                                                                                    <input className="form-input" id="average-mortgage-rate-monthlypayment" type="text" name="averageInterestRate"
                                                                                        value={averageMortgageRate + "%"}
                                                                                        readOnly
                                                                                        data-constraints="@Required"
                                                                                        onChange={handleInputValueMortgageRate} />
                                                                                    <p style={{ fontSize: '11px', fontWeight: '600' }}>Average interest rate by Federal Reserve Bank of St. Louis</p>
                                                                                </div>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseCustomeInterestRate" className="accordion-collapse collapse" >
                                                                            <div className="accordion-body">
                                                                                <input className="form-input" id="average-mortgage-rate-monthlypayment" type="text" name="averageInterestRate"
                                                                                    placeholder="Enter your value"
                                                                                    onChange={handleInputValueMortgageRate}
                                                                                    data-constraints="@Required"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-wrap">
                                                                    <label className="" htmlFor="discounted-debt-obligation">Discounted Debt Obligation (USD)</label>
                                                                    <input className="form-input" id="discounted-debt-obligation" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(discountedDebtObligation)} data-constraints="@Required" />
                                                                </div>
                                                                <div className="form-wrap">
                                                                    <label className="" htmlFor="escrow-deposit">Escrow deposit for one year obligation (USD)</label>
                                                                    <input className="form-input" id="escrow-deposit" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(escrowDepost)} data-constraints="@Required" />
                                                                </div>
                                                                <div className="form-wrap">
                                                                    <label className="" htmlFor="contact-1-name">Escrow Depreciation Differential (USD)</label>
                                                                    <input className="form-input" id="contact-1-name" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(escrowDepreciationDifferential)} data-constraints="@Required" />
                                                                </div>
                                                                <div className="form-wrap">
                                                                    <label className="" htmlFor="asset-cost">Asset cost (USD)</label>
                                                                    <input className="form-input" id="asset-cost" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(assetCost)} data-constraints="@Required" />
                                                                </div>
                                                                <div className="form-wrap">
                                                                    <label className="" htmlFor="asset-cost">Total payable/value at purchase of the Asset (USD)</label>
                                                                    <input className="form-input" id="asset-cost" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(totalPayable)} data-constraints="@Required" />
                                                                </div>
                                                                <div className="form-divider "></div>
                                                                <div className="form-wrap">
                                                                    <label className="" htmlFor="asset-cost">Find the anticipated gain (USD) from the transaction based the AMR %</label>
                                                                    <input className="form-input" id="asset-cost" type="text" placeholder="Enter the property market price best estimation" name="name" onChange={handleChangePropertyMarketPrice} data-constraints="@Required" />
                                                                </div>
                                                                <ul className="form-wrap-list">
                                                                    <li>Gain from the deal <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(gainAtSale)}</span>
                                                                    </li>
                                                                </ul>
                                                                <div className="form-button">
                                                                    <button className="button button-block button-secondary" type="button" onClick={calculateGain}>Calculate</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedObject.dealtype === "forSale" && (
                                                    <div className="col-md-6 col-lg-12">
                                                        <div className="block-info bg-default">
                                                            <h5>Monthly payment: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(((isNaN(totaPrincipleAndInterest) ? 0 : totaPrincipleAndInterest) + propertyMonthlyTaxPayment + monthlyHOAPayment + inputValueMortgageInsurance + inputValueHomeInsurance))}</h5>
                                                            <div>
                                                                <div className="accordion" id="accordionExample">
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header">
                                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                                <div className="form-wrap">
                                                                                    <label className="" htmlFor="total-principle-interest">Total principle & interest</label>
                                                                                    <input className="form-input" id="total-principle-interest" type="text" name="total-principle-interest" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(isNaN(totaPrincipleAndInterest) ? Math.round((valueForAskingPrice - valueForDownPayment) / (12 * 30)) : totaPrincipleAndInterest)} data-constraints="@Required" />
                                                                                </div>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <div className="form-wrap">
                                                                                    <label className="" htmlFor="discounted-debt-obligation">Asking price (USD)</label>
                                                                                    <input className="form-input" id="discounted-debt-obligation" type="text" name="name"
                                                                                        defaultValue={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(valueForAskingPrice)} data-constraints="@Required"
                                                                                        onChange={handleInputValueChangeAskingPrice} />
                                                                                </div>
                                                                                <div className="form-wrap">
                                                                                    <label className="" htmlFor="escrow-deposit">Downpayment (USD)</label>
                                                                                    <input className="form-input" id="escrow-deposit" type="text" name="name" defaultValue={valueForDownPaymentPercent + "%"} data-constraints="@Required" onChange={handleInputValueChangeDownPaymentPercent} />
                                                                                    <p hidden={valueForDownPaymentPercent >= 20 ? true : false}>Mortgage insurance is normally required for down payments below 20%.</p>
                                                                                    <input className="form-input" id="escrow-deposit" type="text" name="name" readOnly value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(valueForDownPayment)} data-constraints="@Required"
                                                                                        onChange={handleInputValueChangeDownPayment}
                                                                                    />
                                                                                </div>

                                                                                <div className="accordion-item">
                                                                                    <h2 className="accordion-header">
                                                                                        <button
                                                                                            className={`accordion-button ${isSubAccordionOpen ? '' : 'collapsed'}`}
                                                                                            type="button"
                                                                                            onClick={toggleSubAccordion}
                                                                                        >
                                                                                            <div className="form-wrap">
                                                                                                <label htmlFor="average-mortgage-rate-monthlypayment">
                                                                                                    30 year-fixed Aver. Iterest Rate
                                                                                                </label>
                                                                                                <input
                                                                                                    className="form-input"
                                                                                                    id="average-mortgage-rate-monthlypayment"
                                                                                                    type="text"
                                                                                                    name="averageInterestRate"
                                                                                                    value={averageMortgageRate + "%"}
                                                                                                    readOnly
                                                                                                    data-constraints="@Required"
                                                                                                    onChange={handleInputValueMortgageRate}
                                                                                                />
                                                                                                <p style={{ fontSize: '11px', fontWeight: '600' }}>
                                                                                                    Average interest rate by Federal Reserve Bank of St. Louis
                                                                                                </p>
                                                                                            </div>
                                                                                        </button>
                                                                                    </h2>
                                                                                    <div
                                                                                        id="collapseOneSub"
                                                                                        className={`accordion-collapse collapse ${isSubAccordionOpen ? 'show' : ''}`}
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                id="average-mortgage-rate-monthlypayment"
                                                                                                type="text"
                                                                                                name="averageInterestRate"
                                                                                                placeholder="Enter your value"
                                                                                                onChange={handleInputValueMortgageRate}
                                                                                                data-constraints="@Required"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                                <div className="form-wrap">
                                                                                    <label className="" htmlFor="monthly-tax-payment">Property Tax</label>
                                                                                    <input
                                                                                        className="form-input"
                                                                                        id="monthly-tax-payment"
                                                                                        type="text"
                                                                                        name="monthly-tax-payment"
                                                                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(propertyMonthlyTaxPayment)}
                                                                                        readOnly
                                                                                        data-constraints="@Required"
                                                                                    />
                                                                                </div>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-tax-payment"
                                                                                    type="text"
                                                                                    name="monthly-tax-payment"
                                                                                    placeholder="Enter your value"
                                                                                    // defaultValue={propertyMonthlyTaxPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(propertyMonthlyTaxPayment)}
                                                                                    onChange={handleInputValuePropertyMonthlyTaxPayment}
                                                                                    data-constraints="@Required"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                                <div className="form-wrap">
                                                                                    <label className="" htmlFor="monthly-tax-payment">Monthly HOA payment</label>
                                                                                    <input
                                                                                        className="form-input"
                                                                                        id="monthly-HOA-payment"
                                                                                        type="text"
                                                                                        name="monthly-HOA-payment"
                                                                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                        readOnly
                                                                                        data-constraints="@Required"
                                                                                    />

                                                                                </div>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-HOA-payment"
                                                                                    type="text"
                                                                                    name="monthly-HOA-payment"
                                                                                    placeholder="Enter your value"
                                                                                    // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                    onChange={handleInputValueMontlyHOAPayment}
                                                                                    data-constraints="@Required"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item" hidden={valueForDownPaymentPercent >= 20 ? true : false}>
                                                                        <h2 className="accordion-header">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                                <div className="form-wrap">
                                                                                    <label className="" htmlFor="monthly-tax-payment">Mortgage insurance payment</label>
                                                                                    <input
                                                                                        className="form-input"
                                                                                        id="monthly-mortgage-insurance-payment"
                                                                                        type="text"
                                                                                        name="monthly-mortgage-insurance-payment"
                                                                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(inputValueMortgageInsurance)}
                                                                                        readOnly
                                                                                        data-constraints="@Required"
                                                                                    />

                                                                                </div>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-mortgage-insurance-payment-custom"
                                                                                    type="text"
                                                                                    name="monthly-mortgage-insurance-payment-custom"
                                                                                    placeholder="Enter your value"
                                                                                    // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                    onChange={handleInputValueMortgageInsurance}
                                                                                    data-constraints="@Required"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                                <div className="form-wrap">
                                                                                    <label className="" htmlFor="monthly-tax-payment">Home insurance payment</label>
                                                                                    <input
                                                                                        className="form-input"
                                                                                        id="monthly-home-insurance-payment"
                                                                                        type="text"
                                                                                        name="monthly-home-insurance-payment"
                                                                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(inputValueHomeInsurance)}
                                                                                        readOnly
                                                                                        data-constraints="@Required"
                                                                                    />

                                                                                </div>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <input
                                                                                    className="form-input"
                                                                                    id="monthly-home-insurance-payment-custom"
                                                                                    type="text"
                                                                                    name="monthly-home-insurance-payment-custom"
                                                                                    placeholder="Enter your value"
                                                                                    // defaultValue={monthlyHOAPayment === 0 ? "" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 5, useGrouping: true }).format(monthlyHOAPayment)}
                                                                                    onChange={handleInputValueHomeInsurance}
                                                                                    data-constraints="@Required"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-md-6 col-lg-12">
                                                    <div className="block-info bg-default">
                                                        <div className="form-wrap chat-bot">
                                                            <div className="container"><ChatBot /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    )
                }
            </div>
        </main>
    );
}

export default ItemDetailsPopUp;
