import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { useParams } from 'react-router-dom';
import backendUrl from './config';
import Loader from './loader';
import { useLanguage } from './languageContext';
import { useAuth } from './authContext';

function StripeAppPayment({ paymentMade }) {
    const { currentLanguage } = useLanguage();
    const { loggedIn, userId } = useAuth();
    const stripeKey = process.env.REACT_APP_KEY_STRIPE;
    const { itemId } = useParams();
    const { product } = useParams();
    paymentMade = false;
    let productSpec = {};
    if (product === "realestate") {
        productSpec = {
            name: "Subscription to Real Estate stock",
            price: 7.99,
            productBy: "Copala Inc"
        };
    } else if (product === "vehicle") {
        productSpec = {
            name: "Subscription to Vehicle stock",
            price: 3.99,
            productBy: "Copala Inc"
        };
    } else if (product === "service") {
        productSpec = {
            name: "Subscription to Service stock",
            price: 1.99,
            productBy: "Copala Inc"
        };
    } else if (product === "demand") {
        productSpec = {
            name: "Subscription to Service stock",
            price: 0.99,
            productBy: "Copala Inc"
        };
    };
    const [selectedObjectAddress, setSelectedObjectAddress] = useState('');
    const [eligibleForFreeSubscription, seteligibleForFreeSubscription] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        fetch(`${backendUrl}/process-payment/${new Date().getTime()}/${product}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                setSelectedObjectAddress(responseData.addressOftheItem);
                seteligibleForFreeSubscription(responseData.eligibleForFreeSubscription);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [itemId]);

    if (loading) {
        return <Loader key={1} loading={loading} />;
    }

    const makePayment = token => {
        const body = {
            token,
            productSpec,
            itemId
        };
        const headers = {
            "Content-Type": "application/json"
        };

        fetch(`${backendUrl}/process-payment/${product}/${itemId}`, {
            method: "POST",
            headers,
            body: JSON.stringify(body)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.json();
            })
            .then(data => {
                paymentMade = true;
                if (product === "realestate") {
                    navigate(`/home`);
                } else if (product === "vehicle") {
                    navigate(`/vehicles`);
                } else if (product === "service") {
                    navigate(`/services`);
                } else if (product === "demand") {
                    navigate(`/demands`);
                }
                // setPaymentMade(true);
            })
            .catch(error => console.error('Error:', error));
    };

    const subscribeOneMonthFree = () => {
        const body = {
            productSpec,
            itemId
        };
        const headers = {
            "Content-Type": "application/json"
        };

        fetch(`${backendUrl}/process-freesubscription/${product}/${itemId}`, {
            method: "POST",
            headers,
            body: JSON.stringify(body)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.json();
            })
            .then(data => {
                paymentMade = true;
                if (product === "realestate") {
                    navigate(`/home`);
                } else if (product === "vehicle") {
                    navigate(`/vehicles`);
                } else if (product === "service") {
                    navigate(`/services`);
                } else if (product === "demand") {
                    navigate(`/demands`);
                }
                // setPaymentMade(true);
            })
            .catch(error => console.error('Error:', error));
    };

    const isAdmin = loggedIn && userId === '655c4db70c42d834a913a5e4' || userId === '65b025dda83b1aad04a20be1';

    // if (isAdmin) {
    //     seteligibleForFreeSubscription(true);
    // }

    return (
        <div>
            {languageToUse === "ge" && (
                <div>
                    <section className="section section-md bg-default payment-section">
                        <div className="payment-container">
                            <div className="container py-5 payment-form-1">
                                <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                    {selectedObjectAddress && (
                                        <div>
                                            <h3 className="text-body-secondary">თვიური გამოწერა</h3>
                                            <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                                            <h4 hidden={product === "vehicle"} className="fs-5 text-body-secondary">{selectedObjectAddress}</h4>
                                        </div>
                                    )}
                                </div>
                                <div className="payment-form-1">
                                    <div className="row row-cols-1 text-center">
                                        {(eligibleForFreeSubscription || isAdmin) && (
                                            <div className="col-12 col-md-4 mb-4 payment-frame">
                                                <div className="card mb-4 shadow-sm card-payment">
                                                    <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'white', fontWeight: '1000' }}>
                                                        <h4 className="my-0 fw-normal">უფასო</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <h1 className="card-title pricing-card-title">$0 /პირველი თვე<small className="text-body-secondary fw-light"></small></h1>
                                                        <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                            <li className="mb-2">1 განცხადება</li>
                                                            <li hidden={product !== "realestate"} className="mb-2">სამგანზომილებიანი ვირთუალური გაადღება და ფოტოები $120</li>
                                                            <li className="mb-2">უფასო კონსულტაციები</li>
                                                            <li hidden={product !== "realestate"} className="mb-2">გადაუდებელი სერვისები (იხილეთ გვედრი "ჩვენს შესახებ")</li>
                                                        </ul>
                                                        <button onClick={subscribeOneMonthFree} type="button" className="button-sm button-secondary-reverse" style={{ width: '120px', maxHeight: '36px', borderRadius: '6px', fontWeight: '750', letterSpacing: '0', padding: '4px 10px', fontSize: '14.2px', color: 'white' }} >Pay No Free</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-12 col-md-4 mb-4 payment-frame first-option">
                                            <div className="card mb-4 shadow-sm card-payment first-option">
                                                <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'black', fontWeight: '2000' }}>
                                                    <h4 className="my-0 fw-normal">პოპულარული</h4>
                                                </div>
                                                <div className="card-body">
                                                    <h1 hidden={product !== "demand"} className="card-title pricing-card-title">$0.99 /თვეში<small className="text-body-secondary fw-light"></small></h1>
                                                    <h1 hidden={product !== "service"} className="card-title pricing-card-title">$1.99 /თვეში<small className="text-body-secondary fw-light"></small></h1>
                                                    <h1 hidden={product !== "vehicle"} className="card-title pricing-card-title">$3.99 /თვეში<small className="text-body-secondary fw-light"></small></h1>
                                                    <h1 hidden={product !== "realestate"} className="card-title pricing-card-title">$7.99 /თვეში<small className="text-body-secondary fw-light"></small></h1>
                                                    <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                        <li className="mb-2">1 განცხადება</li>
                                                        <li hidden={product !== "realestate"} className="mb-2">სამგანზომილებიანი ვირთუალური გაადღება და ფოტოები $100</li>
                                                        <li className="mb-2">უფასო კონსულტაციები</li>
                                                        <li hidden={product !== "realestate"} className="mb-2">გადაუდებელი სერვისები (იხილეთ გვედრი "ჩვენს შესახებ")</li>
                                                    </ul>
                                                    <StripeCheckout
                                                        stripeKey={stripeKey}
                                                        token={makePayment}
                                                        name="Buy Subscription"
                                                        amount={productSpec.price * 100}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 col-md-4 mb-4 payment-frame">
                                    <div className="card mb-4 shadow-sm card-payment">
                                        <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'white', fontWeight: '1000' }}>
                                            <h4 className="my-0 fw-normal">Pro</h4>
                                        </div>
                                        <div className="card-body">
                                            <h1 className="card-title pricing-card-title">$20 /month<small className="text-body-secondary fw-light"></small></h1>
                                            <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                <li className="mb-2">3 listings per month</li>
                                                <li className="mb-2">Shooting VRT and photos for $114</li>
                                                <li className="mb-2">free consultations</li>
                                                <li className="mb-2">anytime cancellation</li>
                                            </ul>
                                            <StripeCheckout
                                                stripeKey={stripeKey}
                                                token={makePayment}
                                                name="Buy Subscription"
                                                amount={productSpec.price * 200}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="container">
                            <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                        </div>
                    </section>
                    <section className="section section-md bg-default"></section>
                </div>
            )}
            {languageToUse === "en" && (
                <div>
                    <div>
                        <section className="section section-md bg-default payment-section">
                            <div className="payment-container">
                                <div className="container py-5 payment-form-1">
                                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                        {selectedObjectAddress && (
                                            <div>
                                                <h3 className="text-body-secondary">Monthly Subscription</h3>
                                                <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                                                <h4 className="fs-5 text-body-secondary">{selectedObjectAddress}</h4>
                                            </div>
                                        )}
                                    </div>
                                    <div className="payment-form-1">
                                        <div className="row row-cols-1 text-center">
                                            {(eligibleForFreeSubscription || isAdmin) && (
                                                <div className="col-12 col-md-4 mb-4 payment-frame">
                                                    <div className="card mb-4 shadow-sm card-payment">
                                                        <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'white', fontWeight: '1000' }}>
                                                            <h4 className="my-0 fw-normal">Free</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <h1 className="card-title pricing-card-title">$0 /first month<small className="text-body-secondary fw-light"></small></h1>
                                                            <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                                <li className="mb-2">1 listing one month free</li>
                                                                <li hidden={product !== "realestate"} className="mb-2">Shooting VRT and photos for $120</li>
                                                                <li className="mb-2">free consultations</li>
                                                                <li className="mb-2">anytime cancellation</li>
                                                            </ul>
                                                            <button onClick={subscribeOneMonthFree} type="button" className="button-sm button-secondary-reverse" style={{ width: '120px', maxHeight: '36px', borderRadius: '6px', fontWeight: '750', letterSpacing: '0', padding: '4px 10px', fontSize: '14.2px', color: 'white' }} >Pay No Free</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-12 col-md-4 mb-4 payment-frame first-option">
                                                <div className="card mb-4 shadow-sm card-payment first-option">
                                                    <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'black', fontWeight: '2000' }}>
                                                        <h4 className="my-0 fw-normal">Essential</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <h1 className="card-title pricing-card-title">$7.99 /month<small className="text-body-secondary fw-light"></small></h1>
                                                        <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                            <li className="mb-2">1 listing</li>
                                                            <li hidden={product !== "realestate"} className="mb-2">Shooting VRT and photos for $100</li>
                                                            <li className="mb-2">free consultations</li>
                                                            <li className="mb-2">anytime cancellation</li>
                                                        </ul>
                                                        <StripeCheckout
                                                            stripeKey={stripeKey}
                                                            token={makePayment}
                                                            name="Buy Subscription"
                                                            amount={productSpec.price * 100}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-md-4 mb-4 payment-frame">
                                                <div className="card mb-4 shadow-sm card-payment">
                                                    <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'white', fontWeight: '1000' }}>
                                                        <h4 className="my-0 fw-normal">Pro</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <h1 className="card-title pricing-card-title">$20 /month<small className="text-body-secondary fw-light"></small></h1>
                                                        <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                            <li className="mb-2">3 listings per month</li>
                                                            <li className="mb-2">Shooting VRT and photos for $100</li>
                                                            <li className="mb-2">free consultations</li>
                                                            <li className="mb-2">anytime cancellation</li>
                                                        </ul>
                                                        <StripeCheckout
                                                            stripeKey={stripeKey}
                                                            token={makePayment}
                                                            name="Buy Subscription"
                                                            amount={productSpec.price * 200}
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className="container">
                                <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                            </div>
                        </section>
                        <section className="section section-md bg-default"></section>
                    </div>
                </div>
            )}
            {languageToUse === "ru" && (
                <div>
                    <div>
                        <section className="section section-md bg-default payment-section">
                            <div className="payment-container">
                                <div className="container py-5 payment-form-1">
                                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                        {selectedObjectAddress && (
                                            <div>
                                                <h3 className="text-body-secondary">Месячная подписка</h3>
                                                <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                                                <h4 className="fs-5 text-body-secondary">{selectedObjectAddress}</h4>
                                            </div>
                                        )}
                                    </div>
                                    <div className="payment-form-1">
                                        <div className="row row-cols-1 text-center">
                                            {(eligibleForFreeSubscription || isAdmin) && (
                                                <div className="col-12 col-md-4 mb-4 payment-frame">
                                                    <div className="card mb-4 shadow-sm card-payment">
                                                        <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'white', fontWeight: '1000' }}>
                                                            <h4 className="my-0 fw-normal">Бесплатно</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <h1 className="card-title pricing-card-title">$0 /первый месяц<small className="text-body-secondary fw-light"></small></h1>
                                                            <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                                <li className="mb-2">1 объявление один месяц бесплатно</li>
                                                                <li hidden={product !== "realestate"} className="mb-2">Съемка VRT и фотографий за 120$</li>
                                                                <li className="mb-2">бесплатные консультации</li>
                                                                <li className="mb-2">отмена в любое время</li>
                                                            </ul>
                                                            <button onClick={subscribeOneMonthFree} type="button" className="button-sm button-secondary-reverse" style={{ width: '120px', maxHeight: '36px', borderRadius: '6px', fontWeight: '750', letterSpacing: '0', padding: '4px 10px', fontSize: '14.2px', color: 'white' }} >Бесплатно</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-12 col-md-4 mb-4 payment-frame first-option">
                                                <div className="card mb-4 shadow-sm card-payment first-option">
                                                    <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'black', fontWeight: '2000' }}>
                                                        <h4 className="my-0 fw-normal">Популярный</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <h1 className="card-title pricing-card-title">$7.99 /месяц<small className="text-body-secondary fw-light"></small></h1>
                                                        <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                            <li className="mb-2">1 Объявление</li>
                                                            <li hidden={product !== "realestate"} className="mb-2">Съемка VRT и фотографий за $100</li>
                                                            <li className="mb-2">бесплатные консультации</li>
                                                            <li className="mb-2">отмена в любое время</li>
                                                        </ul>
                                                        <StripeCheckout
                                                            stripeKey={stripeKey}
                                                            token={makePayment}
                                                            name="Buy Subscription"
                                                            amount={productSpec.price * 100}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-md-4 mb-4 payment-frame">
                                                <div className="card mb-4 shadow-sm card-payment">
                                                    <div className="card-header py-3" style={{ backgroundColor: '#fdde52', color: 'white', fontWeight: '1000' }}>
                                                        <h4 className="my-0 fw-normal">Pro</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <h1 className="card-title pricing-card-title">$20 /month<small className="text-body-secondary fw-light"></small></h1>
                                                        <ul className="list-unstyled mt-3 mb-4 subscription-list">
                                                            <li className="mb-2">3 listings per month</li>
                                                            <li className="mb-2">Shooting VRT and photos for $100</li>
                                                            <li className="mb-2">free consultations</li>
                                                            <li className="mb-2">anytime cancellation</li>
                                                        </ul>
                                                        <StripeCheckout
                                                            stripeKey={stripeKey}
                                                            token={makePayment}
                                                            name="Buy Subscription"
                                                            amount={productSpec.price * 200}
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className="container">
                                <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                            </div>
                        </section>
                        <section className="section section-md bg-default"></section>
                    </div>
                </div>
            )}
        </div>
    )
}


export default StripeAppPayment;