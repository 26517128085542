import React, { useState } from 'react';
import MyListedVehicles from './myListedVehicles';
import VehicleDetailsPopUp from './vehicleDetailsPopUp';

const HomeMyVehicles = () => {
    const [showItemDetails, setShowItemDetails] = useState(false);
    const [selectedListingId, setSelectedListingId] = useState(null);

    const handleItemClick = (listingId) => {
        setSelectedListingId(listingId);
        setShowItemDetails(true);
    };

    const handleItemClose = () => {
        setShowItemDetails(false);
    };

    return (
        <div className="container home-container">
            <MyListedVehicles onItemClick={handleItemClick} listingId={selectedListingId} /> {/* Pass both onItemClick and listingId */}
            {showItemDetails && <VehicleDetailsPopUp listingId={selectedListingId} onClick={handleItemClose} />}
        </div>
    );
};

export default HomeMyVehicles;