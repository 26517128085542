// import React, { useState, useEffect } from "react";
// import Slider from 'react-slick';
// import FavorateButton from "./favorateButton";
// import backendUrl from './config';
// import { Link } from 'react-router-dom';
// import { useLanguage } from './languageContext';

// function Vehicle(props) {
//     const product = "vehicle";
//     const { currentLanguage } = useLanguage();
//     const [responseMessage, setResponseMessage] = useState("");
//     const [imagePaths, setImagePaths] = useState([]);

//     useEffect(() => {
//         const fetchPhotos = async () => {
//             setImagePaths([]);
//             // Use a Set to store unique URLs
//             const uniqueImagePaths = new Set();
//             // Receive and add each image URL to the imagePaths array
//             for (let i = 0; i < props.imgs.length; i++) {
//                 try {
//                     const response = await fetch(`${backendUrl}/api/photos/${product}/${props.listingId}/${i}`, {
//                         method: 'GET',
//                         headers: {
//                             'Content-Type': 'application/json',
//                             // Add any additional headers if needed
//                         },
//                         credentials: 'include', // Include credentials in the request
//                     });
//                     if (response.ok) {
//                         const contentType = response.headers.get('content-type');

//                         if (contentType && contentType.includes('application/json')) {
//                             const data = await response.json();
//                             setResponseMessage(data.message);
//                         } else {
//                             const blob = await response.blob();
//                             const imgUrl = URL.createObjectURL(blob);

//                             // Add the unique URL to the set
//                             uniqueImagePaths.add(imgUrl);
//                         }
//                     } else {
//                         setResponseMessage("Error fetching photos. Response failed.");
//                     }
//                 } catch (error) {
//                     console.error("Error fetching photos:", error);
//                     setResponseMessage("Error fetching photos.");
//                     console.log(responseMessage);
//                 }
//             }
//             setImagePaths([...uniqueImagePaths]);
//         };

//         fetchPhotos();
//     }, [props.listingId, props.imgs, responseMessage]);

//     // Add props.listingId as a dependency to refetch when the ID changes

//     // slider
//     var settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1
//     };

//     // slider
//     return (<div>
//         {currentLanguage === "ge" && (
//             <div className="card shadow-sm g-1 listingitem-card">
//                 <div className='card-upper-body'>
//                     <div className="slick-slider-1">
//                         <div className="slick-slider-price">
//                             <div hidden={props.subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.askingPrice)}</span></div>
//                             {/* <div hidden={props.subscriptionStatus === "active" || props.subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={props.lisingtype === "listings"} href={`${backendUrl}/process-payment/` + props.listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
//                             <div hidden={props.subscriptionStatus === "active"} className=""><span> <Link to={`${backendUrl}/process-payment/${product}/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>გამოქვეყნება</Link></span></div>
//                         </div>
//                     </div>
//                     <div className="image-slider-wrapper">
//                         <Link to={`/vehicledetails/` + props.listingId}>
//                             <Slider {...settings}>
//                                 {imagePaths && imagePaths.length > 0 ? (
//                                     imagePaths.map((image, index) => (
//                                         <img
//                                             key={index}
//                                             src={image}
//                                             alt={`Item ${index}`}
//                                             style={{ width: '100%', maxHeight: '300px' }}
//                                         />
//                                     ))
//                                 ) : (
//                                     <img src="./images/ser-r.png"
//                                         alt="Default Item"
//                                         style={{ width: '100%', maxHeight: '300px' }} />
//                                 )}
//                             </Slider>
//                         </Link>
//                     </div>
//                 </div>
//                 <div className="card-body">
//                     <div className="temple">
//                         <ul className="location">
//                             <li>
//                                 <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{props.city}</span>
//                                 <span className="like-button"><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span>
//                             </li>
//                         </ul>
//                         <ul className="feature-list">
//                             <li>
//                                 <img src="" alt="" /><span style={{ fontWeight: '550' }}>მწარმოებელი:</span><span className="makeSpan" >{props.make}</span>
//                             </li>
//                             <li>
//                                 <img src="" alt="" /><span style={{ fontWeight: '550' }}>მოდელი:</span><span className="modelSpan" >{props.model}</span>
//                             </li>
//                             <li>
//                                 <img src="" alt="" /><span style={{ fontWeight: '550' }}>წელი:</span><span className="yearSpan" >{props.year}</span>
//                             </li>
//                         </ul>
//                         <ul className="price-list-val">
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <li><img src="./images/odometer2.png" alt="" style={{ width: '40px', height: '40px' }} />{props.mileage} მილი</li>
//                                 <li><img src="./images/fuel4.png" alt="" style={{ width: '24px', height: '24px' }} />{props.fuel === "gas" ? "ბენზინი" : ""} {props.fuel === "diesel" ? "დიზელი" : ""}</li>
//                                 <li><img src="./images/deal1.jpeg" alt="" style={{ width: '30px', height: '30px' }} />{props.dealtype === "forRent" ? "ქირით" : ""} {props.dealtype === "forSale" ? "იყიდება" : ""}</li>
//                             </div>

//                         </ul>
//                     </div>
//                 </div >
//             </div >
//         )}
//         {currentLanguage === "en" && (
//             <div>
//                 <div className="card shadow-sm g-1 listingitem-card">
//                     <div className='card-upper-body'>
//                         <div className="slick-slider-1">
//                             <div className="slick-slider-price">
//                                 <div hidden={props.subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.askingPrice)}</span></div>
//                                 {/* <div hidden={props.subscriptionStatus === "active" || props.subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={props.lisingtype === "listings"} href={`${backendUrl}/process-payment/` + props.listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
//                                 <div hidden={props.subscriptionStatus === "active"} className=""><span> <Link to={`${backendUrl}/process-payment/${product}/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</Link></span></div>
//                             </div>
//                         </div>
//                         <div className="image-slider-wrapper">
//                             <Link to={`/vehicledetails/` + props.listingId}>
//                                 <Slider {...settings}>
//                                     {imagePaths && imagePaths.length > 0 ? (
//                                         imagePaths.map((image, index) => (
//                                             <img
//                                                 key={index}
//                                                 src={image}
//                                                 alt={`Item ${index}`}
//                                                 className="image-slider-item"
//                                                 style={{ width: '100%', maxHeight: '300px' }}
//                                             />
//                                         ))
//                                     ) : (
//                                         <img src="./images/ser-r.png"
//                                             alt="Default Item"
//                                             className="image-slider-item"
//                                             style={{ width: '100%', maxHeight: '300px' }}
//                                         />
//                                     )}
//                                 </Slider>
//                             </Link>
//                         </div>
//                     </div>
//                     <div className="card-body">
//                         <div className="temple">
//                             <ul className="location">
//                                 <li>
//                                     <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{props.city}</span>
//                                     <span className="like-button"><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span>
//                                 </li>
//                             </ul>
//                             <ul className="feature-list">
//                                 <li>
//                                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Make:</span><span className="makeSpan" >{props.make}</span>
//                                 </li>
//                                 <li>
//                                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Model:</span><span className="modelSpan" >{props.model}</span>
//                                 </li>
//                                 <li>
//                                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Year:</span><span className="yearSpan" >{props.year}</span>
//                                 </li>
//                             </ul>
//                             <ul className="price-list-val">
//                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                     <li><img src="./images/odometer2.png" alt="" style={{ width: '40px', height: '40px' }} />{props.mileage} miles</li>
//                                     <li><img src="./images/fuel4.png" alt="" style={{ width: '24px', height: '24px' }} />{props.fuel === "gas" ? "Gas" : ""} {props.fuel === "diesel" ? "Diesel" : ""}</li>
//                                     <li><img src="./images/deal1.jpeg" alt="" style={{ width: '30px', height: '30px' }} />{props.dealtype === "forRent" ? "For rent" : ""} {props.dealtype === "forSale" ? "For sale" : ""}</li>
//                                 </div>

//                             </ul>
//                         </div>
//                     </div >
//                 </div >
//             </div>
//         )}
//     </div>
//     )
// }
// export default Vehicle;


import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import FavorateButton from "./favorateButton";
import backendUrl from './config';
import { Link as RouterLink } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll';
import { useLanguage } from './languageContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Vehicle({ listingId, onClick, city, lisingtype, imgs, subscriptionStatus, askingPrice, make, model, year, mileage, fuel, dealtype }) {
    const product = "vehicle";
    const { currentLanguage } = useLanguage();
    const [responseMessage, setResponseMessage] = useState("");
    const [imagePaths, setImagePaths] = useState([]);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const handleVehicleClick = () => {
        onClick(listingId); // Pass the listingId to onClick
    };

    const openInNewWindow = () => {
        const url = `/vehicledetails/` + listingId
        window.open(url);
    };

    useEffect(() => {
        const fetchPhotos = async () => {
            setImagePaths([]);
            // Use a Set to store unique URLs
            const uniqueImagePaths = new Set();
            // Receive and add each image URL to the imagePaths array
            for (let i = 0; i < imgs.length; i++) {
                try {
                    const response = await fetch(`${backendUrl}/api/photos/${product}/${listingId}/${i}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            // Add any additional headers if needed
                        },
                        credentials: 'include', // Include credentials in the request
                    });
                    if (response.ok) {
                        const contentType = response.headers.get('content-type');

                        if (contentType && contentType.includes('application/json')) {
                            const data = await response.json();
                            setResponseMessage(data.message);
                        } else {
                            const blob = await response.blob();
                            const imgUrl = URL.createObjectURL(blob);

                            // Add the unique URL to the set
                            uniqueImagePaths.add(imgUrl);
                        }
                    } else {
                        setResponseMessage("Error fetching photos. Response failed.");
                    }
                } catch (error) {
                    console.error("Error fetching photos:", error);
                    setResponseMessage("Error fetching photos.");
                    console.log(responseMessage);
                }
            }
            setImagePaths([...uniqueImagePaths]);
        };

        fetchPhotos();
    }, [listingId, imgs, responseMessage]);

    // Add props.listingId as a dependency to refetch when the ID changes

    // slider
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Set the autoplay speed in milliseconds (e.g., 2000ms = 2 seconds)
    };

    // slider
    return (<div>
        {languageToUse === "ge" && (
            <div className="card shadow-sm g-1 listingitem-card">
                <div className='card-upper-body'>
                    <div className="slick-slider-1">
                        <div className="slick-slider-price">
                            <div hidden={subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(askingPrice)}</span></div>
                            {/* <div hidden={subscriptionStatus === "active" || subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={lisingtype === "listings"} href={`${backendUrl}/process-payment/` + listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
                            <div hidden={subscriptionStatus === "active"} className=""><span> <RouterLink to={`$/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>გამოქვეყნება</RouterLink></span></div>
                        </div>
                    </div>
                    <ScrollLink
                        className=""
                        to="listingItemCard"
                        smooth={true}
                        duration={5}
                    >
                        <div className="image-slider-wrapper" onClick={handleVehicleClick} style={{ cursor: 'pointer' }}>
                            {/* <Link to={`/vehicledetails/` + listingId}> */}
                            <Slider {...settings}>
                                {imagePaths && imagePaths.length > 0 ? (
                                    imagePaths.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Item ${index}`}
                                            style={{ width: '100%', maxHeight: '300px' }}
                                        />
                                    ))
                                ) : (
                                    <img src="./images/ser-r.png"
                                        alt="Default Item"
                                        style={{ width: '100%', maxHeight: '300px' }} />
                                )}
                            </Slider>
                            {/* </Link> */}
                        </div>
                    </ScrollLink>
                </div>
                <div className="card-body">
                    <div className="temple">
                        <ul className="location">
                            <li>
                                <div style={{ display: "flex" }}>
                                    <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{city}</span>
                                    <div style={{ position: 'absolute', right: '1px', margin: '1px', height: "24px", width: "24px", cursor: "pointer" }} onClick={openInNewWindow}><img src="./images/openinnewwindow.png" /></div>
                                </div>
                                <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
                            </li>
                        </ul>
                        <ul className="feature-list">
                            <li>
                                <img src="" alt="" /><span style={{ fontWeight: '550' }}>მწარმოებელი:</span><span className="makeSpan" >{make}</span>
                            </li>
                            <li>
                                <img src="" alt="" /><span style={{ fontWeight: '550' }}>მოდელი:</span><span className="modelSpan" >{model}</span>
                            </li>
                            <li>
                                <img src="" alt="" /><span style={{ fontWeight: '550' }}>წელი:</span><span className="yearSpan" >{year}</span>
                            </li>
                        </ul>
                        <ul className="price-list-val">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <li><img src="./images/odometer2.png" alt="" style={{ width: '40px', height: '40px' }} />{mileage} მლ</li>
                                <li><img src="./images/fuel4.png" alt="" style={{ width: '24px', height: '24px' }} />{fuel === "gas" ? "ბენზინი" : ""} {fuel === "diesel" ? "დიზელი" : ""}</li>
                                <li><img src="./images/deal1.jpeg" alt="" style={{ width: '30px', height: '30px' }} />{dealtype === "forRent" ? "ქირით" : ""} {dealtype === "forSale" ? "იყიდება" : ""}</li>
                            </div>

                        </ul>
                    </div>
                </div >
            </div >
        )}
        {languageToUse === "en" && (
            <div>
                <div className="card shadow-sm g-1 listingitem-card">
                    <div className='card-upper-body'>
                        <div className="slick-slider-1">
                            <div className="slick-slider-price">
                                <div hidden={subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(askingPrice)}</span></div>
                                {/* <div hidden={subscriptionStatus === "active" || subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={lisingtype === "listings"} href={`${backendUrl}/process-payment/` + listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
                                <div hidden={subscriptionStatus === "active"} className=""><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</RouterLink></span></div>
                            </div>
                        </div>
                        <ScrollLink
                            className=""
                            to="listingItemCard"
                            smooth={true}
                            duration={5}
                        >
                            <div className="image-slider-wrapper" onClick={handleVehicleClick} style={{ cursor: 'pointer' }}>
                                {/* <Link to={`/vehicledetails/` + listingId}> */}
                                <Slider {...settings}>
                                    {imagePaths && imagePaths.length > 0 ? (
                                        imagePaths.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`Item ${index}`}
                                                className="image-slider-item"
                                                style={{ width: '100%', maxHeight: '300px' }}
                                            />
                                        ))
                                    ) : (
                                        <img src="./images/ser-r.png"
                                            alt="Default Item"
                                            className="image-slider-item"
                                            style={{ width: '100%', maxHeight: '300px' }}
                                        />
                                    )}
                                </Slider>
                                {/* </Link> */}
                            </div>
                        </ScrollLink>
                    </div>
                    <div className="card-body">
                        <div className="temple">
                            <ul className="location">
                                <li>
                                    <div style={{ display: "flex" }}>
                                        <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{city}</span>
                                        <div style={{ position: 'absolute', right: '1px', margin: '1px', height: "24px", width: "24px", cursor: "pointer" }} onClick={openInNewWindow}><img src="./images/openinnewwindow.png" /></div>
                                    </div>
                                    <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
                                </li>
                            </ul>
                            <ul className="feature-list">
                                <li>
                                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Make:</span><span className="makeSpan" >{make}</span>
                                </li>
                                <li>
                                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Model:</span><span className="modelSpan" >{model}</span>
                                </li>
                                <li>
                                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Year:</span><span className="yearSpan" >{year}</span>
                                </li>
                            </ul>
                            <ul className="price-list-val">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <li><img src="./images/odometer2.png" alt="" style={{ width: '40px', height: '40px' }} />{mileage} miles</li>
                                    <li><img src="./images/fuel4.png" alt="" style={{ width: '24px', height: '24px' }} />{fuel === "gas" ? "Gas" : ""} {fuel === "diesel" ? "Diesel" : ""}</li>
                                    <li><img src="./images/deal1.jpeg" alt="" style={{ width: '30px', height: '30px' }} />{dealtype === "forRent" ? "For rent" : ""} {dealtype === "forSale" ? "For sale" : ""}</li>
                                </div>

                            </ul>
                        </div>
                    </div >
                </div >
            </div>
        )}
        {languageToUse === "ru" && (
            <div>
                <div className="card shadow-sm g-1 listingitem-card">
                    <div className='card-upper-body'>
                        <div className="slick-slider-1">
                            <div className="slick-slider-price">
                                <div hidden={subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(askingPrice)}</span></div>
                                {/* <div hidden={subscriptionStatus === "active" || subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={lisingtype === "listings"} href={`${backendUrl}/process-payment/` + listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
                                <div hidden={subscriptionStatus === "active"} className=""><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>Подписаться</RouterLink></span></div>
                            </div>
                        </div>
                        <ScrollLink
                            className=""
                            to="listingItemCard"
                            smooth={true}
                            duration={5}
                        >
                            <div className="image-slider-wrapper" onClick={handleVehicleClick} style={{ cursor: 'pointer' }}>
                                {/* <Link to={`/vehicledetails/` + listingId}> */}
                                <Slider {...settings}>
                                    {imagePaths && imagePaths.length > 0 ? (
                                        imagePaths.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`Item ${index}`}
                                                className="image-slider-item"
                                                style={{ width: '100%', maxHeight: '300px' }}
                                            />
                                        ))
                                    ) : (
                                        <img src="./images/ser-r.png"
                                            alt="Default Item"
                                            className="image-slider-item"
                                            style={{ width: '100%', maxHeight: '300px' }}
                                        />
                                    )}
                                </Slider>
                                {/* </Link> */}
                            </div>
                        </ScrollLink>
                    </div>
                    <div className="card-body">
                        <div className="temple">
                            <ul className="location">
                                <li>
                                    <div style={{ display: "flex" }}>
                                        <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{city}</span>
                                        <div style={{ position: 'absolute', right: '1px', margin: '1px', height: "24px", width: "24px", cursor: "pointer" }} onClick={openInNewWindow}><img src="./images/openinnewwindow.png" /></div>
                                    </div>
                                    <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
                                </li>
                            </ul>
                            <ul className="feature-list">
                                <li>
                                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Делать:</span><span className="makeSpan" >{make}</span>
                                </li>
                                <li>
                                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Модель:</span><span className="modelSpan" >{model}</span>
                                </li>
                                <li>
                                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Год:</span><span className="yearSpan" >{year}</span>
                                </li>
                            </ul>
                            <ul className="price-list-val">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <li><img src="./images/odometer2.png" alt="" style={{ width: '40px', height: '40px' }} />{mileage} миль</li>
                                    <li><img src="./images/fuel4.png" alt="" style={{ width: '24px', height: '24px' }} />{fuel === "gas" ? "Газ" : ""} {fuel === "diesel" ? "Дизель" : ""}</li>
                                    <li><img src="./images/deal1.jpeg" alt="" style={{ width: '30px', height: '30px' }} />{dealtype === "forRent" ? "В аренду" : ""} {dealtype === "forSale" ? "Продается" : ""}</li>
                                </div>

                            </ul>
                        </div>
                    </div >
                </div >
            </div>
        )}
    </div>
    )
}
export default Vehicle;