// import React, { useState, useEffect } from "react";
// import Slider from 'react-slick';
// import FavorateButton from "./favorateButton";
// import backendUrl from './config';
// import { Link } from 'react-router-dom';
// import { useLanguage } from './languageContext';

// function DemandsPost(props) {
//     const product = "demand";
//     const { currentLanguage } = useLanguage();
//     const [responseMessage, setResponseMessage] = useState("");
//     const [imagePaths, setImagePaths] = useState([]);
//     const maxWords = 50; // Adjust as needed
//     const truncatedComment = props.comment.split(' ').slice(0, maxWords).join(' ');

//     useEffect(() => {
//         const fetchPhotos = async () => {
//             setImagePaths([]);
//             // Use a Set to store unique URLs
//             const uniqueImagePaths = new Set();
//             // Receive and add each image URL to the imagePaths array
//             for (let i = 0; i < props.imgs.length; i++) {
//                 try {
//                     const response = await fetch(`${backendUrl}/api/photos/${product}/${props.listingId}/${i}`, {
//                         method: 'GET',
//                         headers: {
//                             'Content-Type': 'application/json',
//                             // Add any additional headers if needed
//                         },
//                         credentials: 'include', // Include credentials in the request
//                     });
//                     if (response.ok) {
//                         const contentType = response.headers.get('content-type');

//                         if (contentType && contentType.includes('application/json')) {
//                             const data = await response.json();
//                             setResponseMessage(data.message);
//                         } else {
//                             const blob = await response.blob();
//                             const imgUrl = URL.createObjectURL(blob);

//                             // Add the unique URL to the set
//                             uniqueImagePaths.add(imgUrl);
//                         }
//                     } else {
//                         setResponseMessage("Error fetching photos. Response failed.");
//                     }
//                 } catch (error) {
//                     console.error("Error fetching photos:", error);
//                     setResponseMessage("Error fetching photos.");
//                 }
//             }
//             setImagePaths([...uniqueImagePaths]);
//         };

//         fetchPhotos();
//     }, [props.listingId, props.imgs, responseMessage]);


//     // console.log("props.listingId", props.listingId);

//     // slider
//     var settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1
//     };

//     // slider
//     return (<div>
//         {currentLanguage === "ge" && (
//             <div className="col-sm-12">
//                 <div className="row row-50 row-lg-70">
//                     <div className="col-12">
//                         <article className="product-classic product-classic-horizontal">
//                             <div className="product-classic-inner service-post">
//                                 <div className="product-classic-left marging-for-service-img">
//                                     <div className="card-service-listing">
//                                         <div className="image-slider-wrapper">
//                                             <Link to={`/demanddetails/` + props.listingId}>
//                                                 <Slider {...settings}>
//                                                     {imagePaths && imagePaths.length > 0 ? (
//                                                         imagePaths.map((image, index) => (
//                                                             <img
//                                                                 key={index}
//                                                                 src={image}
//                                                                 alt={`Item ${index}`}
//                                                                 style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto !important', height: 'auto !important' }}
//                                                             />
//                                                         ))
//                                                     ) : (
//                                                         <img
//                                                             src="./images/ser-r.png"
//                                                             alt="Default Item"
//                                                             style={{ width: '100px', height: '100px' }}
//                                                         />
//                                                     )}
//                                                 </Slider>
//                                             </Link>
//                                         </div>
//                                         <div hidden={props.subscriptionStatus !== "active"} className="slick-slider-price product-classic-price" ><span>{props.askingPrice}</span></div>
//                                         <div hidden={props.subscriptionStatus === "active"} className="slick-slider-price product-classic-price"><span> <Link to={`${backendUrl}/process-payment/${product}/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>გამოიწერეთ</Link></span></div>
//                                     </div>
//                                 </div>
//                                 <div className="product-classic-right">
//                                     <h4 className="product-classic-title"><Link to={`/demanddetails/` + props.listingId}>{props.subject}</Link></h4>
//                                     <div className="product-classic-divider"></div>
//                                     <div className="product-classic-text">
//                                         <p>{truncatedComment}<Link hidden={props.comment.trim().split(/\s+/).length < maxWords} to={`/demanddetails/` + props.listingId}>...(მეტის ნახვა)</Link></p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="product-classic-footer">
//                                 <ul className="product-classic-list clearfix icon-size-small">
//                                     <li hidden={props.servicetype !== "accounting"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "accounting" ? "ბუღალტერია" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "legal"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "legal" ? "იურიდიული მომსახურება" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "handyman"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "handyman" ? "ხელმარჯვე ოსტატი" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "construction"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "construction" ? "რემონტები" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "moving"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "moving" ? "გადაზიდვები" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "teaching"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "teaching" ? "სწავლა" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "partnership"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "partnership" ? "პარტნიორობა" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "beauty"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "beauty" ? "სილამაზე" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "cleaning"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "cleaning" ? "დალაგებები" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "HHA"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "HHA" ? "მოვლა" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "housekeeping"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "housekeeping" ? "ჰაუზკიპინგი" : ""}</span></li>
//                                     <li hidden={props.servicetype !== "other"}><span className="list-item-bold">კატეგორია:</span><span>{props.servicetype === "other" ? "სხვა სერვისები" : ""}</span></li>
//                                     <li><img src="./images/location.png" alt="icon" /><span>{props.city}</span></li>
//                                     <li><img src="./images/user-o.png" alt="icon" /><span>{props.name}</span></li>
//                                     <li><img src="./images/mail-o.png" alt="icon" /><span>{props.email}</span></li>
//                                     <li><img src="./images/b-call1.png" alt="icon" /><span>{props.phone}</span></li>
//                                     <li className=""><span className=""><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span></li>
//                                 </ul>
//                             </div>
//                         </article>
//                     </div>
//                 </div>
//             </div >
//         )
//         }
//         {
//             currentLanguage === "en" && (
//                 <div className="col-sm-12">
//                     <div className="row row-50 row-lg-70">
//                         <div className="col-12">
//                             <article className="product-classic product-classic-horizontal">
//                                 <div className="product-classic-inner service-post">
//                                     <div className="product-classic-left marging-for-service-img">
//                                         <div className="card-service-listing">
//                                             <div className="image-slider-wrapper">
//                                                 <Link to={`/demanddetails/` + props.listingId}>
//                                                     <Slider {...settings}>
//                                                         {imagePaths && imagePaths.length > 0 ? (
//                                                             imagePaths.map((image, index) => (
//                                                                 <img
//                                                                     key={index}
//                                                                     src={image}
//                                                                     alt={`Item ${index}`}
//                                                                     style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto !important', height: 'auto !important' }}
//                                                                 />
//                                                             ))
//                                                         ) : (
//                                                             <img
//                                                                 src="./images/ser-r.png"
//                                                                 alt="Default Item"
//                                                                 style={{ width: '100px', height: '100px' }}
//                                                             />
//                                                         )}
//                                                     </Slider>
//                                                 </Link>
//                                             </div>
//                                             <div hidden={props.subscriptionStatus !== "active"} className="slick-slider-price product-classic-price" ><span>{props.askingPrice}</span></div>
//                                             <div hidden={props.subscriptionStatus === "active"} className="slick-slider-price product-classic-price"><span> <Link to={`${backendUrl}/process-payment/${product}/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</Link></span></div>
//                                         </div>
//                                     </div>
//                                     <div className="product-classic-right">
//                                         <h4 className="product-classic-title"><Link to={`/demanddetails/` + props.listingId}>{props.subject}</Link></h4>
//                                         <div className="product-classic-divider"></div>
//                                         <div className="product-classic-text">
//                                             <p>{truncatedComment}<Link hidden={props.comment.trim().split(/\s+/).length < maxWords} to={`/demanddetails/` + props.listingId}>...(Read more)</Link></p>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="product-classic-footer">
//                                     <ul className="product-classic-list clearfix icon-size-small">
//                                         <li hidden={props.servicetype !== "accounting"}><span className="list-item-bold">Type:</span><span>Accounting</span></li>
//                                         <li hidden={props.servicetype !== "legal"}><span className="list-item-bold">Type:</span><span>Legal</span></li>
//                                         <li hidden={props.servicetype !== "handyman"}><span className="list-item-bold">Type:</span><span>Handyman</span></li>
//                                         <li hidden={props.servicetype !== "construction"}><span className="list-item-bold">Type:</span><span>Construction/Renovation</span></li>
//                                         <li hidden={props.servicetype !== "moving"}><span className="list-item-bold">Type:</span><span>Moving</span></li>
//                                         <li hidden={props.servicetype !== "teaching"}><span className="list-item-bold">Type:</span><span>Teaching"</span></li>
//                                         <li hidden={props.servicetype !== "partnership"}><span className="list-item-bold">Type:</span><span>Partnership</span></li>
//                                         <li hidden={props.servicetype !== "beauty"}><span className="list-item-bold">Type:</span><span>Beauty</span></li>
//                                         <li hidden={props.servicetype !== "cleaning"}><span className="list-item-bold">Type:</span><span>Cleaning</span></li>
//                                         <li hidden={props.servicetype !== "HHA"}><span className="list-item-bold">Type:</span><span>HHA</span></li>
//                                         <li hidden={props.servicetype !== "housekeeping"}><span className="list-item-bold">Type:</span><span>Housekeeping</span></li>
//                                         <li hidden={props.servicetype !== "other"}><span className="list-item-bold">Type:</span><span>Other</span></li>
//                                         <li><img src="./images/location.png" alt="icon" /><span>{props.city}</span></li>
//                                         <li><img src="./images/user-o.png" alt="icon" /><span>{props.name}</span></li>
//                                         <li><img src="./images/mail-o.png" alt="icon" /><span>{props.email}</span></li>
//                                         <li><img src="./images/b-call1.png" alt="icon" /><span>{props.phone}</span></li>
//                                         <li className=""><span className=""><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span></li>
//                                     </ul>
//                                 </div>
//                             </article>
//                         </div>
//                     </div>
//                 </div>
//             )
//         }
//     </div >
//     )
// }
// export default DemandsPost;

import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import FavorateButton from "./favorateButton";
import backendUrl from './config';
import { Link as RouterLink } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll';
import { useLanguage } from './languageContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function DemandsPost({ listingId, onClick, comment, imgs, subscriptionStatus, askingPrice, subject, lisingtype, servicetype, city, name, email, phone }) {
    const product = "demand";
    const { currentLanguage } = useLanguage();
    const [responseMessage, setResponseMessage] = useState("");
    const [imagePaths, setImagePaths] = useState([]);
    const maxWords = 50; // Adjust as needed
    const truncatedComment = comment.split(' ').slice(0, maxWords).join(' ');

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const handleDemandClick = () => {
        onClick(listingId); // Pass the listingId to onClick
    };

    const openInNewWindow = () => {
        const url = `/demanddetails/` + listingId
        window.open(url);
    };

    useEffect(() => {
        const fetchPhotos = async () => {
            setImagePaths([]);
            // Use a Set to store unique URLs
            const uniqueImagePaths = new Set();
            // Receive and add each image URL to the imagePaths array
            for (let i = 0; i < imgs.length; i++) {
                try {
                    const response = await fetch(`${backendUrl}/api/photos/${product}/${listingId}/${i}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include', // Include credentials in the request
                    });
                    if (response.ok) {
                        const contentType = response.headers.get('content-type');

                        if (contentType && contentType.includes('application/json')) {
                            const data = await response.json();
                            setResponseMessage(data.message);
                        } else {
                            const blob = await response.blob();
                            const imgUrl = URL.createObjectURL(blob);

                            // Add the unique URL to the set
                            uniqueImagePaths.add(imgUrl);
                        }
                    } else {
                        setResponseMessage("Error fetching photos. Response failed.");
                    }
                } catch (error) {
                    console.error("Error fetching photos:", error);
                    setResponseMessage("Error fetching photos.");
                }
            }
            setImagePaths([...uniqueImagePaths]);
        };

        fetchPhotos();
    }, [listingId, imgs, responseMessage]);;

    // slider
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Set the autoplay speed in milliseconds (e.g., 2000ms = 2 seconds)
    };

    // slider
    return (<div>
        {languageToUse === "ge" && (
            // <div className="col-sm-12">
            <div className="col-sm-12" style={{ padding: '0px' }}>
                <div className="row row-50 row-lg-70">
                    <div className="col-12">
                        <article className="product-classic product-classic-horizontal">
                            <div className="product-classic-inner service-post">
                                <div className="product-classic-left marging-for-service-img">
                                    <div className="card-service-listing">
                                        <ScrollLink
                                            className=""
                                            to="listingItemCard"
                                            smooth={true}
                                            duration={5}
                                        >
                                            <div className="image-slider-wrapper" onClick={handleDemandClick} style={{ cursor: 'pointer' }}>
                                                {/* <Link to={`/demanddetails/` + listingId}> */}
                                                <Slider {...settings}>
                                                    {imagePaths && imagePaths.length > 0 ? (
                                                        imagePaths.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                src={image}
                                                                alt={`Item ${index}`}
                                                                style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto !important', height: 'auto !important' }}
                                                            />
                                                        ))
                                                    ) : (
                                                        <img
                                                            src="./images/ser-r.png"
                                                            alt="Default Item"
                                                            style={{ width: '100px', height: '100px' }}
                                                        />
                                                    )}
                                                </Slider>
                                                {/* </Link> */}
                                            </div>
                                        </ScrollLink>
                                        <div hidden={subscriptionStatus !== "active"} className="slick-slider-price product-classic-price" ><span>{askingPrice}</span></div>
                                        <div hidden={subscriptionStatus === "active"} className="slick-slider-price product-classic-price"><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>გამოიწერეთ</RouterLink></span></div>
                                    </div>
                                </div>
                                <div className="product-classic-right">
                                    <h4 className="product-classic-title" onClick={handleDemandClick} style={{ cursor: 'pointer', color: '#4b9ef1' }}>{subject}</h4>
                                    <div className="product-classic-divider"></div>
                                    <div className="product-classic-text">
                                        {/* <p>{truncatedComment}<RouterLink hidden={comment.trim().split(/\s+/).length < maxWords} to={`/demanddetails/` + listingId}>...(მეტის ნახვა)</RouterLink></p> */}
                                        <p>{truncatedComment}<span hidden={comment.trim().split(/\s+/).length < maxWords} onClick={handleDemandClick} style={{ cursor: 'pointer', color: "darkred" }}>...(მეტის ნახვა)</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="product-classic-footer">
                                <ul className="product-classic-list clearfix icon-size-small">
                                    <li hidden={servicetype !== "accounting"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "accounting" ? "ბუღალტერია" : ""}</span></li>
                                    <li hidden={servicetype !== "legal"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "legal" ? "იურიდიული მომსახურება" : ""}</span></li>
                                    <li hidden={servicetype !== "handyman"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "handyman" ? "ხელმარჯვე ოსტატი" : ""}</span></li>
                                    <li hidden={servicetype !== "construction"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "construction" ? "რემონტები" : ""}</span></li>
                                    <li hidden={servicetype !== "moving"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "moving" ? "გადაზიდვები" : ""}</span></li>
                                    <li hidden={servicetype !== "teaching"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "teaching" ? "სწავლა" : ""}</span></li>
                                    <li hidden={servicetype !== "partnership"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "partnership" ? "პარტნიორობა" : ""}</span></li>
                                    <li hidden={servicetype !== "beauty"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "beauty" ? "სილამაზე" : ""}</span></li>
                                    <li hidden={servicetype !== "cleaning"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "cleaning" ? "დალაგებები" : ""}</span></li>
                                    <li hidden={servicetype !== "HHA"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "HHA" ? "მოვლა" : ""}</span></li>
                                    <li hidden={servicetype !== "housekeeping"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "housekeeping" ? "ჰაუზკიპინგი" : ""}</span></li>
                                    <li hidden={servicetype !== "other"}><span className="list-item-bold">კატეგორია:</span><span>{servicetype === "other" ? "სხვა სერვისები" : ""}</span></li>
                                    <li><img src="./images/location.png" alt="icon" /><span>{city}</span></li>
                                    <li><img src="./images/user-o.png" alt="icon" /><span>{name}</span></li>
                                    <li><img src="./images/mail-o.png" alt="icon" /><span>{email}</span></li>
                                    <li><img src="./images/b-call1.png" alt="icon" /><span>{phone}</span></li>
                                    <li className=""><span className=""><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span></li>
                                    <li onClick={openInNewWindow} style={{ cursor: "pointer", position: 'absolute', right: '20px' }}><img src="./images/openinnewwindow.png" /></li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
            </div >
        )
        }
        {
            languageToUse === "en" && (
                // <div className="col-sm-12">
                <div className="col-sm-12" style={{ padding: '0px' }}>
                    <div className="row row-50 row-lg-70">
                        <div className="col-12">
                            <article className="product-classic product-classic-horizontal">
                                <div className="product-classic-inner service-post">
                                    <div className="product-classic-left marging-for-service-img">
                                        <div className="card-service-listing">
                                            <ScrollLink
                                                className=""
                                                to="listingItemCard"
                                                smooth={true}
                                                duration={5}
                                            >
                                                <div className="image-slider-wrapper" onClick={handleDemandClick} style={{ cursor: 'pointer' }}>
                                                    {/* <Link to={`/demanddetails/` + listingId}> */}
                                                    <Slider {...settings}>
                                                        {imagePaths && imagePaths.length > 0 ? (
                                                            imagePaths.map((image, index) => (
                                                                <img
                                                                    key={index}
                                                                    src={image}
                                                                    alt={`Item ${index}`}
                                                                    style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto !important', height: 'auto !important' }}
                                                                />
                                                            ))
                                                        ) : (
                                                            <img
                                                                src="./images/ser-r.png"
                                                                alt="Default Item"
                                                                style={{ width: '100px', height: '100px' }}
                                                            />
                                                        )}
                                                    </Slider>
                                                    {/* </Link> */}
                                                </div>
                                            </ScrollLink>
                                            <div hidden={subscriptionStatus !== "active"} className="slick-slider-price product-classic-price" ><span>{askingPrice}</span></div>
                                            <div hidden={subscriptionStatus === "active"} className="slick-slider-price product-classic-price"><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</RouterLink></span></div>
                                        </div>
                                    </div>
                                    <div className="product-classic-right">
                                        <h4 className="product-classic-title" onClick={handleDemandClick} style={{ cursor: 'pointer', color: '#4b9ef1' }}>{subject}</h4>
                                        <div className="product-classic-divider"></div>
                                        <div className="product-classic-text">
                                            {/* <p>{truncatedComment}<RouterLink hidden={comment.trim().split(/\s+/).length < maxWords} to={`/demanddetails/` + listingId}>...(Read more)</RouterLink></p> */}
                                            <p>{truncatedComment}<span hidden={comment.trim().split(/\s+/).length < maxWords} onClick={handleDemandClick} style={{ cursor: 'pointer', color: "darkred" }}>...(Read more)</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-classic-footer">
                                    <ul className="product-classic-list clearfix icon-size-small">
                                        <li hidden={servicetype !== "accounting"}><span className="list-item-bold">Type:</span><span>Accounting</span></li>
                                        <li hidden={servicetype !== "legal"}><span className="list-item-bold">Type:</span><span>Legal</span></li>
                                        <li hidden={servicetype !== "handyman"}><span className="list-item-bold">Type:</span><span>Handyman</span></li>
                                        <li hidden={servicetype !== "construction"}><span className="list-item-bold">Type:</span><span>Construction/Renovation</span></li>
                                        <li hidden={servicetype !== "moving"}><span className="list-item-bold">Type:</span><span>Moving</span></li>
                                        <li hidden={servicetype !== "teaching"}><span className="list-item-bold">Type:</span><span>Teaching"</span></li>
                                        <li hidden={servicetype !== "partnership"}><span className="list-item-bold">Type:</span><span>Partnership</span></li>
                                        <li hidden={servicetype !== "beauty"}><span className="list-item-bold">Type:</span><span>Beauty</span></li>
                                        <li hidden={servicetype !== "cleaning"}><span className="list-item-bold">Type:</span><span>Cleaning</span></li>
                                        <li hidden={servicetype !== "HHA"}><span className="list-item-bold">Type:</span><span>HHA</span></li>
                                        <li hidden={servicetype !== "housekeeping"}><span className="list-item-bold">Type:</span><span>Housekeeping</span></li>
                                        <li hidden={servicetype !== "other"}><span className="list-item-bold">Type:</span><span>Other</span></li>
                                        <li><img src="./images/location.png" alt="icon" /><span>{city}</span></li>
                                        <li><img src="./images/user-o.png" alt="icon" /><span>{name}</span></li>
                                        <li><img src="./images/mail-o.png" alt="icon" /><span>{email}</span></li>
                                        <li><img src="./images/b-call1.png" alt="icon" /><span>{phone}</span></li>
                                        <li className=""><span className=""><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span></li>
                                        <li onClick={openInNewWindow} style={{ cursor: "pointer", position: 'absolute', right: '20px' }}><img src="./images/openinnewwindow.png" /></li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            )
        }
        {
            languageToUse === "ru" && (
                // <div className="col-sm-12">
                <div className="col-sm-12" style={{ padding: '0px' }}>
                    <div className="row row-50 row-lg-70">
                        <div className="col-12">
                            <article className="product-classic product-classic-horizontal">
                                <div className="product-classic-inner service-post">
                                    <div className="product-classic-left marging-for-service-img">
                                        <div className="card-service-listing">
                                            <ScrollLink
                                                className=""
                                                to="listingItemCard"
                                                smooth={true}
                                                duration={5}
                                            >
                                                <div className="image-slider-wrapper" onClick={handleDemandClick} style={{ cursor: 'pointer' }}>
                                                    {/* <Link to={`/demanddetails/` + listingId}> */}
                                                    <Slider {...settings}>
                                                        {imagePaths && imagePaths.length > 0 ? (
                                                            imagePaths.map((image, index) => (
                                                                <img
                                                                    key={index}
                                                                    src={image}
                                                                    alt={`Item ${index}`}
                                                                    style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto !important', height: 'auto !important' }}
                                                                />
                                                            ))
                                                        ) : (
                                                            <img
                                                                src="./images/ser-r.png"
                                                                alt="Default Item"
                                                                style={{ width: '100px', height: '100px' }}
                                                            />
                                                        )}
                                                    </Slider>
                                                    {/* </Link> */}
                                                </div>
                                            </ScrollLink>
                                            <div hidden={subscriptionStatus !== "active"} className="slick-slider-price product-classic-price" ><span>{askingPrice}</span></div>
                                            <div hidden={subscriptionStatus === "active"} className="slick-slider-price product-classic-price"><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>Подписаться</RouterLink></span></div>
                                        </div>
                                    </div>
                                    <div className="product-classic-right">
                                        <h4 className="product-classic-title" onClick={handleDemandClick} style={{ cursor: 'pointer', color: '#4b9ef1' }}>{subject}</h4>
                                        <div className="product-classic-divider"></div>
                                        <div className="product-classic-text">
                                            {/* <p>{truncatedComment}<RouterLink hidden={comment.trim().split(/\s+/).length < maxWords} to={`/demanddetails/` + listingId}>...(Read more)</RouterLink></p> */}
                                            <p>{truncatedComment}<span hidden={comment.trim().split(/\s+/).length < maxWords} onClick={handleDemandClick} style={{ cursor: 'pointer', color: "darkred" }}>...(Читать далее)</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-classic-footer">
                                    <ul className="product-classic-list clearfix icon-size-small">
                                        <li hidden={servicetype !== "accounting"}><span className="list-item-bold">Категория:</span><span>Бухгалтерский учет</span></li>
                                        <li hidden={servicetype !== "legal"}><span className="list-item-bold">Категория:</span><span>Юридический</span></li>
                                        <li hidden={servicetype !== "handyman"}><span className="list-item-bold">Категория:</span><span>Разнорабочий</span></li>
                                        <li hidden={servicetype !== "construction"}><span className="list-item-bold">Категория:</span><span>Строительство/Ремонт</span></li>
                                        <li hidden={servicetype !== "moving"}><span className="list-item-bold">Категория:</span><span>Перевозки</span></li>
                                        <li hidden={servicetype !== "teaching"}><span className="list-item-bold">Категория:</span><span>Обучение</span></li>
                                        <li hidden={servicetype !== "partnership"}><span className="list-item-bold">Категория:</span><span>Партнерство</span></li>
                                        <li hidden={servicetype !== "beauty"}><span className="list-item-bold">Категория:</span><span>Красота</span></li>
                                        <li hidden={servicetype !== "cleaning"}><span className="list-item-bold">Категория:</span><span>Уборка</span></li>
                                        <li hidden={servicetype !== "HHA"}><span className="list-item-bold">Категория:</span><span>HHA Домашнее здравоохранение</span></li>
                                        <li hidden={servicetype !== "housekeeping"}><span className="list-item-bold">Категория:</span><span>Хаускипинг</span></li>
                                        <li hidden={servicetype !== "other"}><span className="list-item-bold">Категория:</span><span>Другая</span></li>
                                        <li><img src="./images/location.png" alt="icon" /><span>{city}</span></li>
                                        <li><img src="./images/user-o.png" alt="icon" /><span>{name}</span></li>
                                        <li><img src="./images/mail-o.png" alt="icon" /><span>{email}</span></li>
                                        <li><img src="./images/b-call1.png" alt="icon" /><span>{phone}</span></li>
                                        <li className=""><span className=""><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span></li>
                                        <li onClick={openInNewWindow} style={{ cursor: "pointer", position: 'absolute', right: '20px' }}><img src="./images/openinnewwindow.png" /></li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            )
        }
    </div >
    )
}
export default DemandsPost;