import React, { useState } from "react";
import LogInRegisterManu from "./logInRegisterMenu";
import PopUpButton from "./popUpButton";
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from './languageContext';

function Login() {
  const { currentLanguage } = useLanguage();
  const [tradeTabActive, setTtradeTabActive] = useState("true");
  const [addListinsgOrMyFavorates, setAddListinsgOrMyFavorates] = useState("true");

  const location = useLocation();
  const { search } = location;

  const storedLanguage = sessionStorage.getItem('currentLanguage');
  const languageToUse = storedLanguage || currentLanguage;

  const handleTradeTabToggle = () => {
    setTtradeTabActive(!tradeTabActive);
  }

  const handleRatesHighTabToggle = () => {
    setTtradeTabActive(!tradeTabActive);
  }

  const handleAddLIstingsTabToggle = () => {
    setAddListinsgOrMyFavorates(!addListinsgOrMyFavorates);
  }

  const handleMyFavorateTabToggle = () => {
    setAddListinsgOrMyFavorates(!addListinsgOrMyFavorates);
  }


  return (<div>
    {languageToUse === "ge" && (
      <div>
        <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="28">
          <div className="container">
            <h2 className="breadcrumbs-custom-title">შემოდით თქვენს აგარიშზე ან დარეგისტრირდით საიტზე, რათა შეძლოთ განცხადებების ატვირთვა ან თქვენი რჩეული განცხადებების შენახვა</h2>
          </div>
        </section>
        <section className="section-xs bg-white">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li><Link to={`/home${search}`}>მთავარი</Link></li>
              <li><Link to={`/${search}`}>განცხადებები</Link></li>
              <li><Link to={`/listing${search}`}>განცხადების ატვირთვა</Link></li>
              <li><PopUpButton /></li>
            </ul>
          </div>
        </section>
        <div className="container" style={{ minHeight: '640px' }}>
          <div className="row">
            <div className="col-lg-4 col-md-3 col-sm-12">
              <section className="section section-md bg-default text-center">
                {/* <!-- tabs --> */}
                <div className="tabs-custom tabs-horizontal tabs-corporate" id="tabs-1">
                  {/* <!-- Nav tabs--> */}
                  <ul className="nav nav-tabs">
                    <li className="nav-item" onClick={handleTradeTabToggle} role="presentation"><button className={`nav-link ${tradeTabActive ? 'active' : ''}`} data-toggle="tab">ფართის გაქირავება</button></li>
                    <li className="nav-item" onClick={handleRatesHighTabToggle} role="presentation"><button className={`nav-link ${tradeTabActive ? '' : 'active'}`} data-toggle="tab">ყველასთვის</button></li>
                  </ul>
                  {/* <!-- Tab panes--> */}
                  <div className="tab-content">
                    <div className={`tab-pane fade ${tradeTabActive ? 'active show' : ''}`} id="tabs-1-1">
                      <p> ეს არის საცხოვრებლების გაქირავების პლათფორმა ქართველი ემიგრანტებისთვისა და ყველა სხვა მაცხოვრებლისათვის </p>
                      <p> მათ ვისაც გაგაჩნიათ ან გეგმავთ იქირაოთ და გადააქირაოთ ფართები, ეს პლათფორმა სასშუალებას მოგცემთ თქვენი განცხადება ხელისაწვდომი გახადოთ ყველა ქართველი ემიგრანტისა და ასევე სხვა მაცხოვრებლებისთვისაც რომნლებიც ამ განცხადებას ინგლისურ ენაზე ნახავენ.</p>
                    </div>
                    <div className={`tab-pane fade ${tradeTabActive ? '' : 'active show'}`} id="tabs-1-2">
                      <p> განცხადებების ეს პლათფორმა არის ორ ენოვანი, არა აქვს მნიშვნელობა რომელ ენაზე განათავსებთ განცადებას ის ორივე ენაზე იქნება ხელმისაწვდომი. </p>
                      <p> ჩვენი ამოცანაა ასვე დაგეხმაროთ დაიწყოთ უძრავი ქოვების გაქირავების საკუთარი ბიზნესი, დახმარება მოიცავს, კონსულტაციას, ასევე უშუალოდ გასაქირავებლად ფართის მოწყობას, ტიხრების გაკეთებას, ავეჯით აღჭურვას, ფოტო და სამგანზომილებიანი ვირტუალური ტურის გადაღებას და განთავსებას, გაქირავებაში მხარდაჭერას, ასევე გაქირავების შემდგომ საველე სერვისებს. </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <section className="section section-md bg-default text-center" style={{ minHeight: '360px' }}>
                {/* <LogInRegisterManu /> */}
                <LogInRegisterManu signInManueTrue={true} />
              </section>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-12">
              <section className="section section-md bg-default text-center">
                {/* <!-- tabs --> */}
                <div className="tabs-custom tabs-horizontal tabs-corporate" id="tabs-1">
                  {/* <!-- Nav tabs--> */}
                  <ul className="nav nav-tabs">
                    <li className="nav-item" onClick={handleAddLIstingsTabToggle} role="presentation"><button className={`nav-link ${addListinsgOrMyFavorates ? 'active' : ''}`} data-toggle="tab">გამოქვეყნება</button></li>
                    <li className="nav-item" onClick={handleMyFavorateTabToggle} role="presentation"><button className={`nav-link ${addListinsgOrMyFavorates ? '' : 'active'}`} data-toggle="tab">ჩეული განცხადებები</button></li>
                  </ul>
                  {/* <!-- Tab panes--> */}
                  <div className="tab-content">
                    <div className={`tab-pane fade ${addListinsgOrMyFavorates ? 'active show' : ''}`} id="tabs-1-1">
                      <p>რეგისტრაციის შემდეგ შეგიძლიათ განათავსოთ თქვენი ქონება, რაც საშუალებას მიცემს საცხოვრებლის მაძიებლებს, მოძებნონ, დაათვალიერონ, დაგიკავშირდნენ და იქირაონ ფართი.</p>
                      <p>წარმატებული რეგისტაციის და თქვნეს ანგარიშზე შესვლის შემდეგ, მოძებნეთ 'განცხადების ატვირთვა' მენიუში 'განცხადებების მართვა'. თქვენ დაინახავთ ფორმას რომელიც უნდა შეავსოთ სრულიად და ზუსტად. მისმართი აუცილებელია შეივსოს ზუსტად და ლათინური ასოებით. აუცილებელია ატვირთოთ ერთი სურათი მაინც. რეკომენდირებულია სამგანზომილბიანი ვირტუალური ტურის გადაღება მათთვის ვინც არაერთხელ გემავს ფართის გაქირავებას.</p>
                      <p>მას შემდეგ რაც ატვირთავთ განცხადებას ის გამოჩნდება თქვენს სამუშაო გვერდზე სადაც შეგიძლით შეხვიდეთ ნებისმიერ დროს. ამისთვის მოძებნეთ 'ჩემი განცხადებები' 'განცხადებების მართვის' მენიუში, იქ ნახავთ თქვენს განცხადებას და დააწკაპუნეთ ვირტუალურ ღილაკს "გამოქვეყნება".</p>
                    </div>
                    <div className={`tab-pane fade ${addListinsgOrMyFavorates ? '' : 'active show'}`} id="tabs-1-2">
                      <p>საცხოვრებლის ძებნისას თქვენ სავარაუდოდ მოგეწონებათ რამოდენიმე ვარიანტი, იმისათვის რომ მონიშნოთ და კვლავ არ ეძებოთ შერჩეული განცხადება, შეგიძლიათ დააწკაპუნოთ ვარსკვლავს რომელიც მდებარეობს განცხადების მარჯვენა დაბალ კუთხეში და ამით მონიშნოთ რჩეული თქვენი განცხადება.  </p>
                      <p>შემდეგ მოძებნეთ „ჩემი რჩეული განცხადებები“ მენიუში „განცხადებების მართვა“ და თქვენ გადახვალთ დვერდზე, სადაც მხოლოდ თავენს მიერ შერჩეული განცხადებებია. თუ რომელიმე განცხადების შენახვას გადაიფიქრებთ, დააწექით იგივე ღილაკს და ის აღარ გამჩნდება თქვენს ანგარიშში შემდგომი შემოსვლისას.</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    )}
    {languageToUse === "en" && (
      <div>
        <div>
          <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="28">
            <div className="container">
              <h2 className="breadcrumbs-custom-title">Sign in or register to create listings and add favorites to your collection.</h2>
            </div>
          </section>
          <section className="section-xs bg-white">
            <div className="container">
              <ul className="breadcrumbs-custom-path">
                <li><Link to={`/home${search}`}>Home</Link></li>
                <li><Link to={`/${search}`}>Properties</Link></li>
                <li><Link to={`/listing${search}`}>Submit Property</Link></li>
                <li><PopUpButton /></li>
              </ul>
            </div>
          </section>
          <div className="container" style={{ minHeight: '640px' }}>
            <div className="row">
              <div className="col-lg-4 col-md-3 col-sm-12">
                <section className="section section-md bg-default text-center">
                  {/* <!-- tabs --> */}
                  <div className="tabs-custom tabs-horizontal tabs-corporate" id="tabs-1">
                    {/* <!-- Nav tabs--> */}
                    <ul className="nav nav-tabs">
                      <li className="nav-item" onClick={handleTradeTabToggle} role="presentation"><button className={`nav-link ${tradeTabActive ? 'active' : ''}`} data-toggle="tab">Rental platform</button></li>
                      <li className="nav-item" onClick={handleRatesHighTabToggle} role="presentation"><button className={`nav-link ${tradeTabActive ? '' : 'active'}`} data-toggle="tab">For everyone</button></li>
                    </ul>
                    {/* <!-- Tab panes--> */}
                    <div className="tab-content">
                      <div className={`tab-pane fade ${tradeTabActive ? 'active show' : ''}`} id="tabs-1-1">
                        <p> This platform is designed to facilitate home rentals in multiple languages.</p>
                        <p> For those of you who have or plan to rent and sublet premises, this platform provides the tools to make your listing accessible to potential renters.</p>
                      </div>
                      <div className={`tab-pane fade ${tradeTabActive ? '' : 'active show'}`} id="tabs-1-2">
                        <p> This rental platform is multilingual, so it doesn't matter which language you use to share your experience—your post will be accessible in the available languages.</p>
                        <p>
                          We are here to assist you in launching your own real estate rental business. Our support includes consulting, arranging the space for rent, handling partitions, furnishing, capturing photographs, and creating a 3D virtual tour. We also provide ongoing rental support and post-rental field services.</p>

                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <section className="section section-md bg-default text-center" style={{ minHeight: '360px' }}>
                  {/* <LogInRegisterManu /> */}
                  <LogInRegisterManu signInManueTrue={true} />
                </section>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-12">
                <section className="section section-md bg-default text-center">
                  {/* <!-- tabs --> */}
                  <div className="tabs-custom tabs-horizontal tabs-corporate" id="tabs-1">
                    {/* <!-- Nav tabs--> */}
                    <ul className="nav nav-tabs">
                      <li className="nav-item" onClick={handleAddLIstingsTabToggle} role="presentation"><button className={`nav-link ${addListinsgOrMyFavorates ? 'active' : ''}`} data-toggle="tab">Add listings</button></li>
                      <li className="nav-item" onClick={handleMyFavorateTabToggle} role="presentation"><button className={`nav-link ${addListinsgOrMyFavorates ? '' : 'active'}`} data-toggle="tab">My favorates</button></li>
                    </ul>
                    {/* <!-- Tab panes--> */}
                    <div className="tab-content">
                      <div className={`tab-pane fade ${addListinsgOrMyFavorates ? 'active show' : ''}`} id="tabs-1-1">
                        <p>This platform enables you to showcase your property, allowing individuals seeking assets like yours to discover and engage in transactions.  </p>
                        <p>Upon successful registration and login, navigate to the "Add Listing" menu to submit your property, specify the asking price, and engage in consultations with our team. This process ensures that you optimize the value of your deal and conduct a secure trade.</p>
                      </div>
                      <div className={`tab-pane fade ${addListinsgOrMyFavorates ? '' : 'active show'}`} id="tabs-1-2">
                        <p>When seeking to rent a property, you can identify preferred assets, bookmark them using the start icon, and, once your selection is finalized, reach out to our team to facilitate the transaction on your behalf.  </p>
                        <p>Collaborate with our team to ensure the maximization of deal value and the execution of a secure trade.</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    {languageToUse === "ru" && (
      <div>
        <div>
          <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="28">
            <div className="container">
              <h2 className="breadcrumbs-custom-title">Войдите или зарегистрируйтесь, чтобы создавать объявления и добавлять избранное в свою коллекцию.</h2>
            </div>
          </section>
          <section className="section-xs bg-white">
            <div className="container">
              <ul className="breadcrumbs-custom-path">
                <li><Link to={`/home${search}`}>Обзор</Link></li>
                <li><Link to={`/${search}`}>Недвижимость</Link></li>
                <li><Link to={`/listing${search}`}>Опубликовать недвижимость</Link></li>
                <li><PopUpButton /></li>
              </ul>
            </div>
          </section>
          <div className="container" style={{ minHeight: '640px' }}>
            <div className="row">
              <div className="col-lg-4 col-md-3 col-sm-12">
                <section className="section section-md bg-default text-center">
                  {/* <!-- tabs --> */}
                  <div className="tabs-custom tabs-horizontal tabs-corporate" id="tabs-1">
                    {/* <!-- Nav tabs--> */}
                    <ul className="nav nav-tabs">
                      <li className="nav-item" onClick={handleTradeTabToggle} role="presentation"><button className={`nav-link ${tradeTabActive ? 'active' : ''}`} data-toggle="tab">Платформа для аренды</button></li>
                      <li className="nav-item" onClick={handleRatesHighTabToggle} role="presentation"><button className={`nav-link ${tradeTabActive ? '' : 'active'}`} data-toggle="tab">Для всех</button></li>
                    </ul>
                    {/* <!-- Tab panes--> */}
                    <div className="tab-content">
                      <div className={`tab-pane fade ${tradeTabActive ? 'active show' : ''}`} id="tabs-1-1">
                        <p> Эта платформа предназначена для облегчения аренды жилья на нескольких языках.</p>
                        <p> Для тех из вас, кто имеет или планирует арендовать или сдавать в субаренду помещения, эта платформа предоставляет инструменты, которые сделают ваше объявление доступным для потенциальных арендаторов.</p>
                      </div>
                      <div className={`tab-pane fade ${tradeTabActive ? '' : 'active show'}`} id="tabs-1-2">
                        <p>Эта платформа аренды является многоязычной, поэтому не имеет значения, какой язык вы используете, чтобы поделиться своим опытом — ваша публикация будет доступна на доступных языках.</p>
                        <p>Мы здесь, чтобы помочь вам в запуске собственного бизнеса по аренде недвижимости. Наша поддержка включает в себя консультации, организацию аренды помещения, установку перегородок, меблировку, фотосъемку и создание виртуального 3D-тура. Мы также обеспечиваем постоянную поддержку при аренде и выездное обслуживание после аренды.</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <section className="section section-md bg-default text-center" style={{ minHeight: '360px' }}>
                  {/* <LogInRegisterManu /> */}
                  <LogInRegisterManu signInManueTrue={true} />
                </section>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-12">
                <section className="section section-md bg-default text-center">
                  {/* <!-- tabs --> */}
                  <div className="tabs-custom tabs-horizontal tabs-corporate" id="tabs-1">
                    {/* <!-- Nav tabs--> */}
                    <ul className="nav nav-tabs">
                      <li className="nav-item" onClick={handleAddLIstingsTabToggle} role="presentation"><button className={`nav-link ${addListinsgOrMyFavorates ? 'active' : ''}`} data-toggle="tab">Опубликовать</button></li>
                      <li className="nav-item" onClick={handleMyFavorateTabToggle} role="presentation"><button className={`nav-link ${addListinsgOrMyFavorates ? '' : 'active'}`} data-toggle="tab">Мои любимые</button></li>
                    </ul>
                    {/* <!-- Tab panes--> */}
                    <div className="tab-content">
                      <div className={`tab-pane fade ${addListinsgOrMyFavorates ? 'active show' : ''}`} id="tabs-1-1">
                        <p>Эта платформа позволяет вам демонстрировать свою собственность, позволяя людям, ищущим активы, подобные вашему, находить и участвовать в транзакциях. </p>
                        <p>После успешной регистрации и входа в систему перейдите в меню «Добавить объявление», чтобы представить свою недвижимость, указать запрашиваемую цену и начать консультации с нашей командой. Этот процесс гарантирует, что вы оптимизируете стоимость своей сделки и проведете безопасную торговлю.</p>
                      </div>
                      <div className={`tab-pane fade ${addListinsgOrMyFavorates ? '' : 'active show'}`} id="tabs-1-2">
                        <p>Желая арендовать недвижимость, вы можете определить предпочтительные активы, добавить их в закладки, используя значок «Пуск», и, как только ваш выбор будет завершен, обратитесь к нашей команде, чтобы облегчить транзакцию от вашего имени.  </p>
                        <p>Сотрудничайте с нашей командой, чтобы обеспечить максимизацию стоимости сделки и выполнение безопасной сделки.</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
  );
}

export default Login;