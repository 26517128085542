import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useAuth } from './authContext';
import { useLanguage } from './languageContext';
import backendUrl from './config';
import { sendEmail } from "./emailSenderFrontEnd";

function DeleteDemand() {
    const product = "demand";
    const { currentLanguage } = useLanguage();
    const { itemId } = useParams();
    const { loggedIn, userId } = useAuth();
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState("");
    const [responseMessageShow, setResponseMessageShow] = useState(false);
    // const [imgNamesArray, setImgNamesArray] = useState([]);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // Need to get photo indexes to delete

    useEffect(() => {
        fetch(`${backendUrl}/item/${product}/${new Date().getTime()}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                // setImgNamesArray(responseData.imgs);
                handleDelete(responseData.imgs);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [loggedIn, navigate, itemId]);

    async function handleDelete(imgNamesArray) {
        try {
            if (imgNamesArray.length > 0) {
                // Make an HTTP DELETE request to delete photos
                const photosResponse = await fetch(`${backendUrl}/api/deleteallphotosofitem/${product}/${itemId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        // Add any additional headers if required
                    },
                    body: JSON.stringify({ imageIds: imgNamesArray }),
                });
                if (!photosResponse.ok) {
                    console.error(`Error deleting photos for item with ID ${itemId}`);
                    setResponseMessage("Error deleting photos. Please try again.");
                    return;
                }
            }
            // Photos deleted successfully, now delete the item
            const itemResponse = await fetch(`${backendUrl}/api/deletedemand/${itemId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if required
                },
            });
            if (!itemResponse.ok) {
                console.error(`Error deleting item with ID ${itemId}`);
                setResponseMessage("Error deleting item. Please try again.");
                return;
            }

            sendEmail(
                "", // from
                "", // name
                "", // phone
                "", // subject
                "", // text
                itemId, // itemId
                "", // address
                "Listing Demand", // product
                "Delete" // activity
            );

            // Both photos and item deleted successfully
            navigate("/demands");
        } catch (error) {
            console.error("Error deleting photos and item:", error);
            setResponseMessage("Error deleting photos and item. Please try again.");
        }
    };

    useEffect(() => {
        if (loggedIn && userId) {
            // You may want to perform additional actions or checks based on user information
        }
    }, [loggedIn, userId]);

    return (
        <div>
            {languageToUse === "en" && (
                <div className="col-12">
                    <button className="button button-sm button-secondary" onClick={handleDelete}>Delete </button>
                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Response: {responseMessage}</p>}
                </div>
            )}
            {languageToUse === "ge" && (
                <div className="col-12">
                    <button className="button button-sm button-secondary" onClick={handleDelete}>წაშლა </button>
                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>შეტყობინება: {responseMessage}</p>}
                </div>
            )}
            {languageToUse === "ru" && (
                <div className="col-12">
                    <button className="button button-sm button-secondary" onClick={handleDelete}>Удалить</button>
                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Ответ: {responseMessage}</p>}
                </div>
            )}
        </div>
    );
}

export default DeleteDemand;