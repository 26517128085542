import React, { createContext, useContext, useState, useEffect } from 'react';
import backendUrl from './config';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState(null);
    const [loginWithGoogle, setLoginWithGoogle] = useState(null);

    // Check for existing session on page load
    useEffect(() => {
        const storedLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        const storedUserId = localStorage.getItem('userId');

        if (storedLoggedIn && storedUserId) {
            setLoggedIn(true);
            setUserId(storedUserId);
        }
    }, []);

    const login = (newUserId) => {
        setLoggedIn(true);
        setUserId(newUserId);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userId', newUserId);
    };

    const logout = () => {
        setLoggedIn(false);
        setUserId(null);
        setLoginWithGoogle(null); // Reset loginWithGoogle function to null
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userId');
    };

    // Function to handle login using Google
    const handleLoginWithGoogle = (credential) => {
        const userObject = jwtDecode(credential);

        if (getUserInfo(userObject.sub)) {
            console.log("Googel user is registered");
            setLoggedIn(true);
        } else {
            registerWithBackend(userObject);
        }
    };

    const registerWithBackend = (userObject) => {
        fetch(`${backendUrl}/google/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: userObject.given_name + " " + userObject.family_name,
                email: userObject.email,
                googleId: userObject.sub
            })
        })
            .then(response => {
                if (response.ok) {
                    getUserInfo(response.user.googleId);
                    setUserId(response.user._id);
                    setLoggedIn(true);
                } else {
                    console.error('Error registering user');
                }
            })
            .catch(error => {
                console.error('Error registering user:', error);
            });
    };

    const getUserInfo = (googleId) => {
        // fetch(`/google/user?googleId=${googleId}`)
        fetch(`${backendUrl}/google/user/${googleId}`)
            .then(response => response.json())
            .then(data => {
                if (data && data._id) {
                    setUserId(data._id);
                    localStorage.setItem('userId', data._id);
                    setLoggedIn(true);
                    return true;
                } else {
                    console.error('User not found in the database');
                    return false;
                }
            })
            .catch(error => {
                console.error('Error fetching user info:', error);
                return false;
            });
    };

    return (
        <AuthContext.Provider value={{ loggedIn, login, logout, userId, loginWithGoogle: handleLoginWithGoogle }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return authContext;
};
