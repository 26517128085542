import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './home';
import Listing from './listing';
import Login from './login';
import ItemDetails from './itemDetails';
import FavorateListings from './favoratelistings';
import MyListings from './mylistings';
import StripeAppPayment from './stripeAppPayment';
import About from './about';
import Loader from './loader';
import Header from './header';
import Footer from './footer';
import { useLanguage } from './languageContext';
import EditListing from './editListing';
import DeleteItem from './deleteItem';
import ListingOptions from './listingOptions';
import VehicleListing from './vehicleListing';
import Poster from './poster';
import VehiclePoster from './vehiclePoster';
import VehicleDetails from './vehiclesDetails';
import DeleteVehicle from './deleteVehicle';
import EditVehicleListing from './editVehicleListing';
import MyListingOptions from './mylistingOptions';
import MyListedVehicles from './myListedVehicles';
import FavorateListingOptions from './favorateListinOptions';
import FavorateVehicles from './favorateVehicles';
import ServicePoster from './servicePoster';
import ServiceListing from './serviceListing';
import ServiceDetails from './serviceDetails';
import EditServiceListing from './editServiseListing';
import DeleteService from './deleteService';
import FavoriteServices from './favoriteServices';
import MyListedServices from './myListedServices';
import DemandPoster from './demandPoster';
import DemandListing from './demandListing';
import EditDemandListing from './editDemandListing';
import DeleteDemand from './detelteDemand';
import FavoriteDemands from './faivoriteDemands';
import MyListedDemands from './myListedDemands';
import DemandDetails from './demandDetails';
import RealEstatePoster from './realEstatePoster';
import HomeVehicles from './homeVehicles';
import HomeServices from './homeServices';
import HomeDemands from './homeDemads';
import HomeFavoriteListings from './homeFavoriteListings';
import HomeMyListings from './homeMylistings';
import HomeMyVehicles from './homeMyVehisles';
import HomeFavoriteVehicles from './homeFavoriteVehilces';
import HomeMyServices from './homeMyServices';
import HomeFavoriteServices from './homeFavoriteServices';
import HomeFavoriteDemands from './homeFavoriteDemans';
import HomeMyDemands from './homeMyDemands';
import VisitCounts from './visitCounts';
import LanguageDropdown from './languageDropdown';
// import FreeSubscription from './freeSubscription';



function Main() {
  const { currentLanguage, switchLanguage } = useLanguage();

  const storedLanguage = sessionStorage.getItem('currentLanguage');
  const languageToUse = storedLanguage || currentLanguage;

  const uniqueKey = new Date().getTime().toString();

  return (
    <div>
      <Header language={languageToUse}></Header>
      <main>
        <div className='container' style={{ display: 'flex' }}>
          {/* <LanguageDropdown /> */}
          <div >
            <img className=''
              src="images/Georgia_flag.png"
              alt="Georgian Flag"
              onClick={() => switchLanguage('ge')}
              style={{ cursor: 'pointer', width: '17px', height: '12px' }}
            />
            <img
              src="images/usa_flag.png"
              alt="English Flag"
              onClick={() => switchLanguage('en')}
              style={{ cursor: 'pointer', marginRight: '1px', width: '17px', height: '12px', marginLeft: '1px' }}
            />
            <img
              // src="images/Flag_USSR1.png"
              // src="images/russia.png"
              src="images/Russian_flag.png"
              alt="Russian language"
              onClick={() => switchLanguage('ru')}
              // style={{ cursor: 'pointer', marginRight: '5px', width: '40px', height: '34px', marginLeft: '2px' }}
              style={{ cursor: 'pointer', marginRight: '1px', width: '17px', height: '12px', marginLeft: '1px' }}
            />
          </div>
          <div style={{ marginLeft: 'auto', marginRight: '1px' }}>
            <Link to={`/home`}>
              <img
                src="images/house_1.png"
                alt="Real-estate"
                style={{ cursor: 'pointer', marginRight: '1px', width: '17px', height: '17px' }}
              />
            </Link>
            <Link to={`/vehicles`}>
              <img
                src="images/car_1.png"
                alt="vehicle"
                style={{ cursor: 'pointer', marginRight: '1px', width: '17px', height: '17px' }}
              />
            </Link>
            <Link to={`/services`}>
              <img
                src="images/service_5.jpg"
                alt="offer"
                style={{ cursor: 'pointer', marginRight: '1px', width: '17px', height: '17px' }}
              />
            </Link>
            <Link to={`/demands`}>
              <img className=''
                src="images/demand_1.jpg"
                alt="demand"
                style={{ cursor: 'pointer', marginRight: '1px', width: '18px', height: '18px' }}
              />
            </Link>
          </div>
        </div>


        <Link to="/itemDetails"></Link>
        <Routes key={`${uniqueKey}-${languageToUse}`}>
          <Route path="/" element={<Poster language={languageToUse} />} />
          <Route path="/home" element={<Home language={languageToUse} />} />
          <Route path="/realEstatePoster" element={<RealEstatePoster language={languageToUse} />} />
          <Route path="/item/:itemId" element={<ItemDetails language={languageToUse} />} />
          <Route path="/listing" element={<Listing language={languageToUse} />} />
          <Route path="/mylistings" element={<HomeMyListings language={languageToUse} />} />
          <Route path="/favoratelistings" element={<HomeFavoriteListings language={languageToUse} />} />
          <Route path="/process-payment/:product/:itemId" element={<StripeAppPayment language={languageToUse} />} />
          <Route path="/about" element={<About language={languageToUse} />} />
          <Route path="/loader" element={<Loader language={languageToUse} />} />
          <Route path="/login" element={<Login language={languageToUse} />} />
          <Route path="/editlisting/:itemId" element={<EditListing language={languageToUse} />} />
          <Route path="/delete/:itemId" element={<DeleteItem language={languageToUse} />} />
          <Route path="/listingoptions" element={<ListingOptions language={languageToUse} />} />
          <Route path="/vehiclelisting" element={<VehicleListing language={languageToUse} />} />
          <Route path="/vehicles" element={<HomeVehicles language={languageToUse} />} />
          <Route path="/vehicledetails/:itemId" element={<VehicleDetails language={languageToUse} />} />
          <Route path="/deletevehicle/:itemId" element={<DeleteVehicle language={languageToUse} />} />
          <Route path="/editvehiclelisting/:itemId" element={<EditVehicleListing language={languageToUse} />} />
          <Route path="/mylistingoptions" element={<MyListingOptions language={languageToUse} />} />
          <Route path="/mylistedvehicles" element={<HomeMyVehicles language={languageToUse} />} />
          <Route path="/favoratelistingoptions" element={<FavorateListingOptions language={languageToUse} />} />
          <Route path="/favoratevehicles" element={<HomeFavoriteVehicles language={languageToUse} />} />
          <Route path="/services" element={<HomeServices language={languageToUse} />} />
          <Route path="/servicelisting" element={<ServiceListing language={languageToUse} />} />
          <Route path="/servicedetails/:itemId" element={<ServiceDetails language={languageToUse} />} />
          <Route path="/editservicelisting/:itemId" element={<EditServiceListing language={languageToUse} />} />
          <Route path="/deleteservice/:itemId" element={<DeleteService language={languageToUse} />} />
          <Route path="/favorateservices" element={<HomeFavoriteServices language={languageToUse} />} />
          <Route path="/mylistedservices" element={<HomeMyServices language={languageToUse} />} />
          <Route path="/demands" element={<HomeDemands language={languageToUse} />} />
          <Route path="/demandlisting" element={<DemandListing language={languageToUse} />} />
          <Route path="/demanddetails/:itemId" element={<DemandDetails language={languageToUse} />} />
          <Route path="/editdemandlisting/:itemId" element={<EditDemandListing language={languageToUse} />} />
          <Route path="/deletedemand/:itemId" element={<DeleteDemand language={languageToUse} />} />
          <Route path="/favoratedemands" element={<HomeFavoriteDemands language={languageToUse} />} />
          <Route path="/mylisteddemads" element={<HomeMyDemands language={languageToUse} />} />
          <Route path="/visitcounts" element={<VisitCounts language={languageToUse} />} />
        </Routes>
      </main>
      <Footer language={languageToUse}></Footer>
    </div>
  );
}

export default Main;


// import React, { useState } from 'react';
// import { Routes, Route, Link } from 'react-router-dom';
// import Home from './home';
// import Listing from './listing';
// import Login from './login';
// import ItemDetails from './itemDetails';
// import FavorateListings from './favoratelistings';
// import MyListings from './mylistings';
// import StripeAppPayment from './stripeAppPayment';
// import About from './about';
// import Loader from './loader';
// import Header from './header';
// import Footer from './footer';
// import { useLanguage } from './languageContext';
// import EditListing from './editListing';
// import DeleteItem from './deleteItem';
// import ListingOptions from './listingOptions';
// import VehicleListing from './vehicleListing';
// import Poster from './poster';
// import VehiclePoster from './vehiclePoster';
// import VehicleDetails from './vehiclesDetails';
// import DeleteVehicle from './deleteVehicle';
// import EditVehicleListing from './editVehicleListing';
// import MyListingOptions from './mylistingOptions';
// import MyListedVehicles from './myListedVehicles';
// import FavorateListingOptions from './favorateListinOptions';
// import FavorateVehicles from './favorateVehicles';
// import ServicePoster from './servicePoster';
// import ServiceListing from './serviceListing';
// import ServiceDetails from './serviceDetails';
// import EditServiceListing from './editServiseListing';
// import DeleteService from './deleteService';
// import FavoriteServices from './favoriteServices';
// import MyListedServices from './myListedServices';
// import DemandPoster from './demandPoster';
// import DemandListing from './demandListing';
// import EditDemandListing from './editDemandListing';
// import DeleteDemand from './detelteDemand';
// import FavoriteDemands from './faivoriteDemands';
// import MyListedDemands from './myListedDemands';
// import DemandDetails from './demandDetails';
// // import FreeSubscription from './freeSubscription';



// function Main() {
//   const { currentLanguage, switchLanguage } = useLanguage();

//   const uniqueKey = new Date().getTime().toString();

//   const [selectedListingId, setSelectedListingId] = useState(null);
//   const [showItemDetails, setShowItemDetails] = useState(false);

//   const toggleItemDetails = (listingId) => {
//     setSelectedListingId(listingId);
//     setShowItemDetails(true);
//   };

//   const hideItemDetails = () => {
//     setShowItemDetails(false);
//     setSelectedListingId(null);
//   };

//   return (
//     <div>
//       <Header language={currentLanguage}></Header>
//       <main>
//         <div style={{ display: 'flex' }}>
//           <div style={{ marginRight: '20px' }}>
//             <img
//               src="images/Georgia_flag.png"
//               alt="Georgian Flag"
//               onClick={() => switchLanguage('ge')}
//               style={{ cursor: 'pointer', width: '30px', height: '20px', margin: '2px' }}
//             />
//             <img
//               src="images/usa_flag.png"
//               alt="English Flag"
//               onClick={() => switchLanguage('en')}
//               style={{ cursor: 'pointer', marginRight: '5px', width: '30px', height: '20px' }}
//             />
//           </div>
//           <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
//             <Link to={`/home`}>
//               <img
//                 src="images/house_1.png"
//                 alt="Real-estate"
//                 style={{ cursor: 'pointer', marginRight: '5px', width: '30px', height: '30px' }}
//               />
//             </Link>
//             <Link to={`/vehicles`}>
//               <img
//                 src="images/car_1.png"
//                 alt="Real-estate"
//                 style={{ cursor: 'pointer', marginRight: '5px', width: '30px', height: '30px' }}
//               />
//             </Link>
//             <Link to={`/services`}>
//               <img
//                 src="images/year.jpeg"
//                 alt="Real-estate"
//                 style={{ cursor: 'pointer', marginRight: '5px', width: '30px', height: '30px' }}
//               />
//             </Link>
//             <Link to={`/demands`}>
//               <img
//                 src="images/demand.png"
//                 alt="Real-estate"
//                 style={{ cursor: 'pointer', marginRight: '5px', width: '30px', height: '30px' }}
//               />
//             </Link>
//           </div>
//         </div>
//         {/* <ItemDetails language={currentLanguage} toggleOverlay={async () => await toggleOverlay(itemId)} itemId={itemId} /> */}
//         {showItemDetails && (
//           <ItemDetails
//             language={currentLanguage}
//             itemId={selectedListingId}
//             onClose={hideItemDetails}
//           />
//         )}

//         <Link to="/itemDetails"></Link>
//         <Routes key={`${uniqueKey}-${currentLanguage}`}>
//           <Route path="/" element={<Poster language={currentLanguage} />} />
//           <Route
//             path="/home"
//             element={
//               <Home
//                 language={currentLanguage}
//                 toggleItemDetails={toggleItemDetails}
//               />
//             }
//           />
//           {/* <Route path="/item/:itemId" element={<ItemDetails language={currentLanguage} />} /> */}
//           <Route path="/listing" element={<Listing language={currentLanguage} />} />
//           <Route path="/mylistings" element={<MyListings language={currentLanguage} />} />
//           <Route path="/favoratelistings" element={<FavorateListings language={currentLanguage} />} />
//           <Route path="/process-payment/:product/:itemId" element={<StripeAppPayment language={currentLanguage} />} />
//           <Route path="/about" element={<About language={currentLanguage} />} />
//           <Route path="/loader" element={<Loader language={currentLanguage} />} />
//           <Route path="/login" element={<Login language={currentLanguage} />} />
//           <Route path="/editlisting/:itemId" element={<EditListing language={currentLanguage} />} />
//           <Route path="/delete/:itemId" element={<DeleteItem language={currentLanguage} />} />
//           <Route path="/listingoptions" element={<ListingOptions language={currentLanguage} />} />
//           <Route path="/vehiclelisting" element={<VehicleListing language={currentLanguage} />} />
//           <Route path="/vehicles" element={<VehiclePoster language={currentLanguage} />} />
//           <Route path="/vehicledetails/:itemId" element={<VehicleDetails language={currentLanguage} />} />
//           <Route path="/deletevehicle/:itemId" element={<DeleteVehicle language={currentLanguage} />} />
//           <Route path="/editvehiclelisting/:itemId" element={<EditVehicleListing language={currentLanguage} />} />
//           <Route path="/mylistingoptions" element={<MyListingOptions language={currentLanguage} />} />
//           <Route path="/mylistedvehicles" element={<MyListedVehicles language={currentLanguage} />} />
//           <Route path="/favoratelistingoptions" element={<FavorateListingOptions language={currentLanguage} />} />
//           <Route path="/favoratevehicles" element={<FavorateVehicles language={currentLanguage} />} />
//           <Route path="/services" element={<ServicePoster language={currentLanguage} />} />
//           <Route path="/servicelisting" element={<ServiceListing language={currentLanguage} />} />
//           <Route path="/servicedetails/:itemId" element={<ServiceDetails language={currentLanguage} />} />
//           <Route path="/editservicelisting/:itemId" element={<EditServiceListing language={currentLanguage} />} />
//           <Route path="/deleteservice/:itemId" element={<DeleteService language={currentLanguage} />} />
//           <Route path="/favorateservices" element={<FavoriteServices language={currentLanguage} />} />
//           <Route path="/mylistedservices" element={<MyListedServices language={currentLanguage} />} />
//           <Route path="/demands" element={<DemandPoster language={currentLanguage} />} />
//           <Route path="/demandlisting" element={<DemandListing language={currentLanguage} />} />
//           <Route path="/demanddetails/:itemId" element={<DemandDetails language={currentLanguage} />} />
//           <Route path="/editdemandlisting/:itemId" element={<EditDemandListing language={currentLanguage} />} />
//           <Route path="/deletedemand/:itemId" element={<DeleteDemand language={currentLanguage} />} />
//           <Route path="/favoratedemands" element={<FavoriteDemands language={currentLanguage} />} />
//           <Route path="/mylisteddemads" element={<MyListedDemands language={currentLanguage} />} />
//         </Routes>
//       </main>
//       <Footer language={currentLanguage}></Footer>
//     </div>
//   );
// }

// export default Main;