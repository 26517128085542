// MapWrapper.js
// import React from 'react';
// import { LoadScript } from '@react-google-maps/api';

// const MapWrapper = ({ children }) => {
//     const googleMapsApiKey = process.env.REACT_APP_KEY_GOOGLE_MAPS;

//     return (
//         <LoadScript googleMapsApiKey={googleMapsApiKey}>
//             {children}
//         </LoadScript>
//     );
// };

// export default MapWrapper;


import React from 'react';

const MapWrapper = ({ children }) => {
    return <>{children}</>;
};

export default MapWrapper;
