import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
// import { State, City } from 'country-state-city';
import { State, City } from 'country-state-city';
import backendUrl from './config';
import { useAuth } from './authContext';
import 'react-datepicker/dist/react-datepicker.css';
import { useLanguage } from './languageContext';
import SubMenue from "./subMenue";


function EditDemandListing() {
    const product = "demand";
    const { currentLanguage } = useLanguage();
    const { itemId } = useParams();
    const { loggedIn, userId } = useAuth();
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // Load selected listing start
    const [selectedDemand, setSelectedDemand] = useState({});
    const [imagePaths, setImagePaths] = useState([]);
    useEffect(() => {
        fetch(`${backendUrl}/item/${product}/${new Date().getTime()}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                setSelectedDemand(responseData);
                setLoading(false);
                fetchPhotos(responseData);

                setInputValueDemandType(responseData.demandtype);
                setInputValueState(responseData.state);
                const stateCities = City.getCitiesOfState('US', responseData.state);
                setCities(stateCities);
                setInputCity(responseData.city);
                setInputValueZip(responseData.zip);
                setInputValueName(responseData.name);
                setInputValueEmail(responseData.email);
                setInputValuePhone(responseData.phone);
                setInputValueSubject(responseData.subject);
                setInputValueComment(responseData.comment);
                setInputValueProposedPrice(responseData.proposedPrice);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [loggedIn, navigate, itemId]);


    // fetch photos 
    const fetchPhotos = async (responseData) => {
        setImagePaths([]);

        // Check if responseData.imgs exists and has a length property
        if (responseData.imgs && responseData.imgs.length) {
            // Use a Set to store unique URLs
            const uniqueImagePaths = new Set();

            // Receive and add each image URL to the imagePaths array
            const numberOfPhotos = responseData.imgs.length;

            for (let i = 0; i < numberOfPhotos; i++) {
                try {
                    const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${i}`);

                    if (response.ok) {
                        const contentType = response.headers.get('content-type');

                        if (contentType && contentType.includes('application/json')) {
                            const data = await response.json();
                            setResponseMessage(data.message);
                        } else {
                            const blob = await response.blob();
                            const imgUrl = URL.createObjectURL(blob);

                            // Add the unique URL to the set
                            uniqueImagePaths.add(imgUrl);
                        }
                    } else {
                        setResponseMessage("Error fetching photos. Response failed.");
                    }
                } catch (error) {
                    console.error("Error fetching photos:", error);
                    setResponseMessage("Error fetching photos.");
                }
            }

            setImagePaths([...uniqueImagePaths]);
        } else {
            // Handle the case where responseData.imgs is undefined or empty
            console.error("Error: responseData.imgs is undefined or empty");
            setResponseMessage("Error fetching photos. Invalid response data.");
        }
    };

    // fetching photo end
    const [loading, setLoading] = useState(true);


    const [inputValueDemandType, setInputValueDemandType] = useState("");
    const [inputValueCity, setInputCity] = useState("");
    const [inputValueState, setInputValueState] = useState("");
    const [inputValueZip, setInputValueZip] = useState("");
    const [inputValueEmail, setInputValueEmail] = useState("");
    const [inputValuePhone, setInputValuePhone] = useState("");
    const [inputValueName, setInputValueName] = useState("");
    const [inputValueSubject, setInputValueSubject] = useState("");
    const [inputValueComment, setInputValueComment] = useState("");
    const [inputValueProposedPrice, setInputValueProposedPrice] = useState("");


    const [images, setImages] = useState([]);
    const [responseMessage, setResponseMessage] = useState("");
    // const [userId, setUserId] = useState("");
    const [responseMessageShow, setResponseMessageShow] = useState(false);

    // sellect city
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);



    useEffect(() => {
        // Populate the state dropdown with options
        const countryStates = State.getStatesOfCountry('US');
        setStates(countryStates);
        // Only fetch data if the component is mounted
        let isMounted = true;

        const fetchData = async () => {
            try {
                // Check if the user is authenticated using the useAuth hook
                if (!loggedIn) {
                    navigate('/login');
                    return;
                }

                if (isMounted) {
                    setLoading(false);
                    // Get the user ID using the useAuth hook
                    // console.log('User ID:', userId);

                    // Additional logic if needed...
                }

            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle the error if needed
            }
        };

        fetchData();

        // Cleanup function to update the mounted status
        return () => {
            isMounted = false;
        };

    }, [loggedIn, userId, navigate]);


    if (loading) {
        return <div>Loading...</div>;
    };

    const handleInputChangeDemandType = (e) => {
        setInputValueDemandType(e.target.value);
    };

    const handleStateChange = (e) => {
        const newState = e.target.value;
        setInputValueState(newState);

        // Update the city dropdown based on the selected state
        const stateCities = City.getCitiesOfState('US', newState);
        setCities(stateCities);
    };
    const handleInputChangeCity = (e) => {
        setInputCity(e.target.value);
    };
    const handleInputChangeZip = (e) => {
        setInputValueZip(e.target.value);
    };

    const handleEmail = (e) => {
        setInputValueEmail(e.target.value);
    };
    const handlePhone = (e) => {
        setInputValuePhone(e.target.value);
    };
    const handleName = (e) => {
        setInputValueName(e.target.value);
    };
    const handleSubject = (e) => {
        setInputValueSubject(e.target.value);
    };
    const handleComment = (e) => {
        setInputValueComment(e.target.value);
    };
    const handleInputChangeProposedPrice = (e) => {
        setInputValueProposedPrice(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        // Check if the number of files exceeds the limit
        if (selectedFiles.length > 3) {
            alert("You can only upload a maximum of three files at once, but you can add more when listing is saved or posted.");
            // Clear the file input
            e.target.value = null;
            return;
        }
        const filesArray = Array.from(selectedFiles);
        // Set the state to trigger a re-render
        setImages((prevImages) => [...prevImages, ...filesArray]);
    };

    const handleDeleteImage = (index, event) => {
        event.preventDefault();

        // Remove the image from the images array based on its index
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });

        // Reset the value of the file input element to clear the uploaded image(s)
        const fileInput = document.getElementById(languageToUse === 'ge' ? 'formFileGe' : 'formFileEn');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleDeleteSavedImage = async (index, event) => {
        event.preventDefault();

        try {
            // Assuming you have an image ID associated with each image in the backend
            const imageIdToDelete = index; // You may need to adjust this based on your backend implementation

            // Make an HTTP DELETE request to the backend endpoint
            const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${imageIdToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if required
                },
                // You can include a request body if needed
                // body: JSON.stringify({ /* any additional data to send to the backend */ }),
            });

            if (response.ok) {
                // Image deletion successful
                // console.log(`Image at index ${index} deleted successfully`);

                // Now you can update the state or perform any other necessary actions
                // For example, remove the image path from the state
                const updatedImagePaths = [...imagePaths];
                updatedImagePaths.splice(index, 1);
                setImagePaths(updatedImagePaths);
            } else {
                // Image deletion failed
                console.error(`Error deleting image at index ${index}`);
                setResponseMessage("Error deleting image. Please try again.");
            }
        } catch (error) {
            console.error("Error deleting image:", error);
            setResponseMessage("Error deleting image. Please try again.");
        }
    };


    const handleUpdateDemandListing = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        images.forEach((file, index) => {
            formData.append(`files`, file);
        });
        formData.append('demandtype', inputValueDemandType);
        formData.append('city', inputValueCity);
        formData.append('state', inputValueState);
        formData.append('zip', inputValueZip);
        formData.append('email', inputValueEmail);
        formData.append('phone', inputValuePhone);
        formData.append('name', inputValueName);
        formData.append('subject', inputValueSubject);
        formData.append('comment', inputValueComment);
        formData.append('proposedPrice', inputValueProposedPrice);

        try {
            // Send the data to the backend
            const response = await fetch(`${backendUrl}/demandlistingupdate/${itemId}`, {
                method: "PATCH",
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    // Add any additional headers if needed
                }
            });

            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const data = await response.json();
                    setImages([]);
                    setResponseMessage(data.message);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                    // Additional actions for success
                } else {
                    // Handle the case where the response is not JSON
                    const errorMessage = await response.text();
                    setResponseMessage(`${errorMessage}`);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                }
                sendEmail({
                    from: inputValueEmail,
                    name: inputValueName,
                    phone: inputValuePhone,
                    subject: 'Edit listing from ' + inputValueName,
                    text: 'Edit listing, address ' + inputValueCity + inputValueState + inputValueZip,
                    itemId: '',
                    location: inputValueCity + inputValueState + inputValueZip,
                    product: 'Listing Demand',
                    activity: "Edit Demand listing"
                });
                navigate(`/demands`);
            } else {
                const errorMessage = await response.text();
                setResponseMessage(`${errorMessage}`);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            }
        } catch (error) {
            setResponseMessage("Error updating data on the server.");
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        }
    };

    // send email when property submitted

    async function sendEmail(data) {
        try {

            // Validate mandatory fields
            if (!inputValueCity || !inputValueState || !inputValueZip) {
                setResponseMessage("Please fill in all mandatory fields and upload at least one file.");
                return; // Stop further execution
            }

            const response = await fetch(`${backendUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                const responseData = await response.json();
                setResponseMessage(responseData.message);
            } else {
                const errorData = await response.json();
                setResponseMessage(`Error: ${errorData.message}`);
            }
        } catch (error) {
            setResponseMessage("Error sending data to the server.");
        }
    };


    // end send email

    // slider
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    // slider
    return (
        <div>
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/demand4-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 hidden={languageToUse !== "ge"} className="breadcrumbs-custom-title" style={{ color: 'black' }}>თქვენი მოთხოვნის შესახებ განცხადებაში ცვლილებების შეტანა</h2>
                        <h2 hidden={languageToUse !== "en"} className="breadcrumbs-custom-title" style={{ color: 'black' }}>Update a posted demand</h2>
                        <h2 hidden={languageToUse !== "ru"} className="breadcrumbs-custom-title" style={{ color: 'black' }}>Обновить опубликованную заявку</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleUpdateDemandListing} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/semandlisting">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3 hidden={languageToUse !== "ge"}>ინფორმაცია მოთხოვნის შესახებ</h3>
                                        <h3 hidden={languageToUse !== "en"}>Information about demands</h3>
                                        <h3 hidden={languageToUse !== "ru"}>Информация о надобности</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="State" value={inputValueState} onChange={handleStateChange}>
                                                    <option hidden={languageToUse !== "ge"} value="">შეარჩიეთ შტატი</option>
                                                    <option hidden={languageToUse !== "en"} value="">State</option>
                                                    <option hidden={languageToUse !== "ru"} value="">Штат</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="City" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option hidden={languageToUse !== "ge"}>შეარჩიეთ ქალაქი</option>
                                                    <option hidden={languageToUse !== "en"}>City</option>
                                                    <option hidden={languageToUse !== "ru"}>Город</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input hidden={languageToUse !== "ge"} type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" required placeholder="ზიპ კოდი" value={inputValueZip} onChange={handleInputChangeZip} />
                                                    <input hidden={languageToUse !== "en"} type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" required placeholder="Zip" value={inputValueZip} onChange={handleInputChangeZip} />
                                                    <input hidden={languageToUse !== "ru"} type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" required placeholder="Почтовый индекс" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select hidden={languageToUse !== "ge"} className="form-input select-filter" id="demandTypeGe" name="demandType" required value={inputValueDemandType} onChange={handleInputChangeDemandType}>
                                                        <option value="Any">აირჩიეთ მოთხოვნის კატეგორია</option>
                                                        <option value="lookingfor">ვეძებ</option>
                                                        <option value="willbuy">შევიძენ</option>
                                                        <option value="willrent">ვიქირავებ</option>
                                                        <option value="other">სხვა მოთხოვნები</option>
                                                    </select>
                                                    <select hidden={languageToUse !== "en"} className="form-input select-filter" id="dealTypeGe" name="dealType" required value={inputValueDemandType} onChange={handleInputChangeDemandType}>
                                                        <option value="Any">Select demand category</option>
                                                        <option value="lookingfor">I am looking for</option>
                                                        <option value="willbuy">I will buy</option>
                                                        <option value="willrent">I will rent</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                    <select hidden={languageToUse !== "ru"} className="form-input select-filter" id="dealTypeGe" name="dealType" required value={inputValueDemandType} onChange={handleInputChangeDemandType}>
                                                        <option value="Any">Выберите категорию спроса</option>
                                                        <option value="lookingfor">Я ищу</option>
                                                        <option value="willbuy">Я куплю</option>
                                                        <option value="willrent">Я сдам в аренду</option>
                                                        <option value="other">Другие</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 hidden={languageToUse !== "ge"}>დეტალური ინფორმაცია</h3>
                                <h3 hidden={languageToUse !== "en"}>More details about demand</h3>
                                <h3 hidden={languageToUse !== "ru"}>Подробнее о потребностях</h3>
                                <div className="row row-20 mt-20">
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea hidden={languageToUse !== "ge"} className="form-input" id="formSubjectGe" name="subject" required rows={1} placeholder="სათაური (სავალდებულოა)" value={inputValueSubject} onChange={handleSubject}></textarea>
                                            <textarea hidden={languageToUse !== "en"} className="form-input" id="formSubjectGe" name="subject" required rows={1} placeholder="Title/subject (required)" value={inputValueSubject} onChange={handleSubject}></textarea>
                                            <textarea hidden={languageToUse !== "ru"} className="form-input" id="formSubjectGe" name="subject" required rows={1} placeholder="Название/тема (обязательно)" value={inputValueSubject} onChange={handleSubject}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea hidden={languageToUse !== "ge"} className="form-input" id="formCommentGe" name="comment" required placeholder="დეტალური ინფორმაცია (სავალდებულოა)" value={inputValueComment} onChange={handleComment}></textarea>
                                            <textarea hidden={languageToUse !== "en"} className="form-input" id="formCommentGe" name="comment" required placeholder="Detailed description (required)" value={inputValueComment} onChange={handleComment}></textarea>
                                            <textarea hidden={languageToUse !== "ru"} className="form-input" id="formCommentGe" name="comment" required placeholder="Подробное описание (обязательно)" value={inputValueComment} onChange={handleComment}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            <input hidden={languageToUse !== "ge"} type="text" className="form-input" id="servicePriceGe" name="property-price" data-constraints="@Required @Numeric" placeholder="მოთხოვნის ღირებულება დოლარებში" value={inputValueProposedPrice === 0 ? '' : inputValueProposedPrice} onChange={handleInputChangeProposedPrice} />
                                            <input hidden={languageToUse !== "en"} type="text" className="form-input" id="servicePriceGe" name="property-price" data-constraints="@Required @Numeric" placeholder="Demand price" value={inputValueProposedPrice === 0 ? '' : inputValueProposedPrice} onChange={handleInputChangeProposedPrice} />
                                            <input hidden={languageToUse !== "ru"} type="text" className="form-input" id="servicePriceGe" name="property-price" data-constraints="@Required @Numeric" placeholder="Предлагаемая цена" value={inputValueProposedPrice === 0 ? '' : inputValueProposedPrice} onChange={handleInputChangeProposedPrice} />
                                        </div>
                                    </div>
                                </div>
                                <Slider {...settings}>
                                    {imagePaths && imagePaths.length > 0 ? (
                                        imagePaths.map((image, index) => (
                                            <div key={index}>
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Item ${index}`}
                                                    width="763" height="443"
                                                />
                                                <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteSavedImage(index, event)}>
                                                    <span hidden={languageToUse !== "ge"}>შენახული ფოტოს წაშლა</span>
                                                    <span hidden={languageToUse !== "en"}>Delete a saved image</span>
                                                    <span hidden={languageToUse !== "ru"}>Удалить сохраненное изображение</span>
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <img src="./images/ser-r.png" alt="Default Item" style={{ width: '100%', maxHeight: '443px' }} />
                                    )}
                                </Slider>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3 hidden={languageToUse !== "ge"}>დაამატე ფოტოები</h3>
                                        <h3 hidden={languageToUse !== "en"}>Add photos</h3>
                                        <h3 hidden={languageToUse !== "ru"}>Добавить фотографии</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileEn" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>
                                                                <span hidden={languageToUse !== "ge"}>ფოტოს წაშლა</span>
                                                                <span hidden={languageToUse !== "en"}>Delete an image</span>
                                                                <span hidden={languageToUse !== "ru"}>Удалить изображение</span>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3 hidden={languageToUse !== "ge"}>განცხადების ავტორი</h3>
                                        <h3 hidden={languageToUse !== "en"}>Listed by</h3>
                                        <h3 hidden={languageToUse !== "ru"}>Опубликован</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input hidden={languageToUse !== "ge"} className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" placeholder="თქვენი სახელი" data-constraints="@Required" value={inputValueName} onChange={handleName} />
                                                    <input hidden={languageToUse !== "en"} className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" placeholder="Name" data-constraints="@Required" value={inputValueName} onChange={handleName} />
                                                    <input hidden={languageToUse !== "ru"} className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" placeholder="Имя" data-constraints="@Required" value={inputValueName} onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input hidden={languageToUse !== "ge"} className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="თქვენი იმეილი" data-constraints="@Email @Required" value={inputValueEmail} onChange={handleEmail} />
                                                    <input hidden={languageToUse !== "en"} className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="Email" data-constraints="@Email @Required" value={inputValueEmail} onChange={handleEmail} />
                                                    <input hidden={languageToUse !== "ru"} className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="Электронная почта" data-constraints="@Email @Required" value={inputValueEmail} onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div Name="form-wrap">
                                                    <input hidden={languageToUse !== "ge"} className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="თქვენი ტელეფონის ნომერი" autoComplete="tel" data-constraints="@PhoneNumber" value={inputValuePhone} onChange={handlePhone} />
                                                    <input hidden={languageToUse !== "en"} className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="Phone" autoComplete="tel" data-constraints="@PhoneNumber" value={inputValuePhone} onChange={handlePhone} />
                                                    <input hidden={languageToUse !== "ru"} className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="Телефон" autoComplete="tel" data-constraints="@PhoneNumber" value={inputValuePhone} onChange={handlePhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {languageToUse === "ge" && (
                                        <div>
                                            <button className="button button-sm button-secondary" type="submit" value="Upload">განცხადების ატვირთვა</button>
                                            {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>გამოხმაურება: {responseMessage}</p>}
                                        </div>
                                    )}
                                    {languageToUse === "en" && (
                                        <div>
                                            <button className="button button-sm button-secondary" type="submit" value="Upload">Submit</button>
                                            {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Response: {responseMessage}</p>}
                                        </div>
                                    )}
                                    {languageToUse === "ru" && (
                                        <div>
                                            <button className="button button-sm button-secondary" type="submit" value="Upload">Опубликовать</button>
                                            {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Ответ: {responseMessage}</p>}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div >
        </div >
    )
}

export default EditDemandListing;