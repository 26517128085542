import React, { useState, useEffect } from "react";
import LogInRegisterManu from "./logInRegisterMenu";
import backendUrl from './config';
import { Link } from 'react-router-dom';
import { useLanguage } from './languageContext';


function Header(props) {
  const { currentLanguage } = useLanguage();
  const [loggedIn, setLoggedIn] = useState(false);
  const isDisabled = !loggedIn;
  const [user, setUser] = useState({});


  const storedLanguage = sessionStorage.getItem('currentLanguage');
  const languageToUse = storedLanguage || currentLanguage;

  useEffect(() => {
    fetch(`${backendUrl}/listing`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if needed
      },
      credentials: 'include', // Include credentials in the request
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);

        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return <header>
    {languageToUse === "ge" && (
      // <div className="container header toHeader">
      //   <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between border-bottom">
      //     <div className="col-md-3 mb-2 mb-md-0">
      //       <div
      //         className="d-inline-flex link-body-emphasis text-decoration-none"
      //       >
      //         <Link to="/about" className="nav-button">
      //           <img src="./images/rental_city_homes_logo_bold_dark.png" alt="Super Team logo" height="60px" width="120px" />
      //         </Link>
      //       </div>
      //     </div>

      //     <ul
      //       className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0"
      //     >
      //       <li className="nav-link px-2 link-secondary button-menue"><Link to="/" className="nav-button menue-font" style={{ textDecoration: "none", color: "inherit", fontWeight: '900' }}>მთავარი</Link></li>
      //       <li
      //         className={`nav-link px-2 link-secondary button-menue ${isDisabled ? ' disabled' : ''}`}
      //         style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
      //         hidden={isDisabled}
      //         onClick={(e) => {
      //           if (isDisabled) {
      //             e.preventDefault(); // Prevent the link from being followed
      //             // Optionally, you can add a disabled message or other behavior
      //           }
      //         }}
      //       ><div className={`${isDisabled ? ' disabled' : ''}`}
      //         style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
      //       ><Link to="/favoratelistingoptions" className="nav-button menue-font" style={{ textDecoration: "none", color: "inherit", fontWeight: '800' }}>ჩემი რჩეული განცხადებები</Link></div>
      //       </li>
      //       <li className="nav-item dropdown">
      //         <a className="nav-link px-2 link-secondary button-menue menue-font" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: "none", color: "inherit", fontWeight: '800' }}>განცხადების მართვა</a>
      //         <ul className="dropdown-menu">
      //           <li className="dropdown-item"><Link to="/listingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "14px" }}>განცხადების დამატება</Link></li>
      //           <li className="dropdown-item"><Link to="/mylistingOptions" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "14px" }}>ჩემი განცხადებები</Link></li>
      //           <li><hr className="dropdown-divider"></hr></li>
      //           <li className="dropdown-item"><Link to="/favoratelistingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "14px" }}>რჩეული განცხადებები</Link></li>
      //         </ul>
      //       </li>

      //       <li className="nav-link px-2 link-secondary button-menue" ><Link to="/about" className="menue-font" style={{ textDecoration: "none", color: "inherit", fontWeight: '800' }}>ჩვენს შესახებ</Link></li>
      //     </ul>

      //     <div className="col-md-3 text-end menue-item-centered">
      //       <LogInRegisterManu />
      //     </div>
      //   </header>
      //   {/* <div className="features-block-item">
      //     <ul className="features-block-list menue-icons">
      //       <li><img src="./images/b-g.png" /></li>
      //       <li><img src="./images/b-g.png" /></li>
      //       <li><img src="./images/b-g.png" /></li>
      //     </ul>
      //   </div> */}
      //   <div id="listingItemCard"></div>
      // </div>




      <div className="container header toHeader">
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between border-bottom">
          <div className="col-md-2 mb-2 mb-md-0">
            {/* <div className=""> */}
            <div className="d-inline-flex link-body-emphasis text-decoration-none">
              <Link to="/about" className="nav-button">
                <img
                  src="./images/rental_city_homes_logo_bold_dark.png"
                  alt="Super Team logo"
                  height="60px"
                  width="120px"
                />
              </Link>
            </div>
          </div>

          <div className="col-md-8 text-end menue-item-centered">
            <button className="menu-button" onClick={toggleMenu}>
              <div className="menu-icon"></div>
              <div className="menu-icon"></div>
              <div className="menu-icon"></div>
            </button>
            <ul className={`nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 ${menuOpen ? 'menu-open' : ''}`}>
              <li className="nav-link px-2 link-secondary button-menue">
                <Link to="/" className="nav-button menue-font"
                  style={{ textDecoration: "none", color: "inherit", fontWeight: '900' }}>მთავარი
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/favoratelistingoptions"
                  className="nav-button menue-font"
                  style={{ textDecoration: 'none', color: 'inherit', fontWeight: '800' }}
                >
                  ჩემი რჩეული განცხადებები
                </Link>
              </li> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link px-2 link-secondary button-menue menue-font"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ textDecoration: 'none', color: 'inherit', fontWeight: '800' }}
                >
                  განცხადების მართვა
                </a>
                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <Link
                      to="/listingoptions"
                      className="nav-button"
                      style={{ textDecoration: 'none', color: 'inherit', fontSize: '14px' }}
                    >
                      განცხადების დამატება
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      to="/mylistingOptions"
                      className="nav-button"
                      style={{ textDecoration: 'none', color: 'inherit', fontSize: '14px' }}
                    >
                      ჩემი განცხადებები
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="dropdown-item">
                    <Link
                      to="/favoratelistingoptions"
                      className="nav-button"
                      style={{ textDecoration: 'none', color: 'inherit', fontSize: '14px' }}
                    >
                      რჩეული განცხადებები
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-link px-2 link-secondary button-menue" >
                <Link to="/about" className="menue-font"
                  style={{ textDecoration: "none", color: "inherit", fontWeight: '800' }}>ჩვენს შესახებ
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2 text-end menue-item-centered">
            <LogInRegisterManu />
          </div>
        </header>
        <div id="listingItemCard"></div>
      </div>


    )}
    {/* {languageToUse === "ge" && (
      <div className="container header toHeader">
        <header
          className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom"
        >
          <div className="col-md-3 mb-2 mb-md-0">
            <div
              className="d-inline-flex link-body-emphasis text-decoration-none"
            >
              <Link to="/about" className="nav-button">
                <img src="./images/400PngdpiLogo.png" alt="Super Team logo" height="80px" width="160px" />
              </Link>
            </div>
          </div>

          <ul
            className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0"
          >
            <li className="nav-link px-2 link-secondary button-menue"><Link to="/" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "15px" }}>მთავარი</Link></li>
            <li
              className={`nav-link px-2 link-secondary button-menue ${isDisabled ? ' disabled' : ''}`}
              style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
              hidden={isDisabled}
              onClick={(e) => {
                if (isDisabled) {
                  e.preventDefault(); // Prevent the link from being followed
                  // Optionally, you can add a disabled message or other behavior
                }
              }}
            ><div className={`${isDisabled ? ' disabled' : ''}`}
              style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
            ><Link to="/favoratelistingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "15px" }}>ჩემი რჩეული განცხადებები</Link></div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link px-2 link-secondary button-menue" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: "none", color: "inherit", fontSize: "15px" }}>განცხადების მართვა</a>
              <ul className="dropdown-menu">
                <li className="dropdown-item"><Link to="/listingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "14px" }}>განცხადების დამატება</Link></li>
                <li className="dropdown-item"><Link to="/mylistingOptions" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "14px" }}>ჩემი განცხადებები</Link></li>
                <li><hr className="dropdown-divider"></hr></li>
                <li className="dropdown-item"><Link to="/favoratelistingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit", fontSize: "14px" }}>რჩეული განცხადებები</Link></li>
              </ul>
            </li>

            <li className="nav-link px-2 link-secondary button-menue" ><Link to="/about" style={{ textDecoration: "none", color: "inherit", fontSize: "15px" }}>ჩვენს შესახებ</Link></li>
          </ul>
          <div className="col-md-3 text-end">
            <LogInRegisterManu />
          </div>
        </header>
        <div id="listingItemCard"></div>
      </div>
    )} */}
    {languageToUse === "en" && (
      <div className="container header toHeader">
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between border-bottom">
          <div className="col-md-3 mb-2 mb-md-0">
            <div
              className="d-inline-flex link-body-emphasis text-decoration-none"
            >
              <Link to="/about" className="nav-button">
                <img src="./images/rental_city_homes_logo_bold_dark.png" alt="Super Team logo" height="60px" width="120px" />
              </Link>
            </div>
          </div>

          <ul
            className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0"
          >
            <li className="nav-link px-2 link-secondary button-menue"><Link to="/" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>Home</Link></li>
            <li
              className={`nav-link px-2 link-secondary button-menue ${isDisabled ? ' disabled' : ''}`}
              style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
              hidden={isDisabled}
              onClick={(e) => {
                if (isDisabled) {
                  e.preventDefault(); // Prevent the link from being followed
                  // Optionally, you can add a disabled message or other behavior
                }
              }}
            ><div className={`${isDisabled ? ' disabled' : ''}`}
              style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
            ><Link to="/favoratelistingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>My favorite listings</Link></div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link px-2 link-secondary button-menue" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: "none", color: "inherit" }}>Manage Listing</a>
              <ul className="dropdown-menu">
                <li className="dropdown-item"><Link to="/listingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>Add listing</Link></li>
                <li className="dropdown-item"><Link to="/mylistingOptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>My listings</Link></li>
                <li><hr className="dropdown-divider"></hr></li>
                <li className="dropdown-item"><Link to="/favoratelistingOptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>My favorite listings</Link></li>
              </ul>
            </li>

            <li className="nav-link px-2 link-secondary button-menue" ><Link to="/about" style={{ textDecoration: "none", color: "inherit" }}>About Us</Link></li>
          </ul>

          <div className="col-md-3 text-end">
            <LogInRegisterManu />
          </div>
        </header>
      </div>
    )}
    {languageToUse === "ru" && (
      <div className="container header toHeader">
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between border-bottom">
          <div className="col-md-3 mb-2 mb-md-0">
            <div
              className="d-inline-flex link-body-emphasis text-decoration-none"
            >
              <Link to="/about" className="nav-button">
                <img src="./images/rental_city_homes_logo_bold_dark.png" alt="Super Team logo" height="60px" width="120px" />
              </Link>
            </div>
          </div>

          <ul
            className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0"
          >
            <li className="nav-link px-2 link-secondary button-menue"><Link to="/" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>Обзор</Link></li>
            <li
              className={`nav-link px-2 link-secondary button-menue ${isDisabled ? ' disabled' : ''}`}
              style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
              hidden={isDisabled}
              onClick={(e) => {
                if (isDisabled) {
                  e.preventDefault(); // Prevent the link from being followed
                  // Optionally, you can add a disabled message or other behavior
                }
              }}
            ><div className={`${isDisabled ? ' disabled' : ''}`}
              style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
            ><Link to="/favoratelistingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>Мои любимые объявления</Link></div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link px-2 link-secondary button-menue" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: "none", color: "inherit" }}>Управление объявлениями</a>
              <ul className="dropdown-menu">
                <li className="dropdown-item"><Link to="/listingoptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>Добавить объявлени</Link></li>
                <li className="dropdown-item"><Link to="/mylistingOptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>Мои объявления</Link></li>
                <li><hr className="dropdown-divider"></hr></li>
                <li className="dropdown-item"><Link to="/favoratelistingOptions" className="nav-button" style={{ textDecoration: "none", color: "inherit" }}>Мои любимые объявления</Link></li>
              </ul>
            </li>

            <li className="nav-link px-2 link-secondary button-menue" ><Link to="/about" style={{ textDecoration: "none", color: "inherit" }}>О нас</Link></li>
          </ul>

          <div className="col-md-3 text-end">
            <LogInRegisterManu />
          </div>
        </header>
      </div>
    )}
  </header>
}

export default Header;