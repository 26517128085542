import React, { useState, useEffect } from 'react';
import backendUrl from './config';
import { useLanguage } from './languageContext';

const ChatBotForManue = () => {
    const { currentLanguage } = useLanguage();
    // const [input, setInput] = useState('');
    // const [chat, setChat] = useState([]);
    const [showChatbox, setShowChatbox] = useState(true);
    const [standardQuestions, setStandardQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [selectedAnswer, setSelectedAnswer] = useState('');
    // const [searchInput, setSearchInput] = useState('');
    // const [searchResults, setSearchResults] = useState([]);
    // Retrieve currentLanguage from sessionStorage
    const storedLanguage = sessionStorage.getItem('currentLanguage');

    // Use storedLanguage if available, otherwise use currentLanguage
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Load standard questions when the component mounts
        loadStandardQuestions();
    }, []);

    const loadStandardQuestions = async () => {
        try {
            if (languageToUse === "en") {
                const response = await fetch(`${backendUrl}/chatbot`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Add any additional headers if needed
                    },
                    credentials: 'include', // Include credentials in the request
                });
                const data = await response.json();
                setStandardQuestions(data.questions);
            } else if (languageToUse === "ge") {
                const response = await fetch(`${backendUrl}/chatbot_ge`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Add any additional headers if needed
                    },
                    credentials: 'include', // Include credentials in the request
                });

                const data = await response.json();
                setStandardQuestions(data.questions);
            } else if (languageToUse === "ru") {
                const response = await fetch(`${backendUrl}/chatbot_ru`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Add any additional headers if needed
                    },
                    credentials: 'include', // Include credentials in the request
                });

                const data = await response.json();
                setStandardQuestions(data.questions);
            }
        } catch (error) {
            console.error('Error loading standard questions:', error.message);
        }
    };


    // const handleInputChange = (e) => {
    //     setInput(e.target.value);
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Add user's message to the chat
    //     setChat([...chat, { text: input, user: 'user' }]);
    //     setInput('');

    //     // Simulate a delay for the bot's response
    //     await new Promise(resolve => setTimeout(resolve, 500));

    //     // Get the bot's response
    //     const response = await getBotResponse(input);

    //     // Add the bot's message to the chat
    //     setChat([...chat, { text: response, user: 'bot' }]);
    // };

    const getBotResponse = async (userMessage) => {
        try {
            if (languageToUse === "en") {
                const response = await fetch(`${backendUrl}/chatbot`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ question: userMessage }),
                });
                const data = await response.json();
                const answer = data.answer;

                return answer;
            } else if (languageToUse === "ge") {
                const response = await fetch(`${backendUrl}/chatbot_ge`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ question: userMessage }),
                });

                const data = await response.json();
                const answer = data.answer;

                return answer;
            } else if (languageToUse === "ru") {
                const response = await fetch(`${backendUrl}/chatbot_ru`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ question: userMessage }),
                });

                const data = await response.json();
                const answer = data.answer;

                return answer;
            }
        } catch (error) {
            console.error('Error getting bot response:', error);
            return "I'm sorry, I encountered an error.";
        }
    };

    const handleChatboxToggle = () => {
        // Toggle the chatbox visibility
        setShowChatbox(!showChatbox);
    };

    const handleStandardQuestionChange = (event) => {
        // Update the selected question when the dropdown changes
        const selectedValue = event.target.value;
        setSelectedQuestion(selectedValue);

        // Simulate a delay for the bot's response
        setTimeout(async () => {
            const response = await getBotResponse(selectedValue);
            setSelectedAnswer(response);
        }, 500);
    };

    // // platform for a smart search start
    // const handleSearchInputChange = (e) => {
    //     setSearchInput(e.target.value);
    // };

    // const handleSearchSubmit = async (e) => {
    //     e.preventDefault();

    //     // Send a search request to the server
    //     try {
    //         const response = await fetch(`${backendUrl}/search`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ keywords: extractKeywords(searchInput) }),
    //         });

    //         const data = await response.json();
    //         // setSearchResults(data.answers);
    //     } catch (error) {
    //         console.error('Error searching for relevant answers:', error.message);
    //     }
    // };

    // const extractKeywords = (input) => {
    //     // Implement logic to extract relevant keywords
    //     // Let's use natural language processing libraries for better keyword extraction
    //     // For simplicity, let's assume a basic extraction of nouns and verbs
    //     const words = input.split(' ');
    //     const relevantKeywords = words.filter(word => isNounOrVerb(word));
    //     return relevantKeywords;
    // };

    // const isNounOrVerb = (word) => {
    //     // Implement logic to check if a word is a noun or verb
    //     // Let's use a part-of-speech tagging library for more accuracy
    //     // For simplicity, let's assume any word is relevant for now
    //     return true;
    // };
    // platform for a smart search end
    return (
        <div>
            {languageToUse === "ge" && (
                <div className="chat-manue">
                    {showChatbox && (
                        <div className="card chat-container-manue">
                            <div className="chat-card-header-menue">
                                <button
                                    hidden="true"
                                    type="button"
                                    className="close btn"
                                    aria-label="Close"
                                    onClick={handleChatboxToggle}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                კითხვების განყოფილება
                            </div>
                            <div className="card-body-chat chat-area-menue">
                                <div className="standard-questions custom-dropdown">
                                    {/* <p style={{ fontWeight: '560' }}>Ask A Question</p> */}
                                    <select
                                        className="form-control custom-dropdown-select chat-question"
                                        value={selectedQuestion}
                                        onChange={handleStandardQuestionChange}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <option value="" disabled>შეარჩიეთ კითხვა</option>
                                        {standardQuestions.map((questionObject, index) => (
                                            <option key={index} value={questionObject.standardQuestion}>
                                                {questionObject.standardQuestion}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="answer-area-menue">
                                    {selectedAnswer && (
                                        <div>
                                            <p style={{ marginTop: '10px', fontWeight: '560' }}>პასუხი:</p>
                                            <div dangerouslySetInnerHTML={{ __html: selectedAnswer }} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <button
                        hidden="true"
                        type="button"
                        className={`button button-sm button-secondary ${showChatbox ? 'chat-open' : ''}`}
                        onClick={handleChatboxToggle}
                    >
                        თუ გაქვთ კითხვა
                    </button>
                </div>
            )}
            {languageToUse === "en" && (
                <div>
                    <div className="chat-manue">
                        {showChatbox && (
                            <div className="card chat-container-manue">
                                <div className="chat-card-header-menue">
                                    <button
                                        hidden="true"
                                        type="button"
                                        className="close btn"
                                        aria-label="Close"
                                        onClick={handleChatboxToggle}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    Question box
                                </div>
                                <div className="card-body-chat chat-area-menue">
                                    <div className="standard-questions custom-dropdown">
                                        {/* <p style={{ fontWeight: '560' }}>Ask A Question</p> */}
                                        <select
                                            className="form-control custom-dropdown-select chat-question"
                                            value={selectedQuestion}
                                            onChange={handleStandardQuestionChange}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <option value="" disabled>Select question</option>
                                            {standardQuestions.map((questionObject, index) => (
                                                <option key={index} value={questionObject.standardQuestion}>
                                                    {questionObject.standardQuestion}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="answer-area-menue">
                                        {selectedAnswer && (
                                            <div>
                                                <p style={{ marginTop: '10px', fontWeight: '560' }}>Answer:</p>
                                                <div dangerouslySetInnerHTML={{ __html: selectedAnswer }} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <button
                            hidden="true"
                            type="button"
                            className={`button button-sm button-secondary ${showChatbox ? 'chat-open' : ''}`}
                            onClick={handleChatboxToggle}
                        >
                            If you have questions
                        </button>
                    </div>
                </div>
            )}
            {languageToUse === "ru" && (
                <div>
                    <div className="chat-manue">
                        {showChatbox && (
                            <div className="card chat-container-manue">
                                <div className="chat-card-header-menue">
                                    <button
                                        hidden="true"
                                        type="button"
                                        className="close btn"
                                        aria-label="Close"
                                        onClick={handleChatboxToggle}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    Блок вопросов
                                </div>
                                <div className="card-body-chat chat-area-menue">
                                    <div className="standard-questions custom-dropdown">
                                        {/* <p style={{ fontWeight: '560' }}>Ask A Question</p> */}
                                        <select
                                            className="form-control custom-dropdown-select chat-question"
                                            value={selectedQuestion}
                                            onChange={handleStandardQuestionChange}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <option value="" disabled>Задайте вопрос</option>
                                            {standardQuestions.map((questionObject, index) => (
                                                <option key={index} value={questionObject.standardQuestion}>
                                                    {questionObject.standardQuestion}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="answer-area-menue">
                                        {selectedAnswer && (
                                            <div>
                                                <p style={{ marginTop: '10px', fontWeight: '560' }}>Ответ:</p>
                                                <div dangerouslySetInnerHTML={{ __html: selectedAnswer }} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <button
                            hidden="true"
                            type="button"
                            className={`button button-sm button-secondary ${showChatbox ? 'chat-open' : ''}`}
                            onClick={handleChatboxToggle}
                        >
                            Если у вас есть вопросы
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatBotForManue;
