import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useLanguage } from './languageContext';

const VehicleMakeSelector = ({
    inputValueMake,
    handleInputChangeMake,
    externalValueMake,
    setExternalValueMake,
}) => {
    const [popularMakes, setPopularMakes] = useState([
        'Ford', 'Chevrolet', 'Toyota', 'Honda', 'Nissan', 'Jeep', 'Dodge', 'Subaru', 'Hyundai', 'GMC',
        'Ram', 'Volkswagen', 'BMW', 'Mercedes-Benz', 'Audi', 'Lexus', 'Chrysler', 'Buick', 'Cadillac',
        'Acura', 'Mazda', 'Kia', 'Tesla', 'Volvo', 'Porsche', 'Jaguar', 'Land Rover', 'Mitsubishi',
        'Mini', 'Fiat', 'Alfa Romeo', 'Genesis', 'Maserati', 'Infiniti', 'Lincoln', 'Bentley', 'Rolls-Royce',
        'Aston Martin', 'McLaren', 'Bugatti',
        // Additional truck makes
        'Freightliner', 'International', 'Kenworth', 'Peterbilt', 'Mack', 'Isuzu', 'Hino', 'Volvo Trucks'
    ]);
    const { currentLanguage } = useLanguage();
    const [makeOptions, setMakeOptions] = useState([]);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Filter and set popular makes options
        setMakeOptions(popularMakes.map(make => ({ value: make, label: make })));
    }, [popularMakes]);

    return (
        <div className="" >
            {languageToUse === "ge" && (
                <Select
                    className="form-input"
                    id="vehicleMake"
                    name="vehicleMake"
                    placeholder="Select make"
                    value={{ value: inputValueMake || externalValueMake, label: inputValueMake || externalValueMake || "შეარჩიეთ მწარმოებელი" }}
                    options={makeOptions}
                    onChange={(selectedOption) => {
                        handleInputChangeMake({ target: { value: selectedOption.value } });
                        setExternalValueMake(selectedOption.value);
                    }}
                    isSearchable // Enable search for arbitrary text input
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderStyle: 'none',
                            height: '24px',
                            minHeight: '24px',
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                    }}
                />
            )}
            {languageToUse === "en" && (
                <Select
                    className="form-input"
                    id="vehicleMake"
                    name="vehicleMake"
                    placeholder="Select make"
                    value={{ value: inputValueMake || externalValueMake, label: inputValueMake || externalValueMake || "Select make" }}
                    options={makeOptions}
                    onChange={(selectedOption) => {
                        handleInputChangeMake({ target: { value: selectedOption.value } });
                        setExternalValueMake(selectedOption.value);
                    }}
                    isSearchable // Enable search for arbitrary text input
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderStyle: 'none',
                            height: '24px',
                            minHeight: '24px',
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                    }}
                />
            )}
            {languageToUse === "ru" && (
                <Select
                    className="form-input"
                    id="vehicleMake"
                    name="vehicleMake"
                    placeholder="Select make"
                    value={{ value: inputValueMake || externalValueMake, label: inputValueMake || externalValueMake || "Выберите производителя" }}
                    options={makeOptions}
                    onChange={(selectedOption) => {
                        handleInputChangeMake({ target: { value: selectedOption.value } });
                        setExternalValueMake(selectedOption.value);
                    }}
                    isSearchable // Enable search for arbitrary text input
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderStyle: 'none',
                            height: '24px',
                            minHeight: '24px',
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            // width: '165px',
                            // minWidth: '165px',
                        }),
                    }}
                />
            )}
        </div>
    );
};

export default VehicleMakeSelector;
