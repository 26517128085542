import React, { useState, useEffect } from "react";
import Property from "./property";
import backendUrl from './config';
import Loader from './loader';

function createEntry(listingObject, onItemClick) {
    return (
        <Property
            key={listingObject._id}
            onClick={() => onItemClick(listingObject._id)}
            listingId={listingObject._id}
            address={listingObject.address}
            city={listingObject.city}
            state={listingObject.state}
            zip={listingObject.zip}
            propertytype={listingObject.propertytype}
            numberOfBedrooms={listingObject.numberOfBedrooms}
            numberOfBathrooms={listingObject.numberOfBathrooms}
            propertyCondition={listingObject.propertyCondition}
            purchasePrice={listingObject.purchasePrice}
            remaningPrinciple={listingObject.remaningPrinciple}
            interestRate={listingObject.interestRate}
            loanAmortizationPeriod={listingObject.loanAmortizationPeriod}
            remaningAmortizationPeriod={listingObject.remaningAmortizationPeriod}
            monthlyPayment={listingObject.monthlyPayment}
            propertyTax={listingObject.propertyTax}
            askingPrice={listingObject.askingPrice}
            img={listingObject.imgs}
            userId={listingObject.userId}
            lisingtype="listings"
            isFavoriteBySelection={false}
        />
    );
}

function TwoLastListings({ onItemClick, listingId }) {
    // load listings
    const [listingData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    // let randomItem = {};

    useEffect(() => {
        fetch(`${backendUrl}/home`)
            .then((response) => response.json())
            .then((responseData) => {
                // const lastTwoElements = responseData.slice(-1);
                const numElementsToSelect = 1; // Change this to the desired number of elements to select
                const randomlySelectedElements = getRandomElements(responseData, numElementsToSelect);
                setData(randomlySelectedElements);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Loader key={1} loading={loading} />;
    }

    // arbitral random randomItem
    function getRandomElements(array, numElements) {
        // Shuffle the array
        const shuffledArray = array.sort(() => Math.random() - 0.5);

        // Select the first 'numElements' elements
        const selectedElements = shuffledArray.slice(0, numElements);

        return selectedElements;
    }

    return (
        <div className="container">
            <div className="cell-inner" style={{ maxWidth: '560px' }}>
                <div className="product-classic-group">
                    <article className="product-classic product-classic-sm">
                        <div className="album py-5 bg-body-tertiary">
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1">
                                <div className="favorate-container">
                                    {listingData && listingData.length > 0 ?
                                        listingData.map(listingObject => createEntry(listingObject, onItemClick))
                                        : (
                                            <img src="./images/ser-r.png" alt="Default" style={{ width: '100%', maxHeight: '400px' }} />
                                        )}
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
}

export default TwoLastListings;


