import React, { useState, useEffect } from "react";
import backendUrl from './config';
import { useLanguage } from './languageContext';

function ChangePassword({ isOpen, onClose }) {
    const { currentLanguage } = useLanguage();
    const [responseMessage, setResponseMessage] = useState('');
    const [username, setUsernameEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const [responseMessageShow, setResponseMessageShow] = useState(false);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;


    const handleClosePopup = () => {
        setUsernameEmail('');
        setCurrentPassword('');
        setNewPassword('');
        setResponseMessage('');
        setResponseMessageShow(false);
        onClose();
    };

    // const handleResetPassword = async () => {
    //     if (newPassword.length < 8) {
    //         setResponseMessageShow(true);
    //         setResponseMessage('Password must be at least 8 characters long');
    //         setTimeout(() => {
    //             setResponseMessageShow(false);
    //         }, 4000);
    //         return;
    //     }
    //     try {
    //         const response = await fetch(`${backendUrl}/change-password`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 username: username,
    //                 currentPassword: currentPassword,
    //                 newPassword: newPassword
    //             }),
    //         });
    //         if (response.ok) {
    //             const data = await response.json();
    //             setResponseMessage(data);
    //             setTimeout(() => {
    //                 onClose();
    //             }, 4000);
    //             // if (!data.status && data.message === 'Authentication failed') {
    //             //     setResponseMessage("Incorrect username or password");
    //             //     setTimeout(() => {
    //             //         onClose();
    //             //     }, 4000);
    //             // }
    //         } else {
    //             setResponseMessage("Error sending data to the server");
    //             setResponseMessageShow(true);
    //             setTimeout(() => {
    //                 setResponseMessageShow(false);
    //             }, 4000);
    //         }
    //     } catch (error) {
    //         console.error("Error:", error);
    //         setResponseMessage("Error sending data to the server");
    //         setResponseMessageShow(true);
    //         setTimeout(() => {
    //             setResponseMessageShow(false);
    //         }, 4000);
    //     }
    // };

    const handleResetPassword = async () => {
        if (newPassword.length < 8) {
            setResponseMessageShow(true);
            setResponseMessage('Password must be at least 8 characters long');
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
            return; // Stop further execution
        }
        try {
            const response = await fetch(`${backendUrl}/change-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: username,
                    currentPassword: currentPassword,
                    newPassword: newPassword
                }),
            });
            if (response.ok) {
                const data = await response.json();
                if (data.message === 'User not found') {
                    setResponseMessage('User not found');
                } else if (data.error === 'Incorrect username or password') {
                    setResponseMessage('Incorrect username or password');
                } else {
                    setResponseMessage(data.message);
                    setTimeout(() => {
                        onClose();
                    }, 4000);
                }
            } else {
                setResponseMessage("Error sending data to the server");
            }
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        } catch (error) {
            console.error("Error:", error);
            setResponseMessage("Error sending data to the server");
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        }
    };


    // useEffect(() => {
    //     // Reset input fields and response message when isOpen changes
    //     if (!isOpen) {
    //         setUsernameEmail('');
    //         setCurrentPassword('');
    //         setNewPassword('');
    //         setResponseMessage('');
    //         setResponseMessageShow(false);
    //     }
    // }, [isOpen]);

    useEffect(() => {
        // Reset input fields and response message when component mounts
        setUsernameEmail('');
        setCurrentPassword('');
        setNewPassword('');
        setResponseMessage('');
        setResponseMessageShow(false);

        // Cleanup function
        return () => {
            // Reset input fields and response message when component unmounts
            setUsernameEmail('');
            setCurrentPassword('');
            setNewPassword('');
            setResponseMessage('');
            setResponseMessageShow(false);
        };
    }, [isOpen]);

    const togglePasswordVisibility = (e) => {
        if (e.target.name === "passwordVisibility") {
            e.preventDefault(); // Prevent form submission
        }
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div>
            {isOpen && languageToUse === "ge" && (
                <div className="rd-navbar-popup bg-gray-12 active" id="navbar-login-register" style={{ fontSize: "14px", fontWeight: "500" }}>
                    <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="navbar-tabs-1">
                                <span className="close" onClick={handleClosePopup} style={{ cursor: 'pointer' }}>&times;</span>
                                <p>პასვორდის შეცვლა</p>
                                <div className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="email"
                                        placeholder="შეიყვანეთ ელფოსტის მისამართი"
                                        value={username}
                                        onChange={(e) => setUsernameEmail(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type="password"
                                        placeholder="შეიყვანეთ მიმდინარე პასვორდი"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type="password"
                                        placeholder="შეიყვანეთ ახალი პასვორდი"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn-toggle-password"
                                        name="passwordVisibility"
                                        onClick={togglePasswordVisibility}
                                        style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                    >
                                        {!showPassword ? 'აჩვენე' : 'დაფარე'}
                                    </button>
                                    <button className="button button-sm button-primary button-block" onClick={handleResetPassword}>პასვორდის შეცვლა</button>
                                    {responseMessageShow && responseMessage && (
                                        <div>
                                            <p className="loginResponses" hidden={responseMessage !== "Incorrect username or password"}>{responseMessage === "Incorrect username or password" ? "შეცდომაა პასვორდში ან მომხმარებლის სახელში" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Password must be at least 8 characters long"}>{responseMessage === "Password must be at least 8 characters long" ? "პასვორდი მინიმუმ 8 სიმბოლოსგან უნდა შედგებოდეს" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Password changed successfully"}>{responseMessage === "Password changed successfully" ? "პასვორდი წარმატებით შეცვალა" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "User not found"}>{responseMessage === "User not found" ? "მომხმარებელი არ მოიძებნა" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Error sending data to the server"}>{responseMessage === "Error sending data to the server" ? "მოთხოვნა არ გაიგზავნა" : "მოხდა შეცდომა თავიდან ცადეთ"}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOpen && languageToUse === "en" && (
                <div className="rd-navbar-popup bg-gray-12 active" id="navbar-login-register">
                    <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="navbar-tabs-1">
                                <span className="close" onClick={handleClosePopup} style={{ cursor: 'pointer' }}>&times;</span>
                                <p>Change Password</p>
                                <div className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="email"
                                        placeholder="Enter your email"
                                        value={username}
                                        onChange={(e) => setUsernameEmail(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type="password"
                                        placeholder="Enter current password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type="password"
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn-toggle-password"
                                        name="passwordVisibility"
                                        onClick={togglePasswordVisibility}
                                        style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                    >
                                        {!showPassword ? 'hide' : 'show'}
                                    </button>
                                    <button className="button button-sm button-primary button-block" onClick={handleResetPassword}>Change password</button>
                                    {responseMessageShow && responseMessage && (
                                        <div>
                                            <p className="loginResponses">{responseMessage}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOpen && languageToUse === "ru" && (
                <div className="rd-navbar-popup bg-gray-12 active" id="navbar-login-register" style={{ fontSize: "14px", fontWeight: "500" }}>
                    <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="navbar-tabs-1">
                                <span className="close" onClick={handleClosePopup} style={{ cursor: 'pointer' }}>&times;</span>
                                <p>Изменить пароль</p>
                                <div className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="email"
                                        placeholder="Введите адрес электронной почты"
                                        value={username}
                                        onChange={(e) => setUsernameEmail(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type="password"
                                        placeholder="Введите текущий пароль"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type="password"
                                        placeholder="Введите новый пароль"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn-toggle-password"
                                        name="passwordVisibility"
                                        onClick={togglePasswordVisibility}
                                        style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                    >
                                        {!showPassword ? 'показать' : 'спрятать'}
                                    </button>
                                    <button className="button button-sm button-primary button-block" onClick={handleResetPassword}>პასვორდის შეცვლა</button>
                                    {responseMessageShow && responseMessage && (
                                        <div>
                                            <p className="loginResponses" hidden={responseMessage !== "Incorrect username or password"}>{responseMessage === "Incorrect username or password" ? "Ошибка в пароле или имени пользователя" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Password must be at least 8 characters long"}>{responseMessage === "Password must be at least 8 characters long" ? "Пароль должен состоять как минимум из 8 символов" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Password changed successfully"}>{responseMessage === "Password changed successfully" ? "Пароль успешно изменен" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "User not found"}>{responseMessage === "User not found" ? "Пользователь не найден" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Error sending data to the server"}>{responseMessage === "Error sending data to the server" ? "Запрос не отправлен" : "Произошла ошибка. Пожалуйста, попробуйте еще раз"}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChangePassword;
