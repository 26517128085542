import React from "react";
import { Link } from 'react-router-dom';
import { useLanguage } from './languageContext';
import PosterCard from "./posterCard";

function FavorateListingOptions() {
    const { currentLanguage } = useLanguage();
    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;
    return <div>
        {languageToUse === "ge" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"უძრავი ქონება"}
                                cardContent={"იპოვეთ თქვენი რჩეული განცხადებები უძრავ ქონებაზე."}
                                cardLink={"/favoratelistings"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"ავტომობილები"}
                                cardContent={"იპოვეთ თქვენი რჩეული განცხადებები ავტომობილების გაყიდვა და გაქირავების შესახებ."}
                                cardLink={"/favoratevehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"მომსახურეობის შეთავაზება"}
                                cardContent={"იპოვეთ თავენი რჩეული განცხადებები სერვისებზე."}
                                cardLink={"/favorateservices"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"ვეძებ და სხვა შეთავაზებები"}
                                cardContent={"იპოვეთ თავენი რჩეული განცხადებები ვიყიდი, ვიქირავებ, გთავაზობთ და სხვა."}
                                cardLink={"/favoratedemands"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "en" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"Real estate"}
                                cardContent={"Find your selected real estate"}
                                cardLink={"/favoratelistings"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Vehicles"}
                                cardContent={"Find your selected vehicles"}
                                cardLink={"/favoratevehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Services and offers"}
                                cardContent={"Find your selected listings on services you looking for - Supply"}
                                cardLink={"/favorateservices"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"I am looking for or offer something"}
                                cardContent={"Find your selected posts abot what others are up to - Demand"}
                                cardLink={"/favoratedemands"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "ru" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"Недвижимость"}
                                cardContent={"Найдите выбранную вами недвижимость"}
                                cardLink={"/favoratelistings"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Автомобили"}
                                cardContent={"Найдите выбранные вами автомобили"}
                                cardLink={"/favoratevehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Услуги и занятость"}
                                cardContent={"Найдите выбранные вами списки услуг, которые вы ищете - Поставка"}
                                cardLink={"/favorateservices"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Я ищу"}
                                cardContent={"Найдите выбранные вами публикации о том, чем занимаются другие – спрос"}
                                cardLink={"/favoratedemands"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
    </div>
}


export default FavorateListingOptions;