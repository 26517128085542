import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useLanguage } from './languageContext';

const CookieConsentBanner = () => {
  const { currentLanguage } = useLanguage();
  const [cookieConsent, setCookieConsent] = useState(false);

  const storedLanguage = sessionStorage.getItem('currentLanguage');
  const languageToUse = storedLanguage || currentLanguage;

  const handleAccept = () => {
    // Set a cookie or update state to indicate consent
    setCookieConsent(true);
    console.log(cookieConsent);
  };

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        cookieName="cookieConsent"
        onAccept={handleAccept}
        style={{ background: '#333' }}
        buttonStyle={{ color: '#fff', background: '#4b9ef1' }}
      >
        <span hidden={languageToUse !== "ge"}> ეს ვებგვერდი იყენებს ეგრეთწოდებულ "ქუქებს" რათა ვებგვერდმა გამართულად იმუშაოს. ისინი აუცილებელია თქვენი ავტორიზაციისათვის და თქვენს მიერ შერჩეული პარამეტრების შესანარჩუნებლად. ჩვენ არ ვიყენებთ მას თქვენი აქტივობების შესახებ ინფორმაციის შეკრების მიზნით. ღილაკზე დაწოლით თქვენ ნებას გვრთავთ გამოყენებული იყოს აუცილებელი "ქუქები".</span>
        <span hidden={languageToUse !== "en"}>This website uses session-related cookies to ensure the proper functioning of the site. These cookies are essential for user authentication and maintaining sessions. They will only be activated if you choose to register on the website. We do not use cookies for tracking or other purposes. Your privacy and data security are important to us. By registering, you consent to the use of session cookies for a seamless user experience.</span>
        <span hidden={languageToUse !== "ru"}>Этот веб-сайт использует файлы cookie, связанные с сеансом, для обеспечения правильной работы сайта. Эти файлы cookie необходимы для аутентификации пользователей и поддержания сеансов. Они будут активированы только в том случае, если вы решите зарегистрироваться на сайте. Мы не используем файлы cookie для отслеживания или других целей. Ваша конфиденциальность и безопасность данных важны для нас. Регистрируясь, вы соглашаетесь на использование сеансовых файлов cookie для обеспечения бесперебойной работы пользователя.</span>
      </CookieConsent>
    </div>
  );
};

export default CookieConsentBanner;
