import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Property from "./property";
import PopUpButton from "./popUpButton";
import backendUrl from './config';
import { Link } from 'react-router-dom';
import { useAuth } from './authContext';
import Loader from './loader';
import { useLanguage } from './languageContext';
import SubMenue from "./subMenue";

function createEntry(listingObject, onItemClick) {
    return (
        <Property
            key={listingObject._id}
            onClick={() => onItemClick(listingObject._id)}
            listingId={listingObject._id}
            address={listingObject.address}
            city={listingObject.city}
            state={listingObject.state}
            zip={listingObject.zip}
            propertytype={listingObject.propertytype}
            numberOfBedrooms={listingObject.numberOfBedrooms}
            numberOfBathrooms={listingObject.numberOfBathrooms}
            propertyCondition={listingObject.propertyCondition}
            purchasePrice={listingObject.purchasePrice}
            remaningPrinciple={listingObject.remaningPrinciple}
            interestRate={listingObject.interestRate}
            loanAmortizationPeriod={listingObject.loanAmortizationPeriod}
            remaningAmortizationPeriod={listingObject.remaningAmortizationPeriod}
            monthlyPayment={listingObject.monthlyPayment}
            propertyTax={listingObject.propertyTax}
            askingPrice={listingObject.askingPrice}
            img={listingObject.imgs}
            userId={listingObject.userId}
            garages={listingObject.garages}
            area={listingObject.area}
            subscriptionStatus={listingObject.subscriptions[listingObject.subscriptions.length - 1].status}
            lisingtype="mylistings"
            isFavoriteBySelection={false}
        />
    );
}

function MyListings({ onItemClick, listingId }) {
    const product = "realestate";
    const { currentLanguage } = useLanguage();
    // const [data, setInitialData] = useState([]);
    const [listingObjects, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    // const [search, setSearch] = useState('');
    const search = '';

    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const { loggedIn, userId } = useAuth();
    useEffect(() => {
        // Only fetch data if the component is mounted
        let isMounted = true;

        const fetchData = async () => {
            try {
                // Check if the user is authenticated using the useAuth hook
                // if (!loggedIn) {
                //     navigate('/login');
                //     return;
                // }

                // Fetch data from the /mylistings endpoint
                const response = await fetch(`${backendUrl}/mylistings/${product}/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (isMounted) {
                    if (response.ok) {
                        const responseData = await response.json();
                        setData(responseData.myListings);
                        setLoading(false);
                    } else {
                        // Handle non-200 status codes here
                        console.error("Non-200 status code:", response.status);
                        throw new Error(`Error: ${response.status}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();

        // Cleanup function to update the mounted status
        return () => {
            isMounted = false;
        };
    }, [loggedIn, userId, navigate]);

    if (loading) {
        return <Loader key={1} loading={loading} />;
    }

    const itemsPerPage = 9;

    const filteredListings = listingObjects.filter((item) => {
        const lowerCaseSearch = search.toLowerCase();
        return (
            lowerCaseSearch === '' ||
            (item && item.address && item.address.toLowerCase().includes(lowerCaseSearch)) ||
            (item && item.state && item.state.toLowerCase().includes(lowerCaseSearch)) ||
            (item && item.city && item.city.toLowerCase().includes(lowerCaseSearch)) ||
            (item && item.zip && item.zip.toString().includes(lowerCaseSearch))
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredListings.reverse().slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

    // Calculate the range of pages to display
    let pageRangeStart = Math.max(1, currentPage - 3);
    let pageRangeEnd = Math.min(currentPage + 3, totalPages);

    // Adjust page range if currentPage is near the first or last page
    if (currentPage <= 3) {
        pageRangeStart = 1; // Start from the first page if currentPage is within the first 3 pages
        pageRangeEnd = Math.min(7, totalPages); // Display 7 pages if total pages are less than 7
    } else if (currentPage >= totalPages - 3) {
        pageRangeStart = Math.max(1, totalPages - 6); // Start from the appropriate page to display 7 pages
    }


    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    // Define a function to handle page navigation
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return <main>
        {languageToUse === "ge" && (
            <div>
                <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="28">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">ჩემი განცხადებები</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="album py-5 bg-body-tertiary">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            {currentItems.map(listingObject => createEntry(listingObject, onItemClick))}
                            {!currentItems.length && (
                                <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                    <h3 className="fs-5 text-body-secondary">თქვენს არ ხართ შესული თქვენს ანგარიშზე ან თქვენს ექაუნთზე არცერთი განცხადება არ არის ატვირთული ან გამოქვეყნებული</h3>
                                    <br />
                                    <h4 className="fs-5 text-body-secondary">ამ მენიუს გამოყენებისათვის უნდა დარეგისტრირდეთ და შეხვიდეთ თქვენს ანგარიშზე. </h4>
                                    <br />
                                    <h4 className="fs-5 text-body-secondary">თუ გსურთ განცხადების გამოქვეყნება, მოძებნეთ "განცხადების ატვირთვა" მენიუში "განცხადებების მართვა", შეავსეთ ფორმა და ატვირთეთ განცხადება, შემდეგ მოძებნეთ "ჩემი განცხადებები" დარწმუნდით რომ ყველაფერი სწორედ გაქვთ ატვირთული და გამოაქვეყნეთ თქვენი განცხადება. </h4>
                                    <br />
                                    <h4 className="fs-5 text-body-secondary">ცვლილებების შეტანისათვის, დააწკაპუნეთ განცხადების სურთზე, მოძებნეთ ღილაკი "განცხადებაში ცვლილების შეტანა" და დააწკაპუნეთ</h4>
                                </div>
                            )}
                        </div>
                    </div>
                    {!!currentItems.length && (
                        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                                (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                                    <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                                )
                            ))}
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                            <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                        </div>
                    )}
                </div>
            </div>
        )}
        {languageToUse === "en" && (
            <div>
                <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="28">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">My listings</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="album py-5 bg-body-tertiary">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            {currentItems.map(listingObject => createEntry(listingObject, onItemClick))}
                            {!currentItems.length && (
                                <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                    <h3 className="fs-5 text-body-secondary">You first need to login. Your current account does not contain any listings.</h3>
                                    <br />
                                    <h4 className="fs-5 text-body-secondary">You need first to register and login to use this menue. If you wish to submit new listings, kindly locate the "Add Listing" option within the "Manage Listings" menu.</h4>
                                </div>
                            )}
                        </div>
                    </div>
                    {!!currentItems.length && (
                        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                                (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                                    <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                                )
                            ))}
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                            <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                        </div>
                    )}
                </div>
            </div>
        )}
        {languageToUse === "ru" && (
            <div>
                <section className="breadcrumbs-custom bg-image-mylisting context-dark" data-opacity="28">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">Мои объявления</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="album py-5 bg-body-tertiary">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            {currentItems.map(listingObject => createEntry(listingObject, onItemClick))}
                            {!currentItems.length && (
                                <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                    <h3 className="fs-5 text-body-secondary">Сначала вам нужно войти в систему. В вашем текущем аккаунте нет объявлений.</h3>
                                    <br />
                                    <h4 className="fs-5 text-body-secondary">Вам необходимо сначала зарегистрироваться и войти в систему, чтобы использовать это меню. Если вы хотите разместить новые объявления, найдите опцию «Добавить объявление» в меню «Управление объявлениями».</h4>
                                </div>
                            )}
                        </div>
                    </div>
                    {!!currentItems.length && (
                        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                                (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                                    <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                                )
                            ))}
                            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                            <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                        </div>
                    )}
                </div>
            </div>
        )}
    </main>
}


export default MyListings;