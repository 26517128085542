// // ColorPicker.js
// import React, { useState } from 'react';
// import { SketchPicker } from 'react-color';

// const ColorPicker = ({ onColorChange, isVisible }) => {
//     const [color, setColor] = useState('#ff0000');

//     const handleChange = (newColor) => {
//         setColor(newColor.hex);
//         onColorChange(newColor.hex);
//     };

//     return isVisible ? (
//         <div>
//             <SketchPicker color={color} onChange={handleChange} />
//         </div>
//     ) : null;
// };

// export default ColorPicker;


// ColorPicker.js
// import React, { useState } from 'react';
// import { HexColorPicker } from 'react-colorful';

// const predefinedColors = [
//     '#ff0000', // red
//     '#00ff00', // green
//     '#0000ff', // blue
//     // Add more colors as needed
// ];

// const ColorPicker = ({ onSelectColor }) => {
//     const [selectedColor, setSelectedColor] = useState(predefinedColors[0]);

//     const handleColorChange = (color) => {
//         setSelectedColor(color);
//         onSelectColor(color);
//     };

//     return (
//         <div>
//             <HexColorPicker color={selectedColor} onChange={handleColorChange} />
//         </div>
//     );
// };

// export default ColorPicker;



// ColorPicker.js
import React from 'react';
import { useLanguage } from './languageContext';

const colorOptionsEn = [
    { label: 'White', value: '#ffffff' },
    { label: 'Brown', value: '#8B4513' },
    { label: 'Blue', value: '#0000ff' },
    { label: 'Red', value: '#ff0000' },
    { label: 'Green', value: '#008000' },
    { label: 'Yellow', value: '#ffff00' },
    { label: 'Orange', value: '#ffa500' },
    { label: 'Purple', value: '#800080' },
    { label: 'Pink', value: '#ffc0cb' },
    { label: 'Aqua', value: '#00ffff' },
    { label: 'Lime', value: '#00ff00' },
    { label: 'Silver', value: '#c0c0c0' },
    { label: 'Gray', value: '#808080' },
    { label: 'Black', value: '#000000' },
];

const colorOptionsGe = [
    { label: 'თეთრი', value: '#ffffff' },
    { label: 'ყავისფერი', value: '#8B4513' },
    { label: 'ლურჯი', value: '#0000ff' },
    { label: 'წითელი', value: '#ff0000' },
    { label: 'მწვანე', value: '#008000' },
    { label: 'ყვითელი', value: '#ffff00' },
    { label: 'სტაფილოსფერი', value: '#ffa500' },
    { label: 'იისფერი', value: '#800080' },
    { label: 'ვარდისფერი', value: '#ffc0cb' },
    { label: 'ღია ცისფერი', value: '#00ffff' },
    { label: 'ღია მწვანე', value: '#00ff00' },
    { label: 'ვერცხლისფერი', value: '#c0c0c0' },
    { label: 'ნაცრისფერი', value: '#808080' },
    { label: 'შავი', value: '#000000' },
];

const colorOptionsRu = [
    { label: 'Белый', value: '#ffffff' },
    { label: 'Коричневый', value: '#8B4513' },
    { label: 'Синий', value: '#0000ff' },
    { label: 'Красный', value: '#ff0000' },
    { label: 'Зеленый', value: '#008000' },
    { label: 'Желтый', value: '#ffff00' },
    { label: 'Oранжевый', value: '#ffa500' },
    { label: 'Фиолетовый', value: '#800080' },
    { label: 'Розовый', value: '#ffc0cb' },
    { label: 'Светло-синий', value: '#00ffff' },
    { label: 'Светло-зеленый', value: '#00ff00' },
    { label: 'Серебряный', value: '#c0c0c0' },
    { label: 'Серый', value: '#808080' },
    { label: 'Черный', value: '#000000' },
];

const ColorPicker = ({ selectedColor, onSelectColor, placeholder }) => {
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const selectedColorLabel = colorOptionsEn.find(option => option.value === selectedValue)?.label;
        onSelectColor(selectedColorLabel);
    };
    const { currentLanguage } = useLanguage();
    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    return (
        <div>
            {languageToUse === "ge" && (
                <select
                    className="form-input select-filter"
                    value={selectedColor}
                    onChange={handleSelectChange}
                >
                    <option value="" disabled selected>
                        {placeholder}
                    </option>
                    {colorOptionsGe.map((option) => (
                        <option
                            key={option.value}
                            value={option.value}
                            style={{
                                backgroundColor: option.value,
                                color: '#000', // Set text color for better visibility
                            }}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
            )}
            {languageToUse === "en" && (
                <select
                    className="form-input select-filter"
                    value={selectedColor}
                    onChange={handleSelectChange}
                >
                    <option value="" disabled selected>
                        {placeholder}
                    </option>
                    {colorOptionsEn.map((option) => (
                        <option
                            key={option.value}
                            value={option.value}
                            style={{
                                backgroundColor: option.value,
                                color: '#000', // Set text color for better visibility
                            }}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
            )}
            {languageToUse === "ru" && (
                <select
                    className="form-input select-filter"
                    value={selectedColor}
                    onChange={handleSelectChange}
                >
                    <option value="" disabled selected>
                        {placeholder}
                    </option>
                    {colorOptionsRu.map((option) => (
                        <option
                            key={option.value}
                            value={option.value}
                            style={{
                                backgroundColor: option.value,
                                color: '#000', // Set text color for better visibility
                            }}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

export default ColorPicker;

