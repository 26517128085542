// import React, { useState, useEffect, useRef } from 'react';
// import { useLanguage } from './languageContext';

// const LanguageDropdown = () => {
//     const { currentLanguage, switchLanguage } = useLanguage();
//     const [maxWidth, setMaxWidth] = useState(0);
//     const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
//     const dropdownRef = useRef(null);
//     const dropdownContentRef = useRef(null);
//     const imagesRef = useRef([]);

//     useEffect(() => {
//         let newMaxWidth = 0;
//         imagesRef.current.forEach(image => {
//             newMaxWidth = Math.max(newMaxWidth, image.clientWidth);
//         });
//         setMaxWidth(newMaxWidth);
//     }, []);

//     useEffect(() => {
//         setSelectedLanguage(currentLanguage);
//     }, [currentLanguage]);

//     useEffect(() => {
//         if (dropdownContentRef.current && dropdownRef.current) {
//             dropdownContentRef.current.style.width = `${maxWidth}px`;
//             dropdownRef.current.style.width = `${maxWidth}px`;
//         }
//     }, [maxWidth]);

//     const handleLanguageSelect = (lang) => {
//         switchLanguage(lang);
//         setSelectedLanguage(lang);
//         sessionStorage.setItem('currentLanguage', lang);
//     };

//     return (
//         <div style={{ marginRight: '20px' }}>
//             <div className="custom-dropdown">
//                 <span className="selected-language">
//                     {selectedLanguage === 'ge' && (
//                         <img
//                             className='language-icon-start'
//                             src="images/Georgia_flag.png"
//                             alt="Georgian Flag"
//                             onClick={() => handleLanguageSelect('ge')}
//                             style={{ cursor: 'pointer', width: '30px', height: '22px' }}
//                         />
//                     )}
//                     {selectedLanguage === 'en' && (
//                         <img
//                             className='language-icon-start'
//                             src="images/usa_flag.png"
//                             alt="English Flag"
//                             onClick={() => handleLanguageSelect('en')}
//                             style={{ cursor: 'pointer', width: '30px', height: '22px' }}
//                         />
//                     )}
//                     {selectedLanguage === 'ru' && (
//                         <img
//                             className='language-icon-start'
//                             src="images/Russian_flag.png"
//                             alt="Russian Flag"
//                             onClick={() => handleLanguageSelect('ru')}
//                             style={{ cursor: 'pointer', width: '30px', height: '22px' }}
//                         />
//                     )}
//                 </span>
//                 <div className="dropdown-content" ref={dropdownContentRef}>
//                     <img
//                         src="images/Georgia_flag.png"
//                         alt="Georgian Flag"
//                         onClick={() => handleLanguageSelect('ge')}
//                         className="language-option"
//                         ref={(el) => imagesRef.current[0] = el}
//                     />
//                     <img
//                         src="images/usa_flag.png"
//                         alt="English Flag"
//                         onClick={() => handleLanguageSelect('en')}
//                         className="language-option"
//                         ref={(el) => imagesRef.current[1] = el}
//                     />
//                     <img
//                         src="images/Russian_flag.png"
//                         alt="Russian language"
//                         onClick={() => handleLanguageSelect('ru')}
//                         className="language-option"
//                         ref={(el) => imagesRef.current[2] = el}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LanguageDropdown;


// import React, { useState, useEffect, useRef } from 'react';
// import { useLanguage } from './languageContext';

// const LanguageDropdown = () => {
//     const { currentLanguage, switchLanguage } = useLanguage();
//     const [maxWidth, setMaxWidth] = useState(0);
//     const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
//     const dropdownRef = useRef(null);
//     const dropdownContentRef = useRef(null);
//     const imagesRef = useRef([]);

//     useEffect(() => {
//         let newMaxWidth = 0;
//         imagesRef.current.forEach(image => {
//             newMaxWidth = Math.max(newMaxWidth, image.clientWidth);
//         });
//         setMaxWidth(newMaxWidth);
//     }, []);

//     useEffect(() => {
//         setSelectedLanguage(currentLanguage);
//     }, [currentLanguage]);

//     useEffect(() => {
//         if (dropdownContentRef.current && dropdownRef.current) {
//             dropdownContentRef.current.style.width = `${maxWidth}px`;
//             dropdownRef.current.style.width = `${maxWidth}px`;
//         }
//     }, [maxWidth]);

//     const handleLanguageSelect = (lang, imageUrl) => {
//         switchLanguage(lang);
//         setSelectedLanguage(lang);
//         sessionStorage.setItem('currentLanguage', lang);
//         // Update placeholder image
//         const placeholderImage = document.getElementById('selected-language-img');
//         if (placeholderImage) {
//             placeholderImage.src = imageUrl;
//         }
//     };

//     return (
//         <div style={{ marginRight: '20px' }}>
//             <div className="custom-dropdown">
//                 <span className="selected-language">
//                     <img
//                         id="selected-language-img"
//                         src={`images/${selectedLanguage}_flag.png`}
//                         alt={`${selectedLanguage} Flag`}
//                         style={{ cursor: 'pointer', width: '30px', height: '22px' }}
//                     />
//                 </span>
//                 <div className="dropdown-content" ref={dropdownContentRef}>
//                     <img
//                         src="images/Georgia_flag.png"
//                         alt="Georgian Flag"
//                         onClick={() => handleLanguageSelect('ge', 'images/Georgia_flag.png')}
//                         className="language-option"
//                         ref={(el) => imagesRef.current[0] = el}
//                     />
//                     <img
//                         src="images/usa_flag.png"
//                         alt="English Flag"
//                         onClick={() => handleLanguageSelect('en', 'images/usa_flag.png')}
//                         className="language-option"
//                         ref={(el) => imagesRef.current[1] = el}
//                     />
//                     <img
//                         src="images/Russian_flag.png"
//                         alt="Russian language"
//                         onClick={() => handleLanguageSelect('ru', 'images/Russian_flag.png')}
//                         className="language-option"
//                         ref={(el) => imagesRef.current[2] = el}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LanguageDropdown;


import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from './languageContext';

const LanguageDropdown = () => {
    const { currentLanguage, switchLanguage } = useLanguage();
    const [maxWidth, setMaxWidth] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        const storedLanguage = sessionStorage.getItem('currentLanguage');
        return storedLanguage || currentLanguage;
    });
    const dropdownRef = useRef(null);
    const dropdownContentRef = useRef(null);
    const imagesRef = useRef([]);

    useEffect(() => {
        let newMaxWidth = 0;
        imagesRef.current.forEach(image => {
            newMaxWidth = Math.max(newMaxWidth, image.clientWidth);
        });
        setMaxWidth(newMaxWidth);
    }, []);

    useEffect(() => {
        setSelectedLanguage(currentLanguage);
    }, [currentLanguage]);

    useEffect(() => {
        if (dropdownContentRef.current && dropdownRef.current) {
            dropdownContentRef.current.style.width = `${maxWidth}px`;
            dropdownRef.current.style.width = `${maxWidth}px`;
        }
    }, [maxWidth]);

    const handleLanguageSelect = (lang, imageUrl) => {
        switchLanguage(lang);
        setSelectedLanguage(lang);
        sessionStorage.setItem('currentLanguage', lang);
        // Update placeholder image
        const placeholderImage = document.getElementById('selected-language-img');
        if (placeholderImage) {
            placeholderImage.src = imageUrl;
        }
    };

    return (
        <div style={{ marginRight: '20px' }}>
            <div className="custom-dropdown">
                <span className="selected-language">
                    <img
                        id="selected-language-img"
                        src={`images/${selectedLanguage}_flag.png`}
                        alt={`${selectedLanguage} Flag`}
                        style={{ cursor: 'pointer', width: '30px', height: '22px' }}
                    />
                </span>
                <div className="dropdown-content" ref={dropdownContentRef}>
                    <img
                        src="images/Georgia_flag.png"
                        alt="Georgian Flag"
                        onClick={() => handleLanguageSelect('ge', 'images/Georgia_flag.png')}
                        className="language-option"
                        ref={(el) => imagesRef.current[0] = el}
                    />
                    <img
                        src="images/usa_flag.png"
                        alt="English Flag"
                        onClick={() => handleLanguageSelect('en', 'images/usa_flag.png')}
                        className="language-option"
                        ref={(el) => imagesRef.current[1] = el}
                    />
                    <img
                        src="images/Russian_flag.png"
                        alt="Russian language"
                        onClick={() => handleLanguageSelect('ru', 'images/Russian_flag.png')}
                        className="language-option"
                        ref={(el) => imagesRef.current[2] = el}
                    />
                </div>
            </div>
        </div>
    );
};

export default LanguageDropdown;
