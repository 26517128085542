import React from "react";
import { Link } from 'react-router-dom';
import { useLanguage } from './languageContext';
import PosterCard from "./posterCard";

function Poster() {
    const { currentLanguage } = useLanguage();
    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;
    return <div>
        {languageToUse === "ge" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"უძრავი ქონება"}
                                cardContent={"იპოვეთ განცხადებები უძრავი ქონებაზე."}
                                cardLink={"/home"}
                                cardImg={"./images/realestate_6.jpg"}
                            // cardImg={"./images/realestate2.jpeg"}
                            />
                            <PosterCard
                                cardTitle={"ავტომობილები"}
                                cardContent={"იპოვეთ განცხადებები ავტომობილების გაყიდვა და გაქირავების შესახებ."}
                                cardLink={"/vehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            // cardImg={"./images/wheel.jpeg"}
                            />
                            <PosterCard
                                cardTitle={"მომსახურეობის შეთავაზება"}
                                cardContent={"იპოვეთ განცხადებები სერვისებზე - შეთავაზებები."}
                                cardLink={"/services"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"ვეძებ, შევიძენ, ვიქირავებ და სხვა მოთხოვნები"}
                                cardContent={"იპოვეთ განცხადებები ვიყიდი, ვიქირავებ, ვეძებ და სხვა. - მოთხოვნები"}
                                cardLink={"/demands"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "en" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"Real estate"}
                                cardContent={"Find realestate listings"}
                                cardLink={"/home"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Vehicles"}
                                cardContent={"Find vehicle listings"}
                                cardLink={"/vehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Services and Employment"}
                                cardContent={"Find services you are looking for - Supply"}
                                cardLink={"/services"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"I am looking for something, I will buy, I will rent and more"}
                                cardContent={"Find what others are looking for - Demand "}
                                cardLink={"/demands"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "ru" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"Недвижимость"}
                                cardContent={"Найдите объявления о недвижимости"}
                                cardLink={"/home"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Автомобили"}
                                cardContent={"Найдите объявления о транспортных средствах"}
                                cardLink={"/vehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Услуги и занятость"}
                                cardContent={"Найдите нужные вам услуги - Поставка"}
                                cardLink={"/services"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Я ищу, куплю, сниму в аренду и многое другое"}
                                cardContent={"Найдите то, что ищут другие – Спрос"}
                                cardLink={"/demands"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
    </div>
}


export default Poster;