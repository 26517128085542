import React from "react";
import { useLanguage } from './languageContext';
import PosterCard from "./posterCard";

function ListingOptions() {
    const { currentLanguage } = useLanguage();
    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;
    return <div>
        {languageToUse === "ge" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"უძრავი ქონება"}
                                cardContent={"განცხადების განთავსება უძრავი ქონების გაქირავების შესახებ."}
                                cardLink={"/listing"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"ავტომობილები"}
                                cardContent={"განცხადების განთავსება ავტომობილების გაყიდვა და გაქირავების შესახებ."}
                                cardLink={"/vehiclelisting"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"მომსახურეობის შეთავაზება"}
                                cardContent={"შეთავაზებები - განცხადების განთავსება თქვენი სერვისების შეთავაზების შესახებ."}
                                cardLink={"/servicelisting"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"ვეძებ და სხვა შეთავაზებები"}
                                cardContent={"მოთხოვნა - განცხადების განთავსება ვიყიდი, ვიქირავებ, ვეძებ და სხვა."}
                                cardLink={"/demandlisting"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "en" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"Real estate"}
                                cardContent={"Post listings"}
                                cardLink={"/listing"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Vehicles"}
                                cardContent={"Post listings"}
                                cardLink={"/vehiclelisting"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Services and Employment"}
                                cardContent={"Supply - publish services to offer"}
                                cardLink={"/servicelisting"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"I am looking for something"}
                                cardContent={"Demand - Post if you looking for some services, job, or buying something"}
                                cardLink={"/demandlisting"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "ru" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"Недвижимость"}
                                cardContent={"Публикации объявлений"}
                                cardLink={"/listing"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Автомобили"}
                                cardContent={"Публикации объявлений"}
                                cardLink={"/vehiclelisting"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Услуги и занятость"}
                                cardContent={"Поставка – публикация предлагаемых услуг"}
                                cardLink={"/servicelisting"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Я ищу"}
                                cardContent={"Спрос. Публикуйте, если вы ищете какие-то услуги, работу или что-то покупаете"}
                                cardLink={"/demandlisting"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
    </div>
}


export default ListingOptions;