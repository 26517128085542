import React from "react";
import { Link } from 'react-router-dom';
import { useLanguage } from './languageContext';
import PosterCard from "./posterCard";

function MyListingOptions() {
    const { currentLanguage } = useLanguage();
    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;
    return <div>
        {languageToUse === "ge" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">
                            <PosterCard
                                cardTitle={"უძრავი ქონება"}
                                cardContent={"აქ იპოვით თქვენს მიერ განთავსებულ განცხადებებს უძრავ ქონებაზე"}
                                cardLink={"/mylistings"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"ავტომობილები"}
                                cardContent={"აქ იპოვით თქვენს მიერ განთავსებულ განცხადებებს ავტომობილებზე."}
                                cardLink={"/mylistedvehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"მომსახურეობის შეთავაზება"}
                                cardContent={"აქ იპოვით თქვენს მიერ განთავსებულ განცხადებებს სერვისების შეთავაზების შესახებ."}
                                cardLink={"/mylistedservices"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"ვეძებ და სხვა შეთავაზებები"}
                                cardContent={"აქ იპოვით თქვენს მიერ განთავსებულ განცხადებებს."}
                                cardLink={"/mylisteddemads"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "en" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">

                            <PosterCard
                                cardTitle={"Real estate"}
                                cardContent={"Here you can find your posted listings."}
                                cardLink={"/mylistings"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Vehicles"}
                                cardContent={"Here you can find your posted vehicles."}
                                cardLink={"/mylistedvehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Services and Employment"}
                                cardContent={"Here you can find you posted offers - Supply"}
                                cardLink={"/mylistedservices"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"I am looking for or offer something"}
                                cardContent={"Here you can find your posts on what yoy are looking for - Demand"}
                                // cardLink={""}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
        {languageToUse === "ru" && (
            <div className="container">
                <article className="box-info box-info-1">
                    <div className="box-info">
                        <div className="row row-30 row-flex">

                            <PosterCard
                                cardTitle={"Недвижимость"}
                                cardContent={"Здесь вы можете найти ваши опубликованные объявления"}
                                cardLink={"/mylistings"}
                                cardImg={"./images/realestate_6.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Автомобили"}
                                cardContent={"Здесь вы можете найти размещенные вами автомобили"}
                                cardLink={"/mylistedvehicles"}
                                cardImg={"./images/wheel_3.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Услуги и занятость"}
                                cardContent={"Здесь вы можете найти размещенные вами предложения - Поставка"}
                                cardLink={"/mylistedservices"}
                                cardImg={"./images/services_12.jpg"}
                            />
                            <PosterCard
                                cardTitle={"Я ищу"}
                                cardContent={"Здесь вы можете найти свои сообщения о том, что вы ищете - Спрос"}
                                cardLink={"/mylisteddemands"}
                                cardImg={"./images/demand_15.jpg"}
                            />
                        </div>
                    </div>
                </article>
            </div>
        )}
    </div>
}


export default MyListingOptions;