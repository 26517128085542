import React, { useState, useEffect, useCallback } from "react";
import backendUrl from './config';
import { useAuth } from './authContext';

function FavorateButton(props) {
    const product = props.product;
    const [isFavorate, setIsFavorate] = useState(props.isFavoriteBySelection);
    const [responseMessage, setResponseMessage] = useState("");

    const { userId } = useAuth();

    // Define the fetchData function here
    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(`${backendUrl}/favoratelistings/${product}/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                const responseData = await response.json();
                const isItemInFavorites = responseData.favorateListings.some(item => item._id === props.listingId);
                setIsFavorate(isItemInFavorites);
            } else {
                console.error("Non-200 status code:", response.status);
                throw new Error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors, you might want to set some state to indicate an error
        }
    }, [props.listingId, userId]);

    useEffect(() => {
        // Fetch data from the backend when the component mounts
        fetchData();
    }, [fetchData]);

    const handleLikeButtonClick = useCallback(async () => {
        setIsFavorate(prevIsFavorate => !prevIsFavorate);

        // Create form data
        const formData = new FormData();
        if (product === "realestate") {
            formData.append('favoriteproperty', props.listingId);
            formData.append('isfavorite', !isFavorate);
        } else if (product === "vehicle") {
            formData.append('favoritevehicle', props.listingId);
            formData.append('isfavorite', !isFavorate);
        } else if (product === "service") {
            formData.append('favoriteservice', props.listingId);
            formData.append('isfavorite', !isFavorate);
        } else if (product === "demand") {
            formData.append('favoritedemand', props.listingId);
            formData.append('isfavorite', !isFavorate);
        }
        try {
            const response = await fetch(`${backendUrl}/favoratelistings/${product}/${userId}`, {
                method: "POST",
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    // Add any additional headers if needed
                }
            });

            if (response.ok) {
                const data = await response.json();
                setResponseMessage(data.message);
                // Fetch updated data from the backend
                fetchData();
            } else {
                setResponseMessage("Error sending data to the server. Response failed.");
            }
        } catch (error) {
            console.error("Error:", error);
            setResponseMessage("Error sending data to the server.");
        }
    }, [props.listingId, isFavorate, userId, fetchData]);

    return (
        <div className="">
            <span className="favorite-btn-star" onClick={handleLikeButtonClick} style={{ color: isFavorate ? 'gold' : 'gray' }}>
                &#9733;
            </span>
        </div>
    );
}

export default FavorateButton;
