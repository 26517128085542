import React, { useState, useEffect } from "react";
import { useLanguage } from './languageContext';
import VehicleMakeSelector from "./vehicleMakeSelector";
import VehicleModelSelector from "./vehicleModelSelector";

function SearchVehicle(props) {
    const { currentLanguage } = useLanguage();
    const [selectedDealType, setInputValueDealType] = useState("");
    const [inputValueMake, setInputValueMake] = useState("");
    const [inputValueModel, setInputValueModel] = useState("");
    const [inputValueYear, setInputValueYear] = useState("");
    const [inputValueFuel, setInputValueFuel] = useState("");
    const [inputValueMileage, setInputValueMileage] = useState("");
    const [inputValueAskingPrice, setInputValueAskingPrice] = useState();

    const [externalValueMake, setExternalValueMake] = useState('');
    const [externalValueModel, setExternalValueModel] = useState('');

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Call onFilterSubmit whenever there's a change in input values
        props.onFilterSubmit(getFilteredData());
    }, [
        selectedDealType,
        inputValueMake,
        inputValueModel,
        inputValueYear,
        inputValueFuel,
        inputValueMileage,
        inputValueAskingPrice,
        props.listedVehicles,
    ]);

    const handleInputChangeMake = (e) => {
        setInputValueMake(e.target.value);
    };
    const handleInputChangeModel = (e) => {
        setInputValueModel(e.target.value);
    };
    const handleInputChangeYear = (e) => {
        setInputValueYear(e.target.value);
    };
    const handleInputChangeFuel = (e) => {
        setInputValueFuel(e.target.value);
    };
    const handleInputChangeMileage = (e) => {
        setInputValueMileage(e.target.value);
    };

    const handleInputChangeAskingPrice = (e) => {
        setInputValueAskingPrice(e.target.value);
    };

    const handleInputDealTypeChange = (e) => {
        setInputValueDealType(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFilter();
    };

    const setFilter = () => {
        // Call onFilterSubmit with the filtered data
        props.onFilterSubmit(getFilteredData());
    };

    const getFilteredData = () => {
        const filterCriteria = {
            dealtype: selectedDealType,
            make: inputValueMake,
            model: inputValueModel,
            year: inputValueYear,
            fuel: inputValueFuel,
            mileage: inputValueMileage,
            askingPrice: inputValueAskingPrice,
        };

        return props.listedVehicles.filter((item) => {
            for (const key in filterCriteria) {
                if (filterCriteria[key] === "Any" || filterCriteria[key] === "") {
                    continue;
                }

                const filterValue = filterCriteria[key]?.toString().toLowerCase(); // Convert filter value to lowercase
                const itemValue = item[key]?.toString().toLowerCase(); // Convert item value to lowercase

                if (key === "dealtype" && filterValue !== itemValue) {
                    return false;
                }
                if (key === "make" && filterValue !== itemValue) {
                    return false;
                }
                if (key === "model" && filterValue !== itemValue) {
                    return false;
                }
                if (key === "year" && parseInt(filterValue) >= parseInt(itemValue)) {
                    return false;
                }
                if (key === "fuel" && filterValue !== itemValue) {
                    return false;
                }
                if (key === "mileage" && parseInt(filterValue) < parseInt(itemValue)) {
                    return false;
                }
                if (key === "askingPrice" && parseInt(filterValue) < parseInt(itemValue)) {
                    return false;
                }
            }
            return true;
        });

    };

    const handleReset = () => {
        props.onFilterSubmit(props.listedVehicles);
        setInputValueMake("");
        setExternalValueMake("");
        setInputValueModel("");
        setInputValueYear("");
        setInputValueFuel("");
        setInputValueMileage("");
        setInputValueAskingPrice("");
        setInputValueDealType("");
    };

    return <div>
        <div>
            <div className="divider-section">
            </div>
            {/* <section className="section section-xs bg-gray-12"> */}
            <section className="section section-xs bg-gray-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <div className="container">
                    <div className="row row-50">
                        <div className="col-lg-12">
                            {/* <article className="block-property bg-default"> */}
                            <article className="block-property bg-default" style={{ padding: '20px' }}>
                                <div className="layout-3">
                                    <h3 hidden={languageToUse !== "ge"}>მოძებნეთ სასურველი ავტომობილი</h3>
                                    <h3 hidden={languageToUse !== "en"}>Find your vehicle</h3>
                                    <h3 hidden={languageToUse !== "ru"}>Найдите автомобиль</h3>
                                    <div className="layout-3-item">
                                        <ul className="list-inline-bordered heading-7">
                                            <li>
                                                <label>
                                                    <input
                                                        name="input-group-radio"
                                                        value="Any"
                                                        type="radio"
                                                        checked={selectedDealType === 'Any' || selectedDealType === ''}
                                                        onChange={handleInputDealTypeChange}
                                                    />
                                                    <span hidden={languageToUse !== "ge"}>ყველა</span>
                                                    <span hidden={languageToUse !== "en"}>All listings</span>
                                                    <span hidden={languageToUse !== "ru"}>Все объявления</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label>
                                                    <input
                                                        name="input-group-radio"
                                                        value="forRent"
                                                        type="radio"
                                                        checked={selectedDealType === 'forRent'}
                                                        onChange={handleInputDealTypeChange}
                                                    />
                                                    <span hidden={languageToUse !== "ge"}>გაქირავება</span>
                                                    <span hidden={languageToUse !== "en"}>Lease</span>
                                                    <span hidden={languageToUse !== "ru"}>Арендовать</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label>
                                                    <input
                                                        name="input-group-radio"
                                                        value="forSale"
                                                        type="radio"
                                                        checked={selectedDealType === 'forSale'}
                                                        onChange={handleInputDealTypeChange}
                                                    />
                                                    <span hidden={languageToUse !== "ge"}>გაყიდვა</span>
                                                    <span hidden={languageToUse !== "en"}>Sell</span>
                                                    <span hidden={languageToUse !== "ru"}>Продавать</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}
                                    className="rd-mailform form-property mt-30"
                                    data-form-output="form-output-global"
                                    data-form-type="contact"
                                    method=""
                                    action=""
                                >
                                    <div className="">
                                        <VehicleMakeSelector
                                            inputValueMake={inputValueMake}
                                            handleInputChangeMake={handleInputChangeMake}
                                            externalValueMake={externalValueMake}
                                            setExternalValueMake={setExternalValueMake}

                                        />
                                    </div>
                                    <div className="">
                                        <VehicleModelSelector
                                            inputValueModel={inputValueModel}
                                            handleInputChangeModel={handleInputChangeModel}
                                            externalValueModel={externalValueModel} // Use inputValueModel as the external value
                                            setExternalValueModel={setInputValueModel} // Pass the function to handle model change
                                            selectedMake={inputValueMake}
                                            handleMakeChange={handleInputChangeModel}
                                        />
                                    </div>
                                    <div className="col-md-3.5">
                                        <div className="form-wrap">
                                            <input hidden={languageToUse !== "ge"} type="text" className="form-input" id="vehicleProductionYear" name="vehicleProductionYear" required placeholder="გამოშვების წელი" value={inputValueYear} onChange={handleInputChangeYear} />
                                            <input hidden={languageToUse !== "en"} type="text" className="form-input" id="vehicleProductionYear" name="vehicleProductionYear" required placeholder="Production date" value={inputValueYear} onChange={handleInputChangeYear} />
                                            <input hidden={languageToUse !== "ru"} type="text" className="form-input" id="vehicleProductionYear" name="vehicleProductionYear" required placeholder="Дата выпуска" value={inputValueYear} onChange={handleInputChangeYear} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            <select hidden={languageToUse !== "ge"} className="form-input select-filter" name="interior" value={inputValueFuel} onChange={handleInputChangeFuel}>
                                                <option value="Any">საწვავი</option>
                                                <option value="gas">ბენზინი</option>
                                                <option value="diesel">დიზელი</option>
                                                <option value="electricity">ელექტრო</option>
                                                <option value="hybrid">ჰიბრიდი ბენზინი</option>
                                                <option value="other">სხვა</option>
                                            </select>
                                            <select hidden={languageToUse !== "en"} className="form-input select-filter" name="interior" value={inputValueFuel} onChange={handleInputChangeFuel}>
                                                <option hidden={languageToUse !== "en"} value="Any">Fuel</option>
                                                <option value="gas">Gas</option>
                                                <option value="diesel">Diesel</option>
                                                <option value="electricity">Electricity</option>
                                                <option value="hybrid">Hybrid gas</option>
                                                <option value="other">Others</option>
                                            </select>
                                            <select hidden={languageToUse !== "ru"} className="form-input select-filter" name="interior" value={inputValueFuel} onChange={handleInputChangeFuel}>
                                                <option value="Any">Топливо</option>
                                                <option value="gas">Бензин</option>
                                                <option value="diesel">Дизель</option>
                                                <option value="electricity">Электричество</option>
                                                <option value="hybrid">Гибридный</option>
                                                <option value="other">Другое</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            <input hidden={languageToUse !== "ge"} className="form-input" name="mileage" placeholder="გარბენი მილებში" value={inputValueMileage} onChange={handleInputChangeMileage}>
                                            </input>
                                            <input hidden={languageToUse !== "en"} className="form-input" name="mileage" placeholder="Milage" value={inputValueMileage} onChange={handleInputChangeMileage}>
                                            </input>
                                            <input hidden={languageToUse !== "ru"} className="form-input" name="mileage" placeholder="Пробег" value={inputValueMileage} onChange={handleInputChangeMileage}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-md-3" hidden={selectedDealType === "forRent"}>
                                        <div className="form-wrap form-wrap-validation">
                                            <select hidden={languageToUse !== "ge"} type="text" className="form-input select-filter" id="askingPrice" name="askingPrice" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                                                <option value="Any">გასაყიდი ფასი</option>
                                                <option value="3000">არაუმეტეს $3000</option>
                                                <option value="5000">არაუმეტეს $5000</option>
                                                <option value="10000">არაუმეტეს $10000</option>
                                                <option value="15000">არაუმეტეს $15000</option>
                                                <option value="20000">არაუმეტეს $20000</option>
                                                <option value="30000">არაუმეტეს $30000</option>
                                                <option value="Any">ნებისმიერი ოდენობა</option>
                                            </select>
                                            <select hidden={languageToUse !== "en"} type="text" className="form-input select-filter" id="askingPrice" name="askingPrice" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                                                <option value="Any">Selling price</option>
                                                <option value="3000">Max $3000</option>
                                                <option value="5000">Max $5000</option>
                                                <option value="10000">Max $10000</option>
                                                <option value="15000">Max $15000</option>
                                                <option value="20000">Max $20000</option>
                                                <option value="30000">Max $30000</option>
                                                <option value="Any">Any amount</option>
                                            </select>
                                            <select hidden={languageToUse !== "ru"} type="text" className="form-input select-filter" id="askingPrice" name="askingPrice" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                                                <option value="Any">Цена продажи</option>
                                                <option value="3000">Макс $3000</option>
                                                <option value="5000">Макс $5000</option>
                                                <option value="10000">Макс $10000</option>
                                                <option value="15000">Макс $15000</option>
                                                <option value="20000">Макс $20000</option>
                                                <option value="30000">Макс $30000</option>
                                                <option value="Any">Любая цена</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3" hidden={selectedDealType === "forSale" || selectedDealType === "Any" || selectedDealType === ""}>
                                        <div className="form-wrap form-wrap-validation">
                                            <select hidden={languageToUse !== "ge"} type="text" className="form-input select-filter" id="askingPriceMonthlyGe" name="askingPriceMonthly" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                                                <option value="Any">ქირის ღირებულება თვეში</option>
                                                <option value="800">არაუმეტეს $800</option>
                                                <option value="1200">არაუმეტეს $1200</option>
                                                <option value="1600">არაუმეტეს $1600</option>
                                                <option value="2000">არაუმეტეს $2000</option>
                                                <option value="2400">არაუმეტეს $2400</option>
                                                <option value="3000">არაუმეტეს $3000</option>
                                                <option value="Any">ქირის ნებისმიერი ოდენობა</option>
                                            </select>
                                            <select hidden={languageToUse !== "en"} type="text" className="form-input select-filter" id="askingPriceMonthlyGe" name="askingPriceMonthly" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                                                <option value="Any">Lease value per month</option>
                                                <option value="800">Max $800</option>
                                                <option value="1200">Max $1200</option>
                                                <option value="1600">Max $1600</option>
                                                <option value="2000">Max $2000</option>
                                                <option value="2400">Max $2400</option>
                                                <option value="3000">Max $3000</option>
                                                <option value="Any">Any amount</option>
                                            </select>
                                            <select hidden={languageToUse !== "ru"} type="text" className="form-input select-filter" id="askingPriceMonthlyGe" name="askingPriceMonthly" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                                                <option value="Any">Стоимость аренды в месяц</option>
                                                <option value="800">Макс $800</option>
                                                <option value="1200">Макс $1200</option>
                                                <option value="1600">Макс $1600</option>
                                                <option value="2000">Макс $2000</option>
                                                <option value="2400">Макс $2400</option>
                                                <option value="3000">Макс $3000</option>
                                                <option value="Any">Любая цена</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-wrap form-wrap-validation">
                                        <div className="">
                                            <button
                                                className="button button-md button-secondary-val form-input"
                                                type="button" onClick={handleReset}
                                            >
                                                <span hidden={languageToUse !== "ge"}>ფილტრის მოხსნა</span>
                                                <span hidden={languageToUse !== "en"}>Remove a filter</span>
                                                <span hidden={languageToUse !== "ru"}>Удалить фильтр</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
}
export default SearchVehicle;