import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import Map from './googleMap';
import Loader from "./loader";
import ChatBot from "./chatBot";
import FavorateButton from "./favorateButton";
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import backendUrl from './config';
import { useAuth } from './authContext';
import { useNavigate } from "react-router-dom";
import { useLanguage } from './languageContext';
import SubMenue from "./subMenue";
import SendEmailMainByUser from "./sendEmaiMainByUser";
import ImgSlider from "./imgSlider";


function ServiceDetails() {
    const product = "service";
    const { userId } = useAuth();
    const { currentLanguage } = useLanguage();
    const { itemId } = useParams();
    const [selectedService, setselectedService] = useState({});
    const [loading, setLoading] = useState(true);

    const [imagePaths, setImagePaths] = useState([]);

    // const [userId, setUserId] = useState("");
    const [responseMessageShow, setResponseMessageShow] = useState(false);


    // send email
    // from, to, subject, text -was here

    const [responseMessageFetchPhotos, setResponseMessageFetchPhotos] = useState("");

    // Information toggle
    const [generalInformationAccordionOpened, setGeneralInformationAccordionOpened] = useState("true");
    const [vehicleInformationAccordionOpened, setVehicleInformationAccordionOpened] = useState("true");
    const [moreInformationAccordionOpened, setMoreInformationAccordionOpened] = useState("true");
    const [damagesAccordionOpened, setDamagesAccordionOpened] = useState("true");
    const [commentAccordionOpened, setCommentAccordionOpened] = useState("true");


    const { loggedIn } = useAuth();
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // deal summary 
    // let averageMortgageRate = 0.007;

    useEffect(() => {
        fetch(`${backendUrl}/item/${product}/${new Date().getTime()}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                setselectedService(responseData);
                setLoading(false);
                fetchPhotos(responseData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [loggedIn, navigate, itemId]);


    // fetch photos 
    const fetchPhotos = async (responseData) => {
        setImagePaths([]);
        // Use a Set to store unique URLs
        const uniqueImagePaths = new Set();
        // Receive and add each image URL to the imagePaths array
        const numberOfPhotos = responseData.imgs.length;
        for (let i = 0; i < numberOfPhotos; i++) {
            try {
                const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${i}`);
                if (response.ok) {
                    const contentType = response.headers.get('content-type');

                    if (contentType && contentType.includes('application/json')) {
                        const data = await response.json();
                        setResponseMessageFetchPhotos(data.message);
                    } else {
                        const blob = await response.blob();
                        const imgUrl = URL.createObjectURL(blob);

                        // Add the unique URL to the set
                        uniqueImagePaths.add(imgUrl);
                    }
                } else {
                    setResponseMessageFetchPhotos("Error fetching photos. Response failed.");
                }
            } catch (error) {
                console.error("Error fetching photos:", error);
                setResponseMessageFetchPhotos("Error fetching photos.");
            }
        }
        setImagePaths([...uniqueImagePaths]);
    };

    // fetching photo end


    if (loading) {
        return <Loader loading={loading} />;
    }

    const handleAccordionGeneralInformationToggle = () => {
        setGeneralInformationAccordionOpened(!generalInformationAccordionOpened);
    }

    const handleAccordionVehicleInformationToggle = () => {
        setVehicleInformationAccordionOpened(!vehicleInformationAccordionOpened);
    }

    const handleAccordionMoreDetalsToggle = () => {
        setMoreInformationAccordionOpened(!moreInformationAccordionOpened);
    }

    const handleAccordionDamagesToggle = () => {
        setDamagesAccordionOpened(!damagesAccordionOpened);
    }

    const handleAccordionCommentInformationToggle = () => {
        setCommentAccordionOpened(!commentAccordionOpened);
    }

    return (
        <div>
            {languageToUse === "ge" && (
                <div>
                    <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                        <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                            <h2 className="breadcrumbs-custom-title">დეტალები სერვისის შესახებ</h2>
                        </div>
                    </section>
                    <SubMenue product={product} />
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{parseInt(selectedService.askingPrice) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(parseInt(selectedService.askingPrice)) : selectedService.askingPrice}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list icon-size-small">
                                                    <li><img src="./images/location.png" alt="icon" /><span>{selectedService.city}</span></li>
                                                    <li><img src="./images/user-o.png" alt="icon" /><span>{selectedService.name}</span></li>
                                                    <li><img src="./images/mail-o.png" alt="icon" /><span>{selectedService.email}</span></li>
                                                    <li><img src="./images/b-call1.png" alt="icon" /><span>{selectedService.phone}</span></li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="layout">
                                                    <dl className="list-terms-inline text-margin">
                                                        <dt className="list-item-bold ">კატეგორია:</dt>
                                                        <dd hidden={selectedService.servicetype !== "accounting"}><span class=""></span><span>{selectedService.servicetype === "accounting" ? "ბუღალტერია" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "legal"}><span class=""></span><span>{selectedService.servicetype === "legal" ? "იურიდიული მომსახურება" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "handyman"}><span class=""></span><span>{selectedService.servicetype === "handyman" ? "ხელმარჯვე ოსტატი" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "construction"}><span class=""></span><span>{selectedService.servicetype === "construction" ? "რემონტები" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "moving"}><span class=""></span><span>{selectedService.servicetype === "moving" ? "გადაზიდვები" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "teaching"}><span class=""></span><span>{selectedService.servicetype === "teaching" ? "სწავლა" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "partnership"}><span class=""></span><span>{selectedService.servicetype === "partnership" ? "პარტნიორობა" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "beauty"}><span class=""></span><span>{selectedService.servicetype === "beauty" ? "სილამაზე" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "cleaning"}><span class=""></span><span>{selectedService.servicetype === "cleaning" ? "დალაგებები" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "HHA"}><span class=""></span><span>{selectedService.servicetype === "HHA" ? "მოვლა" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "housekeeping"}><span class=""></span><span>{selectedService.servicetype === "housekeeping" ? "ჰაუზკიპინგი" : ""}</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "other"}><span class=""></span><span>{selectedService.servicetype === "other" ? "სხვა სერვისები" : ""}</span></dd>
                                                    </dl>
                                                </div>
                                                <div className="card-title "><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>{selectedService.subject}</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedService.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="card-body">
                                            <div className="layout">
                                                <dl className="list-terms-inline text-margin">
                                                    <dt>ფასი:</dt>
                                                    <dd>{selectedService.askingPrice}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <article>

                                        </article>
                                    </div>
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    <div hidden={userId !== selectedService.user._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editservicelisting/${selectedService._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    განცხადებაში ცვლილებების შეტანა
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "დარწმუნებული ხართ, რომ გსურთ ამ განცხადების წაშლა? როგორც კი ამას გააკეთებთ, ვერ აღადგენთ წაშლილ განცხადებას და გამოწერას. თუ განცხადება იყო ფასიანი დაკარგავთ მისი ერთთვიანი გამოწერის დარჩენილ ვადასაც. ეს არ შეეხება სხვა განცხადებებს."
                                                    )
                                                        ? navigate(`/deleteservice/${selectedService._id}`)
                                                        : null
                                                }
                                            >
                                                განცხადების წაშლა
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div>
                                    {/* <div className="block-group-item">
                                        <h3>მანქანის მდებარეობა რუქაზე</h3>
                                        <MapWrapper>
                                            <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedService} defaultCenter={selectedService}></Map>
                                        </MapWrapper>
                                    </div> */}
                                    <div className="blog-post-solo-footer mt-20">
                                    </div>
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">სალომე ღურწკაია</h3>
                                                <p className="block-person-cite">კლიენტებთან ურთიერთობის ექსპერტი</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>5 წლის განმავლობაში დახმარება გაუწია უამრავ კლიენტს</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    დაუკავშირდით
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedService.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {languageToUse === "en" && (
                <div>
                    <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                        <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                            <h2 className="breadcrumbs-custom-title">Detailsl on proposed service</h2>
                        </div>
                    </section>
                    <SubMenue product={product} />
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{parseInt(selectedService.askingPrice) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(parseInt(selectedService.askingPrice)) : selectedService.askingPrice}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list icon-size-small">
                                                    <li><img src="./images/location.png" alt="icon" /><span>{selectedService.city}</span></li>
                                                    <li><img src="./images/user-o.png" alt="icon" /><span>{selectedService.name}</span></li>
                                                    <li><img src="./images/mail-o.png" alt="icon" /><span>{selectedService.email}</span></li>
                                                    <li><img src="./images/b-call1.png" alt="icon" /><span>{selectedService.phone}</span></li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="layout">
                                                    <dl className="list-terms-inline text-margin">
                                                        <dt className="list-item-bold ">Type:</dt>
                                                        <dd hidden={selectedService.servicetype !== "accounting"}><span class=""></span><span>Accounting</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "legal"}><span class=""></span><span>Legal</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "handyman"}><span class=""></span><span>Handyman</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "construction"}><span class=""></span><span>Cnstruction/Renovation</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "moving"}><span class=""></span><span>Moving</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "teaching"}><span class=""></span><span>Teaching</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "partnership"}><span class=""></span><span>Partnership</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "beauty"}><span class=""></span><span>Beauty</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "cleaning"}><span class=""></span><span>Cleaning</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "HHA"}><span class=""></span><span>HHA</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "housekeeping"}><span class=""></span><span>Housekeeping</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "other"}><span class=""></span><span>Other</span></dd>
                                                    </dl>
                                                </div>
                                                <div className="card-title "><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>{selectedService.subject}</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedService.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="card-body">
                                            <div className="layout">
                                                <dl className="list-terms-inline text-margin">
                                                    <dt>Asking price:</dt>
                                                    <dd>{selectedService.askingPrice}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <article>

                                        </article>
                                    </div>
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    <div hidden={userId !== selectedService.user._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editservicelisting/${selectedService._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    Edit listing
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "Are you sure you want to remove this listing? Once you do this, you can't undo it. If you had a paid subscription for that listing, you'll lose it too."
                                                    )
                                                        ? navigate(`/deleteservice/${selectedService._id}`)
                                                        : null
                                                }
                                            >
                                                Delete listing
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div>
                                    {/* <div className="block-group-item">
                                  <h3>Location on map</h3>
                                  <MapWrapper>
                                      <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedService} defaultCenter={selectedService}></Map>
                                  </MapWrapper>
                              </div> */}
                                    <div className="blog-post-solo-footer mt-20">
                                    </div>
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">Sally Gurtskaia</h3>
                                                <p className="block-person-cite">Customer success manager</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>Being a full time customer success manager for over 5 years has given Sally the opportunity to work with the most wonderful clients</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    Get in touch
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedService.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {languageToUse === "ru" && (
                <div>
                    <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                        <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                            <h2 className="breadcrumbs-custom-title">Подробности о предлагаемой услуге</h2>
                        </div>
                    </section>
                    <SubMenue product={product} />
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{parseInt(selectedService.askingPrice) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(parseInt(selectedService.askingPrice)) : selectedService.askingPrice}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list icon-size-small">
                                                    <li><img src="./images/location.png" alt="icon" /><span>{selectedService.city}</span></li>
                                                    <li><img src="./images/user-o.png" alt="icon" /><span>{selectedService.name}</span></li>
                                                    <li><img src="./images/mail-o.png" alt="icon" /><span>{selectedService.email}</span></li>
                                                    <li><img src="./images/b-call1.png" alt="icon" /><span>{selectedService.phone}</span></li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="layout">
                                                    <dl className="list-terms-inline text-margin">
                                                        <dt className="list-item-bold ">Категория:</dt>
                                                        <dd hidden={selectedService.servicetype !== "accounting"}><span class=""></span><span>Бухгалтерский учет</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "legal"}><span class=""></span><span>Юридический</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "handyman"}><span class=""></span><span>Разнорабочий</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "construction"}><span class=""></span><span>Строительство/Ремонт</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "moving"}><span class=""></span><span>Перевозки</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "teaching"}><span class=""></span><span>Обучение</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "partnership"}><span class=""></span><span>Партнерство</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "beauty"}><span class=""></span><span>Красота</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "cleaning"}><span class=""></span><span>Уборка</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "HHA"}><span class=""></span><span>HHA Домашнее здравоохранение</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "housekeeping"}><span class=""></span><span>Хаускипинг</span></dd>
                                                        <dd hidden={selectedService.servicetype !== "other"}><span class=""></span><span>Другие услуги</span></dd>
                                                    </dl>
                                                </div>
                                                <div className="card-title "><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>{selectedService.subject}</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedService.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <div className="card-body">
                                            <div className="layout">
                                                <dl className="list-terms-inline text-margin">
                                                    <dt>Цена:</dt>
                                                    <dd>{selectedService.askingPrice}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <article>

                                        </article>
                                    </div>
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    <div hidden={userId !== selectedService.user._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editservicelisting/${selectedService._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    Редактировать объявление
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "Вы уверены, что хотите удалить это объявление? Сделав это, вы не сможете отменить это. Если у вас была платная подписка на этот список, вы ее тоже потеряете."
                                                    )
                                                        ? navigate(`/deleteservice/${selectedService._id}`)
                                                        : null
                                                }
                                            >
                                                Удалить объявление
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div>
                                    {/* <div className="block-group-item">
                                  <h3>Location on map</h3>
                                  <MapWrapper>
                                      <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedService} defaultCenter={selectedService}></Map>
                                  </MapWrapper>
                              </div> */}
                                    <div className="blog-post-solo-footer mt-20">
                                    </div>
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">Салли Гурцкая</h3>
                                                <p className="block-person-cite">Менеджер по работе с клиентами</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>Будучи менеджером по работе с клиентами на протяжении более 5 лет, Салли получила возможность работать с самыми замечательными клиентами.</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    Связаться
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedService.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ServiceDetails;