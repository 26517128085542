// import React, { useState, useEffect } from "react";
// import Slider from 'react-slick';
// import FavorateButton from "./favorateButton";
// import backendUrl from './config';
// import { Link } from 'react-router-dom';
// import { useLanguage } from './languageContext';

// function Property(props) {
//   const product = "realestate";
//   const { currentLanguage } = useLanguage();
//   const [responseMessage, setResponseMessage] = useState("");
//   const [imagePaths, setImagePaths] = useState([]);


//   useEffect(() => {
//     const fetchPhotos = async () => {
//       setImagePaths([]);
//       // Use a Set to store unique URLs
//       const uniqueImagePaths = new Set();
//       // Receive and add each image URL to the imagePaths array
//       for (let i = 0; i < props.img.length; i++) {
//         try {
//           const response = await fetch(`${backendUrl}/api/photos/${product}/${props.listingId}/${i}`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//               // Add any additional headers if needed
//             },
//             credentials: 'include', // Include credentials in the request
//           });
//           if (response.ok) {
//             const contentType = response.headers.get('content-type');

//             if (contentType && contentType.includes('application/json')) {
//               const data = await response.json();
//               setResponseMessage(data.message);
//             } else {
//               const blob = await response.blob();
//               const imgUrl = URL.createObjectURL(blob);

//               // Add the unique URL to the set
//               uniqueImagePaths.add(imgUrl);
//             }
//           } else {
//             setResponseMessage("Error fetching photos. Response failed.");
//           }
//         } catch (error) {
//           console.error("Error fetching photos:", error);
//           setResponseMessage("Error fetching photos.");
//           console.log(responseMessage);
//         }
//       }
//       setImagePaths([...uniqueImagePaths]);
//     };

//     fetchPhotos();
//   }, [props.listingId, props.img, responseMessage]);

//   // Add props.listingId as a dependency to refetch when the ID changes

//   // slider
//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1
//   };

//   // slider
//   return (<div>
//     {currentLanguage === "ge" && (
//       <div className="card shadow-sm g-1 listingitem-card">
//         <div className='card-upper-body'>
//           <div className="slick-slider-1">
//             <div className="slick-slider-price">
//               <div hidden={props.subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(props.askingPrice)}</span></div>
//               {/* <div hidden={props.subscriptionStatus === "active" || props.subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={props.lisingtype === "listings"} href={`${backendUrl}/process-payment/` + props.listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
//               <div hidden={props.subscriptionStatus === "active"} className=""><span> <Link to={`${backendUrl}/process-payment/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>გამოქვეყნება</Link></span></div>
//             </div>
//           </div>
//           <div className="image-slider-wrapper">
//             <Link to={`/item/` + props.listingId}>
//               <Slider {...settings}>
//                 {imagePaths && imagePaths.length > 0 ? (
//                   imagePaths.map((image, index) => (
//                     <img
//                       key={index}
//                       src={image}
//                       alt={`Item ${index}`}
//                       style={{ width: '100%', maxHeight: '300px' }}
//                     />
//                   ))
//                 ) : (
//                   <img src="./images/ser-r.png"
//                     alt="Default Item"
//                     style={{ width: '100%', maxHeight: '300px' }} />
//                 )}
//               </Slider>
//             </Link>
//           </div>
//         </div>
//         <div className="card-body">
//           <span className="tag">{props.dealtype}</span>
//           <div className="temple">
//             <ul className="location">
//               <li>
//                 <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{props.address}</span>
//                 <span className="like-button"><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span>
//               </li>
//             </ul>
//             {props.dealtype === "forSaleAsMortgageAsset" && (
//               <ul className="feature-list">
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.monthlyPayment)}</span>
//                 </li>
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.remaningPrinciple)}</span>
//                 </li>
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{props.interestRate} %</span>
//                 </li>
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {props.remaningAmortizationPeriod} months</span>
//                 </li>
//               </ul>
//             )}
//             <ul className="price-list-val">
//               <li hidden={props.numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />{props.numberOfBedrooms} ოთ</li>
//               <li hidden={props.numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />სტუდიო </li>
//               <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.numberOfBathrooms}  აბ</li>
//               <li hidden={!props.garages} ><img src="./images/g-o.png" alt="" style={{ width: '28px', height: '28px' }} />{props.garages} აფ</li>
//               <li><img src="./images/s-o.png" alt="" style={{ width: '28px', height: '28px' }} /> {props.area} კვ.ფ</li>
//             </ul>
//           </div>
//         </div >
//       </div >
//     )}
//     {currentLanguage === "en" && (
//       <div>
//         <div className="card shadow-sm g-1 listingitem-card">
//           <div className='card-upper-body'>
//             <div className="slick-slider-1">
//               <div className="slick-slider-price">
//                 <div hidden={props.subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(props.askingPrice)}</span></div>
//                 {/* <div hidden={props.subscriptionStatus === "active" || props.subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={props.lisingtype === "listings"} href={`${backendUrl}/process-payment/` + props.listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
//                 <div hidden={props.subscriptionStatus === "active"} className=""><span> <Link to={`${backendUrl}/process-payment/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</Link></span></div>
//               </div>
//             </div>
//             <div className="image-slider-wrapper">
//               <Link to={`/item/` + props.listingId}>
//                 <Slider {...settings}>
//                   {imagePaths && imagePaths.length > 0 ? (
//                     imagePaths.map((image, index) => (
//                       <img
//                         key={index}
//                         src={image}
//                         alt={`Item ${index}`}
//                         className="image-slider-item"
//                         style={{ width: '100%', maxHeight: '300px' }}
//                       />
//                     ))
//                   ) : (
//                     <img src="./images/ser-r.png"
//                       alt="Default Item"
//                       className="image-slider-item"
//                       style={{ width: '100%', maxHeight: '300px' }}
//                     />
//                   )}
//                 </Slider>
//               </Link>
//             </div>
//           </div>
//           <div className="card-body">
//             <span className="tag">{props.dealtype}</span>
//             <div className="temple">
//               <ul className="location">
//                 <li>
//                   <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{props.address}</span>
//                   <span className="like-button"><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span>
//                 </li>
//               </ul>
//               {props.dealtype === "forSaleAsMortgageAsset" && (
//                 <ul className="feature-list">
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.monthlyPayment)}</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.remaningPrinciple)}</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{props.interestRate} %</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {props.remaningAmortizationPeriod} months</span>
//                   </li>
//                 </ul>
//               )}
//               <ul className="price-list-val">
//                 <li hidden={props.numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.numberOfBedrooms} Br</li>
//                 <li hidden={props.numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />Studio </li>
//                 <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.numberOfBathrooms}  Ba</li>
//                 <li hidden={!props.garages} ><img src="./images/g-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.garages} Gr</li>
//                 <li><img src="./images/s-o.png" alt="" style={{ width: '30px', height: '30px' }} /> {props.area} Sqft</li>
//               </ul>
//             </div>
//           </div >
//         </div >
//       </div>
//     )}
//   </div>
//   )
// }
// export default Property;


// import React, { useState, useEffect } from "react";
// import Slider from 'react-slick';
// import FavorateButton from "./favorateButton";
// import backendUrl from './config';
// import { Link } from 'react-router-dom';
// import { useLanguage } from './languageContext';

// function Property(props) {
//   const product = "realestate";
//   const { currentLanguage } = useLanguage();
//   const [responseMessage, setResponseMessage] = useState("");
//   const [imagePaths, setImagePaths] = useState([]);

//   const handleSliderClick = () => {
//     props.toggleOverlay(props.listingId); // Call toggleOverlay with listingId
//   };

//   useEffect(() => {
//     const fetchPhotos = async () => {
//       setImagePaths([]);
//       // Use a Set to store unique URLs
//       const uniqueImagePaths = new Set();
//       // Receive and add each image URL to the imagePaths array
//       for (let i = 0; i < props.img.length; i++) {
//         try {
//           const response = await fetch(`${backendUrl}/api/photos/${product}/${props.listingId}/${i}`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//               // Add any additional headers if needed
//             },
//             credentials: 'include', // Include credentials in the request
//           });
//           if (response.ok) {
//             const contentType = response.headers.get('content-type');

//             if (contentType && contentType.includes('application/json')) {
//               const data = await response.json();
//               setResponseMessage(data.message);
//             } else {
//               const blob = await response.blob();
//               const imgUrl = URL.createObjectURL(blob);

//               // Add the unique URL to the set
//               uniqueImagePaths.add(imgUrl);
//             }
//           } else {
//             setResponseMessage("Error fetching photos. Response failed.");
//           }
//         } catch (error) {
//           console.error("Error fetching photos:", error);
//           setResponseMessage("Error fetching photos.");
//           console.log(responseMessage);
//         }
//       }
//       setImagePaths([...uniqueImagePaths]);
//     };

//     fetchPhotos();
//   }, [props.listingId, props.img, responseMessage]);

//   // Add props.listingId as a dependency to refetch when the ID changes

//   // slider
//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1
//   };

//   // slider
//   return (<div>
//     {currentLanguage === "ge" && (
//       <div className="card shadow-sm g-1 listingitem-card">
//         <div className='card-upper-body'>
//           <div className="slick-slider-1">
//             <div className="slick-slider-price">
//               <div hidden={props.subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(props.askingPrice)}</span></div>
//               {/* <div hidden={props.subscriptionStatus === "active" || props.subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={props.lisingtype === "listings"} href={`${backendUrl}/process-payment/` + props.listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
//               <div hidden={props.subscriptionStatus === "active"} className=""><span> <Link to={`${backendUrl}/process-payment/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>გამოქვეყნება</Link></span></div>
//             </div>
//           </div>
//           <div className="image-slider-wrapper" onClick={handleSliderClick}>
//             {/* <Link to={`/item/` + props.listingId}> */}
//             <Slider {...settings}>
//               {imagePaths && imagePaths.length > 0 ? (
//                 imagePaths.map((image, index) => (
//                   <img
//                     key={index}
//                     src={image}
//                     alt={`Item ${index}`}
//                     style={{ width: '100%', maxHeight: '300px' }}
//                   />
//                 ))
//               ) : (
//                 <img src="./images/ser-r.png"
//                   alt="Default Item"
//                   style={{ width: '100%', maxHeight: '300px' }} />
//               )}
//             </Slider>
//             {/* </Link> */}
//           </div>
//         </div>
//         <div className="card-body">
//           <span className="tag">{props.dealtype}</span>
//           <div className="temple">
//             <ul className="location">
//               <li>
//                 <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{props.address}</span>
//                 <span className="like-button"><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span>
//               </li>
//             </ul>
//             {props.dealtype === "forSaleAsMortgageAsset" && (
//               <ul className="feature-list">
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.monthlyPayment)}</span>
//                 </li>
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.remaningPrinciple)}</span>
//                 </li>
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{props.interestRate} %</span>
//                 </li>
//                 <li>
//                   <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {props.remaningAmortizationPeriod} months</span>
//                 </li>
//               </ul>
//             )}
//             <ul className="price-list-val">
//               <li hidden={props.numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />{props.numberOfBedrooms} ოთ</li>
//               <li hidden={props.numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />სტუდიო </li>
//               <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.numberOfBathrooms}  აბ</li>
//               <li hidden={!props.garages} ><img src="./images/g-o.png" alt="" style={{ width: '28px', height: '28px' }} />{props.garages} აფ</li>
//               <li><img src="./images/s-o.png" alt="" style={{ width: '28px', height: '28px' }} /> {props.area} კვ.ფ</li>
//             </ul>
//           </div>
//         </div >
//       </div >
//     )}
//     {currentLanguage === "en" && (
//       <div>
//         <div className="card shadow-sm g-1 listingitem-card">
//           <div className='card-upper-body'>
//             <div className="slick-slider-1">
//               <div className="slick-slider-price">
//                 <div hidden={props.subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(props.askingPrice)}</span></div>
//                 {/* <div hidden={props.subscriptionStatus === "active" || props.subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={props.lisingtype === "listings"} href={`${backendUrl}/process-payment/` + props.listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
//                 <div hidden={props.subscriptionStatus === "active"} className=""><span> <Link to={`${backendUrl}/process-payment/${props.listingId}`} className="" hidden={props.lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</Link></span></div>
//               </div>
//             </div>
//             <div className="image-slider-wrapper" onClick={handleSliderClick}>
//               {/* <Link to={`/item/` + props.listingId}> */}
//               <Slider {...settings}>
//                 {imagePaths && imagePaths.length > 0 ? (
//                   imagePaths.map((image, index) => (
//                     <img
//                       key={index}
//                       src={image}
//                       alt={`Item ${index}`}
//                       className="image-slider-item"
//                       style={{ width: '100%', maxHeight: '300px' }}
//                     />
//                   ))
//                 ) : (
//                   <img src="./images/ser-r.png"
//                     alt="Default Item"
//                     className="image-slider-item"
//                     style={{ width: '100%', maxHeight: '300px' }}
//                   />
//                 )}
//               </Slider>
//               {/* </Link> */}
//             </div>
//           </div>
//           <div className="card-body">
//             <span className="tag">{props.dealtype}</span>
//             <div className="temple">
//               <ul className="location">
//                 <li>
//                   <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{props.address}</span>
//                   <span className="like-button"><FavorateButton listingId={props.listingId} product={product} isFavoriteBySelection={false} /></span>
//                 </li>
//               </ul>
//               {props.dealtype === "forSaleAsMortgageAsset" && (
//                 <ul className="feature-list">
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.monthlyPayment)}</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(props.remaningPrinciple)}</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{props.interestRate} %</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {props.remaningAmortizationPeriod} months</span>
//                   </li>
//                 </ul>
//               )}
//               <ul className="price-list-val">
//                 <li hidden={props.numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.numberOfBedrooms} Br</li>
//                 <li hidden={props.numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />Studio </li>
//                 <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.numberOfBathrooms}  Ba</li>
//                 <li hidden={!props.garages} ><img src="./images/g-o.png" alt="" style={{ width: '30px', height: '30px' }} />{props.garages} Gr</li>
//                 <li><img src="./images/s-o.png" alt="" style={{ width: '30px', height: '30px' }} /> {props.area} Sqft</li>
//               </ul>
//             </div>
//           </div >
//         </div >
//       </div>
//     )}
//   </div>
//   )
// }
// export default Property;


// import React, { useState, useEffect } from "react";
// import Slider from 'react-slick';
// import FavorateButton from "./favorateButton";
// import backendUrl from './config';
// import { Link } from 'react-router-dom';
// import { useLanguage } from './languageContext';

// function Property({ listingId, toggleItemDetails, img, subscriptionStatus, dealtype, monthlyPayment, askingPrice, lisingtype, address, remaningPrinciple, interestRate, remaningAmortizationPeriod, numberOfBedrooms, numberOfBathrooms, garages, area }) {
//   const product = "realestate";
//   const { currentLanguage } = useLanguage();
//   const [responseMessage, setResponseMessage] = useState("");
//   const [imagePaths, setImagePaths] = useState([]);

//   useEffect(() => {
//     const fetchPhotos = async () => {
//       setImagePaths([]);
//       const uniqueImagePaths = new Set();
//       for (let i = 0; i < img.length; i++) {
//         try {
//           const response = await fetch(`${backendUrl}/api/photos/${product}/${listingId}/${i}`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             credentials: 'include',
//           });
//           if (response.ok) {
//             const contentType = response.headers.get('content-type');
//             if (contentType && contentType.includes('application/json')) {
//               const data = await response.json();
//               setResponseMessage(data.message);
//             } else {
//               const blob = await response.blob();
//               const imgUrl = URL.createObjectURL(blob);
//               uniqueImagePaths.add(imgUrl);
//             }
//           } else {
//             setResponseMessage("Error fetching photos. Response failed.");
//           }
//         } catch (error) {
//           console.error("Error fetching photos:", error);
//           setResponseMessage("Error fetching photos.");
//         }
//       }
//       setImagePaths([...uniqueImagePaths]);
//     };
//     fetchPhotos();
//   }, [listingId, img]);

//   // Define settings for the slider
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1
//   };

//   return (
//     <div>
//       {currentLanguage === "ge" && (
//         <div className="card shadow-sm g-1 listingitem-card">
//           <div className='card-upper-body'>
//             <div className="slick-slider-1">
//               <div className="slick-slider-price">
//                 <div hidden={subscriptionStatus !== "active"}><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(askingPrice)}</span></div>
//                 <div hidden={subscriptionStatus === "active"}><span><Link to={`${backendUrl}/process-payment/${listingId}`} hidden={lisingtype === "listings"} style={{ color: 'white' }}>გამოქვეყნება</Link></span></div>
//               </div>
//             </div>
//             <div className="image-slider-wrapper" onClick={() => toggleItemDetails(listingId)}>
//               <Slider {...settings}>
//                 {imagePaths && imagePaths.length > 0 ? (
//                   imagePaths.map((image, index) => (
//                     <img
//                       key={index}
//                       src={image}
//                       alt={`Item ${index}`}
//                       style={{ width: '100%', maxHeight: '300px' }}
//                     />
//                   ))
//                 ) : (
//                   <img
//                     src="./images/ser-r.png"
//                     alt="Default Item"
//                     style={{ width: '100%', maxHeight: '300px' }}
//                   />
//                 )}
//               </Slider>
//             </div>
//           </div>
//           <div className="card-body">
//             <span className="tag">{dealtype}</span>
//             <div className="temple">
//               <ul className="location">
//                 <li>
//                   <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{address}</span>
//                   <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
//                 </li>
//               </ul>
//               {dealtype === "forSaleAsMortgageAsset" && (
//                 <ul className="feature-list">
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(monthlyPayment)}</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(remaningPrinciple)}</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{interestRate} %</span>
//                   </li>
//                   <li>
//                     <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {remaningAmortizationPeriod} months</span>
//                   </li>
//                 </ul>
//               )}
//               <ul className="price-list-val">
//                 <li hidden={numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />{numberOfBedrooms} ოთ</li>
//                 <li hidden={numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />სტუდიო </li>
//                 <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBathrooms}  აბ</li>
//                 <li hidden={!garages} ><img src="./images/g-o.png" alt="" style={{ width: '28px', height: '28px' }} />{garages} აფ</li>
//                 <li><img src="./images/s-o.png" alt="" style={{ width: '28px', height: '28px' }} /> {area} კვ.ფ</li>
//               </ul>
//             </div>
//           </div >
//         </div >
//       )}
//       {currentLanguage === "en" && (
//         <div>
//           <div className="card shadow-sm g-1 listingitem-card">
//             <div className='card-upper-body'>
//               <div className="slick-slider-1">
//                 <div className="slick-slider-price">
//                   <div hidden={subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(askingPrice)}</span></div>
//                   {/* <div hidden={props.subscriptionStatus === "active" || props.subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={props.lisingtype === "listings"} href={`${backendUrl}/process-payment/` + props.listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
//                   <div hidden={subscriptionStatus === "active"} className=""><span> <Link to={`${backendUrl}/process-payment/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</Link></span></div>
//                 </div>
//               </div>
//               <div className="image-slider-wrapper" onClick={() => toggleItemDetails(listingId)}>
//                 {/* <Link to={`/item/` + props.listingId}> */}
//                 <Slider {...settings}>
//                   {imagePaths && imagePaths.length > 0 ? (
//                     imagePaths.map((image, index) => (
//                       <img
//                         key={index}
//                         src={image}
//                         alt={`Item ${index}`}
//                         style={{ width: '100%', maxHeight: '300px' }}
//                       />
//                     ))
//                   ) : (
//                     <img
//                       src="./images/ser-r.png"
//                       alt="Default Item"
//                       style={{ width: '100%', maxHeight: '300px' }}
//                     />
//                   )}
//                 </Slider>
//                 {/* </Link> */}
//               </div>
//             </div>
//             <div className="card-body">
//               <span className="tag">{dealtype}</span>
//               <div className="temple">
//                 <ul className="location">
//                   <li>
//                     <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{address}</span>
//                     <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
//                   </li>
//                 </ul>
//                 {dealtype === "forSaleAsMortgageAsset" && (
//                   <ul className="feature-list">
//                     <li>
//                       <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(monthlyPayment)}</span>
//                     </li>
//                     <li>
//                       <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(remaningPrinciple)}</span>
//                     </li>
//                     <li>
//                       <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{interestRate} %</span>
//                     </li>
//                     <li>
//                       <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {remaningAmortizationPeriod} months</span>
//                     </li>
//                   </ul>
//                 )}
//                 <ul className="price-list-val">
//                   <li hidden={numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBedrooms} Br</li>
//                   <li hidden={numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />Studio </li>
//                   <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBathrooms}  Ba</li>
//                   <li hidden={!garages} ><img src="./images/g-o.png" alt="" style={{ width: '30px', height: '30px' }} />{garages} Gr</li>
//                   <li><img src="./images/s-o.png" alt="" style={{ width: '30px', height: '30px' }} /> {area} Sqft</li>
//                 </ul>
//               </div>
//             </div >
//           </div >
//         </div>
//       )}
//     </div>
//   )
// }
// export default Property;


import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import FavorateButton from "./favorateButton";
import backendUrl from './config';
import { Link as RouterLink } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll';
import { useLanguage } from './languageContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Property({ listingId, onClick, img, subscriptionStatus, dealtype, monthlyPayment, askingPrice, lisingtype, address, remaningPrinciple, interestRate, remaningAmortizationPeriod, numberOfBedrooms, numberOfBathrooms, garages, area }) {
  const product = "realestate";
  const { currentLanguage } = useLanguage();
  const [responseMessage, setResponseMessage] = useState("");
  const [imagePaths, setImagePaths] = useState([]);

  const storedLanguage = sessionStorage.getItem('currentLanguage');
  const languageToUse = storedLanguage || currentLanguage;

  const handlePropertyClick = () => {
    onClick(listingId); // Pass the listingId to onClick
  };

  const openInNewWindow = () => {
    const url = `/item/` + listingId;
    window.open(url);
  };

  useEffect(() => {
    const fetchPhotos = async () => {
      setImagePaths([]);
      // Use a Set to store unique URLs
      const uniqueImagePaths = new Set();
      // Receive and add each image URL to the imagePaths array
      for (let i = 0; i < img.length; i++) {
        try {
          const response = await fetch(`${backendUrl}/api/photos/${product}/${listingId}/${i}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Add any additional headers if needed
            },
            credentials: 'include', // Include credentials in the request
          });
          if (response.ok) {
            const contentType = response.headers.get('content-type');

            if (contentType && contentType.includes('application/json')) {
              const data = await response.json();
              setResponseMessage(data.message);
            } else {
              const blob = await response.blob();
              const imgUrl = URL.createObjectURL(blob);

              // Add the unique URL to the set
              uniqueImagePaths.add(imgUrl);
            }
          } else {
            setResponseMessage("Error fetching photos. Response failed.");
          }
        } catch (error) {
          console.error("Error fetching photos:", error);
          setResponseMessage("Error fetching photos.");
          console.log(responseMessage);
        }
      }
      setImagePaths([...uniqueImagePaths]);
    };

    fetchPhotos();
  }, [listingId, img, responseMessage]);

  // Add props.listingId as a dependency to refetch when the ID changes

  // slider
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set the autoplay speed in milliseconds (e.g., 2000ms = 2 seconds)
  };

  // slider
  return (<div>
    {languageToUse === "ge" && (
      <div className="card shadow-sm g-1 listingitem-card" >
        <div className='card-upper-body'>
          <div className="slick-slider-1">
            <div className="slick-slider-price">
              <div hidden={subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(askingPrice)}</span></div>
              {/* <div hidden={subscriptionStatus === "active" || subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={lisingtype === "listings"} href={`${backendUrl}/process-payment/` + listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
              <div hidden={subscriptionStatus === "active"} className=""><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>გამოქვეყნება</RouterLink ></span></div>
            </div>
          </div>
          {/* <div className="image-slider-wrapper" onClick={() => handlePropertyClick(listingId)}> */}
          <ScrollLink
            className=""
            to="listingItemCard"
            smooth={true}
            duration={5}
          >
            <div className="image-slider-wrapper" onClick={handlePropertyClick} style={{ cursor: 'pointer' }}> {/* Pass the listingId to handlePropertyClick */}
              {/* <Link to={`/item/` + props.listingId}> */}
              <Slider {...settings}>
                {imagePaths && imagePaths.length > 0 ? (
                  imagePaths.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Item ${index}`}
                      style={{ width: '100%', maxHeight: '300px' }}
                    />
                  ))
                ) : (
                  <img src="./images/ser-r.png"
                    alt="Default Item"
                    style={{ width: '100%', maxHeight: '300px' }} />
                )}
              </Slider>
              {/* </Link> */}
            </div>
          </ScrollLink>
        </div>
        <div className="card-body">
          <span className="tag">{dealtype}</span>
          <div className="temple">
            <ul className="location">
              <li>
                <div style={{ display: "flex" }}>
                  <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{address}</span>
                  <div style={{ position: 'absolute', right: '1px', margin: '1px', height: "24px", width: "24px", cursor: "pointer" }} onClick={openInNewWindow}><img src="./images/openinnewwindow.png" /></div>
                </div>
                <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
              </li>
            </ul>
            {dealtype === "forSaleAsMortgageAsset" && (
              <ul className="feature-list">
                <li>
                  <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(monthlyPayment)}</span>
                </li>
                <li>
                  <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(remaningPrinciple)}</span>
                </li>
                <li>
                  <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{interestRate} %</span>
                </li>
                <li>
                  <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {remaningAmortizationPeriod} months</span>
                </li>
              </ul>
            )}
            <ul className="price-list-val">
              <li hidden={numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />{numberOfBedrooms} ოთ</li>
              <li hidden={numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '28px', height: '28px' }} />სტუდიო </li>
              <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBathrooms}  აბ</li>
              <li hidden={!garages} ><img src="./images/g-o.png" alt="" style={{ width: '28px', height: '28px' }} />{garages} აფ</li>
              <li><img src="./images/s-o.png" alt="" style={{ width: '28px', height: '28px' }} /> {area} კვ.ფ</li>
            </ul>
          </div>
        </div >
      </div >
    )}
    {languageToUse === "en" && (
      <div>
        <div className="card shadow-sm g-1 listingitem-card">
          <div className='card-upper-body'>
            <div className="slick-slider-1">
              <div className="slick-slider-price">
                <div hidden={subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(askingPrice)}</span></div>
                {/* <div hidden={subscriptionStatus === "active" || subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={lisingtype === "listings"} href={`${backendUrl}/process-payment/` + listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
                <div hidden={subscriptionStatus === "active"} className=""><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>Subscribe</RouterLink ></span></div>
              </div>
            </div>
            <ScrollLink
              className=""
              to="listingItemCard"
              smooth={true}
              duration={5}
            >
              <div className="image-slider-wrapper" onClick={handlePropertyClick} style={{ cursor: 'pointer' }}>
                {/* <Link to={`/item/` + props.listingId}> */}
                <Slider {...settings}>
                  {imagePaths && imagePaths.length > 0 ? (
                    imagePaths.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Item ${index}`}
                        style={{ width: '100%', maxHeight: '300px' }}
                      />
                    ))
                  ) : (
                    <img src="./images/ser-r.png"
                      alt="Default Item"
                      style={{ width: '100%', maxHeight: '300px' }} />
                  )}
                </Slider>
                {/* </Link> */}
              </div>
            </ScrollLink>
          </div>
          <div className="card-body">
            <span className="tag">{dealtype}</span>
            <div className="temple">
              <ul className="location">
                <li>
                  <div style={{ display: "flex" }}>
                    <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{address}</span>
                    <div style={{ position: 'absolute', right: '1px', margin: '1px', height: "24px", width: "24px", cursor: "pointer" }} onClick={openInNewWindow}><img src="./images/openinnewwindow.png" /></div>
                  </div>
                  <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
                </li>
              </ul>
              {dealtype === "forSaleAsMortgageAsset" && (
                <ul className="feature-list">
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(monthlyPayment)}</span>
                  </li>
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(remaningPrinciple)}</span>
                  </li>
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{interestRate} %</span>
                  </li>
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {remaningAmortizationPeriod} months</span>
                  </li>
                </ul>
              )}
              <ul className="price-list-val">
                <li hidden={numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBedrooms} Br</li>
                <li hidden={numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />Studio </li>
                <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBathrooms}  Ba</li>
                <li hidden={!garages} ><img src="./images/g-o.png" alt="" style={{ width: '30px', height: '30px' }} />{garages} Gr</li>
                <li><img src="./images/s-o.png" alt="" style={{ width: '30px', height: '30px' }} /> {area} Sqft</li>
              </ul>
            </div>
          </div >
        </div >
      </div>
    )
    }
    {languageToUse === "ru" && (
      <div>
        <div className="card shadow-sm g-1 listingitem-card">
          <div className='card-upper-body'>
            <div className="slick-slider-1">
              <div className="slick-slider-price">
                <div hidden={subscriptionStatus !== "active"} className=""><span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 6, useGrouping: true }).format(askingPrice)}</span></div>
                {/* <div hidden={subscriptionStatus === "active" || subscriptionStatus === "notRelevant"} className=""><span><a className="" hidden={lisingtype === "listings"} href={`${backendUrl}/process-payment/` + listingId} style={{ color: 'white' }}>Subscribe</a></span></div> */}
                <div hidden={subscriptionStatus === "active"} className=""><span> <RouterLink to={`/process-payment/${product}/${listingId}`} className="" hidden={lisingtype === "listings"} style={{ color: 'white' }}>Подписаться</RouterLink ></span></div>
              </div>
            </div>
            <ScrollLink
              className=""
              to="listingItemCard"
              smooth={true}
              duration={5}
            >
              <div className="image-slider-wrapper" onClick={handlePropertyClick} style={{ cursor: 'pointer' }}>
                {/* <Link to={`/item/` + props.listingId}> */}
                <Slider {...settings}>
                  {imagePaths && imagePaths.length > 0 ? (
                    imagePaths.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Item ${index}`}
                        style={{ width: '100%', maxHeight: '300px' }}
                      />
                    ))
                  ) : (
                    <img src="./images/ser-r.png"
                      alt="Default Item"
                      style={{ width: '100%', maxHeight: '300px' }} />
                  )}
                </Slider>
                {/* </Link> */}
              </div>
            </ScrollLink>
          </div>
          <div className="card-body">
            <span className="tag">{dealtype}</span>
            <div className="temple">
              <ul className="location">
                <li>
                  <div style={{ display: "flex" }}>
                    <img src={"./images/location.png"} alt="location png" style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '16px' }}>{address}</span>
                    <div style={{ position: 'absolute', right: '1px', margin: '1px', height: "24px", width: "24px", cursor: "pointer" }} onClick={openInNewWindow}><img src="./images/openinnewwindow.png" /></div>
                  </div>
                  <span className="like-button"><FavorateButton listingId={listingId} product={product} isFavoriteBySelection={false} /></span>
                </li>
              </ul>
              {dealtype === "forSaleAsMortgageAsset" && (
                <ul className="feature-list">
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Monthly payment:</span><span className="paymentSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(monthlyPayment)}</span>
                  </li>
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Remaining principle:</span><span className="principleSpan" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(remaningPrinciple)}</span>
                  </li>
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Interest rate:</span><span className="rateSpan" >{interestRate} %</span>
                  </li>
                  <li>
                    <img src="" alt="" /><span style={{ fontWeight: '550' }}>Amortization period:</span><span className="periodSpan" > {remaningAmortizationPeriod} months</span>
                  </li>
                </ul>
              )}
              <ul className="price-list-val">
                <li hidden={numberOfBedrooms === 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBedrooms} Сп</li>
                <li hidden={numberOfBedrooms !== 0}><img src="./images/b-o.png" alt="" style={{ width: '30px', height: '30px' }} />Студия </li>
                <li><img src="./images/ba-o.png" alt="" style={{ width: '30px', height: '30px' }} />{numberOfBathrooms}  Ва</li>
                <li hidden={!garages} ><img src="./images/g-o.png" alt="" style={{ width: '30px', height: '30px' }} />{garages} гр</li>
                <li><img src="./images/s-o.png" alt="" style={{ width: '30px', height: '30px' }} /> {area} кв.фут</li>
              </ul>
            </div>
          </div >
        </div >
      </div>
    )
    }
  </div >
  )
}
export default Property;