import React, { useState, useEffect } from "react";
import backendUrl from './config';
import { useLanguage } from './languageContext';

function ResetPassword({ isOpen, onClose }) {
    const { currentLanguage } = useLanguage();
    const [responseMessage, setResponseMessage] = useState('');
    const [username, setUsername] = useState('');
    const [inputValueVerificationCode, setInputValueVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [responseMessageShow, setResponseMessageShow] = useState(false);
    const [recoveryCodeSenderFormShow, setRecovwryCodeSenderFormShow] = useState(true);

    const [showPassword, setShowPassword] = useState(false);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const handleClosePopup = () => {
        setUsername('');
        setInputValueVerificationCode('');
        setNewPassword('');
        setResponseMessage('');
        setResponseMessageShow(false);
        onClose();
    };


    const handleSendRecoveryMessage = async () => {
        try {
            const response = await fetch(`${backendUrl}/recovery/resetpassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: username }),
            });
            if (response.ok) {
                const data = await response.json();
                setResponseMessage(data.message);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                    setRecovwryCodeSenderFormShow(false);
                }, 4000);
            } else {
                setResponseMessage("Error sending data to the server");
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            }
        } catch (error) {
            console.error("Error:", error);
            setResponseMessage("Error sending data to the server.");
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        }
    }

    const handleResetPassword = async () => {
        try {
            if (newPassword.length < 8) {
                setResponseMessageShow(true);
                setResponseMessage('Password must be at least 8 characters long');
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
                return;
            }
            const response = await fetch(`${backendUrl}/reset-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: username,
                    code: inputValueVerificationCode,
                    newPassword: newPassword
                }),
            });
            if (response.ok) {
                const data = await response.json();
                setResponseMessage(data);
                setTimeout(() => {
                    onClose();
                }, 4000);
            } else {
                setResponseMessage("Error sending data to the server");
            }
        } catch (error) {
            console.error("Error:", error);
            setResponseMessage("Error sending data to the server.");
        }
    };

    useEffect(() => {
        // Reset input fields and response message when component mounts
        setUsername('');
        setInputValueVerificationCode('');
        setNewPassword('');
        setResponseMessage('');
        setResponseMessageShow(false);

        return () => {
            // Reset input fields and response message when component unmounts
            setUsername('');
            setInputValueVerificationCode('');
            setNewPassword('');
            setResponseMessage('');
            setResponseMessageShow(false);
        };
    }, [isOpen]);

    const togglePasswordVisibility = (e) => {
        if (e.target.name === "passwordVisibility") {
            e.preventDefault(); // Prevent form submission
        }
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div>
            {isOpen && languageToUse === "ge" && (
                <div className="rd-navbar-popup bg-gray-12 active" id="navbar-login-register" style={{ fontSize: "14px", fontWeight: "500" }}>
                    <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="navbar-tabs-1">
                                <span className="close" onClick={handleClosePopup} style={{ cursor: 'pointer' }}>&times;</span>
                                <p>პასვორდის აღდგენა</p>
                                <div hidden={!recoveryCodeSenderFormShow} className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="email"
                                        placeholder="შეიყვანეთ თქვენი ელფოსტა"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <div className="form-wrap" style={{ fontWeight: "600", marginLeft: "2px", color: "#4b9ef1", fontSize: "12px" }}>
                                        <p hidden={!responseMessageShow}>{responseMessage === "Recovery code sent to your email" ? "პაროლი გამოგზავნილია თქვენს ელფოსტაზე" : ""}</p>
                                    </div>
                                    <button className="button button-sm button-primary button-block" onClick={handleSendRecoveryMessage}>პაროლის აღდგენის მოთხოვნა</button>
                                </div>
                                <div hidden={recoveryCodeSenderFormShow} className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="text"
                                        placeholder="ელფოსტაზე მიიღებული პაროლი"
                                        value={inputValueVerificationCode}
                                        onChange={(e) => setInputValueVerificationCode(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="შეიყვანეთ ახალი პასვორდი"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn-toggle-password"
                                        name="passwordVisibility"
                                        onClick={togglePasswordVisibility}
                                        style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                    >
                                        {!showPassword ? 'აჩვენე' : 'დაფარე'}
                                    </button>
                                    <button className="button button-sm button-primary button-block" onClick={handleResetPassword}>პასვორდის აღდგენა</button>
                                    {responseMessageShow && responseMessage && (
                                        <div>
                                            <p className="loginResponses" hidden={responseMessage !== "Password must be at least 8 characters long"}>{responseMessage === "Password must be at least 8 characters long" ? "პასვორდი მინიმუმ 8 სიმბოლოსგან უნდა შედგებოდეს" : ""}</p>
                                            <p className="loginResponses">{responseMessage === "Password reset successfully" ? "პასვორდი წარმატებით შეიცვალა" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Failed to reset password"}>{responseMessage === "Failed to reset password" ? "პასვორდის შეცვლა შეცდომით დასრულდა" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage === "Password reset successfully"}>{responseMessage === "Invalid verification code" ? "ვერიფიკაციის პაროლი არასწორია" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Error sending data to the server"}>{responseMessage === "Error sending data to the server" ? "მოთხოვნა არ გაიგზავნა" : ""}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOpen && languageToUse === "en" && (
                <div className="rd-navbar-popup bg-gray-12 active" id="navbar-login-register">
                    <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="navbar-tabs-1">
                                <span className="close" onClick={handleClosePopup} style={{ cursor: 'pointer' }}>&times;</span>
                                <p>Reset Password</p>
                                <div hidden={!recoveryCodeSenderFormShow} className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="email"
                                        placeholder="Enter your email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <div className="form-wrap" style={{ fontWeight: "600", marginLeft: "2px", color: "#4b9ef1", fontSize: "12px" }}>
                                        <p hidden={!responseMessageShow}>{responseMessage}</p>
                                    </div>
                                    <button className="button button-sm button-primary button-block" onClick={handleSendRecoveryMessage}>Send Recovery Message</button>
                                </div>
                                <div hidden={recoveryCodeSenderFormShow} className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="text"
                                        placeholder="Enter the code sent to your email"
                                        value={inputValueVerificationCode}
                                        onChange={(e) => setInputValueVerificationCode(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn-toggle-password"
                                        name="passwordVisibility"
                                        onClick={togglePasswordVisibility}
                                        style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                    >
                                        {!showPassword ? 'hide' : 'show'}
                                    </button>
                                    <button className="button button-sm button-primary button-block" onClick={handleResetPassword}>Reset password</button>
                                    {responseMessageShow && responseMessage && (
                                        <div>
                                            <p className="loginResponses">{responseMessage}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOpen && languageToUse === "ru" && (
                <div className="rd-navbar-popup bg-gray-12 active" id="navbar-login-register" style={{ fontSize: "14px", fontWeight: "500" }}>
                    <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="navbar-tabs-1">
                                <span className="close" onClick={handleClosePopup} style={{ cursor: 'pointer' }}>&times;</span>
                                <p>Восстановление пароля</p>
                                <div hidden={!recoveryCodeSenderFormShow} className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="email"
                                        placeholder="Введите адрес электронной почты"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <div className="form-wrap" style={{ fontWeight: "600", marginLeft: "2px", color: "#4b9ef1", fontSize: "12px" }}>
                                        <p hidden={!responseMessageShow}>{responseMessage === "Recovery code sent to your email" ? "Пароль был отправлен на вашу электронную почту" : ""}</p>
                                    </div>
                                    <button className="button button-sm button-primary button-block" onClick={handleSendRecoveryMessage}>Запрос на восстановление пароля</button>
                                </div>
                                <div hidden={recoveryCodeSenderFormShow} className="form-wrap">
                                    <input
                                        className="form-input"
                                        type="text"
                                        placeholder="Пароль получен по электронной почте"
                                        value={inputValueVerificationCode}
                                        onChange={(e) => setInputValueVerificationCode(e.target.value)}
                                    />
                                    <input
                                        className="form-input"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Введите новый пароль"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn-toggle-password"
                                        name="passwordVisibility"
                                        onClick={togglePasswordVisibility}
                                        style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                    >
                                        {!showPassword ? 'показать' : 'спрятать'}
                                    </button>
                                    <button className="button button-sm button-primary button-block" onClick={handleResetPassword}>Восстановление пароля</button>
                                    {responseMessageShow && responseMessage && (
                                        <div>
                                            <p className="loginResponses" hidden={responseMessage !== "Password must be at least 8 characters long"}>{responseMessage === "Password must be at least 8 characters long" ? "Пароль должен состоять как минимум из 8 символов" : ""}</p>
                                            <p className="loginResponses">{responseMessage === "Password reset successfully" ? "Пароль успешно изменен" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Failed to reset password"}>{responseMessage === "Failed to reset password" ? "Сменить пароль не удалось" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage === "Password reset successfully"}>{responseMessage === "Invalid verification code" ? "Пароль проверки неверен" : ""}</p>
                                            <p className="loginResponses" hidden={responseMessage !== "Error sending data to the server"}>{responseMessage === "Error sending data to the server" ? "Запрос не отправлен" : ""}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ResetPassword;