import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import Loader from "./loader";
// import { State, City } from 'country-state-city';
import { State, City } from 'country-state-city';
import backendUrl from './config';
import { useAuth } from './authContext';
import 'react-datepicker/dist/react-datepicker.css';
import { useLanguage } from './languageContext';
import ColorPicker from "./colorPicker";
import VinDecoder from "./VINdecoder";
import VehicleMakeSelector from "./vehicleMakeSelector";
import VehicleModelSelector from "./vehicleModelSelector";
import SubMenue from "./subMenue";


function VehicleListing() {
    const product = "vehicle";
    const { currentLanguage } = useLanguage();
    const [loading, setLoading] = useState(true);

    const [forSale, setForSale] = useState(false);
    const [forRent, setForRent] = useState(false);

    const [inputValueDealType, setInputValueDealType] = useState("");
    const [inputValueVIN, setInputValueVIN] = useState("");
    const [inputValueCity, setInputCity] = useState("");
    const [inputValueState, setInputValueState] = useState("");
    const [inputValueZip, setInputValueZip] = useState("");
    const [inputValueMake, setInputValueMake] = useState("");
    const [inputValueModel, setInputValueModel] = useState("");
    const [inputValueYear, setInputValueYear] = useState("");
    const [inputValueTransmission, setInputValueTransmission] = useState("");
    const [inputValueEngine, setInputValueEngine] = useState("");
    // const [inputValueNumberofcylinders, setInputValueNumberofcylinders] = useState("");
    // const [inputValueNumberofdoors, setInputValueNumberofdoors] = useState("");
    const [inputValueFuel, setInputValueFuel] = useState("");
    const [inputValueMileage, setInputValueMileage] = useState("");
    const [inputValueExteriorcolor, setInputValueExteriorcolor] = useState("");
    const [inputValueInteriorcolor, setInputValueInteriorcolor] = useState("");
    const [inputValueInterior, setInputValueInterior] = useState("");
    const [inputValueAccident, setInputValueAccident] = useState("");
    const [inputValueCondition, setInputValueCondition] = useState("");
    const [inputValueTires, setInputValueTires] = useState("");
    const [inputValueMechanicaldefects, setInputValueMechanicaldefects] = useState(false);
    const [inputValueDamagespreventingdriving, setInputValueDamagespreventingdriving] = useState(false);
    const [inputValueExteriordamage, setInputValueExteriordamage] = useState(false);
    const [inputValueCrack, setInputValueCrack] = useState(false);
    const [inputValueNumberofkeys, setInputValueNumberofkeys] = useState("");
    const [inputValueOwnershipstatus, setInputValueOwnershipstatus] = useState("");
    const [inputValueEmail, setInputValueEmail] = useState("");
    const [inputValuePhone, setInputValuePhone] = useState("");
    const [inputValueName, setInputValueName] = useState("");
    const [inputValueAskingPrice, setInputValueAskingPrice] = useState(0);
    const [inputValueComment, setInputValueComment] = useState("");

    const [images, setImages] = useState([]);
    const [responseMessage, setResponseMessage] = useState("");
    // const [userId, setUserId] = useState("");
    const [responseMessageShow, setResponseMessageShow] = useState(false);


    // sellect city
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);

    const { loggedIn, userId } = useAuth();
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Populate the state dropdown with options
        const countryStates = State.getStatesOfCountry('US');
        setStates(countryStates);
        // Only fetch data if the component is mounted
        let isMounted = true;

        const fetchData = async () => {
            try {
                // Check if the user is authenticated using the useAuth hook
                if (!loggedIn) {
                    navigate('/login');
                    return;
                }

                if (isMounted) {
                    setLoading(false);
                    // Get the user ID using the useAuth hook
                    // console.log('User ID:', userId);

                    // Additional logic if needed...
                }

            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle the error if needed
            }
        };

        fetchData();

        // Cleanup function to update the mounted status
        return () => {
            isMounted = false;
        };

    }, [loggedIn, userId, navigate]);


    if (loading) {
        return <Loader key={1} loading={loading} />;
    }

    const handleInputChangeDealType = (e) => {
        if (e.target.value === "forSale") {
            setForSale(true);
            setForRent(false);
        } else if (e.target.value === "forRent") {
            setForSale(false);
            setForRent(true);
        } else {
            alert("Please select a valid value! Is this vehicle for Sale or Rent?");
            return; // Prevent form submission
        }
        setInputValueDealType(e.target.value);
    };

    const handleInputChangeVIN = (e) => {
        setInputValueVIN(e.target.value);
    };

    const handleStateChange = (e) => {
        const newState = e.target.value;
        setInputValueState(newState);

        // Update the city dropdown based on the selected state
        const stateCities = City.getCitiesOfState('US', newState);
        setCities(stateCities);
    };
    const handleInputChangeCity = (e) => {
        setInputCity(e.target.value);
    };
    const handleInputChangeZip = (e) => {
        setInputValueZip(e.target.value);
    };

    const handleVinDecode = (make, model, year) => {
        setInputValueMake(make);
        setInputValueModel(model);
        setInputValueYear(year);
    };

    const handleInputChangeMake = (e) => {
        setInputValueMake(e.target.value);
    };
    const handleInputChangeModel = (e) => {
        setInputValueModel(e.target.value);
    };
    const handleInputChangeYear = (e) => {
        setInputValueYear(e.target.value);
    };
    const handleInputChangeTransmission = (e) => {
        setInputValueTransmission(e.target.value);
    };
    const handleInputChangeEngine = (e) => {
        setInputValueEngine(e.target.value);
    };
    // const handleInputChangeNumberofcylinders = (e) => {
    //     setInputValueNumberofcylinders(e.target.value);
    // };
    // const handleInputChangeNumberofdoors = (e) => {
    //     setInputValueNumberofdoors(e.target.value);
    // };
    const handleInputChangeFuel = (e) => {
        setInputValueFuel(e.target.value);
    };
    const handleInputChangeMileage = (e) => {
        setInputValueMileage(e.target.value);
    };

    const handleInputChangeExteriorcolor = (value) => {
        setInputValueExteriorcolor(value);
    };

    const handleInputChangeInteriorcolor = (label) => {
        setInputValueInteriorcolor(label);
    };
    const handleInputChangeInterior = (e) => {
        setInputValueInterior(e.target.value);
    };
    const handleInputAccident = (e) => {
        setInputValueAccident(e.target.value);
    };
    const handleInputCondition = (e) => {
        setInputValueCondition(e.target.value);
    };
    const handleInputTires = (e) => {
        setInputValueTires(e.target.value);
    };
    const handleInputMechanicaldefects = (e) => {
        setInputValueMechanicaldefects(e.target.checked);
    };
    const handleInputDamagespreventingdriving = (e) => {
        setInputValueDamagespreventingdriving(e.target.checked);
    };
    const handleExteriordamage = (e) => {
        setInputValueExteriordamage(e.target.checked);
    };
    const handleCrack = (e) => {
        setInputValueCrack(e.target.checked);
    };
    const handleNumberofkeys = (e) => {
        setInputValueNumberofkeys(e.target.value);
    };
    const handleOwnershipstatus = (e) => {
        setInputValueOwnershipstatus(e.target.value);
    };
    const handleEmail = (e) => {
        setInputValueEmail(e.target.value);
    };
    const handlePhone = (e) => {
        setInputValuePhone(e.target.value);
    };
    const handleName = (e) => {
        setInputValueName(e.target.value);
    };
    const handleInputChangeAskingPrice = (e) => {
        setInputValueAskingPrice(e.target.value);
    };
    const handleComment = (e) => {
        setInputValueComment(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        if (selectedFiles.length > 3) {
            alert("You can only upload a maximum of three files at once, but you can add more when listing is saved or posted.");
            // Clear the file input
            e.target.value = null;
            return;
        }
        const filesArray = Array.from(selectedFiles);
        // Set the state to trigger a re-render
        setImages((prevImages) => [...prevImages, ...filesArray]);
    };

    const handleDeleteImage = (index, event) => {
        event.preventDefault();

        // Remove the image from the images array based on its index
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });

        // Reset the value of the file input element to clear the uploaded image(s)
        const fileInput = document.getElementById(languageToUse === 'ge' ? 'formFileGe' : 'formFileEn');
        if (fileInput) {
            fileInput.value = '';
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        images.forEach((file, index) => {
            formData.append(`files`, file);
        });
        formData.append('dealtype', inputValueDealType);
        formData.append('VIN', inputValueVIN);
        formData.append('city', inputValueCity);
        formData.append('state', inputValueState);
        formData.append('zip', inputValueZip);
        formData.append('make', inputValueMake);
        formData.append('model', inputValueModel);
        formData.append('year', inputValueYear);
        formData.append('transmission', inputValueTransmission);
        formData.append('engine', inputValueEngine);
        // formData.append('numberofcylinders', inputValueNumberofcylinders);
        // formData.append('numberofdoors', inputValueNumberofdoors);
        formData.append('fuel', inputValueFuel);
        formData.append('mileage', inputValueMileage);
        formData.append('exteriorcolor', inputValueExteriorcolor);
        formData.append('interiorcolor', inputValueInteriorcolor);
        formData.append('interior', inputValueInterior);
        formData.append('accident', inputValueAccident);
        formData.append('condition', inputValueCondition);
        formData.append('tires', inputValueTires);
        formData.append('damagespreventingdriving', inputValueDamagespreventingdriving);
        formData.append('exteriordamage', inputValueExteriordamage);
        formData.append('crack', inputValueCrack);
        formData.append('numberofkeys', inputValueNumberofkeys);
        formData.append('ownershipstatus', inputValueOwnershipstatus);
        formData.append('email', inputValueEmail);
        formData.append('phone', inputValuePhone);
        formData.append('name', inputValueName);
        formData.append('askingPrice', inputValueAskingPrice);
        formData.append('comment', inputValueComment);

        let vehicleListingId = "";
        try {
            // Send the data to the backend
            const response = await fetch(`${backendUrl}/vehiclelisting/${userId}`, {
                method: "POST",
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    // Add any additional headers if needed
                }
            });

            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const data = await response.json();
                    setResponseMessage(data.message);
                    vehicleListingId = data.vehicleListingId;
                    setImages([]);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                    // Additional actions for success
                } else {
                    // Handle the case where the response is not JSON
                    const errorMessage = await response.text();
                    setResponseMessage(`${errorMessage}`);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                }
                sendEmail({
                    from: inputValueEmail,
                    name: inputValueName,
                    phone: inputValuePhone,
                    subject: 'New listing from ' + inputValueName,
                    text: 'New listing, address ' + inputValueCity + inputValueState + inputValueZip,
                    itemId: vehicleListingId,
                    address: inputValueCity + inputValueState + inputValueZip,
                    product: 'Lising Vehicle',
                    activity: "Posting"
                });
                // navigate("/mylistings");
                navigate(`/process-payment/${product}/${vehicleListingId}`);
            } else {
                const errorMessage = await response.text();
                setResponseMessage(`${errorMessage}`);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            }
        } catch (error) {
            setResponseMessage("Error sending data to the server.");
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        }
    };

    // send email when property submitted


    async function sendEmail(data) {
        try {

            // Validate mandatory fields
            if (!inputValueCity || !inputValueState || !inputValueZip) {
                setResponseMessage("Please fill in all mandatory fields and upload at least one file.");
                return; // Stop further execution
            }

            const response = await fetch(`${backendUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                const responseData = await response.json();
                setResponseMessage(responseData.message);
            } else {
                const errorData = await response.json();
                setResponseMessage(`Error: ${errorData.message}`);
            }
        } catch (error) {
            setResponseMessage("Error sending data to the server.");
        }
    };

    // end send email

    // slider
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    // slider

    return <div>
        {languageToUse === "ge" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground1-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title">ავტომობილის გაყიდვა-გაქირავების შესახებ განცხადების ატვირთვა</h2>
                    </div>
                </section>
                {/* <section className="breadcrumbs-custom bg-image-addlisting context-dark" data-opacity="28">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">{product === "realestate" ? "უძრავი ქონების შესახებ განცხადების ატვირთვა" : ""}</h2>
                        <h2 className="breadcrumbs-custom-title">{product === "vehicle" ? "ავტომობილის გაყიდვა-გაქირავების შესახებ განცხადების ატვირთვა" : ""}</h2>
                    </div>
                </section> */}
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleSubmit} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/vehiclelisting">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>ავტომობილის საიდენტიფიკაციო ნომერი, მისამართი, ფასი</h3>
                                        <div className="row row-20 mt-20">

                                            <div className="col-md-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="VIN" type="text" name="VIN" placeholder="ავტომობილის საიდენტიფიკაციო ნომერი VIN" value={inputValueVIN} onChange={handleInputChangeVIN} />
                                                    <VinDecoder vin={inputValueVIN} onVinDecode={handleVinDecode} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="შტატი" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">შეარჩიეთ შტატი</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="ქალაქი" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>შეარჩიეთ ქალაქი</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" required placeholder="ზიპ კოდი" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="dealTypeGe" name="dealType" required value={inputValueDealType} onChange={handleInputChangeDealType}>
                                                        <option value="Any">გაყიდვა/გაქირავება</option>
                                                        <option value="forSale">გაყიდვა</option>
                                                        <option value="forRent">გაქირავება</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input hidden={!forSale} type="text" className="form-input" id="propertyPriceForSaleGe" name="property-price" required={forSale} data-constraints="@Required @Numeric" placeholder="ღირებულება დოლარებში" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="propertyPriceForRentGe" name="property-price" required={forRent} data-constraints="@Required @Numeric" placeholder="ქირის ღირებულება დოლარებში" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-1 section-1-start">
                                        <h3>ავტომობილის მოდელი</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-3">
                                                {/* <div className="input-group mb-3">
                                                    <input className="form-input" id="make" name="makeGe" required placeholder="მწარმოებელი" value={inputValueMake} onChange={handleInputChangeMake}>
                                                    </input>
                                                </div> */}
                                                <VehicleMakeSelector
                                                    inputValueMake={inputValueMake}
                                                    handleInputChangeMake={handleInputChangeMake}
                                                    setExternalValueMake={setInputValueMake} // Assume setExternalValueMake is a function to update external value
                                                />

                                            </div>
                                            <div className="col-md-3">
                                                {/* <div className="form-wrap">
                                                    <input className="form-input" name="model" required placeholder="მოდელი" value={inputValueModel} onChange={handleInputChangeModel}>
                                                    </input>
                                                </div> */}
                                                <VehicleModelSelector
                                                    inputValueModel={inputValueModel}
                                                    handleInputChangeModel={handleInputChangeModel}
                                                    externalValueModel={inputValueModel} // Use inputValueModel as the external value
                                                    setExternalValueModel={setInputValueModel} // Pass the function to handle model change
                                                    selectedMake={inputValueMake}
                                                    handleMakeChange={handleInputChangeModel}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="vehicleProductionYear" name="vehicleProductionYear" required placeholder="გამოშვების წელი" value={inputValueYear} onChange={handleInputChangeYear} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="interior" value={inputValueFuel} onChange={handleInputChangeFuel}>
                                                        <option value="Any">საწვავი</option>
                                                        <option value="gas">ბენზინი</option>
                                                        <option value="diesel">დიზელი</option>
                                                        <option value="electricity">ელექტრო</option>
                                                        <option value="hybrid">ჰიბრიდი ბენზინი</option>
                                                        <option value="other">სხვა</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input select-filter" name="transmission" placeholder="ტრანსმისია (არ არის სავალდებულო)" value={inputValueTransmission} onChange={handleInputChangeTransmission}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input select-filter" name="engine" placeholder="ძრავი (არ არის სავალდებულო)" value={inputValueEngine} onChange={handleInputChangeEngine}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input" name="mileage" placeholder="გარბენი მილებში" value={inputValueMileage} onChange={handleInputChangeMileage}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <ColorPicker onSelectColor={handleInputChangeExteriorcolor} placeholder={'ავტომანქანის ფერი'} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <ColorPicker onSelectColor={handleInputChangeInteriorcolor} placeholder={'სალონის ფერი'} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="interior" value={inputValueInterior} onChange={handleInputChangeInterior}>
                                                        <option value="Any">ტყავის/სხვა მასალის სკამები</option>
                                                        <option value="Leather">ტყავის სკამები</option>
                                                        <option value="NoLeather">ნაჭრის სკამები</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>ფოტოების ატვირთვა</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileGe" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>ფოტოს წაშლა</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>განცხადების ავტორი</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" required placeholder="თქვენი სახელი" data-constraints="@Required" onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="თქვენი იმეილი" data-constraints="@Email @Required" onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="თქვენი ტელეფონის ნომერი" autoComplete="tel" data-constraints="@PhoneNumber" onChange={handlePhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>დეტალური ინფორმაცია</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="accident" value={inputValueAccident} onChange={handleInputAccident}>
                                                        <option value="Any">არის თუ არა ნაავარიები</option>
                                                        <option value="noAccident">არ არის ნაავარიები</option>
                                                        <option value="oneAccident">არის ერთხელ ნაავრიები</option>
                                                        <option value="moreAccidents">არის მრავალჯერ ნაავარიები</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="condition" value={inputValueCondition} onChange={handleInputCondition}>
                                                        <option value="Any">მანქანის მდგომარეობა</option>
                                                        <option value="amazing">საუკეთესო მდგომარეობაში</option>
                                                        <option value="good">კარგ მდგომარეობაში</option>
                                                        <option value="rough">ცუდ მდგომარეობაში</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="tires" value={inputValueTires} onChange={handleInputTires}>
                                                        <option value="Any">საბურავების მდგომარეობა</option>
                                                        <option value="good">კარგ მდგომარეობაში</option>
                                                        <option value="mismatch">ყველა საბურავი ერთნაირი არაა</option>
                                                        <option value="replacementNeeded">საჭიროებს შეცვლას</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="numberofkeys" value={inputValueNumberofkeys} onChange={handleNumberofkeys}>
                                                        <option value="Any">გასაღებების რაოდენობა</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="ownershipstatus" value={inputValueOwnershipstatus} onChange={handleOwnershipstatus}>
                                                        <option value="Any">საკუთრების ფორმა</option>
                                                        <option value="owned">საკუთრებაში</option>
                                                        <option value="leased">ლიზინგი</option>
                                                        <option value="financed">ბანკის სესხი</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="section-1">
                                                <h3>დაზიანებები</h3>
                                                <div className="row row-20 mt-20">
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="mechanicaldefects" value={inputValueMechanicaldefects} type="checkbox" checked={inputValueMechanicaldefects} onChange={handleInputMechanicaldefects} />   სავალი ნაწილი დაზიანებულია
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="damagespreventingdriving" value={inputValueDamagespreventingdriving} type="checkbox" checked={inputValueDamagespreventingdriving} onChange={handleInputDamagespreventingdriving} />   დაზიანება - მანქანა ვერ გადაადგილდება
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="exteriordamage" value={inputValueExteriordamage} type="checkbox" checked={inputValueExteriordamage} onChange={handleExteriordamage} />   ექტერიერი დაზიანებულია
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="crack" value={inputValueCrack} type="checkbox" checked={inputValueCrack} onChange={handleCrack} />   ნაკაწრები
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <textarea className="form-input" id="formCommentGe" name="comment" placeholder="დამატებითი ინფორმაცია და კომენტარი (არ არის სავალდებულო)" onChange={handleComment}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">განცხადების ატვირთვა</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>გამოხმაურება: {responseMessage}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div >
        )
        }
        {languageToUse === "en" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground1-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title">List your vehichle</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleSubmit} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/vehiclelisting">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>VIN, location and price</h3>
                                        <div className="row row-20 mt-20">

                                            <div className="col-md-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="VIN" type="text" name="VIN" required placeholder="Vehicle Identification Number (VIN)" value={inputValueVIN} onChange={handleInputChangeVIN} />
                                                    <VinDecoder vin={inputValueVIN} onVinDecode={handleVinDecode} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="State" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">Pick up State</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="City" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>Pick up City</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" required placeholder="Zip" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="dealTypeGe" name="dealType" required value={inputValueDealType} onChange={handleInputChangeDealType}>
                                                        <option value="Any">For sale/ for rent</option>
                                                        <option value="forSale">For sale</option>
                                                        <option value="forRent">For rent</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input hidden={!forSale} type="text" className="form-input" id="propertyPriceForSaleGe" name="property-price" required={forSale} data-constraints="@Required @Numeric" placeholder="Asking price" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="propertyPriceForRentGe" name="property-price" required={forRent} data-constraints="@Required @Numeric" placeholder="Asking lease price/month" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-1 section-1-start">
                                        <h3>Info about vehicle</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-3">
                                                {/* <div className="input-group mb-3">
                                                    <input className="form-input" id="make" name="makeGe" required placeholder="მწარმოებელი" value={inputValueMake} onChange={handleInputChangeMake}>
                                                    </input>
                                                </div> */}
                                                <VehicleMakeSelector
                                                    inputValueMake={inputValueMake}
                                                    handleInputChangeMake={handleInputChangeMake}
                                                    setExternalValueMake={setInputValueMake} // Assume setExternalValueMake is a function to update external value
                                                />

                                            </div>
                                            <div className="col-md-3">
                                                {/* <div className="form-wrap">
                                                    <input className="form-input" name="model" required placeholder="მოდელი" value={inputValueModel} onChange={handleInputChangeModel}>
                                                    </input>
                                                </div> */}
                                                <VehicleModelSelector
                                                    inputValueModel={inputValueModel}
                                                    handleInputChangeModel={handleInputChangeModel}
                                                    externalValueModel={inputValueModel} // Use inputValueModel as the external value
                                                    setExternalValueModel={setInputValueModel} // Pass the function to handle model change
                                                    selectedMake={inputValueMake}
                                                    handleMakeChange={handleInputChangeModel}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="vehicleProductionYear" name="vehicleProductionYear" required placeholder="Production year" value={inputValueYear} onChange={handleInputChangeYear} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="interior" value={inputValueFuel} onChange={handleInputChangeFuel}>
                                                        <option value="Any">Fuel</option>
                                                        <option value="gas">Gas</option>
                                                        <option value="diesel">Diesel</option>
                                                        <option value="electricity">electricity</option>
                                                        <option value="hybrid">Hybrid gas</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input select-filter" name="transmission" placeholder="Info about transmission (not required)" value={inputValueTransmission} onChange={handleInputChangeTransmission}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input select-filter" name="engine" placeholder="Infor about engine (not required)" value={inputValueEngine} onChange={handleInputChangeEngine}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input" name="mileage" placeholder="Mileage" value={inputValueMileage} onChange={handleInputChangeMileage}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <ColorPicker onSelectColor={handleInputChangeExteriorcolor} placeholder={'Exterior color'} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <ColorPicker onSelectColor={handleInputChangeInteriorcolor} placeholder={'Interior color'} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="interior" value={inputValueInterior} onChange={handleInputChangeInterior}>
                                                        <option value="Any">Leather sits or not</option>
                                                        <option value="Leather">Leather sits</option>
                                                        <option value="NoLeather">No leather sits</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Upload images</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileEn" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>Delete an image</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Listed by</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" required placeholder="Name" data-constraints="@Required" onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="Email" data-constraints="@Email @Required" onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="Phone" autoComplete="tel" data-constraints="@PhoneNumber" onChange={handlePhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Detailed info</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="accident" value={inputValueAccident} onChange={handleInputAccident}>
                                                        <option value="Any">Has the vehicle ever been in an accident?</option>
                                                        <option value="noAccident">Never</option>
                                                        <option value="oneAccident">Once</option>
                                                        <option value="moreAccidents">More than once</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="condition" value={inputValueCondition} onChange={handleInputCondition}>
                                                        <option value="Any">The condition of the vehicle?</option>
                                                        <option value="amazing">Great</option>
                                                        <option value="good">Good</option>
                                                        <option value="rough">Rough</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="tires" value={inputValueTires} onChange={handleInputTires}>
                                                        <option value="Any">The condition of the tires</option>
                                                        <option value="good">Good</option>
                                                        <option value="mismatch">Tires do not match</option>
                                                        <option value="replacementNeeded">Requires replacement</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="numberofkeys" value={inputValueNumberofkeys} onChange={handleNumberofkeys}>
                                                        <option value="Any">Number of keys</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="ownershipstatus" value={inputValueOwnershipstatus} onChange={handleOwnershipstatus}>
                                                        <option value="Any">Ownership</option>
                                                        <option value="owned">Owned</option>
                                                        <option value="leased">Leased</option>
                                                        <option value="financed">Financed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="section-1">
                                                <h3>Damages</h3>
                                                <div className="row row-20 mt-20">
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="mechanicaldefects" value={inputValueMechanicaldefects} type="checkbox" checked={inputValueMechanicaldefects} onChange={handleInputMechanicaldefects} />   Mechanical damages
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="damagespreventingdriving" value={inputValueDamagespreventingdriving} type="checkbox" checked={inputValueDamagespreventingdriving} onChange={handleInputDamagespreventingdriving} />   Vehicle is not in the condition to be driven
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="exteriordamage" value={inputValueExteriordamage} type="checkbox" checked={inputValueExteriordamage} onChange={handleExteriordamage} />   Exterior damages
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="crack" value={inputValueCrack} type="checkbox" checked={inputValueCrack} onChange={handleCrack} />   Crackes and chips
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <textarea className="form-input" id="formCommentGe" name="comment" placeholder="More information or comments (not required)" onChange={handleComment}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">Submit</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Response: {responseMessage}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div >
        )
        }
        {languageToUse === "ru" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground1-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title">Объявление для своего автомобиля</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleSubmit} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/vehiclelisting">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>VIN, местонахождение и цена</h3>
                                        <div className="row row-20 mt-20">

                                            <div className="col-md-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="VIN" type="text" name="VIN" required placeholder="Идентификационный номер автомобиля (VIN)" value={inputValueVIN} onChange={handleInputChangeVIN} />
                                                    <VinDecoder vin={inputValueVIN} onVinDecode={handleVinDecode} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="Штат" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">Выберите штат</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="Город" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>Выберите город</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" required placeholder="Почтовый индекс" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="dealTypeGe" name="dealType" required value={inputValueDealType} onChange={handleInputChangeDealType}>
                                                        <option value="Any">Продается/ сдается в аренду</option>
                                                        <option value="forSale">Продается</option>
                                                        <option value="forRent">В аренду</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input hidden={!forSale} type="text" className="form-input" id="propertyPriceForSaleGe" name="property-price" required={forSale} data-constraints="@Required @Numeric" placeholder="Цена" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="propertyPriceForRentGe" name="property-price" required={forRent} data-constraints="@Required @Numeric" placeholder="Стоимость аренды/месяц" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-1 section-1-start">
                                        <h3>Информация об автомобиле</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-3">
                                                <VehicleMakeSelector
                                                    inputValueMake={inputValueMake}
                                                    handleInputChangeMake={handleInputChangeMake}
                                                    setExternalValueMake={setInputValueMake} // Assume setExternalValueMake is a function to update external value
                                                />

                                            </div>
                                            <div className="col-md-3">
                                                <VehicleModelSelector
                                                    inputValueModel={inputValueModel}
                                                    handleInputChangeModel={handleInputChangeModel}
                                                    externalValueModel={inputValueModel} // Use inputValueModel as the external value
                                                    setExternalValueModel={setInputValueModel} // Pass the function to handle model change
                                                    selectedMake={inputValueMake}
                                                    handleMakeChange={handleInputChangeModel}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="vehicleProductionYear" name="vehicleProductionYear" required placeholder="Год выпуска" value={inputValueYear} onChange={handleInputChangeYear} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="interior" value={inputValueFuel} onChange={handleInputChangeFuel}>
                                                        <option value="Any">Топливо</option>
                                                        <option value="gas">Бензин</option>
                                                        <option value="diesel">Дизель</option>
                                                        <option value="electricity">Электричество</option>
                                                        <option value="hybrid">Гибридный</option>
                                                        <option value="other">Другое</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input select-filter" name="transmission" placeholder="Информация о коробке передачи (не обязательно)" value={inputValueTransmission} onChange={handleInputChangeTransmission}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input select-filter" name="engine" placeholder="Информация о двигателе (не обязательно)" value={inputValueEngine} onChange={handleInputChangeEngine}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input className="form-input" name="mileage" placeholder="Пробег" value={inputValueMileage} onChange={handleInputChangeMileage}>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <ColorPicker onSelectColor={handleInputChangeExteriorcolor} placeholder={'Внешний цвет'} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <ColorPicker onSelectColor={handleInputChangeInteriorcolor} placeholder={'Цвет салона'} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="interior" value={inputValueInterior} onChange={handleInputChangeInterior}>
                                                        <option value="Any">Кожаный салон или нет</option>
                                                        <option value="Leather">Кожаный салон</option>
                                                        <option value="NoLeather">Не Кожаный</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Добавить фотографии</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileEn" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>Удалить изображение</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Публикователь</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" required placeholder="Имя" data-constraints="@Required" onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="Электронная почта" data-constraints="@Email @Required" onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="Телефон" autoComplete="tel" data-constraints="@PhoneNumber" onChange={handlePhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Больше информации</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="accident" value={inputValueAccident} onChange={handleInputAccident}>
                                                        <option value="Any">Попадал ли автомобиль в аварию?</option>
                                                        <option value="noAccident">Нет</option>
                                                        <option value="oneAccident">Один раз</option>
                                                        <option value="moreAccidents">Много раз</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="condition" value={inputValueCondition} onChange={handleInputCondition}>
                                                        <option value="Any">Состояние автомобиля?</option>
                                                        <option value="amazing">В отличном состоянии</option>
                                                        <option value="good">В хорошем состоянии</option>
                                                        <option value="rough">В тяжелом состоянии</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="tires" value={inputValueTires} onChange={handleInputTires}>
                                                        <option value="Any">Состояние шин:</option>
                                                        <option value="good">В отличном состоянии</option>
                                                        <option value="mismatch">Несоответствие шин</option>
                                                        <option value="replacementNeeded">Требует замены</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="numberofkeys" value={inputValueNumberofkeys} onChange={handleNumberofkeys}>
                                                        <option value="Any">Копия ключей</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="ownershipstatus" value={inputValueOwnershipstatus} onChange={handleOwnershipstatus}>
                                                        <option value="Any">Статус собственности:</option>
                                                        <option value="owned">В собственности</option>
                                                        <option value="leased">Арендаван</option>
                                                        <option value="financed">Финансируется</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="section-1">
                                                <h3>Повреждения</h3>
                                                <div className="row row-20 mt-20">
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="mechanicaldefects" value={inputValueMechanicaldefects} type="checkbox" checked={inputValueMechanicaldefects} onChange={handleInputMechanicaldefects} /> Механические дефекты
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="damagespreventingdriving" value={inputValueDamagespreventingdriving} type="checkbox" checked={inputValueDamagespreventingdriving} onChange={handleInputDamagespreventingdriving} /> Автомобиль имеет проблемы и не может управляться
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="exteriordamage" value={inputValueExteriordamage} type="checkbox" checked={inputValueExteriordamage} onChange={handleExteriordamage} /> Внешние повреждения
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="crack" value={inputValueCrack} type="checkbox" checked={inputValueCrack} onChange={handleCrack} /> Трещины и сколы
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <textarea className="form-input" id="formCommentGe" name="comment" placeholder="Дополнительная информация или комментарии (не обязательно)" onChange={handleComment}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">Публиковать</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Ответ: {responseMessage}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div >
        )
        }
    </div >
}

export default VehicleListing;