import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Property from "./property";
import Loader from "./loader";
import Map from './googleMap';
import AddressSearch from "./addressSearch";
import backendUrl from './config';
import { useAuth } from './authContext';
import { useLanguage } from './languageContext';
import MapWrapper from "./mapWrapper";
import SubMenue from "./subMenue";

function createEntry(listingObject, onItemClick) {
  return (
    <Property
      key={listingObject._id}
      onClick={() => onItemClick(listingObject._id)}
      listingId={listingObject._id}
      address={listingObject.address}
      city={listingObject.city}
      state={listingObject.state}
      zip={listingObject.zip}
      propertytype={listingObject.propertytype}
      numberOfBedrooms={listingObject.numberOfBedrooms}
      numberOfBathrooms={listingObject.numberOfBathrooms}
      propertyCondition={listingObject.propertyCondition}
      purchasePrice={listingObject.purchasePrice}
      remaningPrinciple={listingObject.remaningPrinciple}
      interestRate={listingObject.interestRate}
      loanAmortizationPeriod={listingObject.loanAmortizationPeriod}
      remaningAmortizationPeriod={listingObject.remaningAmortizationPeriod}
      monthlyPayment={listingObject.monthlyPayment}
      propertyTax={listingObject.propertyTax}
      askingPrice={listingObject.askingPrice}
      img={listingObject.imgs}
      userId={listingObject.userId}
      garages={listingObject.garages}
      area={listingObject.area}
      // subscriptionStatus={listingObject.subscription.status}
      subscriptionStatus={listingObject.subscriptions[listingObject.subscriptions.length - 1].status}
      //  userEmail={listingObject.userEmail}
      //  googleId={listingObject.googleId}
      isFavoriteBySelection={true}
    />
  );
}

function FavorateListings({ onItemClick, listingId }) {
  const product = "realestate";
  const { currentLanguage } = useLanguage();
  const [data, setInitialData] = useState([]);
  const [favoratelistingObjects, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const storedLanguage = sessionStorage.getItem('currentLanguage');
  const languageToUse = storedLanguage || currentLanguage;

  const { loggedIn, userId } = useAuth();
  useEffect(() => {
    // Only fetch data if the component is mounted
    let isMounted = true;

    const fetchData = async () => {
      try {
        // Check if the user is authenticated using the useAuth hook
        // if (!loggedIn) {
        //   navigate('/login');
        //   return;
        // }

        // Fetch data from the /favoratelistings endpoint
        const response = await fetch(`${backendUrl}/favoratelistings/${product}/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (isMounted) {
          if (response.ok) {
            const responseData = await response.json();
            setInitialData(responseData.favorateListings);
            setData(responseData.favorateListings);
            setLoading(false);
          } else {
            // Handle non-200 status codes here
            console.error("Non-200 status code:", response.status);
            throw new Error(`Error: ${response.status}`);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function to update the mounted status
    return () => {
      isMounted = false;
    };
  }, [loggedIn, navigate]);


  if (loading) {
    return <Loader loading={loading} />;
  }

  // Render your component content here


  // Only predefined number of items are listed per page
  const itemsPerPage = 2;

  const filteredListings = favoratelistingObjects.filter((item) => {
    const lowerCaseSearch = search.toLowerCase();
    return (
      lowerCaseSearch === '' ||
      (item && item.address && item.address.toLowerCase().includes(lowerCaseSearch)) ||
      (item && item.state && item.state.toLowerCase().includes(lowerCaseSearch)) ||
      (item && item.city && item.city.toLowerCase().includes(lowerCaseSearch)) ||
      (item && item.zip && item.zip.toString().includes(lowerCaseSearch))
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredListings.reverse().slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total number of pages
  const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

  // Calculate the range of pages to display
  let pageRangeStart = Math.max(1, currentPage - 3);
  let pageRangeEnd = Math.min(currentPage + 3, totalPages);

  // Adjust page range if currentPage is near the first or last page
  if (currentPage <= 3) {
    pageRangeStart = 1; // Start from the first page if currentPage is within the first 3 pages
    pageRangeEnd = Math.min(7, totalPages); // Display 7 pages if total pages are less than 7
  } else if (currentPage >= totalPages - 3) {
    pageRangeStart = Math.max(1, totalPages - 6); // Start from the appropriate page to display 7 pages
  }


  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  // Define a function to handle page navigation
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return <div>
    {languageToUse === "ge" && (
      <div>
        <section className="breadcrumbs-custom bg-image-favorate context-dark" data-opacity="28">
          <div className="container">
            <h2 className="breadcrumbs-custom-title">ჩემი რჩეული განცხადებები <AddressSearch listingObjects={data} onAddressInputChange={(value) => setSearch(value)} /></h2>
          </div>
        </section>
        <SubMenue product={product} />
        <div className="section-single-inner">
          <div className="section-single-main bg-gray-12">
            <div className="">
              <div className="range">
                <div className="cell-xl-6 height-fill">
                  <div className="google-map-container google-map-container_product-light" data-zoom="5">
                    <div className="google-map google-map-4 google-map-favorate">
                    </div>
                    <article className="product-classic product-classic-horizontal product-classic-sm">
                      <div className="album py-5 bg-body-tertiary">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1" >
                          <MapWrapper>
                            <Map mapStylesHeight="1240px" mapSylesWidth="100%" favorateListings={favoratelistingObjects} defaultCenter={favoratelistingObjects[0]}></Map>
                          </MapWrapper>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="cell-xl-6 cell-type-1">
                  <div>
                    <div className="cell-inner block-1">
                      <h2 className="tag-inline-caption"><span>რჩეული განცხადებები</span><span className="tag-inline tag-inline-left heading-7">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 25 23">
                          <polygon points="7.1,0 0,11.5 7.1,23 25,23 25,0 "></polygon>
                        </svg><span>{favoratelistingObjects.length}</span></span></h2>
                      <div className="product-classic-group">
                        <article className="product-classic product-classic-sm">
                          <div className="album py-5 bg-body-tertiary">
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1">
                              <div className="favorate-container">
                                {currentItems.map(listingObject => createEntry(listingObject, onItemClick))}
                                {!currentItems.length && (
                                  <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                    <h3 className="fs-5 text-body-secondary"> თქვენს არ ხართ შესული თქვენს ანგარიშზე ან ანგარიშზე რჩეული განცხადებები არ მოიპოვება</h3>
                                    <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                                    <h4 className="fs-5 text-body-secondary">ამ მენიუს გამოყენებისათვის უნდა დარეგისტრირდეთ და შეხვიდეთ თქვენს ანგარიშზე. იმისათვის რომ შეინახოთ თქვენთვის საინტერესო განცხადებები, დარეგისტრირდით და ვარსკვლავზე <span style={{ color: 'gold' }}>&#9733;</span> დაწკაპუნებით მონიშნეთ თქვენს მიერ მოწონებული განცხადება</h4>
                                  </div>
                                )}
                              </div>
                            </div>
                            {!!currentItems.length && (
                              <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                                  (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                                    <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                                  )
                                ))}
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                                <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                              </div>
                            )}
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    {languageToUse === "en" && (
      <div>
        <section className="breadcrumbs-custom bg-image-favorate context-dark" data-opacity="28">
          <div className="container">
            <h2 className="breadcrumbs-custom-title">My favorite listings <AddressSearch listingObjects={data} onAddressInputChange={(value) => setSearch(value)} /></h2>
          </div>
        </section>
        <SubMenue product={product} />
        <div className="section-single-inner">
          <div className="section-single-main bg-gray-12">
            <div className="">
              <div className="range">
                <div className="cell-xl-6 height-fill">
                  <div className="google-map-container google-map-container_product-light" data-zoom="5">
                    <div className="google-map google-map-4 google-map-favorate">
                    </div>
                    <article className="product-classic product-classic-horizontal product-classic-sm">
                      <div className="album py-5 bg-body-tertiary">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1" >
                          <MapWrapper>
                            <Map mapStylesHeight="1240px" mapSylesWidth="100%" favorateListings={favoratelistingObjects} defaultCenter={favoratelistingObjects[0]}></Map>
                          </MapWrapper>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="cell-xl-6 cell-type-1">
                  <div>
                    <div className="cell-inner block-1">
                      <h2 className="tag-inline-caption"><span>Favorite properties</span><span className="tag-inline tag-inline-left heading-7">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 25 23">
                          <polygon points="7.1,0 0,11.5 7.1,23 25,23 25,0 "></polygon>
                        </svg><span>{favoratelistingObjects.length}</span></span></h2>
                      <div className="product-classic-group">
                        <article className="product-classic product-classic-sm">
                          <div className="album py-5 bg-body-tertiary">
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1">
                              <div className="favorate-container">
                                {currentItems.map(listingObject => createEntry(listingObject, onItemClick))}
                                {!currentItems.length && (
                                  <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                    <h3 className="fs-5 text-body-secondary">You first need to login. There are no favorite listings associated with your current account.</h3>
                                    <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                                    <h4 className="fs-5 text-body-secondary">You need first to register and login to use this menue. To designate favorite listings, please navigate to the homepage and make your selections by clicking <span style={{ color: 'gold' }}>&#9733;</span> button.</h4>
                                  </div>
                                )}
                              </div>
                            </div>
                            {!!currentItems.length && (
                              <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                                  (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                                    <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                                  )
                                ))}
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                                <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                              </div>
                            )}
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    {languageToUse === "ru" && (
      <div>
        <section className="breadcrumbs-custom bg-image-favorate context-dark" data-opacity="28">
          <div className="container">
            <h2 className="breadcrumbs-custom-title">Мои любимые объявления <AddressSearch listingObjects={data} onAddressInputChange={(value) => setSearch(value)} /></h2>
          </div>
        </section>
        <SubMenue product={product} />
        <div className="section-single-inner">
          <div className="section-single-main bg-gray-12">
            <div className="">
              <div className="range">
                <div className="cell-xl-6 height-fill">
                  <div className="google-map-container google-map-container_product-light" data-zoom="5">
                    <div className="google-map google-map-4 google-map-favorate">
                    </div>
                    <article className="product-classic product-classic-horizontal product-classic-sm">
                      <div className="album py-5 bg-body-tertiary">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1" >
                          <MapWrapper>
                            <Map mapStylesHeight="1240px" mapSylesWidth="100%" favorateListings={favoratelistingObjects} defaultCenter={favoratelistingObjects[0]}></Map>
                          </MapWrapper>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="cell-xl-6 cell-type-1">
                  <div>
                    <div className="cell-inner block-1">
                      <h2 className="tag-inline-caption"><span>Любимые объекты недвижимости</span><span className="tag-inline tag-inline-left heading-7">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 25 23">
                          <polygon points="7.1,0 0,11.5 7.1,23 25,23 25,0 "></polygon>
                        </svg><span>{favoratelistingObjects.length}</span></span></h2>
                      <div className="product-classic-group">
                        <article className="product-classic product-classic-sm">
                          <div className="album py-5 bg-body-tertiary">
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1">
                              <div className="favorate-container">
                                {currentItems.map(listingObject => createEntry(listingObject, onItemClick))}
                                {!currentItems.length && (
                                  <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                                    <h3 className="fs-5 text-body-secondary">Сначала вам нужно войти в систему. С вашей текущей учетной записью не связано избранных объявлений.</h3>
                                    <h3 className="text-body-secondary heading-decoration-v"><span className="heading-inner"></span></h3>
                                    <h4 className="fs-5 text-body-secondary">Вам необходимо сначала зарегистрироваться и войти в систему, чтобы использовать это меню. Чтобы обозначить избранные объявления, перейдите на домашнюю страницу и сделайте свой выбор, нажав <span style={{ color: 'gold' }}>&#9733;</span> button.</h4>
                                  </div>
                                )}
                              </div>
                            </div>
                            {!!currentItems.length && (
                              <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                                  (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                                    <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                                  )
                                ))}
                                <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                                <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                              </div>
                            )}
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
}

export default FavorateListings;