import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import VerificationComponent from "./verificationByEmail";
import backendUrl from './config';
import { useAuth } from './authContext';
import { useLanguage } from './languageContext';
import ChangePassword from "./changePassword";
import ResetPassword from "./resetPassword";
import GoogleSignInComponent from './googleSignIn';

function LogInRegisterManu({ onRender, ...props }) {
    const { currentLanguage } = useLanguage();
    const { loggedIn, login, logout, userId, loginWithGoogle } = useAuth();
    const navigate = useNavigate();
    const [showSignInbox, setShowSignInbox] = useState(props.signInManueTrue ? true : false);
    const [SignIOrRegister, setShowSignIOrRegister] = useState(true);
    const [responseMessage, setResponseMessage] = useState("");
    const [responseMessageLogIn, setResponseMessageLogIn] = useState(true);
    const [verificationBoxOpen, setVerificationBoxOpen] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(false);
    const [user, setUser] = useState({});

    const [showPassword, setShowPassword] = useState(false);

    const [responseMessageShow, setResponseMessageShow] = useState(false);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const handleVerificationSuccess = (isSuccessful) => {
        setVerificationStatus(isSuccessful);
        if (isSuccessful) {
            login();
            setTimeout(() => {
                setShowSignInbox(false);
                window.location.reload();
                handleNavigation('/');
            }, 2000);
        }
    };

    const handleSignInRegisterToggle = async (e) => {
        e.preventDefault();
        setShowSignIOrRegister(!SignIOrRegister);
        if (loggedIn) {
            try {
                // Send the data to the backend
                const response = await fetch(`${backendUrl}/logout`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "Logout successful" && languageToUse === "ge") {
                        setResponseMessage("წარმატებული გამოსვლა");
                    } else if (data.message === "Server error during logout" && languageToUse === "ge") {
                        setResponseMessage("შეცდომა გამოსვლის დროს");
                    } else {
                        setResponseMessage(data.message);
                    }
                    // setLoggedIn(false);
                    logout();
                    handleNavigation('/');
                } else {
                    setResponseMessage("Error sending data to the server.");
                }
            } catch (error) {
                console.error("Error:", error);
                setResponseMessage("Error sending data to the server.");
            }
        } else if (!loggedIn) {

        }
    };

    const handleSignInBoxToggle = async () => {
        if (loggedIn) {
            try {
                // Send the data to the backend
                const response = await fetch(`${backendUrl}/logout`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "Logout successful" && languageToUse === "ge") {
                        setResponseMessage("წარმატებული გამოსვლა");
                    } else if (data.message === "Server error during logout" && languageToUse === "ge") {
                        setResponseMessage("შეცდომა გამოსვლის დროს");
                    } else {
                        setResponseMessage(data.message);
                    }
                    // setLoggedIn(false);
                    logout();
                    setShowSignInbox(!showSignInbox);
                    handleNavigation('/');
                } else {
                    setResponseMessage("Error sending data to the server.");
                }
            } catch (error) {
                console.error("Error:", error);
                setResponseMessage("Error sending data to the server.");
            }
        } else {
            // Toggle the SignInBox visibility
            setShowSignInbox(!showSignInbox);
        }
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    // Login method start
    const [inputValueUserName, setInputValueUserName] = useState("");
    const [inputValuePassword, setInputValuePassword] = useState("");
    const [verificationBoxOpenForLogIn, setVerificationBoxOpenForLogIn] = useState(false);


    const handleInputUserName = (e) => {
        setInputValueUserName(e.target.value);
    };
    const handleInputPassword = (e) => {
        setInputValuePassword(e.target.value);
    };

    const togglePasswordVisibility = (e) => {
        if (e.target.name === "passwordVisibility") {
            e.preventDefault(); // Prevent form submission
        }
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleLogInSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backendUrl}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: inputValueUserName,
                    password: inputValuePassword
                }),
            });

            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);

            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    login(data.user._id);
                    handleNavigation('/');

                } else if (!data.status) {
                    // setLoggedIn(false);
                    setResponseMessageLogIn(data.status);
                    if (data.message === 'Email is not verified. Please verify your email before logging in.') {
                        setResponseMessageLogIn(true);
                        setVerificationBoxOpenForLogIn(true);
                    } else if (data.message === 'Authentication failed. Incorrect username or password.') {
                        setVerificationBoxOpenForLogIn(false);
                    } else if (data.message === 'Authentication failed') {
                        setVerificationBoxOpenForLogIn(false);
                    }
                }
            } else {
                setResponseMessage("Error sending data to the server");
                // setLoggedIn(false);
                setResponseMessageLogIn(false);
                setTimeout(() => {
                    setResponseMessageLogIn(true);
                }, 3000);
            }
        } catch (error) {
            console.error("Error:", error);
            setResponseMessage("Error sending data to the server.");
        }
    };

    // pasword change start 
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);

    const handleCloseChangePassword = () => {
        setShowChangePassword(false); // Close the change password popup
        setResponseMessageLogIn(true);
        // setShowSignInbox(true);
    };

    const handleCloseResetPassword = () => {
        setShowResetPassword(false); // Close the change password popup
        setResponseMessageLogIn(true);
        // setShowSignInbox(true);
    };

    const handleChangePassword = (e) => {
        if (e.target.name === "changePasswordButton") {
            e.preventDefault(); // Prevent form submission
        }
        setShowChangePassword(true);
        setShowSignInbox(false);
    };

    const handleResetPassword = (e) => {
        if (e.target.name === "resetPasswordButton") {
            e.preventDefault(); // Prevent form submission
        }
        setShowResetPassword(true);
        setShowSignInbox(false);
    };


    // end

    // login method end
    // Register method start
    const [inputValueRegisterUserName, setRegisterInputValueUserName] = useState("");
    const [inputValueRegisterPassword, setRegisterInputValuePassword] = useState("");
    const [inputValueRegisterConfirmPassword, setRegisterInputValueConfirmPassword] = useState("");
    const [doMatch, setDoMatch] = useState(false);

    const handleRegisterInputUserName = (e) => {
        setRegisterInputValueUserName(e.target.value);
    };
    const handleRegisterInputPassword = (e) => {
        setRegisterInputValuePassword(e.target.value);
    };
    const handleRegisterInputConfirmPassword = (e) => {
        setRegisterInputValueConfirmPassword(e.target.value);
        checkPassword(inputValueRegisterPassword, inputValueRegisterConfirmPassword);
    };

    function checkPassword(Password, ConfirmPassword) {
        if (Password !== '' && Password === ConfirmPassword) {
            setDoMatch(true);
            return true;
        } else {
            setDoMatch(false);
            return false;
        }
    }

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        setResponseMessageShow(true);
        setTimeout(() => {
            setResponseMessageShow(false);
        }, 4000);
        // Check if the password meets the minimum length requirement
        if (inputValueRegisterPassword.length < 8) {
            setResponseMessage(languageToUse === "ge" ? 'პასვორდი მინიმუმ 8 სიმბოლოსგან უნდა შედგებოდეს' : 'Password must be at least 8 characters long.');
            return; // Stop further execution
        }

        if (checkPassword(inputValueRegisterPassword, inputValueRegisterConfirmPassword)) {
            try {
                setDoMatch(false);
                // Send the data to the backend
                const response = await fetch(`${backendUrl}/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: inputValueRegisterUserName,
                        password: inputValueRegisterPassword
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.error) {
                        // Check for the specific error indicating that the user already exists
                        if (response.status === 400 && data.error === 'User already exists') {
                            setResponseMessage(languageToUse === "ge" ? "მომხმარებელი ამ სახელით არსებობს, შეარჩიეთ განსხვავებული სახელი" : 'User already exists. Please choose a different username.');
                        } else if (response.status === 401 && data.error === 'User is not verified') {
                            setResponseMessage(languageToUse === "ge" ? "მომხმარებელი შემოწმებული არ არის. შეამოწმეთ იმეილი" : "User is not verified. Please verify your email, when login.");
                        }
                        else {
                            setResponseMessage('Error: ' + data.error);
                        }
                    } else {
                        if (data.message === "User registered successfully" && languageToUse === "ge") {
                            setResponseMessage("რეგისტრაცია წარმატებით დასრულდა")
                        } else if (data.message === "Failed to set subscription status" && languageToUse === "ge") {
                            setResponseMessage("ვერ დასრულდა წარმატებით")
                        } else {
                            setResponseMessage(data.message);
                        }
                        setVerificationBoxOpen(!verificationBoxOpen);
                    }
                } else {
                    if (response.status === 400) {
                        setResponseMessage(languageToUse === "ge" ? "მომხმარებელი ამ სახელით არსებობს, შეარჩიეთ განსხვავებული სახელი" : "User already exists. Please choose a different username.");
                    } else {
                        setResponseMessage("Error sending data to the server.");
                    }
                }
            } catch (error) {
                console.error("Error:", error);
                setResponseMessage("Error sending data to the server.");
            }
        } else {
            setDoMatch(true);
        }
    };

    //   register method end

    return <div>
        {languageToUse === "ge" && (
            <div className="rd-navbar-aside-item" style={{ fontSize: "12px" }}>
                <div className="block-inline">
                    <button className={`button ${showSignInbox ? 'active' : ''}`}
                        onClick={handleSignInBoxToggle}><span className="unit-left"><span className="icon text-middle mdi mdi-login"></span></span><span className="unit-body"><span className="menue-font" style={{ color: '#696969' }}>{loggedIn ? "გამოსვლა" : "შესვლა / რეგისტრაცია"}</span></span></button>
                </div>
                {showSignInbox && (
                    <div className={`rd-navbar-popup bg-gray-12 ${showSignInbox && !loggedIn ? 'active' : ''}`} id="navbar-login-register">
                        {/* <!-- tabs--> */}
                        <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                            {/* <!-- Nav tabs--> */}
                            <ul className="nav nav-tabs">
                                <li className="nav-item" role="presentation"><a className={`nav-link ${SignIOrRegister ? 'active' : ''}`} data-toggle="tab" onClick={handleSignInRegisterToggle}>შესვლა</a></li>
                                <li className="nav-item" role="presentation"><a className={`nav-link ${SignIOrRegister ? '' : 'active'}`} data-toggle="tab" onClick={handleSignInRegisterToggle}>რეგისტრაცია</a></li>
                                {/* <li className="nav-link px-2 link-secondary button-menue" role="presentation"><button onClick={handleSignInRegisterToggle} className={`nav-link ${SignIOrRegister ? 'active' : ''}`} > {loggedIn ? "Logout" : "Login"} / Register</button></li> */}
                            </ul>
                            {/* <!-- Tab panes--> */}
                            <div className="tab-content">
                                <div className={`tab-pane ${SignIOrRegister ? 'show active' : ''}`} id="navbar-tabs-1">
                                    <form onSubmit={handleLogInSubmit} action={`${backendUrl}/login`} method="POST" className="rd-form form-1">
                                        <div className="form-wrap">
                                            <input className="form-input" id="navbar-login-email" type="email" name="email" placeholder="E-mail" data-constraints="@Email @Required" value={inputValueUserName} onChange={handleInputUserName} />
                                        </div>

                                        <div className="form-wrap">
                                            <input
                                                className="form-input"
                                                id="navbar-login-password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                placeholder="Password"
                                                data-constraints="@Required"
                                                value={inputValuePassword}
                                                onChange={handleInputPassword}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <button
                                                    className="btn-toggle-password"
                                                    name="passwordVisibility"
                                                    onClick={togglePasswordVisibility}
                                                    style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                >
                                                    {showPassword ? 'დაფარე პასწორდი' : 'აჩვენე პასვორდი'}
                                                </button>
                                                <button className="btn-toggle-password" name="resetPasswordButton" style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                    hidden={responseMessageLogIn}
                                                    onClick={handleResetPassword}
                                                >პაროლის აღდგენა
                                                </button>
                                                <button className="btn-toggle-password" name="changePasswordButton" style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                    hidden={!responseMessageLogIn}
                                                    onClick={handleChangePassword}
                                                >პაროლის შეცვლა
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-wrap" style={{ fontWeight: "800", marginLeft: "2px", color: "#4b9ef1" }}>
                                            <p hidden={responseMessageLogIn || !responseMessageShow}>შეამოწმე პასვორდი ან სახელი</p>
                                        </div>
                                        <div hidden={!verificationBoxOpenForLogIn} className="form-wrap">
                                            <VerificationComponent username={inputValueUserName} onVerificationSuccess={handleVerificationSuccess} />
                                        </div>
                                        <div className="form-wrap">
                                            <button className="button button-sm button-primary button-block" type="submit">შესვლა</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-wrap">
                                        <div className="text-decoration-lines"><span className="text-decoration-lines-content">ან შედით</span></div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div>
                                            <GoogleSignInComponent setUser={setUser} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${SignIOrRegister ? '' : 'show active'}`} id="navbar-tabs-2">
                                    <form onSubmit={handleRegisterSubmit} action={`${backendUrl}/register`} method="POST" className="rd-form form-1">
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-email" type="email" name="email" placeholder="ელექტრონული ფოსტა" data-constraints="@Email @Required" value={inputValueRegisterUserName} onChange={handleRegisterInputUserName} />
                                        </div>
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-password" type="password" name="password" placeholder="პასვორდი" data-constraints="@Required" value={inputValueRegisterPassword} onChange={handleRegisterInputPassword} />
                                        </div>
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-password-confirm" type="password" name="password" placeholder="დაადასტურე პასვორდი" data-constraints="@Required" value={inputValueRegisterConfirmPassword} onChange={handleRegisterInputConfirmPassword} />
                                        </div>
                                        <div className="form-wrap">
                                            <p hidden={!responseMessageShow}>{responseMessage}</p>
                                            <p hidden={!doMatch || !responseMessageShow}>პასვორდები არ ემთხვევა</p>
                                        </div>
                                        <div className="form-wrap">
                                            <button className="button button-sm button-primary button-block" type="submit">შექმენი ანგარიში</button>
                                        </div>
                                        <div hidden={!verificationBoxOpen} className="form-wrap">
                                            <VerificationComponent username={inputValueRegisterUserName} onVerificationSuccess={handleVerificationSuccess} />
                                        </div>
                                        <div className="form-wrap">
                                            <div className="text-decoration-lines"><span className="text-decoration-lines-content"></span></div>
                                            {/* <div className="text-decoration-lines"><span className="text-decoration-lines-content">or enter with</span></div> */}
                                        </div>
                                        <div className="form-wrap">
                                            {/* <div className="button-group"><a className="button button-facebook button-icon button-icon-only" href="#" aria-label="Facebook"><span className="icon mdi mdi mdi-facebook"></span></a><a className="button button-twitter button-icon button-icon-only" href="#" aria-label="Twitter"><span className="icon mdi mdi-twitter"></span></a><a className="button button-google button-icon button-icon-only" href="#" aria-label="Google+"><span className="icon mdi mdi-google"></span></a></div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )}
        <ResetPassword isOpen={showResetPassword} onClose={handleCloseResetPassword} />
        <ChangePassword isOpen={showChangePassword} onClose={handleCloseChangePassword} />
        {languageToUse === "en" && (
            <div className="rd-navbar-aside-item">
                <div className="block-inline">
                    <button className={`button ${showSignInbox ? 'active' : ''}`}
                        onClick={handleSignInBoxToggle}><span className="unit-left"><span className="icon text-middle mdi mdi-login"></span></span><span className="unit-body"><span style={{ color: '#696969' }}>{loggedIn ? "Logout" : "Login / Register"}</span></span></button>
                </div>
                {showSignInbox && (
                    <div className={`rd-navbar-popup bg-gray-12 ${showSignInbox && !loggedIn ? 'active' : ''}`} id="navbar-login-register">
                        {/* <!-- tabs--> */}
                        <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                            {/* <!-- Nav tabs--> */}
                            <ul className="nav nav-tabs">
                                <li className="nav-item" role="presentation"><a className={`nav-link ${SignIOrRegister ? 'active' : ''}`} data-toggle="tab" onClick={handleSignInRegisterToggle}>Login</a></li>
                                <li className="nav-item" role="presentation"><a className={`nav-link ${SignIOrRegister ? '' : 'active'}`} data-toggle="tab" onClick={handleSignInRegisterToggle}>Register</a></li>
                                {/* <li className="nav-link px-2 link-secondary button-menue" role="presentation"><button onClick={handleSignInRegisterToggle} className={`nav-link ${SignIOrRegister ? 'active' : ''}`} > {loggedIn ? "Logout" : "Login"} / Register</button></li> */}
                            </ul>
                            {/* <!-- Tab panes--> */}
                            <div className="tab-content">
                                <div className={`tab-pane ${SignIOrRegister ? 'show active' : ''}`} id="navbar-tabs-1">
                                    <form onSubmit={handleLogInSubmit} action={`${backendUrl}/login`} method="POST" className="rd-form form-1">
                                        <div className="form-wrap">
                                            <input className="form-input" id="navbar-login-email" type="email" name="email" placeholder="E-mail" data-constraints="@Email @Required" value={inputValueUserName} onChange={handleInputUserName} />
                                        </div>
                                        <div className="form-wrap">
                                            <input
                                                className="form-input"
                                                id="navbar-login-password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                placeholder="Password"
                                                data-constraints="@Required"
                                                value={inputValuePassword}
                                                onChange={handleInputPassword}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <button
                                                    className="btn-toggle-password"
                                                    name="passwordVisibility"
                                                    onClick={togglePasswordVisibility}
                                                    style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                >
                                                    {showPassword ? 'hide' : 'show'}
                                                </button>
                                                <button className="btn-toggle-password" name="resetPasswordButton" style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                    hidden={responseMessageLogIn}
                                                    onClick={handleResetPassword}
                                                >Recover password
                                                </button>
                                                <button className="btn-toggle-password" name="changePasswordButton" style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                    hidden={!responseMessageLogIn}
                                                    onClick={handleChangePassword}
                                                >Change password
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-wrap loginResponses" style={{ fontWeight: "600", marginLeft: "2px", color: "#4b9ef1" }}>
                                            <p hidden={responseMessageLogIn || !responseMessageShow}>Check password or user name</p>
                                        </div>
                                        <div hidden={!verificationBoxOpenForLogIn} className="form-wrap">
                                            <VerificationComponent username={inputValueUserName} onVerificationSuccess={handleVerificationSuccess} />
                                        </div>
                                        <div className="form-wrap">
                                            <button className="button button-sm button-primary button-block" type="submit">Sign in</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-wrap">
                                        <div className="text-decoration-lines"><span className="text-decoration-lines-content">or enter with</span></div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div>
                                            <GoogleSignInComponent setUser={setUser} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${SignIOrRegister ? '' : 'show active'}`} id="navbar-tabs-2">
                                    <form onSubmit={handleRegisterSubmit} action={`${backendUrl}/register`} method="POST" className="rd-form form-1">
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-email" type="email" name="email" placeholder="E-mail" data-constraints="@Email @Required" value={inputValueRegisterUserName} onChange={handleRegisterInputUserName} />
                                        </div>
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-password" type="password" name="password" placeholder="Password" data-constraints="@Required" value={inputValueRegisterPassword} onChange={handleRegisterInputPassword} />
                                        </div>
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-password-confirm" type="password" name="password" placeholder="Confirm Password" data-constraints="@Required" value={inputValueRegisterConfirmPassword} onChange={handleRegisterInputConfirmPassword} />
                                        </div>

                                        <div className="form-wrap">
                                            <p hidden={!responseMessageShow}>{responseMessage}</p>
                                            <p hidden={!doMatch || !responseMessageShow}>Passwords do not match</p>
                                        </div>
                                        <div className="form-wrap">
                                            <button className="button button-sm button-primary button-block" type="submit">Create an Account</button>
                                        </div>
                                        <div hidden={!verificationBoxOpen} className="form-wrap">
                                            <VerificationComponent username={inputValueRegisterUserName} onVerificationSuccess={handleVerificationSuccess} />
                                        </div>
                                        <div className="form-wrap">
                                            <div className="text-decoration-lines"><span className="text-decoration-lines-content">or enter with</span></div>
                                        </div>
                                        <div className="form-wrap">
                                            {/* <div className="button-group"><a className="button button-facebook button-icon button-icon-only" href="#" aria-label="Facebook"><span className="icon mdi mdi mdi-facebook"></span></a><a className="button button-twitter button-icon button-icon-only" href="#" aria-label="Twitter"><span className="icon mdi mdi-twitter"></span></a><a className="button button-google button-icon button-icon-only" href="#" aria-label="Google+"><span className="icon mdi mdi-google"></span></a></div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )}

        {languageToUse === "ru" && (
            <div className="rd-navbar-aside-item">
                <div className="block-inline">
                    <button className={`button ${showSignInbox ? 'active' : ''}`}
                        onClick={handleSignInBoxToggle}><span className="unit-left"><span className="icon text-middle mdi mdi-login"></span></span><span className="unit-body"><span style={{ color: '#696969' }}>{loggedIn ? "Выйти" : "Войти / Зарегистрироваться"}</span></span></button>
                </div>
                {showSignInbox && (
                    <div className={`rd-navbar-popup bg-gray-12 ${showSignInbox && !loggedIn ? 'active' : ''}`} id="navbar-login-register">
                        {/* <!-- tabs--> */}
                        <div className="tabs-custom tabs-horizontal tabs-line" id="navbar-tabs">
                            {/* <!-- Nav tabs--> */}
                            <ul className="nav nav-tabs">
                                <li className="nav-item" role="presentation"><a className={`nav-link ${SignIOrRegister ? 'active' : ''}`} data-toggle="tab" onClick={handleSignInRegisterToggle}>Войти</a></li>
                                <li className="nav-item" role="presentation"><a className={`nav-link ${SignIOrRegister ? '' : 'active'}`} data-toggle="tab" onClick={handleSignInRegisterToggle}>Зарегистрироваться</a></li>
                                {/* <li className="nav-link px-2 link-secondary button-menue" role="presentation"><button onClick={handleSignInRegisterToggle} className={`nav-link ${SignIOrRegister ? 'active' : ''}`} > {loggedIn ? "Logout" : "Login"} / Register</button></li> */}
                            </ul>
                            {/* <!-- Tab panes--> */}
                            <div className="tab-content">
                                <div className={`tab-pane ${SignIOrRegister ? 'show active' : ''}`} id="navbar-tabs-1">
                                    <form onSubmit={handleLogInSubmit} action={`${backendUrl}/login`} method="POST" className="rd-form form-1">
                                        <div className="form-wrap">
                                            <input className="form-input" id="navbar-login-email" type="email" name="email" placeholder="E-mail" data-constraints="@Email @Required" value={inputValueUserName} onChange={handleInputUserName} />
                                        </div>
                                        <div className="form-wrap">
                                            <input
                                                className="form-input"
                                                id="navbar-login-password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                placeholder="Password"
                                                data-constraints="@Required"
                                                value={inputValuePassword}
                                                onChange={handleInputPassword}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <button
                                                    className="btn-toggle-password"
                                                    name="passwordVisibility"
                                                    onClick={togglePasswordVisibility}
                                                    style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                >
                                                    {showPassword ? 'скрывать' : 'показывать'}
                                                </button>
                                                <button className="btn-toggle-password" name="resetPasswordButton" style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                    hidden={responseMessageLogIn}
                                                    onClick={handleResetPassword}
                                                >Восстановить пароль
                                                </button>
                                                <button className="btn-toggle-password" name="changePasswordButton" style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
                                                    hidden={!responseMessageLogIn}
                                                    onClick={handleChangePassword}
                                                >Изменить пароль
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-wrap loginResponses" style={{ fontWeight: "600", marginLeft: "2px", color: "#4b9ef1" }}>
                                            <p hidden={responseMessageLogIn || !responseMessageShow}>Check password or user name</p>
                                        </div>
                                        <div hidden={!verificationBoxOpenForLogIn} className="form-wrap">
                                            <VerificationComponent username={inputValueUserName} onVerificationSuccess={handleVerificationSuccess} />
                                        </div>
                                        <div className="form-wrap">
                                            <button className="button button-sm button-primary button-block" type="submit">Войти</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-wrap">
                                        <div className="text-decoration-lines"><span className="text-decoration-lines-content">или войдите с помощью</span></div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div>
                                            <GoogleSignInComponent setUser={setUser} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${SignIOrRegister ? '' : 'show active'}`} id="navbar-tabs-2">
                                    <form onSubmit={handleRegisterSubmit} action={`${backendUrl}/register`} method="POST" className="rd-form form-1">
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-email" type="email" name="email" placeholder="E-mail" data-constraints="@Email @Required" value={inputValueRegisterUserName} onChange={handleRegisterInputUserName} />
                                        </div>
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-password" type="password" name="password" placeholder="Password" data-constraints="@Required" value={inputValueRegisterPassword} onChange={handleRegisterInputPassword} />
                                        </div>
                                        <div className="form-wrap">
                                            <input className="form-input" id="register-password-confirm" type="password" name="password" placeholder="Confirm Password" data-constraints="@Required" value={inputValueRegisterConfirmPassword} onChange={handleRegisterInputConfirmPassword} />
                                        </div>

                                        <div className="form-wrap">
                                            <p hidden={!responseMessageShow}>{responseMessage}</p>
                                            <p hidden={!doMatch || !responseMessageShow}>Пароли не совпадают</p>
                                        </div>
                                        <div className="form-wrap">
                                            <button className="button button-sm button-primary button-block" type="submit">Завести аккаунт</button>
                                        </div>
                                        <div hidden={!verificationBoxOpen} className="form-wrap">
                                            <VerificationComponent username={inputValueRegisterUserName} onVerificationSuccess={handleVerificationSuccess} />
                                        </div>
                                        <div className="form-wrap">
                                            <div className="text-decoration-lines"><span className="text-decoration-lines-content">или войдите с помощью</span></div>
                                        </div>
                                        <div className="form-wrap">
                                            {/* <div className="button-group"><a className="button button-facebook button-icon button-icon-only" href="#" aria-label="Facebook"><span className="icon mdi mdi mdi-facebook"></span></a><a className="button button-twitter button-icon button-icon-only" href="#" aria-label="Twitter"><span className="icon mdi mdi-twitter"></span></a><a className="button button-google button-icon button-icon-only" href="#" aria-label="Google+"><span className="icon mdi mdi-google"></span></a></div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )}
    </div>
}

export default LogInRegisterManu;