import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        // Set isVisible state based on scroll position
        if (window.scrollY > 20) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            {isVisible && (
                <button
                    id="ui-to-top"
                    className="ui-to-top active"
                    style={{
                        width: '44px',
                        height: '44px',
                        borderRadius: '50%',
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor: '#4b9ef1',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderColor: '#4b9ef1'
                    }}
                    onClick={scrollToTop}
                >
                    <span style={{ borderLeft: '8px solid transparent', borderRight: '8px solid transparent', borderBottom: '12px solid white' }}></span>
                </button>
            )}
        </div>
    );
};

export default BackToTopButton;

