import React from 'react';
import { GoogleMap, MarkerF, LoadScript } from '@react-google-maps/api';


function Map(props) {
  // const googleMapsApiKey = process.env.REACT_APP_KEY_GOOGLE_MAPS;
  const mapStyles = {
    height: props.mapStylesHeight,
    width: props.mapSylesWidth,
    overflow: 'hidden', // Add overflow to prevent partial display
  };

  const isLatAvailable = props.defaultCenter && props.defaultCenter.lat !== null && props.defaultCenter.lat !== undefined && props.defaultCenter.lng !== null && props.defaultCenter.lng !== undefined;;

  const defaultCenter = {
    lat: isLatAvailable ? parseFloat(props.defaultCenter.lat) : 40.712776,
    lng: isLatAvailable ? parseFloat(props.defaultCenter.lng) : -74.005974,
  };

  return (
    // <LoadScript googleMapsApiKey={googleMapsApiKey}>
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={12}
      center={defaultCenter}
    >
      {props.favorateListings && props.favorateListings.length > 0 && props.favorateListings.map((favorateListing, index) => { return <MarkerF key={index} position={{ lat: parseFloat(favorateListing.lat), lng: parseFloat(favorateListing.lng) }}></MarkerF> })}
      <MarkerF position={{ lat: parseFloat(props.favorateListings.lat), lng: parseFloat(props.favorateListings.lng) }}></MarkerF>
    </GoogleMap>
    // </LoadScript >
  );
}

export default Map;