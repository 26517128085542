import React, { useState } from "react";
import { useLanguage } from './languageContext';

function AddressSearch(props) {
    const { currentLanguage } = useLanguage();
    const [inputValueAddress, setInputValueAddress] = useState("");

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const handleInputChangeAddress = (e) => {
        setInputValueAddress(e.target.value);
        props.onAddressInputChange(inputValueAddress);
    };

    return <div>
        {languageToUse === "ge" && (
            <div className="form-wrap form-wrap-validation">
                <input placeholder="ქალაქი, ზიპ კოდი, მისმართი   (გამოიყენეთ ლათინური ასოები)" type="address" className="form-control" id="streetAddress" name="address" autoComplete="address-line1" value={inputValueAddress} onChange={handleInputChangeAddress}
                    style={{
                        textAlign: 'center',
                        backgroundColor: '#F8F8F8',
                        color: '#101010',
                        padding: '10px',
                        // borderRadius: '5px', 
                        border: '1px solid #ccc',
                        fontWeight: 'bold',
                    }} />
            </div>
        )}
        {languageToUse === "en" && (
            <div className="form-wrap form-wrap-validation">
                <input placeholder="City, Neighborhood, ZIP, Address" type="address" className="form-control" id="streetAddress" name="address" autoComplete="address-line1" value={inputValueAddress} onChange={handleInputChangeAddress}
                    style={{
                        textAlign: 'center',
                        backgroundColor: '#F8F8F8',
                        color: '#101010',
                        padding: '10px',
                        // borderRadius: '5px', 
                        border: '1px solid #ccc',
                        fontWeight: 'bold',
                    }} />
            </div>
        )}
        {languageToUse === "ru" && (
            <div className="form-wrap form-wrap-validation">
                <input placeholder="Город, Район, Почтовый индекс, Адрес  (используйте латинские буквы)" type="address" className="form-control" id="streetAddress" name="address" autoComplete="address-line1" value={inputValueAddress} onChange={handleInputChangeAddress}
                    style={{
                        textAlign: 'center',
                        backgroundColor: '#F8F8F8',
                        color: '#101010',
                        padding: '10px',
                        // borderRadius: '5px', 
                        border: '1px solid #ccc',
                        fontWeight: 'bold',
                    }} />
            </div>
        )}
    </div>
}
export default AddressSearch;