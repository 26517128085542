import React from 'react';

function Loader({ loading }) {
    return (
        <div className={`preloader ${loading ? '' : 'loaded'}`}>
            <div className="banter-loader">
                {[...Array(9)].map((_, index) => (
                    <div className="banter-loader__box" key={index}></div>
                ))}
            </div>
        </div>
    );
}

export default Loader; 