import React, { useState } from 'react';
import ChatBotForManue from './chatBotForManue';
import { useLanguage } from './languageContext';
import { Link } from 'react-router-dom';


const PopUpButton = () => {
    const { currentLanguage } = useLanguage();
    const [showPopup, setShowPopup] = useState(false);

    // Retrieve currentLanguage from sessionStorage
    const storedLanguage = sessionStorage.getItem('currentLanguage');

    // Use storedLanguage if available, otherwise use currentLanguage
    const languageToUse = storedLanguage || currentLanguage;

    const handleLinkClick = () => {
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    return (
        <div>
            {languageToUse === "ge" && (
                <div className="">
                    <Link className='plain-link extra-bold' onClick={handleLinkClick} style={{ color: '#4b9ef1', fontWeight: '600', fontSize: '18px' }}>
                        კითხვები &amp; პასუხები
                    </Link>
                    {showPopup && (
                        <div className="popup-container">
                            <div className="popup-content">
                                <ChatBotForManue language={languageToUse} />
                                <button className='btn' style={{ color: 'goldenrod' }} onClick={handlePopupClose}>დახურვა</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {languageToUse === "en" && (
                <div className="">
                    <Link className='plain-link' onClick={handleLinkClick} style={{ color: '#4b9ef1', fontWeight: '560', fontSize: '18px' }}>
                        Q&amp;A
                    </Link>
                    {showPopup && (
                        <div className="popup-container">
                            <div className="popup-content">
                                <ChatBotForManue language={languageToUse} />
                                <button className='btn' style={{ color: 'goldenrod' }} onClick={handlePopupClose}>Close</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {languageToUse === "ru" && (
                <div className="">
                    <Link className='plain-link' onClick={handleLinkClick} style={{ color: '#4b9ef1', fontWeight: '560', fontSize: '18px' }}>
                        В&amp;О
                    </Link>
                    {showPopup && (
                        <div className="popup-container">
                            <div className="popup-content">
                                <ChatBotForManue language={languageToUse} />
                                <button className='btn' style={{ color: 'goldenrod' }} onClick={handlePopupClose}>Закрыть</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PopUpButton;
