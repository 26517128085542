// import React, { useState, useEffect } from "react";
// import Property from "./property";
// import Search from "./search";
// import AddressSearch from "./addressSearch";
// import Loader from './loader';
// import PopUpButton from "./popUpButton";
// import ChatBot from "./chatBot";
// import backendUrl from './config';
// import { Link } from 'react-router-dom';
// import SubMenue from "./subMenue";

// let listingData;

// function createEntry(listingObject) {
//   return (
//     <Property
//       key={listingObject._id}
//       listingId={listingObject._id}
//       address={listingObject.address}
//       city={listingObject.city}
//       state={listingObject.state}
//       zip={listingObject.zip}
//       propertytype={listingObject.propertytype}
//       numberOfBedrooms={listingObject.numberOfBedrooms}
//       numberOfBathrooms={listingObject.numberOfBathrooms}
//       propertyCondition={listingObject.propertyCondition}
//       purchasePrice={listingObject.purchasePrice}
//       remaningPrinciple={listingObject.remaningPrinciple}
//       interestRate={listingObject.interestRate}
//       loanAmortizationPeriod={listingObject.loanAmortizationPeriod}
//       remaningAmortizationPeriod={listingObject.remaningAmortizationPeriod}
//       monthlyPayment={listingObject.monthlyPayment}
//       propertyTax={listingObject.propertyTax}
//       askingPrice={listingObject.askingPrice}
//       subscriptionStatus={listingObject.subscriptions[listingObject.subscriptions.length - 1].status}
//       img={listingObject.imgs}
//       userId={listingObject.userId}
//       garages={listingObject.garages}
//       area={listingObject.area}
//       lisingtype="listings"
//       isFavoriteBySelection={false}
//     />
//   );
// }

// function Home(props) {
//   const product = "realestate";
//   const [data, setInitialData] = useState([]);
//   const [listingObjects, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [addressFilter, setAddressFilter] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     fetch(`${backendUrl}/home`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         // Add any additional headers if needed
//       },
//       credentials: 'include', // Include credentials in the request
//     })
//       .then((response) => response.json())
//       .then((responseData) => {
//         setInitialData(responseData);
//         setData(responseData);
//         listingData = responseData;
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return <Loader key={1} loading={loading} />;
//   }

//   function AppyAddressFilter(value) {
//     // setData(data);
//     setAddressFilter(value);
//   }

//   const itemsPerPage = 9;

//   const filteredListings = listingObjects.filter((item) => {
//     const lowerCaseSearch = addressFilter.toLowerCase();
//     return (
//       lowerCaseSearch === '' ||
//       (item && item.address && item.address.toLowerCase().includes(lowerCaseSearch)) ||
//       (item && item.state && item.state.toLowerCase().includes(lowerCaseSearch)) ||
//       (item && item.city && item.city.toLowerCase().includes(lowerCaseSearch)) ||
//       (item && item.zip && item.zip.toString().includes(lowerCaseSearch))
//     );
//   });

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = filteredListings.reverse().slice(indexOfFirstItem, indexOfLastItem);

//   const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

//   const nextPage = () => {
//     setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };

//   const prevPage = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   function ApplySearchFilter(filteredBySearchListings) {
//     setData(filteredBySearchListings);
//   }

//   return <main>
//     {props.language === "ge" && (
//       <div>
//         <section className="breadcrumbs-custom bg-image context-dark" data-opacity="28">
//           <div className="container">
//             <h2 className="breadcrumbs-custom-title">მოძებნეთ სახლი/ბინა/ფართი<AddressSearch key={1} listingObjects={data} onAddressInputChange={(value) => AppyAddressFilter(value)} /></h2>
//           </div>
//         </section>
//         <SubMenue product={product} />
//         <Search listingObjects={data} onAddressInputChange={(value) => setAddressFilter(value)} onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)} />
//         <div className="album py-5 bg-body-tertiary">
//           <div className="container">
//             <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
//               {currentItems.map(createEntry)}
//             </div>
//           </div>
//           <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
//             <button className="button-secondary" style={{ marginRight: "20px", borderRadius: "6px", width: "90px" }} onClick={prevPage} disabled={currentPage === 1}>წინა</button>
//             <span style={{ color: "goldenrod", fontWeight: "450" }}>{`Page  ${currentPage}  of  ${totalPages}`}</span>
//             <button className="button-secondary" style={{ marginLeft: "20px", borderRadius: "6px", width: "90px" }} onClick={nextPage} disabled={currentPage === totalPages}>შემდეგი</button>
//           </div>
//         </div>
//         <div className="col-md-6 col-lg-12">
//           <div className="block-info bg-default">
//             <div className="form-wrap chat-bot">
//               <div className="container"><ChatBot key={1} /></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )}
//     {props.language === "en" && (
//       <div>
//         <section className="breadcrumbs-custom bg-image context-dark" data-opacity="28">
//           <div className="container">
//             <h2 className="breadcrumbs-custom-title">Find your property<AddressSearch key={1} listingObjects={data} onAddressInputChange={(value) => AppyAddressFilter(value)} /></h2>
//           </div>
//         </section>
//         <SubMenue product={product} />
//         <Search listingObjects={data} onAddressInputChange={(value) => setAddressFilter(value)} onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)} />
//         <div className="album py-5 bg-body-tertiary">
//           <div className="container">
//             <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
//               {currentItems.map(createEntry)}
//             </div>
//           </div>
//           <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
//             <button className="button-secondary" style={{ marginRight: "20px", borderRadius: "6px" }} onClick={prevPage} disabled={currentPage === 1}>Prev</button>
//             <span style={{ color: "goldenrod", fontWeight: "450" }}>{`Page  ${currentPage}  of  ${totalPages}`}</span>
//             <button className="button-secondary" style={{ marginLeft: "20px", borderRadius: "6px" }} onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
//           </div>
//         </div>
//         <div className="col-md-6 col-lg-12">
//           <div className="block-info bg-default">
//             <div className="form-wrap chat-bot">
//               <div className="container"><ChatBot key={1} /></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )}
//   </main>
// }

// export { listingData };
// export default Home;


import React, { useState, useEffect } from 'react';
import RealEstatePoster from './realEstatePoster';
import ItemDetailsPopUp from './itemDetalsPopUp';
import { useAuth } from './authContext';
import backendUrl from './config';
import VisitCounts from './visitCounts';

const Home = () => {
  const product = 'realestate';
  const [showItemDetails, setShowItemDetails] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);

  const handleItemClick = (listingId) => {
    setSelectedListingId(listingId);
    setShowItemDetails(true);
  };

  const handleItemClose = () => {
    setShowItemDetails(false);
  };

  useEffect(() => {
    // Function to fetch IP address
    const fetchIpAddress = async () => {
      try {
        const responseIp = await fetch('https://api.ipify.org?format=json');
        const data = await responseIp.json();
        const ipAddress = data.ip;

        // Send request to backend with IP address
        const responseBackend = await fetch(`${backendUrl}/visitcount/${product}`, {
          method: 'POST',
          body: JSON.stringify({ ipAddress }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (responseBackend.ok) {
          const responseData = await responseBackend.json();
        } else {
          throw new Error('Failed to fetch visit count');
        }
      } catch (error) {
        console.error('Error fetching visit count:', error);
      }
    };

    // Call fetchIpAddress function when component mounts
    fetchIpAddress();
  }, []);

  return (
    <div className="container home-container">
      <VisitCounts product={product} />
      <RealEstatePoster onItemClick={handleItemClick} listingId={selectedListingId} /> {/* Pass both onItemClick and listingId */}
      {showItemDetails && <ItemDetailsPopUp listingId={selectedListingId} onClick={handleItemClose} />}
    </div>
  );
};

export default Home;