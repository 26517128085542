import React, { useState } from 'react';
import ServiceDetailsPopUp from './serviceDetailsPopUp';
import FavoriteServices from './favoriteServices';

const HomeFavoriteServices = () => {
    const [showItemDetails, setShowItemDetails] = useState(false);
    const [selectedListingId, setSelectedListingId] = useState(null);

    const handleItemClick = (listingId) => {
        setSelectedListingId(listingId);
        setShowItemDetails(true);
    };

    const handleItemClose = () => {
        setShowItemDetails(false);
    };

    return (
        <div className="container home-container">
            <FavoriteServices onItemClick={handleItemClick} listingId={selectedListingId} /> {/* Pass both onItemClick and listingId */}
            {showItemDetails && <ServiceDetailsPopUp listingId={selectedListingId} onClick={handleItemClose} />}
        </div>
    );
};

export default HomeFavoriteServices;