import React, { useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLanguage } from './languageContext';

function Search(props) {
  const { currentLanguage } = useLanguage();
  const [inputValuePType, setInputValuPType] = useState("Any");
  const [inputValueNBedrooms, setInputValueNBedrooms] = useState("");
  const [inputValueNBathrooms, setInputValueNBathrooms] = useState("");
  const [inputValuePCondition, setInputValuePCondition] = useState("Any");
  const [inputValuePPrice, setInputValuePPrice] = useState("");
  const [inputValueRPrinciple, setInputValueRPrinciple] = useState("");
  const [inputValueIRate, setInputValueIRate] = useState("");
  const [inputValueRentChargePeriod, setInputValueRentChargePeriod] = useState("");

  const [inputValueAskingPrice, setInputValueAskingPrice] = useState("");
  const [selectedDealType, setSelectedDealType] = useState("forRent");

  const [inputValueRentalUnit, setInputValueRentalUnit] = useState('');
  const [inputValueRentalUnitFurnished, setInputValueRentalUnitFurnished] = useState('');
  const [inputMoveInDate, setInputMoveInDate] = useState(null);

  const storedLanguage = sessionStorage.getItem('currentLanguage');
  const languageToUse = storedLanguage || currentLanguage;

  useEffect(() => {
    // Call onFilterSubmit whenever there's a change in input values
    props.onFilterSubmit(getFilteredData());
  }, [
    inputValuePType,
    inputValueNBedrooms,
    inputValueNBathrooms,
    inputValuePCondition,
    inputValuePPrice,
    inputValueRPrinciple,
    inputValueIRate,
    inputValueRentChargePeriod,
    inputValueAskingPrice,
    selectedDealType,
    props.listingObjects,
    inputMoveInDate,
    inputValueRentalUnit,
    inputValueRentalUnitFurnished,
  ]);

  const handleInputChangePType = (e) => {
    setInputValuPType(e.target.value);
  };
  const handleInputChangeNBedrooms = (e) => {
    setInputValueNBedrooms(e.target.value);
  };
  const handleInputChangeNBathrooms = (e) => {
    setInputValueNBathrooms(e.target.value);
  };
  const handleInputChangePCondition = (e) => {
    setInputValuePCondition(e.target.value);
  };
  const handleInputChangePPrice = (e) => {
    setInputValuePPrice(e.target.value);
  };
  const handleInputChangeRPrinciple = (e) => {
    setInputValueRPrinciple(e.target.value);
  };
  const handleInputChangeIRate = (e) => {
    setInputValueIRate(e.target.value);
  };

  const handleInputDealTypeChange = (e) => {
    setSelectedDealType(e.target.value);
  };

  const handleInputChangeRentChargePeriod = (e) => {
    setInputValueRentChargePeriod(e.target.value);
  };

  const handleInputChangeAskingPrice = (e) => {
    setInputValueAskingPrice(e.target.value);
  };

  // rent related
  const handleInputRentalUnit = (e) => {
    setInputValueRentalUnit(e.target.value);
  };

  const handleRentalUnitFurnished = (e) => {
    setInputValueRentalUnitFurnished(e.target.value);
  };

  const handleInputMoveInDate = (date) => {
    setInputMoveInDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilter();
  };

  const setFilter = () => {
    // Call onFilterSubmit with the filtered data
    props.onFilterSubmit(getFilteredData());
  };

  const getFilteredData = () => {
    const filterCriteria = {
      propertytype: inputValuePType,
      numberOfBedrooms: inputValueNBedrooms,
      numberOfBathrooms: inputValueNBathrooms,
      propertyCondition: inputValuePCondition,
      purchasePrice: inputValuePPrice,
      remaningPrinciple: inputValueRPrinciple,
      interestRate: inputValueIRate,
      rentChargePeriod: inputValueRentChargePeriod,
      askingPrice: inputValueAskingPrice,
      dealtype: selectedDealType,
      rentalUnitAvalabilityDate: inputMoveInDate,
      rentalUnit: inputValueRentalUnit,
      rentalUnitFurnished: inputValueRentalUnitFurnished,
    };

    return props.listingObjects.filter((item) => {
      for (const key in filterCriteria) {
        if (filterCriteria[key] === "Any" || filterCriteria[key] === "") {
          continue;
        }
        if (key === "propertytype" && filterCriteria[key] !== item[key]) {
          return false;
        }
        if (key === "numberOfBedrooms" && parseInt(filterCriteria[key]) > item[key]) {
          return false;
        }
        if (key === "numberOfBathrooms" && parseInt(filterCriteria[key]) > item[key]) {
          return false;
        }
        if (key === "propertyCondition" && filterCriteria[key] !== item[key]) {
          return false;
        }
        if (key === "purchasePrice" && parseInt(filterCriteria[key]) < item[key]) {
          return false;
        }
        if (key === "remaningPrinciple" && parseInt(filterCriteria[key]) < item[key]) {
          return false;
        }
        if (key === "interestRate" && parseInt(filterCriteria[key]) < item[key]) {
          return false;
        }
        if (key === "rentChargePeriod" && (filterCriteria[key]) !== item[key]) {
          return false;
        }
        if (key === "askingPrice" && parseInt(filterCriteria[key]) < item[key]) {
          return false;
        }
        if (key === "dealtype" && (filterCriteria[key] !== item[key])) {
          return false;
        }
        if (key === "rentalUnit" && (filterCriteria[key] !== item[key])) {
          return false;
        }
        if (key === "rentalUnitFurnished" && (filterCriteria[key] !== item[key])) {
          return false;
        }

        const itemDate = new Date(item[key]);
        // Check for "null" value
        if (key === "rentalUnitAvalabilityDate" && (filterCriteria[key] === null)) {
          // Skip filtering for this key
        } else if (key === "rentalUnitAvalabilityDate" && (filterCriteria[key] < itemDate)) {
          return false;
        }
      }
      // if (selectedDealType !== "any") {
      //   if (key === "dealtype" && (filterCriteria[key] !== item[key])) {
      //     return false;
      //   }
      // }
      return true;
    });
  };

  const handleReset = () => {
    props.onFilterSubmit(props.listingObjects);
    setInputValuPType("Any");
    setInputValueNBedrooms("");
    setInputValueNBathrooms("");
    setInputValuePCondition("Any");
    setInputValuePPrice("");
    setInputValueRPrinciple("");
    setInputValueIRate("");
    setInputValueRentChargePeriod("");
    setInputValueAskingPrice("");
    setSelectedDealType("forRent");
    setInputValueRentalUnit('');
    setInputValueRentalUnitFurnished('');
    setInputMoveInDate(null);
  };

  return <div>
    {languageToUse === "ge" && (
      <div>
        <div className="divider-section">
        </div>
        {/* <section className="section section-xs bg-gray-12"> */}
        <section className="section section-xs bg-gray-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <div className="container">
            <div className="row row-50">
              <div className="col-lg-12">
                {/* <article className="block-property bg-default"> */}
                <article className="block-property bg-default" style={{ padding: '20px' }}>
                  <div className="layout-3">
                    <h3>მოძებნეთ სასურველი საცხოვრებელი</h3>
                    <div className="layout-3-item">
                      <ul className="list-inline-bordered heading-7">
                        <li>
                          <label>
                            <input
                              name="input-group-radio"
                              value="forRent"
                              type="radio"
                              checked={selectedDealType === 'forRent'}
                              onChange={handleInputDealTypeChange}
                            />
                            <span>გაქირავება</span>
                          </label>
                        </li>
                        {/* <li>
                      <label>
                        <input
                          name="input-group-radio"
                          value="forSale"
                          type="radio"
                          checked={selectedDealType === 'forSale'}
                          onChange={handleInputDealTypeChange}
                        />
                        <span>For sale</span>
                      </label>
                    </li> */}
                        {/* <li>
                      <label>
                        <input
                          name="input-group-radio"
                          value="forSaleAsMortgageAsset"
                          type="radio"
                          checked={selectedDealType === 'forSaleAsMortgageAsset'}
                          onChange={handleInputDealTypeChange}
                        />
                        <span>For Mortgage Trade</span>
                      </label>
                    </li> */}
                      </ul>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}
                    className="rd-mailform form-property mt-30"
                    data-form-output="form-output-global"
                    data-form-type="contact"
                    method=""
                    action=""
                  >
                    {/* <div className="form-wrap form-wrap-validation">
                  <input placeholder="City, Neighborhood, ZIP, Address" type="address" className="form-control" id="streetAddress" name="address" value={inputValueAddress} onChange={handleInputChangeAddress} />
                </div> */}
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="propertytype" name="propertytype" value={inputValuePType} onChange={handleInputChangePType}>
                        <option value="Any">საცხოვრებლის ტიპი</option>
                        <option value="House">სახლი</option>
                        <option value="Townhouse">ბინა ქალაქურ სახლში</option>
                        <option value="Condo">ბინა კორპუსში (კონდო)</option>
                        <option value="Coop">კოოპერატივი</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="rentalUnit-filter" name="rentalUnit-filter" value={inputValueRentalUnit} onChange={handleInputRentalUnit}>
                        <option value="Any">გასაქირავებელი ფართი</option>
                        <option value="entireProperty">მთლიანი ბინა/სახლი</option>
                        <option value="room">ოთახი</option>
                        <option value="bed">ლოგინი</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select
                        className="form-input select-filter"
                        id="ifUnitIsFurnihsedOrNot-filter"
                        name="ifUnitIsFurnihsedOrNot-filter"
                        value={inputValueRentalUnitFurnished}
                        onChange={handleRentalUnitFurnished}
                      >
                        <option value="Any">ავეჯით ან უავეჯოდ</option>
                        <option value="furnished">ავეჯით</option>
                        <option value="notfurnished">უავეჯოდ</option>
                      </select>
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="numberOfBedrooms" name="numberOfBedrooms" value={inputValueNBedrooms} onChange={handleInputChangeNBedrooms}>
                        <option value="Any">საძინებლის რაოდენობა</option>
                        <option value="0">სტუდიო</option>
                        <option value="1">1+</option>
                        <option value="2">2+</option>
                        <option value="3">3+</option>
                        <option value="4">4+</option>
                        <option value="5">5+</option>
                        <option value="6">6+</option>
                        <option value="7">7+</option>
                        <option value="8">8+</option>
                      </select>
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="numberOfBathrooms" name="numberOfBathrooms" value={inputValueNBathrooms} onChange={handleInputChangeNBathrooms}>
                        <option value="Any">აბაზანის რაოდენობა</option>
                        <option value="1">1+</option>
                        <option value="2">2+</option>
                        <option value="3">3+</option>
                        <option value="4">4+</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="propertyCondition" name="propertyCondition" value={inputValuePCondition} onChange={handleInputChangePCondition}>
                        <option value="Any">საცხოვრებლის მდგომარეობა</option>
                        <option value="Ready for living">ახალი გარემონტებული</option>
                        <option value="Requires renovation">კარგ მდგომარეობაში</option>
                        <option value="Not habitable">საჭიროებს რემონტს</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="PurchasePrice" name="purchasePrice" value={inputValuePPrice} onChange={handleInputChangePPrice}>
                        <option value="Any">Any Last Purchase Price</option>
                        <option value="500000">Max $500,000</option>
                        <option value="800000">Max $800,000</option>
                        <option value="1200000">Max $1,200,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forSaleAsMortgageAsset"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="remaningPrinciple" name="remaningPrinciple" value={inputValueRPrinciple} onChange={handleInputChangeRPrinciple}>
                        <option value="Any">Any Principle Owed</option>
                        <option value="100000">max $100,000</option>
                        <option value="200000">max $200,000</option>
                        <option value="300000">max $300,000</option>
                        <option value="400000">max $400,000</option>
                        <option value="500000">max $500,000</option>
                        <option value="600000">max $600,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forSaleAsMortgageAsset"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="interestRate" name="interestRate" value={inputValueIRate} onChange={handleInputChangeIRate}>
                        <option value="Any">Any Interest Rate</option>
                        <option value="1">max 1%</option>
                        <option value="2">max 2%</option>
                        <option value="3">max 3%</option>
                        <option value="4">max 4%</option>
                        <option value="5">max 5%</option>
                        <option value="6">max 6%</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceEn" name="askingPrice" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">Any Asking Price</option>
                        <option value="100000">Max $100,000</option>
                        <option value="200000">max $200,000</option>
                        <option value="300000">Max $300,000</option>
                        <option value="400000">Max $400,000</option>
                        <option value="500000">Max $500,000</option>
                        <option value="600000">Max $600,000</option>
                        <option value="1000000">Max $1,000,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceGe" name="rentChargePeriod" value={inputValueRentChargePeriod} onChange={handleInputChangeRentChargePeriod}>
                        <option value="">თვიური/დღიური გაქირავება</option>
                        <option value="monthly">თვიური</option>
                        <option value="daily">დღიური</option>
                        <option value="Any">ნებისმიერი, თვიური/დღიური</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent" || inputValueRentChargePeriod === "daily"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceMonthlyGe" name="askingPriceMonthly" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">ქირის ღირებულება</option>
                        <option value="500">Max $500</option>
                        <option value="800">Max $800</option>
                        <option value="1000">Max $1000</option>
                        <option value="1200">Max $1500</option>
                        <option value="1500">Max $2000</option>
                        <option value="2000">Max $3000</option>
                        <option value="2000">Max $4000</option>
                        <option value="2000">Max $5000</option>
                        <option value="Any">ქირის ნებისმიერი ოდენობა</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent" || inputValueRentChargePeriod === "monthly" || inputValueRentChargePeriod === "" || inputValueRentChargePeriod === "Any"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceDailyGe" name="askingPriceDaily" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">ქირის დღიური ღირებულება</option>
                        <option value="30">არაუმეტეს $30</option>
                        <option value="40">არაუმეტეს $40</option>
                        <option value="50">არაუმეტეს $50</option>
                        <option value="70">არაუმეტეს $70</option>
                        <option value="100">არაუმეტეს $100</option>
                        <option value="200">არაუმეტეს $200</option>
                        <option value="Any">ქირის ნებისმიერი ოდენობა</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation higher-z-index">
                      <DatePicker
                        selected={inputMoveInDate}
                        onChange={handleInputMoveInDate}
                        placeholderText="შესვლის თარიღი"
                        dateFormat="MM/dd/yyyy"
                        className="form-input select-filter"
                        popperModifiers={{
                          flip: {
                            behavior: ["top"],
                          },
                          preventOverflow: {
                            enabled: false,
                          },
                          hide: { enabled: false },
                        }}
                      />
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <div className="">
                        <button
                          className="button button-md button-secondary-val form-input"
                          type="button" onClick={handleReset}
                        >
                          ფილტრის მოხსნა
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="panel-1">
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
    {languageToUse === "en" && (
      <div>
        <div className="divider-section">
        </div>
        {/* <section className="section section-xs bg-gray-12"> */}
        <section className="section section-xs bg-gray-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <div className="container">
            <div className="row row-50">
              <div className="col-lg-12">
                {/* <article className="block-property bg-default"> */}
                <article className="block-property bg-default" style={{ padding: '20px' }}>
                  <div className="layout-3">
                    <h3>Find Your Property</h3>
                    <div className="layout-3-item">
                      <ul className="list-inline-bordered heading-7">
                        <li>
                          <label>
                            <input
                              name="input-group-radio"
                              value="forRent"
                              type="radio"
                              checked={selectedDealType === 'forRent'}
                              onChange={handleInputDealTypeChange}
                            />
                            <span>For Rent</span>
                          </label>
                        </li>
                        {/* <li>
                          <label>
                            <input
                              name="input-group-radio"
                              value="forSale"
                              type="radio"
                              checked={selectedDealType === 'forSale'}
                              onChange={handleInputDealTypeChange}
                            />
                            <span>For sale</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              name="input-group-radio"
                              value="forSaleAsMortgageAsset"
                              type="radio"
                              checked={selectedDealType === 'forSaleAsMortgageAsset'}
                              onChange={handleInputDealTypeChange}
                            />
                            <span>For Mortgage Trade</span>
                          </label>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}
                    className="rd-mailform form-property mt-30"
                    data-form-output="form-output-global"
                    data-form-type="contact"
                    method=""
                    action=""
                  >
                    {/* <div className="form-wrap form-wrap-validation">
                  <input placeholder="City, Neighborhood, ZIP, Address" type="address" className="form-control" id="streetAddress" name="address" value={inputValueAddress} onChange={handleInputChangeAddress} />
                </div> */}
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="propertytype" name="propertytype" value={inputValuePType} onChange={handleInputChangePType}>
                        <option value="Any">Any Property Type</option>
                        <option value="House">House</option>
                        <option value="Townhouse">Townhouse</option>
                        <option value="Condo">Condo</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="rentalUnit-filter" name="rentalUnit-filter" value={inputValueRentalUnit} onChange={handleInputRentalUnit}>
                        <option value="Any">Rental unit</option>
                        <option value="entireProperty">Entire property</option>
                        <option value="room">Room</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select
                        className="form-input select-filter"
                        id="ifUnitIsFurnihsedOrNot-filter"
                        name="ifUnitIsFurnihsedOrNot-filter"
                        value={inputValueRentalUnitFurnished}
                        onChange={handleRentalUnitFurnished}
                      >
                        <option value="Any">Select Furnishing Status</option>
                        <option value="furnished">Furnished</option>
                        <option value="notfurnished">Not Furnished</option>
                      </select>
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="numberOfBedrooms" name="numberOfBedrooms" value={inputValueNBedrooms} onChange={handleInputChangeNBedrooms}>
                        <option value="Any">Any Number of Bedrooms</option>
                        <option value="1">1+</option>
                        <option value="2">2+</option>
                        <option value="3">3+</option>
                        <option value="4">4+</option>
                        <option value="5">5+</option>
                        <option value="6">6+</option>
                        <option value="7">7+</option>
                        <option value="8">8+</option>
                      </select>
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="numberOfBathrooms" name="numberOfBathrooms" value={inputValueNBathrooms} onChange={handleInputChangeNBathrooms}>
                        <option value="Any">Any Number of Bathrooms</option>
                        <option value="1">1+</option>
                        <option value="2">2+</option>
                        <option value="3">3+</option>
                        <option value="4">4+</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="propertyCondition" name="propertyCondition" value={inputValuePCondition} onChange={handleInputChangePCondition}>
                        <option value="Any">Any Property Condition</option>
                        <option value="Ready for living">Ready for living</option>
                        <option value="Requires renovation">Requires renovation</option>
                        <option value="Not habitable">Not habitable</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="PurchasePrice" name="purchasePrice" value={inputValuePPrice} onChange={handleInputChangePPrice}>
                        <option value="Any">Any Last Purchase Price</option>
                        <option value="500000">Max $500,000</option>
                        <option value="800000">Max $800,000</option>
                        <option value="1200000">Max $1,200,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forSaleAsMortgageAsset"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="remaningPrinciple" name="remaningPrinciple" value={inputValueRPrinciple} onChange={handleInputChangeRPrinciple}>
                        <option value="Any">Any Principle Owed</option>
                        <option value="100000">max $100,000</option>
                        <option value="200000">max $200,000</option>
                        <option value="300000">max $300,000</option>
                        <option value="400000">max $400,000</option>
                        <option value="500000">max $500,000</option>
                        <option value="600000">max $600,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forSaleAsMortgageAsset"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="interestRate" name="interestRate" value={inputValueIRate} onChange={handleInputChangeIRate}>
                        <option value="Any">Any Interest Rate</option>
                        <option value="1">max 1%</option>
                        <option value="2">max 2%</option>
                        <option value="3">max 3%</option>
                        <option value="4">max 4%</option>
                        <option value="5">max 5%</option>
                        <option value="6">max 6%</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" name="askingPrice" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">Any Asking Price</option>
                        <option value="100000">Max $100,000</option>
                        <option value="200000">max $200,000</option>
                        <option value="300000">Max $300,000</option>
                        <option value="400000">Max $400,000</option>
                        <option value="500000">Max $500,000</option>
                        <option value="600000">Max $600,000</option>
                        <option value="1000000">Max $1,000,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="rentChargePeriod" name="rentChargePeriod" value={inputValueRentChargePeriod} onChange={handleInputChangeRentChargePeriod}>
                        <option value="">Monthly/Daily rental</option>
                        <option value="monthly">Monthly</option>
                        <option value="daily">Daily</option>
                        <option value="Any">Any monthly or daily</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent" || inputValueRentChargePeriod === "daily"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceMonthly" name="askingPriceMonthly" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">Rent Price</option>
                        <option value="500">Max $500</option>
                        <option value="800">Max $800</option>
                        <option value="1000">Max $1000</option>
                        <option value="1200">Max $1500</option>
                        <option value="1500">Max $2000</option>
                        <option value="2000">Max $3000</option>
                        <option value="2000">Max $4000</option>
                        <option value="2000">Max $5000</option>
                        <option value="Any">Any rent price</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent" || inputValueRentChargePeriod === "monthly" || inputValueRentChargePeriod === "" || inputValueRentChargePeriod === "Any"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceDaily" name="askingPriceDaily" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">Daily Rent Price</option>
                        <option value="30">Max $30</option>
                        <option value="40">Max $40</option>
                        <option value="50">Max $50</option>
                        <option value="70">Max $70</option>
                        <option value="100">Max $100</option>
                        <option value="200">Max $200</option>
                        <option value="Any">Any rent price</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation higher-z-index">
                      <DatePicker
                        selected={inputMoveInDate}
                        onChange={handleInputMoveInDate}
                        placeholderText="Move in Date"
                        dateFormat="MM/dd/yyyy"
                        className="form-input select-filter"
                        popperModifiers={{
                          flip: {
                            behavior: ["top"],
                          },
                          preventOverflow: {
                            enabled: false,
                          },
                          hide: { enabled: false },
                        }}
                      />
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <div className="">
                        <button
                          className="button button-md button-secondary-val form-input"
                          type="button" onClick={handleReset}
                        >
                          Ignore filter
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="panel-1">
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
    {languageToUse === "ru" && (
      <div>
        <div className="divider-section">
        </div>
        {/* <section className="section section-xs bg-gray-12"> */}
        <section className="section section-xs bg-gray-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <div className="container">
            <div className="row row-50">
              <div className="col-lg-12">
                {/* <article className="block-property bg-default"> */}
                <article className="block-property bg-default" style={{ padding: '20px' }}>
                  <div className="layout-3">
                    <h3>Найдите свою недвижимость</h3>
                    <div className="layout-3-item">
                      <ul className="list-inline-bordered heading-7">
                        <li>
                          <label>
                            <input
                              name="input-group-radio"
                              value="forRent"
                              type="radio"
                              checked={selectedDealType === 'forRent'}
                              onChange={handleInputDealTypeChange}
                            />
                            <span>Аренда</span>
                          </label>
                        </li>
                        {/* <li>
                          <label>
                            <input
                              name="input-group-radio"
                              value="forSale"
                              type="radio"
                              checked={selectedDealType === 'forSale'}
                              onChange={handleInputDealTypeChange}
                            />
                            <span>For sale</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              name="input-group-radio"
                              value="forSaleAsMortgageAsset"
                              type="radio"
                              checked={selectedDealType === 'forSaleAsMortgageAsset'}
                              onChange={handleInputDealTypeChange}
                            />
                            <span>For Mortgage Trade</span>
                          </label>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}
                    className="rd-mailform form-property mt-30"
                    data-form-output="form-output-global"
                    data-form-type="contact"
                    method=""
                    action=""
                  >
                    {/* <div className="form-wrap form-wrap-validation">
                  <input placeholder="City, Neighborhood, ZIP, Address" type="address" className="form-control" id="streetAddress" name="address" value={inputValueAddress} onChange={handleInputChangeAddress} />
                </div> */}
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="propertytype" name="propertytype" value={inputValuePType} onChange={handleInputChangePType}>
                        <option value="Any">Тип недвижимости</option>
                        <option value="House">Дом</option>
                        <option value="Townhouse">Таунхаус</option>
                        <option value="Condo">Кондоминиум</option>
                        <option value="Coop">Кооператив</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="rentalUnit-filter" name="rentalUnit-filter" value={inputValueRentalUnit} onChange={handleInputRentalUnit}>
                        <option value="Any">Арендная единица</option>
                        <option value="entireProperty">Вся недвижимость/Квартира</option>
                        <option value="room">Комната</option>
                        <option value="bed">Койко-места</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select
                        className="form-input select-filter"
                        id="ifUnitIsFurnihsedOrNot-filter"
                        name="ifUnitIsFurnihsedOrNot-filter"
                        value={inputValueRentalUnitFurnished}
                        onChange={handleRentalUnitFurnished}
                      >
                        <option value="Any">Статус меблировки</option>
                        <option value="furnished">Мебелировання</option>
                        <option value="notfurnished">Без мебели</option>
                      </select>
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="numberOfBedrooms" name="numberOfBedrooms" value={inputValueNBedrooms} onChange={handleInputChangeNBedrooms}>
                        <option value="Any">Количество спален</option>
                        <option value="1">1+</option>
                        <option value="2">2+</option>
                        <option value="3">3+</option>
                        <option value="4">4+</option>
                        <option value="5">5+</option>
                        <option value="6">6+</option>
                        <option value="7">7+</option>
                        <option value="8">8+</option>
                      </select>
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="numberOfBathrooms" name="numberOfBathrooms" value={inputValueNBathrooms} onChange={handleInputChangeNBathrooms}>
                        <option value="Any">Количество ванных комнат</option>
                        <option value="1">1+</option>
                        <option value="2">2+</option>
                        <option value="3">3+</option>
                        <option value="4">4+</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select className="form-input select-filter" id="propertyCondition" name="propertyCondition" value={inputValuePCondition} onChange={handleInputChangePCondition}>
                        <option value="Any">Любое состояние недвижимости</option>
                        <option value="Ready for living">Готов к проживанию</option>
                        <option value="Requires renovation">Требует ремонта</option>
                        <option value="Not habitable">Не пригоден для проживания</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="PurchasePrice" name="purchasePrice" value={inputValuePPrice} onChange={handleInputChangePPrice}>
                        <option value="Any">Any Last Purchase Price</option>
                        <option value="500000">Max $500,000</option>
                        <option value="800000">Max $800,000</option>
                        <option value="1200000">Max $1,200,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forSaleAsMortgageAsset"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="remaningPrinciple" name="remaningPrinciple" value={inputValueRPrinciple} onChange={handleInputChangeRPrinciple}>
                        <option value="Any">Any Principle Owed</option>
                        <option value="100000">max $100,000</option>
                        <option value="200000">max $200,000</option>
                        <option value="300000">max $300,000</option>
                        <option value="400000">max $400,000</option>
                        <option value="500000">max $500,000</option>
                        <option value="600000">max $600,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forSaleAsMortgageAsset"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="interestRate" name="interestRate" value={inputValueIRate} onChange={handleInputChangeIRate}>
                        <option value="Any">Any Interest Rate</option>
                        <option value="1">max 1%</option>
                        <option value="2">max 2%</option>
                        <option value="3">max 3%</option>
                        <option value="4">max 4%</option>
                        <option value="5">max 5%</option>
                        <option value="6">max 6%</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType === "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" name="askingPrice" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">Any Asking Price</option>
                        <option value="100000">Max $100,000</option>
                        <option value="200000">max $200,000</option>
                        <option value="300000">Max $300,000</option>
                        <option value="400000">Max $400,000</option>
                        <option value="500000">Max $500,000</option>
                        <option value="600000">Max $600,000</option>
                        <option value="1000000">Max $1,000,000</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="rentChargePeriod" name="rentChargePeriod" value={inputValueRentChargePeriod} onChange={handleInputChangeRentChargePeriod}>
                        <option value="">Снять</option>
                        <option value="monthly">Ежемесячно</option>
                        <option value="daily">Посуточная</option>
                        <option value="Any">Помесячная или Посуточная аренда</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent" || inputValueRentChargePeriod === "daily"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceMonthly" name="askingPriceMonthly" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">Стоимость аренды</option>
                        <option value="500">Макс $500</option>
                        <option value="800">Макс $800</option>
                        <option value="1000">Макс $1000</option>
                        <option value="1200">Макс $1500</option>
                        <option value="1500">Макс $2000</option>
                        <option value="2000">Макс $3000</option>
                        <option value="2000">Макс $4000</option>
                        <option value="2000">Макс $5000</option>
                        <option value="Any">Любая стоимость аренды</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent" || inputValueRentChargePeriod === "monthly" || inputValueRentChargePeriod === "" || inputValueRentChargePeriod === "Any"} className="form-wrap form-wrap-validation">
                      <select type="text" className="form-input select-filter" id="askingPriceDaily" name="askingPriceDaily" value={inputValueAskingPrice} onChange={handleInputChangeAskingPrice}>
                        <option value="Any">Цена посуточной аренды</option>
                        <option value="30">Макс $30</option>
                        <option value="40">Макс $40</option>
                        <option value="50">Макс $50</option>
                        <option value="70">Макс $70</option>
                        <option value="100">Макс $100</option>
                        <option value="200">Макс $200</option>
                        <option value="Any">Любая стоимость аренды</option>
                      </select>
                    </div>
                    <div hidden={selectedDealType !== "forRent"} className="form-wrap form-wrap-validation higher-z-index">
                      <DatePicker
                        selected={inputMoveInDate}
                        onChange={handleInputMoveInDate}
                        placeholderText="Дата переезда"
                        dateFormat="MM/dd/yyyy"
                        className="form-input select-filter"
                        popperModifiers={{
                          flip: {
                            behavior: ["top"],
                          },
                          preventOverflow: {
                            enabled: false,
                          },
                          hide: { enabled: false },
                        }}
                      />
                    </div>
                    <div className="form-wrap form-wrap-validation">
                      <div className="">
                        <button
                          className="button button-md button-secondary-val form-input"
                          type="button" onClick={handleReset}
                        >
                          Игнорировать фильтр
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="panel-1">
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
  </div>
}
export default Search;