
// import React, { useState, useEffect } from 'react';
// import { useAuth } from './authContext';

// const VisitCounts = () => {
//     const { loggedIn, userId } = useAuth();

//     const [visitCount, setVisitCount] = useState(0);
//     const [ipAddress, setIpAddress] = useState('');
//     const [visitDates, setVisitDates] = useState([]);

//     useEffect(() => {
//         fetch('/visitcountreport')
//             .then(res => res.json())
//             .then(data => {
//                 setVisitCount(data.uniqueVisits);
//                 setIpAddress(data.ipAddress);
//                 setVisitDates(data.visitDates || []);
//             })
//             .catch(err => console.error('Error fetching visit count:', err));
//     }, []);


//     // Check if user is authenticated and has the username "admin"
//     const isAdmin = loggedIn && userId === '655c4db70c42d834a913a5e4';

//     return (
//         <div className="container home-container">
//             <div>
//                 {isAdmin && ( // Render VisitCounter only if user is admin
//                     <div style={{ fontSize: '11px' }}>
//                         <h5 style={{ fontSize: '11px' }}>Page Visits: {visitCount}</h5>
//                         <p>IP Address: {ipAddress}</p>
//                         <p>Unique Visit Dates:</p>
//                         <ul>
//                             {visitDates && visitDates.length > 0 ? (
//                                 visitDates.map(date => (
//                                     <li key={date}>{date}</li>
//                                 ))
//                             ) : (
//                                 <li>No visit dates available</li>
//                             )}
//                         </ul>
//                     </div>
//                 )}
//             </div>

//         </div>
//     );
// };

// export default VisitCounts;


// import React, { useState, useEffect } from 'react';
// import { useAuth } from './authContext';
// import backendUrl from './config';

// const VisitCounts = () => {
//     const { loggedIn, userId } = useAuth();

//     const [visitCount, setVisitCount] = useState(0);
//     const [ipAddress, setIpAddress] = useState('');
//     const [visitDates, setVisitDates] = useState([]);

//     useEffect(() => {
//         if (!loggedIn) return; // Do not fetch if user is not logged in

//         fetch(`${backendUrl}/visitcountreport`)
//             .then(res => res.json())
//             .then(data => {
//                 // Assuming data is an array of visit counts for each IP address
//                 // Summing up the visit counts to get the total visit count
//                 const totalVisitCount = data.reduce((total, item) => total + item.visitCount, 0);
//                 setVisitCount(totalVisitCount);

//                 // Assuming you want to display only the first IP address and its visit dates
//                 if (data.length > 0) {
//                     setIpAddress(data[0].ipAddress);
//                     setVisitDates(data[0].visitDates || []);
//                 }
//             })
//             .catch(err => console.error('Error fetching visit count:', err));
//     }, [loggedIn]); // Fetch only when user logs in


//     // Check if user is authenticated and has the username "admin"
//     const isAdmin = loggedIn && userId === '655c4db70c42d834a913a5e4';

//     return (
//         <div className="container home-container">
//             <div>
//                 {isAdmin && ( // Render VisitCounter only if user is admin
//                     <div style={{ fontSize: '11px' }}>
//                         <h5 style={{ fontSize: '11px' }}>Page Visits: {visitCount}</h5>
//                         <p>IP Address: {ipAddress}</p>
//                         <p>Unique Visit Dates:</p>
//                         <ul>
//                             {visitDates && visitDates.length > 0 ? (
//                                 visitDates.map(date => (
//                                     <li key={date}>{date}</li>
//                                 ))
//                             ) : (
//                                 <li>No visit dates available</li>
//                             )}
//                         </ul>
//                     </div>
//                 )}
//             </div>

//         </div>
//     );
// };

// export default VisitCounts;


// import React, { useState, useEffect } from 'react';
// import { useAuth } from './authContext';
// import backendUrl from './config';

// const VisitCounts = () => {
//     const { loggedIn, userId } = useAuth();

//     const [visitCounts, setVisitCounts] = useState([]);

//     useEffect(() => {
//         if (!loggedIn) return; // Do not fetch if user is not logged in

//         fetch(`${backendUrl}/visitcountreport`)
//             .then(res => res.json())
//             .then(data => {
//                 setVisitCounts(data);
//             })
//             .catch(err => console.error('Error fetching visit count:', err));
//     }, [loggedIn]); // Fetch only when user logs in

//     // Check if user is authenticated and has the username "admin"
//     const isAdmin = loggedIn && userId === '655c4db70c42d834a913a5e4';

//     return (
//         <div className="container home-container">
//             <div>
//                 {isAdmin && ( // Render VisitCounter only if user is admin
//                     <table style={{ fontSize: '11px', width: '100%', borderCollapse: 'collapse' }}>
//                         <thead>
//                             <tr style={{ backgroundColor: '#f2f2f2' }}>
//                                 <th style={{ padding: '8px', border: '1px solid #ddd' }}>IP Address</th>
//                                 <th style={{ padding: '8px', border: '1px solid #ddd' }}>Unique Visit Count</th>
//                                 <th style={{ padding: '8px', border: '1px solid #ddd' }}>Unique Visit Dates</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {visitCounts.length > 0 ? (
//                                 visitCounts.map((visitData, index) => (
//                                     <tr key={index}>
//                                         <td style={{ padding: '8px', border: '1px solid #ddd' }}>{visitData.ipAddress}</td>
//                                         <td style={{ padding: '8px', border: '1px solid #ddd' }}>{visitData.visitCount}</td>
//                                         <td style={{ padding: '8px', border: '1px solid #ddd' }}>
//                                             <ul style={{ margin: '0', padding: '0', listStyleType: 'none' }}>
//                                                 {visitData.uniqueVisitDates && visitData.uniqueVisitDates.length > 0 ? (
//                                                     visitData.uniqueVisitDates.map((date, index) => (
//                                                         <li key={index}>{date}</li>
//                                                     ))
//                                                 ) : (
//                                                     <li>No visit dates available</li>
//                                                 )}
//                                             </ul>
//                                         </td>
//                                     </tr>
//                                 ))
//                             ) : (
//                                 <tr>
//                                     <td colSpan="3" style={{ padding: '8px', border: '1px solid #ddd' }}>No visit counts available</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 )}
//             </div>
//         </div>
//     );

// };

// export default VisitCounts;

import React, { useState, useEffect } from 'react';
import { useAuth } from './authContext';
import backendUrl from './config';

const VisitCounts = ({ product }) => {
    const { loggedIn, userId } = useAuth();

    const [visitCounts, setVisitCounts] = useState([]);

    useEffect(() => {
        if (!loggedIn) return; // Do not fetch if user is not logged in

        fetch(`${backendUrl}/visitcountreport/${product}`)
            .then(res => res.json())
            .then(data => {
                setVisitCounts(data);
            })
            .catch(err => console.error('Error fetching visit count:', err));
    }, [loggedIn]); // Fetch only when user logs in

    // Check if user is authenticated and has the username "admin"
    const isAdmin = loggedIn && userId === '655c4db70c42d834a913a5e4';

    return (
        <div className="container home-container">
            <div>
                {isAdmin && (
                    <table style={{ fontSize: '11px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#f2f2f2' }}>
                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>#</th>
                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>IP Address</th>
                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Unique Visit Count</th>
                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Unique Visit Dates and Times</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitCounts.length > 0 ? (
                                visitCounts.map((visitData, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>{index + 1}</td>
                                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>{visitData.ipAddress}</td>
                                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>{visitData.visitCount}</td>
                                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                            <ul style={{ margin: '0', padding: '0', listStyleType: 'none' }}>
                                                {visitData.visitDetails && visitData.visitDetails.length > 0 && visitData.visitDetails.map((detail, detailIndex) => (
                                                    <li key={detailIndex}>
                                                        {detail.date} ({detail.firstVisit ? detail.firstVisit + ' - ' : ''}{detail.lastVisit ? detail.lastVisit : ''})
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" style={{ padding: '8px', border: '1px solid #ddd' }}>No visit counts available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );

};

export default VisitCounts;

