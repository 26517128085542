// import React, { useState, useEffect } from "react";
// import AddressSearch from "./addressSearch";
// import Loader from './loader';
// import PopUpButton from "./popUpButton";
// import ChatBot from "./chatBot";
// import backendUrl from './config';
// import { Link } from 'react-router-dom';
// import Vehicle from "./vehicle";
// import { useLanguage } from './languageContext';
// import SearchVehicle from "./searchVehilce";
// import SubMenue from "./subMenue";

// let listingData;

// function createEntry(listedVehicle) {
//     return (
//         <Vehicle
//             key={listedVehicle._id}
//             listingId={listedVehicle._id}
//             dealtype={listedVehicle.dealtype}
//             VIN={listedVehicle.VIN}
//             city={listedVehicle.city}
//             state={listedVehicle.state}
//             zip={listedVehicle.zip}
//             make={listedVehicle.make}
//             model={listedVehicle.model}
//             year={listedVehicle.year}
//             transmission={listedVehicle.transmission}
//             engine={listedVehicle.engine}
//             fuel={listedVehicle.fuel}
//             mileage={listedVehicle.mileage}
//             exteriorcolor={listedVehicle.exteriorcolor}
//             interiorcolor={listedVehicle.interiorcolor}
//             interior={listedVehicle.interior}
//             accident={listedVehicle.accident}
//             condition={listedVehicle.condition}
//             tires={listedVehicle.tires}
//             userId={listedVehicle.userId}
//             askingPrice={listedVehicle.askingPrice}
//             subscriptionStatus={listedVehicle.subscriptions[listedVehicle.subscriptions.length - 1].status}
//             imgs={listedVehicle.imgs}
//             lisingtype="listings"
//             isFavoriteBySelection={false}
//         />
//     );
// }

// function VehiclePoster(props) {
//     const product = "vehicle";
//     const { currentLanguage } = useLanguage();
//     const [data, setInitialData] = useState([]);
//     const [listedVehicles, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [addressFilter, setAddressFilter] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);

//     useEffect(() => {
//         fetch(`${backendUrl}/vehicles`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 // Add any additional headers if needed
//             },
//             credentials: 'include', // Include credentials in the request
//         })
//             .then((response) => response.json())
//             .then((responseData) => {
//                 setInitialData(responseData);
//                 setData(responseData);
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 console.error("Error fetching data:", error);
//                 setLoading(false);
//             });
//     }, []);

//     if (loading) {
//         return <Loader key={1} loading={loading} />;
//     }

//     function AppyAddressFilter(value) {
//         // setData(data);
//         setAddressFilter(value);
//     }

//     const itemsPerPage = 9;

//     const filteredListings = listedVehicles && listedVehicles.filter((item) => {
//         const lowerCaseSearch = addressFilter.toLowerCase();
//         return (
//             lowerCaseSearch === '' ||
//             (item && item.state && item.state.toLowerCase().includes(lowerCaseSearch)) ||
//             (item && item.city && item.city.toLowerCase().includes(lowerCaseSearch)) ||
//             (item && item.zip && item.zip.toString().includes(lowerCaseSearch))
//         );
//     });

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = (filteredListings || []).reverse().slice(indexOfFirstItem, indexOfLastItem);

//     const totalPages = filteredListings ? Math.ceil(filteredListings.length / itemsPerPage) : 0;


//     const nextPage = () => {
//         setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//     };

//     const prevPage = () => {
//         setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//     };

//     function ApplySearchFilter(filteredBySearchListings) {
//         setData(filteredBySearchListings);
//     }

//     return <main>
//         {currentLanguage === "ge" && (
//             <div>
//                 <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
//                     <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
//                         <h2 className="breadcrumbs-custom-title">მოძებნეთ ავტომანქანა</h2>
//                         <div className="container">
//                             <AddressSearch key={1} listedVehicles={data} onAddressInputChange={(value) => AppyAddressFilter(value)} />
//                         </div>
//                     </div>
//                 </section>
//                 <SubMenue product={product} />
//                 <SearchVehicle listedVehicles={data}
//                     onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)}
//                 />
//                 <div className="album py-5 bg-body-tertiary">
//                     <div className="container">
//                         <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
//                             {currentItems.map(createEntry)}
//                         </div>
//                     </div>
//                     <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
//                         <button className="button-secondary" style={{ marginRight: "20px", borderRadius: "6px", width: "90px" }} onClick={prevPage} disabled={currentPage === 1}>წინა</button>
//                         <span style={{ color: "goldenrod", fontWeight: "450" }}>{`Page  ${currentPage}  of  ${totalPages}`}</span>
//                         <button className="button-secondary" style={{ marginLeft: "20px", borderRadius: "6px", width: "90px" }} onClick={nextPage} disabled={currentPage === totalPages}>შემდეგი</button>
//                     </div>
//                 </div>
//                 <div className="col-md-6 col-lg-12">
//                     <div className="block-info bg-default">
//                         <div className="form-wrap chat-bot">
//                             <div className="container"><ChatBot key={1} /></div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )}
//         {currentLanguage === "en" && (
//             <div>
//                 <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
//                     <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
//                         <h2 className="breadcrumbs-custom-title">Find vehicle</h2>
//                         <div className="container">
//                             <AddressSearch key={1} listedVehicles={data} onAddressInputChange={(value) => AppyAddressFilter(value)} />
//                         </div>
//                     </div>
//                 </section>
//                 <SubMenue product={product} />
//                 <SearchVehicle listedVehicles={data}
//                     onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)}
//                 />
//                 <div className="album py-5 bg-body-tertiary">
//                     <div className="container">
//                         <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
//                             {currentItems.map(createEntry)}
//                         </div>
//                     </div>
//                     <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
//                         <button className="button-secondary" style={{ marginRight: "20px", borderRadius: "6px" }} onClick={prevPage} disabled={currentPage === 1}>Prev</button>
//                         <span style={{ color: "goldenrod", fontWeight: "450" }}>{`Page  ${currentPage}  of  ${totalPages}`}</span>
//                         <button className="button-secondary" style={{ marginLeft: "20px", borderRadius: "6px" }} onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
//                     </div>
//                 </div>
//                 <div className="col-md-6 col-lg-12">
//                     <div className="block-info bg-default">
//                         <div className="form-wrap chat-bot">
//                             <div className="container"><ChatBot key={1} /></div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )}
//     </main>
// }

// export { listingData };
// export default VehiclePoster;


import React, { useState, useEffect } from "react";
import AddressSearch from "./addressSearch";
import Loader from './loader';
import PopUpButton from "./popUpButton";
import ChatBot from "./chatBot";
import backendUrl from './config';
import { Link } from 'react-router-dom';
import Vehicle from "./vehicle";
import { useLanguage } from './languageContext';
import SearchVehicle from "./searchVehilce";
import SubMenue from "./subMenue";

let listingData;

function createEntry(listedVehicle, onItemClick) {
    return (
        <Vehicle
            key={listedVehicle._id}
            onClick={() => onItemClick(listedVehicle._id)}
            listingId={listedVehicle._id}
            dealtype={listedVehicle.dealtype}
            VIN={listedVehicle.VIN}
            city={listedVehicle.city}
            state={listedVehicle.state}
            zip={listedVehicle.zip}
            make={listedVehicle.make}
            model={listedVehicle.model}
            year={listedVehicle.year}
            transmission={listedVehicle.transmission}
            engine={listedVehicle.engine}
            fuel={listedVehicle.fuel}
            mileage={listedVehicle.mileage}
            exteriorcolor={listedVehicle.exteriorcolor}
            interiorcolor={listedVehicle.interiorcolor}
            interior={listedVehicle.interior}
            accident={listedVehicle.accident}
            condition={listedVehicle.condition}
            tires={listedVehicle.tires}
            userId={listedVehicle.userId}
            askingPrice={listedVehicle.askingPrice}
            subscriptionStatus={listedVehicle.subscriptions[listedVehicle.subscriptions.length - 1].status}
            imgs={listedVehicle.imgs}
            lisingtype="listings"
            isFavoriteBySelection={false}
        />
    );
}

const VehiclePoster = ({ onItemClick, listingId }) => {
    const product = "vehicle";
    const { currentLanguage } = useLanguage();
    const [data, setInitialData] = useState([]);
    const [listedVehicles, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addressFilter, setAddressFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        fetch(`${backendUrl}/vehicles`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // Add any additional headers if needed
            },
            credentials: 'include', // Include credentials in the request
        })
            .then((response) => response.json())
            .then((responseData) => {
                setInitialData(responseData);
                setData(responseData);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Loader key={1} loading={loading} />;
    }

    function AppyAddressFilter(value) {
        // setData(data);
        setAddressFilter(value);
    }

    const itemsPerPage = 9;

    const filteredListings = listedVehicles && listedVehicles.filter((item) => {
        const lowerCaseSearch = addressFilter.toLowerCase();
        return (
            lowerCaseSearch === '' ||
            (item && item.state && item.state.toLowerCase().includes(lowerCaseSearch)) ||
            (item && item.city && item.city.toLowerCase().includes(lowerCaseSearch)) ||
            (item && item.zip && item.zip.toString().includes(lowerCaseSearch))
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (filteredListings || []).reverse().slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

    // Calculate the range of pages to display
    let pageRangeStart = Math.max(1, currentPage - 3);
    let pageRangeEnd = Math.min(currentPage + 3, totalPages);

    // Adjust page range if currentPage is near the first or last page
    if (currentPage <= 3) {
        pageRangeStart = 1; // Start from the first page if currentPage is within the first 3 pages
        pageRangeEnd = Math.min(7, totalPages); // Display 7 pages if total pages are less than 7
    } else if (currentPage >= totalPages - 3) {
        pageRangeStart = Math.max(1, totalPages - 6); // Start from the appropriate page to display 7 pages
    }


    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    // Define a function to handle page navigation
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    function ApplySearchFilter(filteredBySearchListings) {
        setCurrentPage(1);
        setData(filteredBySearchListings);
    }

    return <main>
        {languageToUse === "ge" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title">მოძებნეთ ავტომანქანა</h2>
                        <div className="container">
                            <AddressSearch key={1} listedVehicles={data} onAddressInputChange={(value) => AppyAddressFilter(value)} />
                        </div>
                    </div>
                </section>
                <SubMenue product={product} />
                <SearchVehicle listedVehicles={data}
                    onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)}
                />
                {/* <div className="album py-5 bg-body-tertiary"> */}
                <div className="album bg-body-tertiary">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            {currentItems.map(listedVehicle => createEntry(listedVehicle, onItemClick))}
                        </div>
                    </div>
                </div>
                <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                        (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                            <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                        )
                    ))}
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                    <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                </div>
                <div className="col-md-6 col-lg-12">
                    <div className="block-info bg-default">
                        <div className="form-wrap chat-bot">
                            <div className="container"><ChatBot key={1} /></div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {languageToUse === "en" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title">Find vehicle</h2>
                        <div className="container">
                            <AddressSearch key={1} listedVehicles={data} onAddressInputChange={(value) => AppyAddressFilter(value)} />
                        </div>
                    </div>
                </section>
                <SubMenue product={product} />
                <SearchVehicle listedVehicles={data}
                    onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)}
                />
                {/* <div className="album py-5 bg-body-tertiary"> */}
                <div className="album bg-body-tertiary">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            {currentItems.map(listedVehicle => createEntry(listedVehicle, onItemClick))}
                        </div>
                    </div>
                </div>
                <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                        (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                            <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                        )
                    ))}
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                    <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                </div>
                <div className="col-md-6 col-lg-12">
                    <div className="block-info bg-default">
                        <div className="form-wrap chat-bot">
                            <div className="container"><ChatBot key={1} /></div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {languageToUse === "ru" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/vehicleBackground2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title">Найти автомобиль</h2>
                        <div className="container">
                            <AddressSearch key={1} listedVehicles={data} onAddressInputChange={(value) => AppyAddressFilter(value)} />
                        </div>
                    </div>
                </section>
                <SubMenue product={product} />
                <SearchVehicle listedVehicles={data}
                    onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)}
                />
                {/* <div className="album py-5 bg-body-tertiary"> */}
                <div className="album bg-body-tertiary">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                            {currentItems.map(listedVehicle => createEntry(listedVehicle, onItemClick))}
                        </div>
                    </div>
                </div>
                <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                        (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                            <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                        )
                    ))}
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                    <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                </div>
                <div className="col-md-6 col-lg-12">
                    <div className="block-info bg-default">
                        <div className="form-wrap chat-bot">
                            <div className="container"><ChatBot key={1} /></div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </main>
}

export { listingData };
export default VehiclePoster;