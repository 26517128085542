import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './components/main';
import { AuthProvider } from './components/authContext'
import { LanguageProvider } from './components/languageContext';

function App() {
  return (
    <Router>
      <LanguageProvider>
        <div className="App">
          <AuthProvider>
            <Main />
          </AuthProvider>
        </div>
      </LanguageProvider>
    </Router>
  );
}

export default App;