import React, { useState } from 'react';
import backendUrl from './config';
import { useLanguage } from './languageContext';

const VerificationComponent = (props) => {
    const { currentLanguage } = useLanguage();
    const [verificationCode, setVerificationCode] = useState('');
    const [responseMessage, setResponseMessage] = useState("");
    const [emailverified, setEmailverified] = useState(true);

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const handleVerification = async () => {
        try {
            // Send the verification code to the backend for validation
            const response = await fetch(`${backendUrl}/verify/registration`, {
                method: "POST",
                body: JSON.stringify({
                    username: props.username,
                    verificationCode: verificationCode,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if needed
                }
            });

            if (response.ok) {
                const data = await response.json();
                setResponseMessage(data.message);
                // Call the parent component's callback to indicate successful verification
                props.onVerificationSuccess(true);
                setEmailverified(true);
            } else {
                console.error("Error verifying user:", response.status, response.statusText);
                // Call the parent component's callback to indicate unsuccessful verification
                props.onVerificationSuccess(false);
                setEmailverified(false);
            }
        } catch (error) {
            console.error("Error:", error);
            // Call the parent component's callback to indicate unsuccessful verification
            props.onVerificationSuccess(false);
            setEmailverified(false);
        }
    };

    return (
        <div>
            {languageToUse === "ge" && (
                <div>
                    <p>შეიყვანეთ ვერიფიკაციის კოდი, რომელიც გამოგზავნილია ელექტრონული ფოსტით</p>
                    <input
                        type="text"
                        className='form-input'
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <button className='button button-sm button-primary button-block' onClick={handleVerification}>გაიარეთ ვერიფიკაცია</button>
                    <p>{responseMessage}</p>
                    <p hidden={emailverified}>ვერიფიკაცია წარუმატებლად დასრულდა, კიდევ ცადეთ</p></div>
            )}
            {languageToUse === "en" && (
                <div>
                    <p>Enter the verification code sent to your email:</p>
                    <input
                        type="text"
                        className='form-input'
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <button className='button button-sm button-primary button-block' onClick={handleVerification}>Verify</button>
                    <p>{responseMessage}</p>
                    <p hidden={emailverified}>Verification failed, try again</p></div>
            )}
            {languageToUse === "ru" && (
                <div>
                    <p>Введите код подтверждения, отправленный на вашу электронную почту:</p>
                    <input
                        type="text"
                        className='form-input'
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <button className='button button-sm button-primary button-block' onClick={handleVerification}>Проверить</button>
                    <p>{responseMessage}</p>
                    <p hidden={emailverified}>Проверка не удалась, попробуйте еще раз</p></div>
            )}
        </div>
    );
};

export default VerificationComponent;
