// GoogleSignInComponent.js
import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './authContext';

function GoogleSignInComponent({ setUser, onLoggedIn }) {
    const { loginWithGoogle } = useAuth();
    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: "977694153105-6qtglkrvj10hv78k7op893v9abdkg5sh.apps.googleusercontent.com",
            callback: handleCallbackResponse
        })

        google.accounts.id.renderButton(
            document.getElementById("logInDiv"),
            { theme: "outline", size: "large" }
        )
    }, [])

    function handleCallbackResponse(response) {
        // console.log("Encoded JWT ID token: " + response.credential);
        var userObject = jwtDecode(response.credential);
        // console.log(userObject);
        setUser(userObject);
        document.getElementById("logInDiv").hidden = false; //set "true" if googelLogIn div neesd to be closed after login
        if (onLoggedIn) {
            onLoggedIn(); // Call the callback function to notify parent component
        }
        // Call the loginWithGoogle function from the auth context to update authentication status
        loginWithGoogle(response.credential);
    }

    return (
        <div>
            <div className="" id="logInDiv"></div>
        </div>
    );
}

export default GoogleSignInComponent;
