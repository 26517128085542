import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
// import { State, City } from 'country-state-city';
import { State, City } from 'country-state-city';
import PopUpButton from './popUpButton';
import backendUrl from './config';
import { Link } from 'react-router-dom';
import { useAuth } from './authContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLanguage } from './languageContext';
import SubMenue from "./subMenue";


function EditListing() {
    const product = "realestate";
    const { currentLanguage } = useLanguage();
    const { itemId } = useParams();
    const { loggedIn, userId } = useAuth();
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // Load selected listing start
    const [selectedObject, setSelectedObject] = useState({});
    const [imagePaths, setImagePaths] = useState([]);
    useEffect(() => {
        fetch(`${backendUrl}/editlisting/${new Date().getTime()}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                setSelectedObject(responseData);
                setLoading(false);
                fetchPhotos(responseData);

                setInputValueAddress(responseData.address);
                setInputValueState(responseData.state);
                const stateCities = City.getCitiesOfState('US', responseData.state);
                setCities(stateCities);
                setInputCity(responseData.city);
                setInputValueZip(responseData.zip);
                setInputValuPTypee(responseData.propertytype);
                setInputValueNBedrooms(responseData.numberOfBedrooms);
                setInputValueNBathrooms(responseData.numberOfBathrooms);
                setInputValuePCondition(responseData.propertyCondition);
                setInputValuePPrice(responseData.purchasePrice);
                setInputValueRPrinciple(responseData.remaningPrinciple);
                setInputValueIRate(responseData.interestRate);
                setInputValueAmortizationPeriod(responseData.loanAmortizationPeriod);
                setInputValueRAmortizationPeriod(responseData.remaningAmortizationPeriod);
                setInputValueMPayment(responseData.monthlyPayment);
                setInputValuePTax(responseData.propertyTax);
                setInputValueAskingPrice(responseData.askingPrice);
                setInputValueArea(responseData.area);
                setInputValueGarages(responseData.garages);
                setInputValueTwostories(responseData.twostories);
                setInputValueBasketballcourt(responseData.basketballcourt);
                setInputValueGym(responseData.gym);
                setInputValueFireplace(responseData.fireplace);
                setInputValueSprinklers(responseData.sprinklers);
                setInputValuePrivatespace(responseData.privatespace);
                setInputValueBalcony(responseData.balcony);
                setInputValueLaundry(responseData.laundry);
                setInputValueOceanview(responseData.oceanview);
                setInputValueCentralheating(responseData.centralheating);
                setInputValueLawn(responseData.lawn);
                setInputValueOutdoorarea(responseData.outdoorarea);
                setInputValueWifi(responseData.wifi);
                setInputValueHometheater(responseData.hometheater);
                setInputValueParkinglot(responseData.parkinglot);
                setInputValueName(responseData.name);
                setInputValueEmail(responseData.email);
                setInputValuePhone(responseData.phone);
                setInputValueListingby(responseData.listingby);
                setInputValueComment(responseData.comment);
                setInputValueDealType(responseData.dealtype);
                setInputValueYearOfBuild(responseData.yearOfBuild);
                setInputValueSqftLot(responseData.sqftLot);
                setInputValueMonthlyHOApayment(responseData.monthlyHOApayment);
                setInputValueCatsAndDogsAllowed(responseData.catsAndDogsAllowed);
                setInputValueElevator(responseData.elevator);
                setInputValueFulltimeDoorman(responseData.fulltimeDoorman);
                setInputValueBikeRoom(responseData.bikeRoom);
                setInputValueStorageInBuilding(responseData.storageInBuilding);
                setInputValueCentralAir(responseData.centralAir);
                setInputValueRoofdeck(responseData.roofdeck);
                setInputValueRentalUnit(responseData.rentalUnit);
                setInputValueRentalUnitFurnished(responseData.rentalUnitFurnished);
                // setInputRentalUnitAvalabilityDate(responseData.rentalUnitAvalabilityDate);
                setInputValueDailyOrMonthly(responseData.rentChargePeriod);
                setInputValueLinkToVRT(responseData.linkToVRT);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [loggedIn, navigate, itemId]);


    // fetch photos 
    const fetchPhotos = async (responseData) => {
        setImagePaths([]);

        // Check if responseData.imgs exists and has a length property
        if (responseData.imgs && responseData.imgs.length) {
            // Use a Set to store unique URLs
            const uniqueImagePaths = new Set();

            // Receive and add each image URL to the imagePaths array
            const numberOfPhotos = responseData.imgs.length;

            for (let i = 0; i < numberOfPhotos; i++) {
                try {
                    const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${i}`);

                    if (response.ok) {
                        const contentType = response.headers.get('content-type');

                        if (contentType && contentType.includes('application/json')) {
                            const data = await response.json();
                            setResponseMessage(data.message);
                        } else {
                            const blob = await response.blob();
                            const imgUrl = URL.createObjectURL(blob);

                            // Add the unique URL to the set
                            uniqueImagePaths.add(imgUrl);
                        }
                    } else {
                        setResponseMessage("Error fetching photos. Response failed.");
                    }
                } catch (error) {
                    console.error("Error fetching photos:", error);
                    setResponseMessage("Error fetching photos.");
                }
            }

            setImagePaths([...uniqueImagePaths]);
        } else {
            // Handle the case where responseData.imgs is undefined or empty
            console.error("Error: responseData.imgs is undefined or empty");
            setResponseMessage("Error fetching photos. Invalid response data.");
        }
    };

    // fetching photo end
    const [loading, setLoading] = useState(true);

    const [inputValueAddress, setInputValueAddress] = useState("");
    const [inputValueCity, setInputCity] = useState("");
    const [inputValueState, setInputValueState] = useState("");
    const [inputValueZip, setInputValueZip] = useState("");
    const [inputValuePType, setInputValuPTypee] = useState("");
    const [inputValueNBedrooms, setInputValueNBedrooms] = useState("");
    const [inputValueNBathrooms, setInputValueNBathrooms] = useState("");
    const [inputValuePCondition, setInputValuePCondition] = useState("");
    const [inputValuePPrice, setInputValuePPrice] = useState(0);
    const [inputValueRPrinciple, setInputValueRPrinciple] = useState(0);
    const [inputValueIRate, setInputValueIRate] = useState(0);
    const [inputValueAmortizationPeriod, setInputValueAmortizationPeriod] = useState(0);
    const [inputValueRAmortizationPeriod, setInputValueRAmortizationPeriod] = useState(0);
    const [inputValueMPayment, setInputValueMPayment] = useState(0);
    const [inputValuePTax, setInputValuePTax] = useState(0);
    const [inputValueAskingPrice, setInputValueAskingPrice] = useState(0);
    const [inputValueArea, setInputValueArea] = useState("");
    const [inputValueGarages, setInputValueGarages] = useState("");
    const [inputValueTwostories, setInputValueTwostories] = useState(false);
    const [inputValueBasketballcourt, setInputValueBasketballcourt] = useState(false);
    const [inputValueGym, setInputValueGym] = useState(false);
    const [inputValueFireplace, setInputValueFireplace] = useState(false);
    const [inputValueSprinklers, setInputValueSprinklers] = useState(false);
    const [inputValuePrivatespace, setInputValuePrivatespace] = useState(false);
    const [inputValueBalcony, setInputValueBalcony] = useState(false);
    const [inputValueLaundry, setInputValueLaundry] = useState(false);
    const [inputValueOceanview, setInputValueOceanview] = useState(false);
    const [inputValueCentralheating, setInputValueCentralheating] = useState(false);
    const [inputValueLawn, setInputValueLawn] = useState(false);
    const [inputValueOutdoorarea, setInputValueOutdoorarea] = useState(false);
    const [inputValueWifi, setInputValueWifi] = useState(false);
    const [inputValueHometheater, setInputValueHometheater] = useState(false);
    const [inputValueParkinglot, setInputValueParkinglot] = useState(false);
    const [inputValueName, setInputValueName] = useState("");
    const [inputValueEmail, setInputValueEmail] = useState("");
    const [inputValuePhone, setInputValuePhone] = useState("");
    const [inputValueListingby, setInputValueListingby] = useState("");
    const [inputValueComment, setInputValueComment] = useState(false);
    const [inputValueDealType, setInputValueDealType] = useState("forRent");
    const [images, setImages] = useState([]);
    const [responseMessage, setResponseMessage] = useState("");
    // const [userId, setUserId] = useState("");

    // New features
    const [inputValueYearOfBuild, setInputValueYearOfBuild] = useState("");
    const [inputValueSqftLot, setInputValueSqftLot] = useState("");
    const [inputValueMonthlyHOApayment, setInputValueMonthlyHOApayment] = useState(0);

    const [inputValueCatsAndDogsAllowed, setInputValueCatsAndDogsAllowed] = useState(false);
    const [inputValueElevator, setInputValueElevator] = useState(false);
    const [inputValueFulltimeDoorman, setInputValueFulltimeDoorman] = useState(false);
    const [inputValueBikeRoom, setInputValueBikeRoom] = useState(false);
    const [inputValueStorageInBuilding, setInputValueStorageInBuilding] = useState(false);
    const [inputValueCentralAir, setInputValueCentralAir] = useState(false);
    const [inputValueRoofdeck, setInputValueRoofdeck] = useState(false);

    const [inputValueRentalUnit, setInputValueRentalUnit] = useState('');
    const [inputValueRentalUnitFurnished, setInputValueRentalUnitFurnished] = useState('');

    const [forSaleAsMortgageAsset, setForSaleAsMortgageAsset] = useState(false);
    const [forSale, setForSale] = useState(false);
    const [forRent, setForRent] = useState(true);
    const [inputRentalUnitAvalabilityDate, setInputRentalUnitAvalabilityDate] = useState(new Date());

    const [inputValueDailyOrMonthly, setInputValueDailyOrMonthly] = useState('');

    const [inputValueLinkToVRT, setInputValueLinkToVRT] = useState('');

    const [responseMessageShow, setResponseMessageShow] = useState(false);


    // sellect city
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);



    useEffect(() => {
        // Populate the state dropdown with options
        const countryStates = State.getStatesOfCountry('US');
        setStates(countryStates);
        // Only fetch data if the component is mounted
        let isMounted = true;

        const fetchData = async () => {
            try {
                // Check if the user is authenticated using the useAuth hook
                if (!loggedIn) {
                    navigate('/login');
                    return;
                }

                if (isMounted) {
                    setLoading(false);
                    // Get the user ID using the useAuth hook
                    // console.log('User ID:', userId);

                    // Additional logic if needed...
                }

            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle the error if needed
            }
        };

        fetchData();

        // Cleanup function to update the mounted status
        return () => {
            isMounted = false;
        };

    }, [loggedIn, userId, navigate]);


    if (loading) {
        return <div>Loading...</div>;
    };

    const handleInputChangeAddress = (e) => {
        setInputValueAddress(e.target.value);
    };
    const handleStateChange = (e) => {
        const newState = e.target.value;
        setInputValueState(newState);

        // Update the city dropdown based on the selected state
        const stateCities = City.getCitiesOfState('US', newState);
        setCities(stateCities);
    };
    const handleInputChangeCity = (e) => {
        setInputCity(e.target.value);
    };
    const handleInputChangeZip = (e) => {
        setInputValueZip(e.target.value);
    };
    const handleInputChangePType = (e) => {
        setInputValuPTypee(e.target.value);
    };
    const handleInputChangeNBedrooms = (e) => {
        setInputValueNBedrooms(e.target.value);
    };
    const handleInputChangeNBathrooms = (e) => {
        setInputValueNBathrooms(e.target.value);
    };
    const handleInputChangePCondition = (e) => {
        setInputValuePCondition(e.target.value);
    };
    const handleInputChangePPrice = (e) => {
        setInputValuePPrice(e.target.value);
    };
    const handleInputChangeRPrinciple = (e) => {
        setInputValueRPrinciple(e.target.value);
    };
    const handleInputChangeIRate = (e) => {
        const value = e.target.value;
        // Regular expression to allow only numeric values and decimal points
        if (/^\d*\.?\d*$/.test(value) || value === '') {
            setInputValueIRate(value);
        }
    };
    const handleInputChangeAmortizationPeriod = (e) => {
        setInputValueAmortizationPeriod(e.target.value);
    };
    const handleInputChangeRAmortizationPeriod = (e) => {
        setInputValueRAmortizationPeriod(e.target.value);
    };
    const handleInputChangeMPayment = (e) => {
        setInputValueMPayment(e.target.value);
    };
    const handleInputChangePTax = (e) => {
        setInputValuePTax(e.target.value);
    };
    const handleInputChangeAskingPrice = (e) => {
        setInputValueAskingPrice(e.target.value);
    };
    const handleInputChangeArea = (e) => {
        setInputValueArea(e.target.value);
    };
    const handleInputGarages = (e) => {
        setInputValueGarages(e.target.value);
    };
    const handleInputTwostories = (e) => {
        setInputValueTwostories(e.target.checked);
    };
    const handleInputBasketballcourt = (e) => {
        setInputValueBasketballcourt(e.target.checked);
    };
    const handleInputGym = (e) => {
        setInputValueGym(e.target.checked);
    };
    const handleFireplace = (e) => {
        setInputValueFireplace(e.target.checked);
    };
    const handleSprinklers = (e) => {
        setInputValueSprinklers(e.target.checked);
    };
    const handlePrivatespace = (e) => {
        setInputValuePrivatespace(e.target.checked);
    };
    const handleBalcony = (e) => {
        setInputValueBalcony(e.target.checked);
    };
    const handleLaundry = (e) => {
        setInputValueLaundry(e.target.checked);
    };
    const handleOceanview = (e) => {
        setInputValueOceanview(e.target.checked);
    };
    const handleCentralheating = (e) => {
        setInputValueCentralheating(e.target.checked);
    };
    const handleLawn = (e) => {
        setInputValueLawn(e.target.checked);
    };
    const handleOutdoorarea = (e) => {
        setInputValueOutdoorarea(e.target.checked);
    };
    const handleWifi = (e) => {
        setInputValueWifi(e.target.checked);
    };
    const handleHometheater = (e) => {
        setInputValueHometheater(e.target.checked);
    };
    const handleParkinglot = (e) => {
        setInputValueParkinglot(e.target.checked);
    };
    const handleName = (e) => {
        setInputValueName(e.target.value);
    };
    const handleEmail = (e) => {
        setInputValueEmail(e.target.value);
    };
    const handlePhone = (e) => {
        setInputValuePhone(e.target.value);
    };
    const handleInputValueListingby = (e) => {
        setInputValueListingby(e.target.value);
    };
    const handleComment = (e) => {
        setInputValueComment(e.target.value);
    };
    const handleInputDealType = (e) => {
        if (e.target.value === "forSaleAsMortgageAsset") {
            // forSaleAsMortgageAsset = true;
            setForSaleAsMortgageAsset(true);
            setForSale(false);
            setForRent(false);
        } else if (e.target.value === "forSale") {
            setForSaleAsMortgageAsset(false);
            setForSale(true);
            setForRent(false);
        } else {
            setForSaleAsMortgageAsset(false);
            setForSale(false);
            setForRent(true);
        }
        setInputValueDealType(e.target.value);
    };

    // rent related
    const handleInputRentalUnit = (e) => {
        setInputValueRentalUnit(e.target.value);
    };

    const handleRentalUnitFurnished = (e) => {
        setInputValueRentalUnitFurnished(e.target.value);
    };

    const handleInputRentalUnitAvalabilityDate = (date) => {
        setInputRentalUnitAvalabilityDate(date);
    };
    // new features
    const handleInputChangeYearOfBuild = (e) => {
        setInputValueYearOfBuild(e.target.value);
    };
    const handleInputChangeSqftLot = (e) => {
        setInputValueSqftLot(e.target.value);
    };
    const handleInputChangeMonthlyHOApayment = (e) => {
        setInputValueMonthlyHOApayment(e.target.value);
    };
    const handleInputCatsAndDogsAllowed = (e) => {
        setInputValueCatsAndDogsAllowed(e.target.value);
    };
    const handleInputElevator = (e) => {
        setInputValueElevator(e.target.value);
    };
    const handleInputFulltimeDoorman = (e) => {
        setInputValueFulltimeDoorman(e.target.value);
    };
    const handleBikeRoom = (e) => {
        setInputValueBikeRoom(e.target.checked);
    };
    const handleStorageInBuilding = (e) => {
        setInputValueStorageInBuilding(e.target.checked);
    };
    const handleCentralAir = (e) => {
        setInputValueCentralAir(e.target.checked);
    };
    const handleRoofdeck = (e) => {
        setInputValueRoofdeck(e.target.checked);
    };

    const handleInputValueDailyOrMonthly = (e) => {
        setInputValueDailyOrMonthly(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        if (selectedFiles.length > 3) {
            alert("You can only upload a maximum of three files at once, but you can add more when listing is saved or posted.");
            // Clear the file input
            e.target.value = null;
            return;
        }
        const filesArray = Array.from(selectedFiles);
        // Set the state to trigger a re-render
        setImages((prevImages) => [...prevImages, ...filesArray]);
    };

    const handleDeleteImage = (index, event) => {
        event.preventDefault();

        // Remove the image from the images array based on its index
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });

        // Reset the value of the file input element to clear the uploaded image(s)
        const fileInput = document.getElementById(languageToUse === 'ge' ? 'formFileGe' : 'formFileEn');
        if (fileInput) {
            fileInput.value = '';
        }
    };
    const handleDeleteSavedImage = async (index, event) => {
        event.preventDefault();

        try {
            // Assuming you have an image ID associated with each image in the backend
            const imageIdToDelete = index; // You may need to adjust this based on your backend implementation

            // Make an HTTP DELETE request to the backend endpoint
            const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${imageIdToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if required
                },
                // You can include a request body if needed
                // body: JSON.stringify({ /* any additional data to send to the backend */ }),
            });

            if (response.ok) {
                // Image deletion successful
                // console.log(`Image at index ${index} deleted successfully`);

                // Now you can update the state or perform any other necessary actions
                // For example, remove the image path from the state
                const updatedImagePaths = [...imagePaths];
                updatedImagePaths.splice(index, 1);
                setImagePaths(updatedImagePaths);
            } else {
                // Image deletion failed
                console.error(`Error deleting image at index ${index}`);
                setResponseMessage("Error deleting image. Please try again.");
            }
        } catch (error) {
            console.error("Error deleting image:", error);
            setResponseMessage("Error deleting image. Please try again.");
        }
    };


    const handleLinkToVRT = (e) => {
        setInputValueLinkToVRT(e.target.value);
    };

    const handleUpdateListing = async (e) => {
        e.preventDefault();
        if (inputValueNBathrooms === "") {
            alert("Please select a valid number of bathrooms.");
            return; // Prevent form submission
        }
        if (inputValueNBedrooms === "") {
            alert("Please select a valid number of bedrooms.");
            return; // Prevent form submission
        }
        if (inputValueGarages === "") {
            alert("Please select a valid number of garages.");
            return; // Prevent form submission
        }
        const formData = new FormData();
        images.forEach((file, index) => {
            formData.append(`files`, file);
        });
        formData.append('address', inputValueAddress);
        formData.append('city', inputValueCity);
        formData.append('state', inputValueState);
        formData.append('zip', inputValueZip);
        formData.append('propertytype', inputValuePType);
        formData.append('numberOfBedrooms', inputValueNBedrooms);
        formData.append('numberOfBathrooms', inputValueNBathrooms);
        formData.append('propertyCondition', inputValuePCondition);
        formData.append('purchasePrice', inputValuePPrice);
        formData.append('remaningPrinciple', inputValueRPrinciple);
        formData.append('interestRate', inputValueIRate);
        formData.append('loanAmortizationPeriod', inputValueAmortizationPeriod);
        formData.append('remaningAmortizationPeriod', inputValueRAmortizationPeriod);
        formData.append('monthlyPayment', inputValueMPayment);
        formData.append('propertyTax', inputValuePTax);
        formData.append('askingPrice', inputValueAskingPrice);
        formData.append('area', inputValueArea);
        formData.append('garages', inputValueGarages);
        formData.append('twostories', inputValueTwostories);
        formData.append('basketballcourt', inputValueBasketballcourt);
        formData.append('gym', inputValueGym);
        formData.append('fireplace', inputValueFireplace);
        formData.append('sprinklers', inputValueSprinklers);
        formData.append('privatespace', inputValuePrivatespace);
        formData.append('balcony', inputValueBalcony);
        formData.append('laundry', inputValueLaundry);
        formData.append('oceanview', inputValueOceanview);
        formData.append('centralheating', inputValueCentralheating);
        formData.append('lawn', inputValueLawn);
        formData.append('outdoorarea', inputValueOutdoorarea);
        formData.append('wifi', inputValueWifi);
        formData.append('hometheater', inputValueHometheater);
        formData.append('parkinglot', inputValueParkinglot);
        formData.append('name', inputValueName);
        formData.append('email', inputValueEmail);
        formData.append('phone', inputValuePhone);
        formData.append('listingby', inputValueListingby);
        formData.append('comment', inputValueComment);
        formData.append('dealtype', inputValueDealType);
        formData.append('rentalUnit', inputValueRentalUnit);
        formData.append('rentalUnitFurnished', inputValueRentalUnitFurnished);
        formData.append('rentalUnitAvalabilityDate', inputRentalUnitAvalabilityDate);
        formData.append('yearOfBuild', inputValueYearOfBuild);
        formData.append('sqftLot', inputValueSqftLot);
        formData.append('monthlyHOApayment', inputValueMonthlyHOApayment);
        formData.append('catsAndDogsAllowed', inputValueCatsAndDogsAllowed);
        formData.append('elevator', inputValueElevator);
        formData.append('fulltimeDoorman', inputValueFulltimeDoorman);
        formData.append('bikeRoom', inputValueBikeRoom);
        formData.append('storageInBuilding', inputValueStorageInBuilding);
        formData.append('centralAir', inputValueCentralAir);
        formData.append('roofdeck', inputValueRoofdeck);
        formData.append('rentChargePeriod', inputValueDailyOrMonthly);
        formData.append('linkToVRT', inputValueLinkToVRT);

        try {
            // Send the data to the backend
            const response = await fetch(`${backendUrl}/listingupdate/${itemId}`, {
                method: "PATCH",
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    // Add any additional headers if needed
                }
            });

            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const data = await response.json();
                    setImages([]);
                    setResponseMessage(data.message);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                    // Additional actions for success
                } else {
                    // Handle the case where the response is not JSON
                    const errorMessage = await response.text();
                    setResponseMessage(`${errorMessage}`);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                }
                sendEmail({
                    from: inputValueEmail,
                    name: inputValueName,
                    phone: inputValuePhone,
                    subject: 'New listing from ' + inputValueName,
                    text: 'New listing, address ' + inputValueAddress + inputValueCity + inputValueState + inputValueZip,
                    itemId: itemId,
                    address: inputValueAddress + inputValueCity + inputValueState + inputValueZip,
                    product: 'Listing Real Estate',
                    activity: "Edit Real Esate listing"
                });
                navigate("/mylistings");
            } else {
                const errorMessage = await response.text();
                setResponseMessage(`${errorMessage}`);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            }
        } catch (error) {
            setResponseMessage("Error updating data on the server.");
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        }
    };

    // send email when property submitted


    async function sendEmail(data) {
        try {

            // Validate mandatory fields
            if (!inputValueAddress || !inputValueCity || !inputValueState || !inputValueZip) {
                setResponseMessage("Please fill in all mandatory fields and upload at least one file.");
                return; // Stop further execution
            }

            const response = await fetch(`${backendUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                const responseData = await response.json();
                setResponseMessage(responseData.message);
            } else {
                const errorData = await response.json();
                setResponseMessage(`Error: ${errorData.message}`);
            }
        } catch (error) {
            setResponseMessage("Error sending data to the server.");
        }
    };

    // end send email

    // slider
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    // slider

    return <div>
        {languageToUse === "ge" && (
            <div>
                <section className="breadcrumbs-custom bg-image-addlisting context-dark" data-opacity="28">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">განცხადებაში ცვლილებების შეტანა</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleUpdateListing} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="PATCH" action="/listing">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>ზოგადი ინფორმაცია საცხოვრებლის შესახებ</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="streetAddress-Ge" type="address" name="address" autoComplete="address-line1" required placeholder="ქუჩის მისამართი (გამოიყენეთ ლათინური ასოები)" value={inputValueAddress} onChange={handleInputChangeAddress} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="შტატი" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">შეარჩიეთ შტატი</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="ქალაქი" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>შეარჩიეთ ქალაქი</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="Zip-Ge" name="zip" autoComplete="postal-code" required placeholder="ზიპ კოდი" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="propertytype-Ge" name="propertytype" required value={inputValuePType} onChange={handleInputChangePType}>
                                                        <option value="Any">საცხოვრებლის ნებისმიერი სახეობა</option>
                                                        <option value="House">სახლი</option>
                                                        <option value="Townhouse">ბინა ქალაქურ სახლში</option>
                                                        <option value="Condo">ბინა კორპუსში (კონდო)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="property-status" value="forRent" data-style="modern" required data-placeholder="Status" onChange={handleInputDealType}>
                                                        {/* <option label="Deal type: Sale/Rent/Other"></option>
                          <option value="forSale">For Sale</option>
                          <option value="forRent">For Rent</option>
                          <option value="forSaleAsMortgageAsset">For Sale As Mortgage Asset</option> */}
                                                        <option value="forRent">გასაქირავებლად</option>
                                                    </select><span className="select-arrow"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="dailyMonthlyGeGe" value={inputValueDailyOrMonthly} required={forRent} onChange={handleInputValueDailyOrMonthly}>
                                                        <option value="">თვიური/დღიური გაქირავება</option>
                                                        <option value="monthly">თვიური</option>
                                                        <option value="daily">დღიური</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="propertyCondition-Ge" name="propertyCondition" value={inputValuePCondition} onChange={handleInputChangePCondition}>
                                                        <option >Property condition</option>
                                                        <option value="Ready for living">Ready for living</option>
                                                        <option value="Requires renovation">Requires renovation</option>
                                                        <option value="Not habitable">Not habitable</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input hidden={!forSale} type="text" className="form-input" id="propertyPriceForSale-Ge" name="property-price" data-constraints="@Required @Numeric" placeholder="Asking price (USD)" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="propertyPriceForRent-Ge" name="property-price" data-constraints="@Required @Numeric" placeholder="ქირის ღირებულება" value={inputValueAskingPrice === 0 ? '' : (inputValueAskingPrice)} onChange={handleInputChangeAskingPrice} />
                                                    {/* <input hidden={!forSale} type="text" className="form-input" id="propertyPriceForSale-Ge" name="property-price" required={forSale} data-constraints="@Required @Numeric" placeholder="Asking price (USD)" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="propertyPriceForRent-Ge" name="property-price" required={forRent} data-constraints="@Required @Numeric" placeholder="ქირის ღირებულება" value={inputValueAskingPrice === 0 ? '' : (inputValueAskingPrice)} onChange={handleInputChangeAskingPrice} /> */}
                                                    <input hidden={!forSaleAsMortgageAsset} type="text" className="form-input" id="propertyPriceForMortgage-Ge" name="property-price" required={forSaleAsMortgageAsset} data-constraints="@Required @Numeric" placeholder="Price of mortgage asset and property" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" hidden={!forRent}>
                                    <div className="section-1">
                                        <h3>ინფორმაცია გასაქირავებელი ფართის შესახებ</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select className="form-input select-filter" id="rentalUnit-Ge" name="rentalUnit" required={forRent} value={inputValueRentalUnit} onChange={handleInputRentalUnit}>
                                                        <option value="Any">გასაქირავებელი ფართი</option>
                                                        <option value="entireProperty">მთლიანი ბინა/სახლი</option>
                                                        <option value="room">ოთახი</option>
                                                        <option value="bed">ლოგინი</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="ifUnitIsFurnihsedOrNot-Ge"
                                                        name="ifUnitIsFurnihsedOrNot"
                                                        required={forRent}
                                                        value={inputValueRentalUnitFurnished}
                                                        onChange={handleRentalUnitFurnished}
                                                    >
                                                        <option value="Any">ავეჯით ან უავეჯოდ</option>
                                                        <option value="furnished">ავეჯით</option>
                                                        <option value="notfurnished">უავეჯოდ</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <DatePicker
                                                        selected={inputRentalUnitAvalabilityDate}
                                                        onChange={handleInputRentalUnitAvalabilityDate}
                                                        placeholderText="ქირაობა შესაძლებელია შემდეგი თარიღიდან"
                                                        dateFormat="MM/dd/yyyy"
                                                        className="form-input select-filter"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" hidden={!forSaleAsMortgageAsset}>
                                    <div className="section-1">
                                        <h3>Information about mortgage</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="PurchasePrice-Ge" name="purchasePrice" required={forSaleAsMortgageAsset} placeholder="Purchase Price" value={inputValuePPrice === 0 ? '' : inputValuePPrice} onChange={handleInputChangePPrice} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="remaningPrinciple-Ge" name="remaningPrinciple" required={forSaleAsMortgageAsset} placeholder="Remaning Principle" value={inputValueRPrinciple === 0 ? '' : inputValueRPrinciple} onChange={handleInputChangeRPrinciple} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="interestRate-Ge" name="interestRate" required={forSaleAsMortgageAsset} placeholder="Interest Rate %" value={inputValueIRate === 0 ? '' : inputValueIRate} onChange={handleInputChangeIRate} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="loanAmortizationPeriod-Ge" name="loanAmortizationPeriod" placeholder="Amortization period in years" value={inputValueAmortizationPeriod === 0 ? '' : inputValueAmortizationPeriod} onChange={handleInputChangeAmortizationPeriod} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="remaningAmortizationPeriod-Ge" name="remaningAmortizationPeriod" required={forSaleAsMortgageAsset} placeholder="Remaning amortization period in months" value={inputValueRAmortizationPeriod === 0 ? '' : inputValueRAmortizationPeriod} onChange={handleInputChangeRAmortizationPeriod} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="monthlyPayment-Ge" name="monthlyPayment" required={forSaleAsMortgageAsset} placeholder="Monthly payment" value={inputValueMPayment === 0 ? '' : inputValueMPayment} onChange={handleInputChangeMPayment} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Slider {...settings}>
                                    {imagePaths && imagePaths.length > 0 ? (
                                        imagePaths.map((image, index) => (
                                            <div key={index}>
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Item ${index}`}
                                                    width="763" height="443"
                                                />
                                                <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteSavedImage(index, event)}>შენახული ფოტოს წაშლა</button>
                                            </div>
                                        ))
                                    ) : (
                                        <img src="./images/ser-r.png" alt="Default Item" style={{ width: '100%', maxHeight: '443px' }} />
                                    )}
                                </Slider>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>დაამატე ფოტოები</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileGe" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>ფოტოს წაშლა</button>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="form-VRT-Ge" type="text" name="VRT" placeholder="Link to VRT" value={inputValueLinkToVRT} onChange={handleLinkToVRT} />
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>განცხადების ავტორი</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-name-Ge" type="text" name="name" autoComplete="name" placeholder="თქვენი სახელი" data-constraints="@Required" value={inputValueName} onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-email-Ge" type="email" name="email" autoComplete="email" placeholder="თქვენი იმეილი" data-constraints="@Email @Required" value={inputValueEmail} onChange={handleEmail} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-phone-Ge" type="text" name="phone" placeholder="თქვენი ტელეფონის ნომერი" autoComplete="tel" data-constraints="@PhoneNumber" value={inputValuePhone} onChange={handlePhone} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="listingby" value={inputValueListingby} required={forRent} onChange={handleInputValueListingby}>
                                                        <option value="">განცხადების ავტორი: მფლობელი/აგენტი</option>
                                                        <option value="owner">მფლობელი</option>
                                                        <option value="agent">აგენტი</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>დეტალური ინფორმაცია</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfBathrooms-Ge"
                                                        name="numberOfBathrooms"
                                                        value={inputValueNBathrooms}
                                                        onChange={handleInputChangeNBathrooms}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>შეარჩიეთ აბაზანის რაოდენობა</option>
                                                        <option value="0">0 აბაზანა</option>
                                                        <option value="1">1 აბაზანა</option>
                                                        <option value="1.5">1.5 აბაზანა</option>
                                                        <option value="2">2 აბაზანა</option>
                                                        <option value="2.5">2.5 აბაზანა</option>
                                                        <option value="3">3 აბაზანა</option>
                                                        <option value="3.5">3.5 აბაზანა</option>
                                                        <option value="4">4 აბაზანა</option>
                                                        <option value="5">5 აბაზანა</option>
                                                        <option value="6">6 აბაზანა</option>
                                                        <option value="7">7 აბაზანა</option>
                                                        <option value="8">8 აბაზანა</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfBedrooms-Ge"
                                                        name="numberOfBedrooms"
                                                        value={inputValueNBedrooms}
                                                        onChange={handleInputChangeNBedrooms}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>შეარჩიეთ ოთახების რაოდენობა</option>
                                                        <option value="0">სტუდიო</option>
                                                        <option value="1">1 საძინებელი</option>
                                                        <option value="2">2 საძინებელი</option>
                                                        <option value="3">3 საძინებელი</option>
                                                        <option value="4">4 საძინებელი</option>
                                                        <option value="5">5 საძინებელი</option>
                                                        <option value="6">6 საძინებელი</option>
                                                        <option value="7">7 საძინებელი</option>
                                                        <option value="8">8 საძინებელი</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfGarages-Ge"
                                                        name="numberOfGarages"
                                                        value={inputValueGarages}
                                                        onChange={handleInputGarages}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>შეარჩიეთ ავტ ფარეხის რაოდენობა</option>
                                                        <option value="0">0 ავტ ფარეხი</option>
                                                        <option value="1">1 ავტ ფარეხი</option>
                                                        <option value="2">2 ავტ ფარეხი</option>
                                                        <option value="3">3 ავტ ფარეხი</option>
                                                        <option value="4">4 ავტ ფარეხი</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="property-area-Ge" type="text" name="property-area" placeholder="ფართის ზომა კვადრატულ ფიტებში" data-constraints="@Numeric" value={inputValueArea} onChange={handleInputChangeArea} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="yearOfBuild-Ge" name="yearOfBuild" placeholder="when was property built" value={inputValueYearOfBuild} onChange={handleInputChangeYearOfBuild} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="sqftLot-Ge" name="sqftLot" placeholder="Lot area Sq. Ft." value={inputValueSqftLot} onChange={handleInputChangeSqftLot} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="number" className="form-input" id="monthlyHOApayment-Ge" name="monthlyHOApayment" placeholder="HOA monthly payment" value={inputValueMonthlyHOApayment === 0 ? '' : inputValueMonthlyHOApayment} onChange={handleInputChangeMonthlyHOApayment} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="number" className="form-input" id="PropertyTax-Ge" name="propertyTax" placeholder="Property tax/year" value={inputValuePTax === 0 ? '' : inputValuePTax} onChange={handleInputChangePTax} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row row-10">
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-1" value={inputValueTwostories} type="checkbox" checked={inputValueTwostories} onChange={handleInputTwostories} />   2 სართულიანი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-2" value={inputValueBasketballcourt} type="checkbox" checked={inputValueBasketballcourt} onChange={handleInputBasketballcourt} />   კალათბურთის მოედანი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-3" value={inputValueGym} type="checkbox" checked={inputValueGym} onChange={handleInputGym} />   სავარჯიშო დარბაზი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-4" value={inputValueFireplace} type="checkbox" checked={inputValueFireplace} onChange={handleFireplace} />   ბუხარი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-5" value={inputValueSprinklers} type="checkbox" checked={inputValueSprinklers} onChange={handleSprinklers} />   სპრინკლერები
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-6" value={inputValuePrivatespace} type="checkbox" checked={inputValuePrivatespace} onChange={handlePrivatespace} />   დამატებითი ფართი
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-7" value={inputValueBalcony} type="checkbox" checked={inputValueBalcony} onChange={handleBalcony} />   ბალკონი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-8" value={inputValueLaundry} type="checkbox" checked={inputValueLaundry} onChange={handleLaundry} />   ლონდრი
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-9" value={inputValueOceanview} type="checkbox" checked={inputValueOceanview} onChange={handleOceanview} />   ოკეანის ხედით
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-10" value={inputValueCentralheating} type="checkbox" checked={inputValueCentralheating} onChange={handleCentralheating} />   ცენტრალური გათბობა
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-11" value={inputValueLawn} type="checkbox" checked={inputValueLawn} onChange={handleLawn} />  მდელო
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-12" value={inputValueOutdoorarea} type="checkbox" checked={inputValueOutdoorarea} onChange={handleOutdoorarea} />   ეზო
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-13" value={inputValueWifi} type="checkbox" checked={inputValueWifi} onChange={handleWifi} />   უკაბელო ინტერნეტი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-14" value={inputValueHometheater} type="checkbox" checked={inputValueHometheater} onChange={handleHometheater} />   სახლის თეატრი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-15" value={inputValueParkinglot} type="checkbox" checked={inputValueParkinglot} onChange={handleParkinglot} />   დასაპრკინგებელი ადგილი
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-16" value={inputValueCatsAndDogsAllowed} type="checkbox" checked={inputValueCatsAndDogsAllowed} onChange={handleInputCatsAndDogsAllowed} />   ძაღლები და კატები დაშვებულია
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-17" value={inputValueElevator} type="checkbox" checked={inputValueElevator} onChange={handleInputElevator} />   ლიფტი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-18" value={inputValueFulltimeDoorman} type="checkbox" checked={inputValueFulltimeDoorman} onChange={handleInputFulltimeDoorman} />   კარისკაცი
                                                                </label>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">


                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-19" value={inputValueBikeRoom} type="checkbox" checked={inputValueBikeRoom} onChange={handleBikeRoom} />   ველოსიპეტის შესანახი ადგილი
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-20" value={inputValueStorageInBuilding} type="checkbox" checked={inputValueStorageInBuilding} onChange={handleStorageInBuilding} />   საკუჭნაო შენობაში
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-20" value={inputValueCentralAir} type="checkbox" checked={inputValueCentralAir} onChange={handleCentralAir} />   კონდენციონერი
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-21" value={inputValueRoofdeck} type="checkbox" checked={inputValueRoofdeck} onChange={handleRoofdeck} />  რუფდეკი
                                                                </label>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">

                                        <textarea className="form-input" id="form-comment-Ge" name="comment" placeholder="დამატებითი ინფორმაცია და კომენტარი (არ არის სავალდებულო)" value={inputValueComment} onChange={handleComment}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">განცხადების ატვირთვა</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>გამოხმაურება: {responseMessage}</p>}
                                    {/* {responseMessage.map((message, index) => (
                  <p key={index} style={{ color: 'red' }}>{message}</p>
                ))} */}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        )}
        {languageToUse === "en" && (
            <div>
                <section className="breadcrumbs-custom bg-image-addlisting context-dark" data-opacity="28">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">Submit Property</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleUpdateListing} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/listing">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>Basic Information About Property</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="streetAddress" type="address" name="address" autoComplete="address-line1" required placeholder="Street address" value={inputValueAddress} onChange={handleInputChangeAddress} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="state" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">Select a State</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="city" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>Select a City</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="Zip" name="zip" autoComplete="postal-code" required placeholder="Zip" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="propertytype" name="propertytype" required value={inputValuePType} onChange={handleInputChangePType}>
                                                        <option >Property type</option>
                                                        <option value="House">House</option>
                                                        <option value="Townhouse">Townhouse</option>
                                                        <option value="Condo">Condo</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="property-status" data-style="modern" required data-placeholder="Status" data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueDealType} onChange={handleInputDealType}>
                                                        {/* <option label="Deal type: Sale/Rent/Other"></option> */}
                                                        {/* <option value="forSale">For Sale</option> */}
                                                        <option value="forRent">For rent</option>
                                                        {/* <option value="forSaleAsMortgageAsset">For Sale As Mortgage Asset</option> */}
                                                    </select><span className="select-arrow"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="dailyMonthly" value={inputValueDailyOrMonthly} required={forRent} data-style="modern" onChange={handleInputValueDailyOrMonthly}>
                                                        <option value="">Monthly/Daily rental</option>
                                                        <option value="monthly">Monthly</option>
                                                        <option value="daily">Daily</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="propertyCondition" name="propertyCondition" value={inputValuePCondition} onChange={handleInputChangePCondition}>
                                                        <option >Property condition</option>
                                                        <option value="Ready for living">Ready for living</option>
                                                        <option value="Requires renovation">Requires renovation</option>
                                                        <option value="Not habitable">Not habitable</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input hidden={!forSale} type="text" className="form-input" id="property-price" name="property-price" data-constraints="@Required @Numeric" placeholder="Asking price (USD)" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="property-price" name="property-price" data-constraints="@Required @Numeric" placeholder="Rent price" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    {/* <input hidden={!forSale} type="text" className="form-input" id="property-price" name="property-price" required={forSale} data-constraints="@Required @Numeric" placeholder="Asking price (USD)" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="property-price" name="property-price" required={forRent} data-constraints="@Required @Numeric" placeholder="Rent price" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} /> */}
                                                    <input hidden={!forSaleAsMortgageAsset} type="text" className="form-input" id="property-price" name="property-price" required={forSaleAsMortgageAsset} data-constraints="@Required @Numeric" placeholder="Price of mortgage asset and property" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" hidden={!forRent}>
                                    <div className="section-1">
                                        <h3>Information on rental unit</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select className="form-input select-filter" id="rentalUnit" name="rentalUnit" required={forRent} value={inputValueRentalUnit} onChange={handleInputRentalUnit}>
                                                        <option value="Any">Rental unit</option>
                                                        <option value="entireProperty">Entire property</option>
                                                        <option value="room">Room</option>
                                                        <option value="bed">Bed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="ifUnitIsFurnihsedOrNot"
                                                        name="ifUnitIsFurnihsedOrNot"
                                                        required={forRent}
                                                        value={inputValueRentalUnitFurnished}
                                                        onChange={handleRentalUnitFurnished}
                                                    >
                                                        <option value="Any">Select furnishing status</option>
                                                        <option value="furnished">Furnished</option>
                                                        <option value="notfurnished">Not Furnished</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <DatePicker
                                                        selected={inputRentalUnitAvalabilityDate}
                                                        onChange={handleInputRentalUnitAvalabilityDate}
                                                        placeholderText="Property is available from"
                                                        dateFormat="MM/dd/yyyy"
                                                        className="form-input select-filter"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" hidden={!forSaleAsMortgageAsset}>
                                    <div className="section-1">
                                        <h3>Information about mortgage</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="PurchasePrice" name="purchasePrice" required={forSaleAsMortgageAsset} placeholder="Purchase Price" value={inputValuePPrice === 0 ? '' : inputValuePPrice} onChange={handleInputChangePPrice} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="remaningPrinciple" name="remaningPrinciple" required={forSaleAsMortgageAsset} placeholder="Remaning Principle" value={inputValueRPrinciple === 0 ? '' : inputValueRPrinciple} onChange={handleInputChangeRPrinciple} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="interestRate" name="interestRate" required={forSaleAsMortgageAsset} placeholder="Interest Rate %" value={inputValueIRate === 0 ? '' : inputValueIRate} onChange={handleInputChangeIRate} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="loanAmortizationPeriod" name="loanAmortizationPeriod" placeholder="Amortization period in years" value={inputValueAmortizationPeriod === 0 ? '' : inputValueAmortizationPeriod} onChange={handleInputChangeAmortizationPeriod} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="remaningAmortizationPeriod" name="remaningAmortizationPeriod" required={forSaleAsMortgageAsset} placeholder="Remaning amortization period in months" value={inputValueRAmortizationPeriod === 0 ? '' : inputValueRAmortizationPeriod} onChange={handleInputChangeRAmortizationPeriod} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="monthlyPayment" name="monthlyPayment" required={forSaleAsMortgageAsset} placeholder="Monthly payment" value={inputValueMPayment === 0 ? '' : inputValueMPayment} onChange={handleInputChangeMPayment} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Slider {...settings}>
                                    {imagePaths && imagePaths.length > 0 ? (
                                        imagePaths.map((image, index) => (
                                            <div key={index}>
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Item ${index}`}
                                                    width="763" height="443"
                                                />
                                                <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteSavedImage(index, event)}>Delete a saved image</button>
                                            </div>
                                        ))
                                    ) : (
                                        <img src="./images/ser-r.png" alt="Default Item" style={{ width: '100%', maxHeight: '443px' }} />
                                    )}
                                </Slider>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Add photos to the gallery</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileEn" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>Delete an image</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="form-VRT" type="text" name="VRT" placeholder="Link to VRT" value={inputValueLinkToVRT} onChange={handleLinkToVRT} />
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Listing by</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-name" type="text" name="name" autoComplete="name" placeholder="Your Name" data-constraints="@Required" value={inputValueName} onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-email" type="email" name="email" autoComplete="email" placeholder="E-mail" data-constraints="@Email @Required" value={inputValueEmail} onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-phone" type="text" name="phone" placeholder="Phone" autoComplete="tel" data-constraints="@PhoneNumber" value={inputValuePhone} onChange={handlePhone} />

                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="listingby" value={inputValueListingby} required={forRent} onChange={handleInputValueListingby}>
                                                        <option value="">Listing by: Owner/Agent</option>
                                                        <option value="owner">Owner</option>
                                                        <option value="agent">Agent</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Detailed Information</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfBathrooms"
                                                        name="numberOfBathrooms"
                                                        value={inputValueNBathrooms}
                                                        onChange={handleInputChangeNBathrooms}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Select number of bathrooms</option>
                                                        <option value="0">0 Baths</option>
                                                        <option value="1">1 Bath</option>
                                                        <option value="1.5">1.5 Baths</option>
                                                        <option value="2">2 Baths</option>
                                                        <option value="2.5">2.5 Baths</option>
                                                        <option value="3">3 Baths</option>
                                                        <option value="3.5">3.5 Baths</option>
                                                        <option value="4">4 Baths</option>
                                                        <option value="5">5 Baths</option>
                                                        <option value="6">6 Baths</option>
                                                        <option value="7">7 Baths</option>
                                                        <option value="8">8 Baths</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfBedrooms"
                                                        name="numberOfBedrooms"
                                                        value={inputValueNBedrooms}
                                                        onChange={handleInputChangeNBedrooms}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Select number of bedrooms</option>
                                                        <option value="0">Studio</option>
                                                        <option value="1">1 Bedroom</option>
                                                        <option value="2">2 Bedrooms</option>
                                                        <option value="3">3 Bedrooms</option>
                                                        <option value="4">4 Bedrooms</option>
                                                        <option value="5">5 Bedrooms</option>
                                                        <option value="6">6 Bedrooms</option>
                                                        <option value="7">7 Bedrooms</option>
                                                        <option value="8">8 Bedrooms</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfGarages"
                                                        name="numberOfGarages"
                                                        value={inputValueGarages}
                                                        onChange={handleInputGarages}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Select number of garages</option>
                                                        <option value="0">0 Garages</option>
                                                        <option value="1">1 Garage</option>
                                                        <option value="2">2 Garages</option>
                                                        <option value="3">3 Garages</option>
                                                        <option value="4">4 Garages</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="property-area" type="text" name="property-area" placeholder="Area, Sq Ft" data-constraints="@Numeric" value={inputValueArea} onChange={handleInputChangeArea} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="yearOfBuild" name="yearOfBuild" placeholder="when was property built" value={inputValueYearOfBuild} onChange={handleInputChangeYearOfBuild} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="sqftLot" name="sqftLot" placeholder="Lot area Sq. Ft." value={inputValueSqftLot} onChange={handleInputChangeSqftLot} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="number" className="form-input" id="monthlyHOApayment" name="monthlyHOApayment" placeholder="HOA monthly payment" value={inputValueMonthlyHOApayment === 0 ? '' : inputValueMonthlyHOApayment} onChange={handleInputChangeMonthlyHOApayment} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="number" className="form-input" id="PropertyTax" name="propertyTax" placeholder="Property tax/year" value={inputValuePTax === 0 ? '' : inputValuePTax} onChange={handleInputChangePTax} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row row-10">
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-1" value={inputValueTwostories} type="checkbox" checked={inputValueTwostories} onChange={handleInputTwostories} />   2 Stories
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-2" value={inputValueBasketballcourt} type="checkbox" checked={inputValueBasketballcourt} onChange={handleInputBasketballcourt} />   Basketball Court
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-3" value={inputValueGym} type="checkbox" checked={inputValueGym} onChange={handleInputGym} />   Gym
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-4" value={inputValueFireplace} type="checkbox" checked={inputValueFireplace} onChange={handleFireplace} />   Fireplace
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-5" value={inputValueSprinklers} type="checkbox" checked={inputValueSprinklers} onChange={handleSprinklers} />   Sprinklers
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-6" value={inputValuePrivatespace} type="checkbox" checked={inputValuePrivatespace} onChange={handlePrivatespace} />   Private Space
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-7" value={inputValueBalcony} type="checkbox" checked={inputValueBalcony} onChange={handleBalcony} />   Balcony
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-8" value={inputValueLaundry} type="checkbox" checked={inputValueLaundry} onChange={handleLaundry} />   Laundry
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-9" value={inputValueOceanview} type="checkbox" checked={inputValueOceanview} onChange={handleOceanview} />   Ocean View
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-10" value={inputValueCentralheating} type="checkbox" checked={inputValueCentralheating} onChange={handleCentralheating} />   Central Heating
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-11" value={inputValueLawn} type="checkbox" checked={inputValueLawn} onChange={handleLawn} />   Lawn
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-12" value={inputValueOutdoorarea} type="checkbox" checked={inputValueOutdoorarea} onChange={handleOutdoorarea} />   Outdoor Area
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-13" value={inputValueWifi} type="checkbox" checked={inputValueWifi} onChange={handleWifi} />   Wi-Fi
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-14" value={inputValueHometheater} type="checkbox" checked={inputValueHometheater} onChange={handleHometheater} />   Home Theater
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-15" value={inputValueParkinglot} type="checkbox" checked={inputValueParkinglot} onChange={handleParkinglot} />   Parking Lot
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-16" value={inputValueCatsAndDogsAllowed} type="checkbox" checked={inputValueCatsAndDogsAllowed} onChange={handleInputCatsAndDogsAllowed} />   Cats and Dogs Allowed
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-17" value={inputValueElevator} type="checkbox" checked={inputValueElevator} onChange={handleInputElevator} />   Elevator
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-18" value={inputValueFulltimeDoorman} type="checkbox" checked={inputValueFulltimeDoorman} onChange={handleInputFulltimeDoorman} />   Full Time Doorman
                                                                </label>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">


                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-19" value={inputValueBikeRoom} type="checkbox" checked={inputValueBikeRoom} onChange={handleBikeRoom} />   Bike Room
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-20" value={inputValueStorageInBuilding} type="checkbox" checked={inputValueStorageInBuilding} onChange={handleStorageInBuilding} />   Storage in Building
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-20" value={inputValueCentralAir} type="checkbox" checked={inputValueCentralAir} onChange={handleCentralAir} />   Centra Air
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-21" value={inputValueRoofdeck} type="checkbox" checked={inputValueRoofdeck} onChange={handleRoofdeck} />  Roof deck
                                                                </label>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <textarea className="form-input" id="form-comment" name="comment" placeholder="Comment (optional)" value={inputValueComment} onChange={handleComment}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">Submit Property</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Response: {responseMessage}</p>}
                                    {/* {responseMessage.map((message, index) => (
                 <p key={index} style={{ color: 'red' }}>{message}</p>
               ))} */}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        )}
        {languageToUse === "ru" && (
            <div>
                <section className="breadcrumbs-custom bg-image-addlisting context-dark" data-opacity="28">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title">Опубликовать недвижимость</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleUpdateListing} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/listing">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>Basic Information About Property</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="streetAddress" type="address" name="address" autoComplete="address-line1" required placeholder="Адрес улицы" value={inputValueAddress} onChange={handleInputChangeAddress} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="Штат" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">Выберите штат</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="Город" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>Выберите город</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="Zip" name="zip" autoComplete="postal-code" required placeholder="Почтовый индекс" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="propertytype" name="propertytype" required value={inputValuePType} onChange={handleInputChangePType}>
                                                        <option >Тип недвижимости</option>
                                                        <option value="House">Дом</option>
                                                        <option value="Townhouse">Таунхаус</option>
                                                        <option value="Condo">Кондоминиум</option>
                                                        <option value="Coop">Кооператив</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="property-status" data-style="modern" required data-placeholder="Status" data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueDealType} onChange={handleInputDealType}>
                                                        {/* <option label="Deal type: Sale/Rent/Other"></option> */}
                                                        {/* <option value="forSale">For Sale</option> */}
                                                        <option value="forRent">В аренду</option>
                                                        {/* <option value="forSaleAsMortgageAsset">For Sale As Mortgage Asset</option> */}
                                                    </select><span className="select-arrow"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="dailyMonthly" value={inputValueDailyOrMonthly} required={forRent} data-style="modern" onChange={handleInputValueDailyOrMonthly}>
                                                        <option value="">Снять</option>
                                                        <option value="monthly">Ежемесячно</option>
                                                        <option value="daily">Посуточная</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="propertyCondition" name="propertyCondition" value={inputValuePCondition} onChange={handleInputChangePCondition}>
                                                        <option >Property condition</option>
                                                        <option value="Ready for living">Ready for living</option>
                                                        <option value="Requires renovation">Requires renovation</option>
                                                        <option value="Not habitable">Not habitable</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input hidden={!forSale} type="text" className="form-input" id="property-price" name="property-price" data-constraints="@Required @Numeric" placeholder="Asking price (USD)" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="property-price" name="property-price" data-constraints="@Required @Numeric" placeholder="Стоимость аренды" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    {/* <input hidden={!forSale} type="text" className="form-input" id="property-price" name="property-price" required={forSale} data-constraints="@Required @Numeric" placeholder="Asking price (USD)" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                    <input hidden={!forRent} type="text" className="form-input" id="property-price" name="property-price" required={forRent} data-constraints="@Required @Numeric" placeholder="Rent price" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} /> */}
                                                    <input hidden={!forSaleAsMortgageAsset} type="text" className="form-input" id="property-price" name="property-price" required={forSaleAsMortgageAsset} data-constraints="@Required @Numeric" placeholder="Price of mortgage asset and property" value={inputValueAskingPrice === 0 ? '' : inputValueAskingPrice} onChange={handleInputChangeAskingPrice} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" hidden={!forRent}>
                                    <div className="section-1">
                                        <h3>Информация об помещении</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select className="form-input select-filter" id="rentalUnit" name="rentalUnit" required={forRent} value={inputValueRentalUnit} onChange={handleInputRentalUnit}>
                                                        <option value="Any">Арендная единица</option>
                                                        <option value="entireProperty">Вся недвижимость/Квартира</option>
                                                        <option value="room">Комната</option>
                                                        <option value="bed">Койко-места</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="ifUnitIsFurnihsedOrNot"
                                                        name="ifUnitIsFurnihsedOrNot"
                                                        required={forRent}
                                                        value={inputValueRentalUnitFurnished}
                                                        onChange={handleRentalUnitFurnished}
                                                    >
                                                        <option value="Any">Статус мебеликовки</option>
                                                        <option value="furnished">Мебелировання</option>
                                                        <option value="notfurnished">Без мебели</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <DatePicker
                                                        selected={inputRentalUnitAvalabilityDate}
                                                        onChange={handleInputRentalUnitAvalabilityDate}
                                                        placeholderText="Property is available from"
                                                        dateFormat="MM/dd/yyyy"
                                                        className="form-input select-filter"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" hidden={!forSaleAsMortgageAsset}>
                                    <div className="section-1">
                                        <h3>Information about mortgage</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="PurchasePrice" name="purchasePrice" required={forSaleAsMortgageAsset} placeholder="Purchase Price" value={inputValuePPrice === 0 ? '' : inputValuePPrice} onChange={handleInputChangePPrice} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="remaningPrinciple" name="remaningPrinciple" required={forSaleAsMortgageAsset} placeholder="Remaning Principle" value={inputValueRPrinciple === 0 ? '' : inputValueRPrinciple} onChange={handleInputChangeRPrinciple} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="interestRate" name="interestRate" required={forSaleAsMortgageAsset} placeholder="Interest Rate %" value={inputValueIRate === 0 ? '' : inputValueIRate} onChange={handleInputChangeIRate} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="loanAmortizationPeriod" name="loanAmortizationPeriod" placeholder="Amortization period in years" value={inputValueAmortizationPeriod === 0 ? '' : inputValueAmortizationPeriod} onChange={handleInputChangeAmortizationPeriod} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="remaningAmortizationPeriod" name="remaningAmortizationPeriod" required={forSaleAsMortgageAsset} placeholder="Remaning amortization period in months" value={inputValueRAmortizationPeriod === 0 ? '' : inputValueRAmortizationPeriod} onChange={handleInputChangeRAmortizationPeriod} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="monthlyPayment" name="monthlyPayment" required={forSaleAsMortgageAsset} placeholder="Monthly payment" value={inputValueMPayment === 0 ? '' : inputValueMPayment} onChange={handleInputChangeMPayment} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Slider {...settings}>
                                    {imagePaths && imagePaths.length > 0 ? (
                                        imagePaths.map((image, index) => (
                                            <div key={index}>
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Item ${index}`}
                                                    width="763" height="443"
                                                />
                                                <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteSavedImage(index, event)}>Удалить сохраненное изображение</button>
                                            </div>
                                        ))
                                    ) : (
                                        <img src="./images/ser-r.png" alt="Default Item" style={{ width: '100%', maxHeight: '443px' }} />
                                    )}
                                </Slider>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Добавить фотографии в галерею</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileEn" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>Удалить изображение</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="form-VRT" type="text" name="VRT" placeholder="Ссылка на видео" value={inputValueLinkToVRT} onChange={handleLinkToVRT} />
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Listing by</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-name" type="text" name="name" autoComplete="name" placeholder="Ваше имя" data-constraints="@Required" value={inputValueName} onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-email" type="email" name="email" autoComplete="email" placeholder="Электронная почта" data-constraints="@Email @Required" value={inputValueEmail} onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="form-phone" type="text" name="phone" placeholder="Телефон" autoComplete="tel" data-constraints="@PhoneNumber" value={inputValuePhone} onChange={handlePhone} />

                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <select className="form-input select-filter" name="listingby" value={inputValueListingby} required={forRent} onChange={handleInputValueListingby}>
                                                        <option value="">Опубликованно: Владелец/Агент</option>
                                                        <option value="owner">Владелец</option>
                                                        <option value="agent">Агент</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Подробная информация</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfBathrooms"
                                                        name="numberOfBathrooms"
                                                        value={inputValueNBathrooms}
                                                        onChange={handleInputChangeNBathrooms}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Выберите количество ванных комнат</option>
                                                        <option value="0">0 Ванная</option>
                                                        <option value="1">1 Ванная</option>
                                                        <option value="1.5">1.5 Ванные</option>
                                                        <option value="2">2 Ванные</option>
                                                        <option value="2.5">2.5 Ванные</option>
                                                        <option value="3">3 Ванные</option>
                                                        <option value="3.5">3.5 Ванные</option>
                                                        <option value="4">4 Ванные</option>
                                                        <option value="5">5 Ванные</option>
                                                        <option value="6">6 Ванные</option>
                                                        <option value="7">7 Ванные</option>
                                                        <option value="8">8 Ванные</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfBedrooms"
                                                        name="numberOfBedrooms"
                                                        value={inputValueNBedrooms}
                                                        onChange={handleInputChangeNBedrooms}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Выберите количество спален</option>
                                                        <option value="0">Студия</option>
                                                        <option value="1">1 Спальня</option>
                                                        <option value="2">2 Спальни</option>
                                                        <option value="3">3 Спальни</option>
                                                        <option value="4">4 Спальни</option>
                                                        <option value="5">5 Спальни</option>
                                                        <option value="6">6 Спальни</option>
                                                        <option value="7">7 Спальни</option>
                                                        <option value="8">8 Спальни</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap form-wrap-validation">
                                                    <select
                                                        className="form-input select-filter"
                                                        id="numberOfGarages"
                                                        name="numberOfGarages"
                                                        value={inputValueGarages}
                                                        onChange={handleInputGarages}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Выберите количество гаражей</option>
                                                        <option value="0">0 гаражей</option>
                                                        <option value="1">1 гаражей</option>
                                                        <option value="2">2 гаражейs</option>
                                                        <option value="3">3 гаражейs</option>
                                                        <option value="4">4 гаражейs</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="property-area" type="text" name="property-area" placeholder="Area, Sq Ft" data-constraints="@Numeric" value={inputValueArea} onChange={handleInputChangeArea} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="yearOfBuild" name="yearOfBuild" placeholder="when was property built" value={inputValueYearOfBuild} onChange={handleInputChangeYearOfBuild} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="sqftLot" name="sqftLot" placeholder="Lot area Sq. Ft." value={inputValueSqftLot} onChange={handleInputChangeSqftLot} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="number" className="form-input" id="monthlyHOApayment" name="monthlyHOApayment" placeholder="HOA monthly payment" value={inputValueMonthlyHOApayment === 0 ? '' : inputValueMonthlyHOApayment} onChange={handleInputChangeMonthlyHOApayment} />
                                                </div>
                                            </div>
                                            <div className="col-md-3" hidden={forRent}>
                                                <div className="form-wrap">
                                                    <input type="number" className="form-input" id="PropertyTax" name="propertyTax" placeholder="Property tax/year" value={inputValuePTax === 0 ? '' : inputValuePTax} onChange={handleInputChangePTax} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row row-10">
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-1" value={inputValueTwostories} type="checkbox" checked={inputValueTwostories} onChange={handleInputTwostories} />    2 этажа
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-2" value={inputValueBasketballcourt} type="checkbox" checked={inputValueBasketballcourt} onChange={handleInputBasketballcourt} />   Баскетбольная площадка
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-3" value={inputValueGym} type="checkbox" checked={inputValueGym} onChange={handleInputGym} />   Спортзал
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-4" value={inputValueFireplace} type="checkbox" checked={inputValueFireplace} onChange={handleFireplace} />   Камин
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-5" value={inputValueSprinklers} type="checkbox" checked={inputValueSprinklers} onChange={handleSprinklers} />    Спринклеры
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-6" value={inputValuePrivatespace} type="checkbox" checked={inputValuePrivatespace} onChange={handlePrivatespace} />   Личное пространство
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-7" value={inputValueBalcony} type="checkbox" checked={inputValueBalcony} onChange={handleBalcony} />   Балкон
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-8" value={inputValueLaundry} type="checkbox" checked={inputValueLaundry} onChange={handleLaundry} />   Прачечная
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-9" value={inputValueOceanview} type="checkbox" checked={inputValueOceanview} onChange={handleOceanview} />   Вид на океан
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-10" value={inputValueCentralheating} type="checkbox" checked={inputValueCentralheating} onChange={handleCentralheating} />   Центральное отопление
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-11" value={inputValueLawn} type="checkbox" checked={inputValueLawn} onChange={handleLawn} />   Лужайка
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-12" value={inputValueOutdoorarea} type="checkbox" checked={inputValueOutdoorarea} onChange={handleOutdoorarea} />   Открытая площадка
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-13" value={inputValueWifi} type="checkbox" checked={inputValueWifi} onChange={handleWifi} />   Wi-Fi
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-14" value={inputValueHometheater} type="checkbox" checked={inputValueHometheater} onChange={handleHometheater} />   Домашний театр
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-15" value={inputValueParkinglot} type="checkbox" checked={inputValueParkinglot} onChange={handleParkinglot} />    Парковка
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-16" value={inputValueCatsAndDogsAllowed} type="checkbox" checked={inputValueCatsAndDogsAllowed} onChange={handleInputCatsAndDogsAllowed} />  Кошки и собаки разрешены
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-17" value={inputValueElevator} type="checkbox" checked={inputValueElevator} onChange={handleInputElevator} />   Лифт
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-18" value={inputValueFulltimeDoorman} type="checkbox" checked={inputValueFulltimeDoorman} onChange={handleInputFulltimeDoorman} />   Швейцар на полную занятость
                                                                </label>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <ul className="list-sm">


                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-19" value={inputValueBikeRoom} type="checkbox" checked={inputValueBikeRoom} onChange={handleBikeRoom} />    Велосипедная комната
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-20" value={inputValueStorageInBuilding} type="checkbox" checked={inputValueStorageInBuilding} onChange={handleStorageInBuilding} />   Складское здание
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-20" value={inputValueCentralAir} type="checkbox" checked={inputValueCentralAir} onChange={handleCentralAir} />   Центральная система кондиционирования
                                                                </label>
                                                            </li>

                                                            <li>
                                                                <label className="checkbox-inline-val">
                                                                    <input name="checkbox-21" value={inputValueRoofdeck} type="checkbox" checked={inputValueRoofdeck} onChange={handleRoofdeck} />  Руфдек
                                                                </label>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-wrap">
                                        <textarea className="form-input" id="form-comment" name="comment" placeholder="Комментарий (необязательно)" value={inputValueComment} onChange={handleComment}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">Опубликовац недвижимость</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Ответ: {responseMessage}</p>}
                                    {/* {responseMessage.map((message, index) => (
                 <p key={index} style={{ color: 'red' }}>{message}</p>
               ))} */}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        )}
    </div>
}

export default EditListing;