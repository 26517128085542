import React, { useState } from 'react';

const ListingNavigation = ({ filteredListings, itemsPerPage }) => {
    // Initialize currentPage state
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

    // Calculate the range of pages to display
    let pageRangeStart = Math.max(1, currentPage - 3);
    let pageRangeEnd = Math.min(currentPage + 3, totalPages);

    // Adjust page range if currentPage is near the first or last page
    if (currentPage <= 3) {
        pageRangeStart = 1; // Start from the first page if currentPage is within the first 3 pages
        pageRangeEnd = Math.min(7, totalPages); // Display 7 pages if total pages are less than 7
    } else if (currentPage >= totalPages - 3) {
        pageRangeStart = Math.max(1, totalPages - 6); // Start from the appropriate page to display 7 pages
    }

    // Define functions for navigating to the next and previous pages
    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    // Define a function to handle page navigation
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                    <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                )
            ))}
            <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
            <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
        </div>
    );
};

export default ListingNavigation;
