import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from './loader';
import ChatBot from "./chatBot";
import backendUrl from './config';
import { Link } from 'react-router-dom';
import { useLanguage } from './languageContext';
import SubMenue from "./subMenue";
import DemandsPost from "./demandsPost";
import { useAuth } from './authContext';
import SearchDemand from "./searchDemand";

let listingData;

function createEntry(listedDemand, onItemClick) {
    return (
        <DemandsPost
            key={listedDemand._id}
            onClick={() => onItemClick(listedDemand._id)}
            listingId={listedDemand._id}
            demandtype={listedDemand.demandtype}
            city={listedDemand.city}
            state={listedDemand.state}
            zip={listedDemand.zip}
            email={listedDemand.email}
            phone={listedDemand.phone}
            name={listedDemand.name}
            subject={listedDemand.subject}
            comment={listedDemand.comment}
            proposedPrice={listedDemand.proposedPrice}
            subscriptionStatus={listedDemand.subscriptions[listedDemand.subscriptions.length - 1].status}
            imgs={listedDemand.imgs}
            lisingtype="listings"
            isFavoriteBySelection={false}
        />
    );
}

function FavoriteDemands({ onItemClick, listingId }) {
    const product = "demand";
    const { currentLanguage } = useLanguage();
    const [data, setInitialData] = useState([]);
    const [listedDemands, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addressFilter, setAddressFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);


    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    const { loggedIn, userId } = useAuth();
    useEffect(() => {
        // Only fetch data if the component is mounted
        let isMounted = true;

        const fetchData = async () => {
            try {
                // Check if the user is authenticated using the useAuth hook
                // if (!loggedIn) {
                //   navigate('/login');
                //   return;
                // }

                // Fetch data from the /favoratelistings endpoint
                const response = await fetch(`${backendUrl}/favoratelistings/${product}/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (isMounted) {
                    if (response.ok) {
                        const responseData = await response.json();
                        setInitialData(responseData.favorateListings);
                        setData(responseData.favorateListings);
                        setLoading(false);
                    } else {
                        // Handle non-200 status codes here
                        console.error("Non-200 status code:", response.status);
                        throw new Error(`Error: ${response.status}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();

        // Cleanup function to update the mounted status
        return () => {
            isMounted = false;
        };
    }, [loggedIn, navigate]);

    if (loading) {
        return <Loader key={1} loading={loading} />;
    }

    function AppyAddressFilter(value) {
        // setData(data);
        setAddressFilter(value);
    }

    const itemsPerPage = 9;

    const filteredListings = listedDemands && listedDemands.filter((item) => {
        const lowerCaseSearch = addressFilter.toLowerCase();
        return (
            lowerCaseSearch === '' ||
            (item && item.state && item.state.toLowerCase().includes(lowerCaseSearch)) ||
            (item && item.city && item.city.toLowerCase().includes(lowerCaseSearch)) ||
            (item && item.zip && item.zip.toString().includes(lowerCaseSearch))
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (filteredListings || []).reverse().slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

    // Calculate the range of pages to display
    let pageRangeStart = Math.max(1, currentPage - 3);
    let pageRangeEnd = Math.min(currentPage + 3, totalPages);

    // Adjust page range if currentPage is near the first or last page
    if (currentPage <= 3) {
        pageRangeStart = 1; // Start from the first page if currentPage is within the first 3 pages
        pageRangeEnd = Math.min(7, totalPages); // Display 7 pages if total pages are less than 7
    } else if (currentPage >= totalPages - 3) {
        pageRangeStart = Math.max(1, totalPages - 6); // Start from the appropriate page to display 7 pages
    }


    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    // Define a function to handle page navigation
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    function ApplySearchFilter(filteredBySearchListings) {
        setData(filteredBySearchListings);
    }

    return <main>
        <div>
            <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/demand2-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                    <h2 hidden={languageToUse !== "ge"} className="breadcrumbs-custom-title" style={{ color: 'white' }}>ჩემი რჩეული განცხადებები სერვისებზე</h2>
                    <h2 hidden={languageToUse !== "en"} className="breadcrumbs-custom-title" style={{ color: 'white' }}>My selected posts about demands</h2>
                    <h2 hidden={languageToUse !== "ru"} className="breadcrumbs-custom-title" style={{ color: 'white' }}>Мои избранные посты о потребностях</h2>
                </div>
            </section>
            <SubMenue product={product} />
            <SearchDemand listedDemands={data}
                onFilterSubmit={(filteredBySearchListings) => ApplySearchFilter(filteredBySearchListings)}
            />
            <div className="album py-5 bg-body-tertiary">
                <div className="container">
                    <div className="row">
                        {currentItems.map(listedDemand => createEntry(listedDemand, onItemClick))}
                    </div>
                </div>
                <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(1)} disabled={currentPage === 1}>«</button>
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={prevPage} disabled={currentPage === 1}>‹</button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                        (pageNumber >= pageRangeStart && pageNumber <= pageRangeEnd) && (
                            <button key={pageNumber} style={{ marginRight: '10px', border: 'none', background: 'transparent', ...(pageNumber === currentPage ? { fontWeight: '600' } : {}) }} onClick={() => goToPage(pageNumber)} disabled={currentPage === pageNumber}>{pageNumber}</button>
                        )
                    ))}
                    <button style={{ marginRight: '10px', border: 'none', fontSize: '18px', background: 'transparent' }} onClick={nextPage} disabled={currentPage === totalPages}>›</button>
                    <button style={{ border: 'none', fontSize: '18px', background: 'transparent' }} onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>»</button>
                </div>
            </div>
            <div className="col-md-6 col-lg-12">
                <div className="block-info bg-default">
                    <div className="form-wrap chat-bot">
                        <div className="container"><ChatBot key={1} /></div>
                    </div>
                </div>
            </div>
        </div>
    </main>
}

export { listingData };
export default FavoriteDemands;