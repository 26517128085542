import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import Loader from "./loader";
// import { State, City } from 'country-state-city';
import { State, City } from 'country-state-city';
import PopUpButton from './popUpButton';
import backendUrl from './config';
import { Link } from 'react-router-dom';
import { useAuth } from './authContext';
import 'react-datepicker/dist/react-datepicker.css';
import { useLanguage } from './languageContext';
import SubMenue from "./subMenue";


function DemandListing() {
    const product = "demand";
    const { currentLanguage } = useLanguage();
    const [loading, setLoading] = useState(true);

    const [inputValueDemandType, setInputValueDemandType] = useState("");
    const [inputValueCity, setInputCity] = useState("");
    const [inputValueState, setInputValueState] = useState("");
    const [inputValueZip, setInputValueZip] = useState("");
    const [inputValueEmail, setInputValueEmail] = useState("");
    const [inputValuePhone, setInputValuePhone] = useState("");
    const [inputValueName, setInputValueName] = useState("");
    const [inputValueSubject, setInputValueSubject] = useState("");
    const [inputValueComment, setInputValueComment] = useState("");
    const [inputValueproposedPrice, setInputValueproposedPrice] = useState(0);

    const [images, setImages] = useState([]);
    const [responseMessage, setResponseMessage] = useState("");
    // const [userId, setUserId] = useState("");
    const [responseMessageShow, setResponseMessageShow] = useState(false);


    // sellect city
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);

    const { loggedIn, userId } = useAuth();
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    useEffect(() => {
        // Populate the state dropdown with options
        const countryStates = State.getStatesOfCountry('US');
        setStates(countryStates);
        // Only fetch data if the component is mounted
        let isMounted = true;

        const fetchData = async () => {
            try {
                // Check if the user is authenticated using the useAuth hook
                if (!loggedIn) {
                    navigate('/login');
                    return;
                }

                if (isMounted) {
                    setLoading(false);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

        // Cleanup function to update the mounted status
        return () => {
            isMounted = false;
        };

    }, [loggedIn, userId, navigate]);


    if (loading) {
        return <Loader key={1} loading={loading} />;
    }

    const handleInputChangeDemandType = (e) => {
        setInputValueDemandType(e.target.value);
    };

    const handleStateChange = (e) => {
        const newState = e.target.value;
        setInputValueState(newState);

        // Update the city dropdown based on the selected state
        const stateCities = City.getCitiesOfState('US', newState);
        setCities(stateCities);
    };
    const handleInputChangeCity = (e) => {
        setInputCity(e.target.value);
    };
    const handleInputChangeZip = (e) => {
        setInputValueZip(e.target.value);
    };
    const handleEmail = (e) => {
        setInputValueEmail(e.target.value);
    };
    const handlePhone = (e) => {
        setInputValuePhone(e.target.value);
    };
    const handleName = (e) => {
        setInputValueName(e.target.value);
    };
    const handleInputChangeproposedPrice = (e) => {
        setInputValueproposedPrice(e.target.value);
    };
    const handleInputChangeSubject = (e) => {
        setInputValueSubject(e.target.value);
    };
    const handleComment = (e) => {
        setInputValueComment(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        // Check if the number of files exceeds the limit
        if (selectedFiles.length > 3) {
            alert("You can only upload a maximum of three files at once, but you can add more when listing is saved or posted.");
            // Clear the file input
            e.target.value = null;
            return;
        }
        const filesArray = Array.from(selectedFiles);
        // Set the state to trigger a re-render
        setImages((prevImages) => [...prevImages, ...filesArray]);
    };

    const handleDeleteImage = (index, event) => {
        event.preventDefault();

        // Remove the image from the images array based on its index
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });

        // Reset the value of the file input element to clear the uploaded image(s)
        const fileInput = document.getElementById(languageToUse === 'ge' ? 'formFileGe' : 'formFileEn');
        if (fileInput) {
            fileInput.value = '';
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        images.forEach((file, index) => {
            formData.append(`files`, file);
        });
        formData.append('demandtype', inputValueDemandType);
        formData.append('city', inputValueCity);
        formData.append('state', inputValueState);
        formData.append('zip', inputValueZip);
        formData.append('email', inputValueEmail);
        formData.append('phone', inputValuePhone);
        formData.append('name', inputValueName);
        formData.append('subject', inputValueSubject);
        formData.append('comment', inputValueComment);
        formData.append('proposedPrice', inputValueproposedPrice);

        let demandListingId = "";
        try {
            // Send the data to the backend
            const response = await fetch(`${backendUrl}/demandlisting/${userId}`, {
                method: "POST",
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    // Add any additional headers if needed
                }
            });

            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const data = await response.json();
                    setResponseMessage(data.message);
                    demandListingId = data.demandListingId;
                    setImages([]);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                    // Additional actions for success
                } else {
                    // Handle the case where the response is not JSON
                    const errorMessage = await response.text();
                    setResponseMessage(`${errorMessage}`);
                    setResponseMessageShow(true);
                    setTimeout(() => {
                        setResponseMessageShow(false);
                    }, 4000);
                }
                sendEmail({
                    from: inputValueEmail,
                    name: inputValueName,
                    phone: inputValuePhone,
                    subject: 'New listing from ' + inputValueName,
                    text: 'New listing, address ' + inputValueCity + inputValueState + inputValueZip,
                    itemId: demandListingId,
                    address: inputValueCity + inputValueState + inputValueZip,
                    product: 'Listing Demand',
                    activity: "Posting"
                });
                // navigate("/mylistings");
                navigate(`/process-payment/${product}/${demandListingId}`);
            } else {
                const errorMessage = await response.text();
                setResponseMessage(`${errorMessage}`);
                setResponseMessageShow(true);
                setTimeout(() => {
                    setResponseMessageShow(false);
                }, 4000);
            }
        } catch (error) {
            setResponseMessage("Error sending data to the server.");
            setResponseMessageShow(true);
            setTimeout(() => {
                setResponseMessageShow(false);
            }, 4000);
        }
    };

    // send email when property submitted


    async function sendEmail(data) {
        try {

            // Validate mandatory fields
            if (!inputValueCity || !inputValueState || !inputValueZip) {
                setResponseMessage("Please fill in all mandatory fields and upload at least one file.");
                return; // Stop further execution
            }

            const response = await fetch(`${backendUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                const responseData = await response.json();
                setResponseMessage(responseData.message);
            } else {
                const errorData = await response.json();
                setResponseMessage(`Error: ${errorData.message}`);
            }
        } catch (error) {
            setResponseMessage("Error sending data to the server.");
        }
    };

    // end send email

    // slider
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    // slider

    return <div>
        {languageToUse === "ge" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/demand4-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title" style={{ color: 'black' }}>თქვენი მოთხოვნის შესახებ განცხადების ატვირთვა</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleSubmit} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/demandlisting">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>ინფორმაცია მოთხოვნის შესახებ</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="შტატი" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">შეარჩიეთ შტატი</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="ქალაქი" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>შეარჩიეთ ქალაქი</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" required placeholder="ზიპ კოდი" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="demandTypeGe" name="demandType" required value={inputValueDemandType} onChange={handleInputChangeDemandType}>
                                                        <option value="Any">აირჩიეთ მოთხოვნის კატეგორია</option>
                                                        <option value="lookingfor">ვეძებ</option>
                                                        <option value="willbuy">შევიძენ</option>
                                                        <option value="willrent">ვიქირავებ</option>
                                                        <option value="other">სხვა მოთხოვნები</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>დეტალური ინფორმაცია</h3>
                                <div className="row row-20 mt-20">
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="formSubjectGe" name="subject" required rows={1} placeholder="სათაური (სავალდებულოა)" onChange={handleInputChangeSubject}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="formCommentGe" name="comment" placeholder="დეტალური ინფორმაცია" onChange={handleComment}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            <input type="text" className="form-input" id="servicePriceGe" name="property-price" data-constraints="@Required @Numeric" placeholder="მოთხოვნის ღირებულება დოლარებში" value={inputValueproposedPrice === 0 ? '' : inputValueproposedPrice} onChange={handleInputChangeproposedPrice} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>ფოტოების ატვირთვა</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileGe" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>ფოტოს წაშლა</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>განცხადების ავტორი</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" placeholder="თქვენი სახელი" data-constraints="@Required" onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="თქვენი იმეილი" data-constraints="@Email @Required" onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="თქვენი ტელეფონის ნომერი" autoComplete="tel" data-constraints="@PhoneNumber" onChange={handlePhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">განცხადების ატვირთვა</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>გამოხმაურება: {responseMessage}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div >
        )
        }
        {languageToUse === "en" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/demand4-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title" style={{ color: 'black' }}>Post your demands</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleSubmit} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/demandlisting">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>Information about demands</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="State" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">State</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="City" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>City</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" placeholder="Zip" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="dealTypeGe" name="dealType" required value={inputValueDemandType} onChange={handleInputChangeDemandType}>
                                                        <option value="Any">Select demand category</option>
                                                        <option value="lookingfor">I am looking for</option>
                                                        <option value="willbuy">I will buy</option>
                                                        <option value="willrent">I will rent</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>More details about demands</h3>
                                <div className="row row-20 mt-20">
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="formSubjectGe" name="subject" required rows={1} placeholder="Title/subject (required)" onChange={handleInputChangeSubject}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="formCommentGe" name="comment" placeholder="Detailed description" onChange={handleComment}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            <input type="text" className="form-input" id="servicePriceGe" name="property-price" data-constraints="@Required @Numeric" placeholder="Service price" value={inputValueproposedPrice === 0 ? '' : inputValueproposedPrice} onChange={handleInputChangeproposedPrice} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Upload photos</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileEn" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>Delete an image</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Listed by</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" placeholder="Name" data-constraints="@Required" onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="Email" data-constraints="@Email @Required" onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="Phone" autoComplete="tel" data-constraints="@PhoneNumber" onChange={handlePhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">Submit</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Response: {responseMessage}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div >
        )
        }
        {languageToUse === "ru" && (
            <div>
                <section className="vehicleBackground context-dark" style={{ backgroundImage: 'url("./images/demand4-op.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ textAlign: 'center', color: '#fff', zIndex: '2', width: '100%' }}>
                        <h2 className="breadcrumbs-custom-title" style={{ color: 'black' }}>Разместите свою надобнасть</h2>
                    </div>
                </section>
                <SubMenue product={product} />
                <div className="divider-section"></div>
                <section className="section section-md bg-gray-12">
                    <div className="container">

                        <form onSubmit={handleSubmit} encType="multipart/form-data" className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="POST" action="/demandlisting">
                            <div className="row row-20">
                                <div className="col-12">
                                    <div className="section-1 section-1-start">
                                        <h3>Информация о надобности</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-md-2">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="State" value={inputValueState} onChange={handleStateChange}>
                                                    <option value="">Штат</option>
                                                    {states.map((state) => (
                                                        <option key={state.isoCode} value={state.isoCode}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-input select-filter" name="state-country" data-style="modern" data-placeholder="City" required data-minimum-results-for-search="Infinity" data-constraints="@Required" value={inputValueCity} onChange={handleInputChangeCity}>
                                                    <option>Город</option>
                                                    {cities.map((city) => (
                                                        <option key={city.name} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-wrap">
                                                    <input type="text" className="form-input" id="ZipGe" name="zip" autoComplete="postal-code" placeholder="Почтовый индекс" value={inputValueZip} onChange={handleInputChangeZip} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="input-group mb-3">
                                                    <select className="form-input select-filter" id="dealTypeGe" name="dealType" required value={inputValueDemandType} onChange={handleInputChangeDemandType}>
                                                        <option value="Any">Выберите категорию спроса</option>
                                                        <option value="lookingfor">Я ищу</option>
                                                        <option value="willbuy">Я куплю</option>
                                                        <option value="willrent">Я сдам в аренду</option>
                                                        <option value="other">Другие</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>Подробнее о потребностях</h3>
                                <div className="row row-20 mt-20">
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="formSubjectGe" name="subject" required rows={1} placeholder="Название/тема (обязательно)" onChange={handleInputChangeSubject}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-wrap">
                                            <textarea className="form-input" id="formCommentGe" name="comment" placeholder="Подробное описание" onChange={handleComment}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-wrap">
                                            <input type="text" className="form-input" id="servicePriceGe" name="property-price" data-constraints="@Required @Numeric" placeholder="Предлагаемая цена" value={inputValueproposedPrice === 0 ? '' : inputValueproposedPrice} onChange={handleInputChangeproposedPrice} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Загрузить фото</h3>
                                        <div>
                                            <input className="form-control" type="file" name="files" multiple id="formFileEn" accept="image/*" onChange={handleFileChange} />
                                        </div>

                                        <div className="rd-file-drop rd-file-drop-1" data-drop-zone="rd-file-drop-1" data-button="rd-file-drop-btn-1" data-field-element="rd-file-drop-meta-1">
                                            <div className="rd-file-drop-meta rd-file-drop-meta-1">
                                                <Slider {...settings}>
                                                    {images.map((image, index) => (
                                                        <div key={index}>
                                                            <img src={URL.createObjectURL(image)} alt={`Object-${index}`} />
                                                            <button className="btn" style={{ color: 'goldenrod' }} onClick={(event) => handleDeleteImage(index, event)}>Удалить изображение</button>

                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="section-1">
                                        <h3>Опубликовано</h3>
                                        <div className="row row-20 mt-20">
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formNameGe" type="text" name="name" autoComplete="name" placeholder="Имя" data-constraints="@Required" onChange={handleName} />

                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formEmailGe" type="email" name="email" autoComplete="email" placeholder="Электронная почта" data-constraints="@Email @Required" onChange={handleEmail} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-wrap">
                                                    <input className="form-input" id="formPhoneGe" type="text" name="phone" placeholder="Телефон" autoComplete="tel" data-constraints="@PhoneNumber" onChange={handlePhone} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="button button-sm button-secondary" type="submit" value="Upload">Публиковать</button>
                                    {responseMessage.trim() !== "" && <p hidden={!responseMessageShow}>Ответ: {responseMessage}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div >
        )
        }
    </div >
}

export default DemandListing;
