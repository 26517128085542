import React, { useState, useEffect } from 'react';
import backendUrl from './config';

const PhotoCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [images, setImages] = useState([]);
    const [responseMessage, setResponseMessage] = useState("");
    const [imagePaths, setImagePaths] = useState([]);

    useEffect(() => {
        fetchImageNames();
    }, []);

    useEffect(() => {
        // Fetch photos when the images state changes
        if (images.length > 0) {
            fetchPhotos(images);
        }
    }, [images], [responseMessage]);

    // Function to change the slide
    const changeSlide = (direction) => {
        let newSlide = currentSlide + direction;

        if (newSlide < 0) {
            newSlide = images.length - 1;
        } else if (newSlide >= images.length) {
            newSlide = 0;
        }

        setCurrentSlide(newSlide);
    };

    // Function to fetch image names from the backend
    const fetchImageNames = async () => {
        try {
            const response = await fetch(`${backendUrl}/home`);
            const data = await response.json();

            // Assuming the backend returns an array of objects with an 'imgs' array
            const imageArrays = data.map(item => item.imgs.filter(Boolean)); // filter(Boolean) removes null or undefined values

            // Flatten the array of arrays into a single array of image filenames
            const flatImages = [].concat(...imageArrays);

            setImages(flatImages);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const fetchPhotos = async (images) => {
        setImagePaths([]);
        // Use a Set to store unique URLs
        const uniqueImagePaths = new Set();

        for (const photoName of images) {
            try {
                const response = await fetch(`${backendUrl}/api/photos/${photoName}`);
                if (response.ok) {
                    const contentType = response.headers.get('content-type');

                    if (contentType && contentType.includes('application/json')) {
                        const data = await response.json();
                        setResponseMessage(data.message);
                    } else {
                        const blob = await response.blob();
                        const imgUrl = URL.createObjectURL(blob);

                        // Add the unique URL to the set
                        uniqueImagePaths.add(imgUrl);
                    }
                } else {
                    setResponseMessage("Error fetching photos. Response failed.");
                }
            } catch (error) {
                console.error("Error fetching photos:", error);
                setResponseMessage("Error fetching photos.");
            }
        }

        setImagePaths([...uniqueImagePaths]);
    };

    useEffect(() => {
        // Load images when the component mounts

        // Automatically change slide every 3 seconds
        const interval = setInterval(() => {
            changeSlide(1);
        }, 3000);

        // Reset to the first slide when reaching the last slide
        if (currentSlide === imagePaths.length - 1) {
            setCurrentSlide(0);
        }

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [currentSlide, imagePaths]); // Re-run effect when currentSlide or imagePaths change

    return (
        <div className="carousel-container">
            <div className="carousel-slide" style={{ transform: `translateX(-${currentSlide * 33.34}%)` }}>
                {imagePaths.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Item ${index}`}
                        style={{ maxHeight: '300px', maxWidth: '100%' }}
                    />
                ))}
            </div>
        </div>
    );
};

export default PhotoCarousel;
