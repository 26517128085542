// import React from "react";
// import { Link } from 'react-router-dom';
// import PopUpButton from "./popUpButton";
// import { useLanguage } from './languageContext';

// function SubMenue(props) {
//   const { currentLanguage } = useLanguage();
//   const product = props.product;

//   // Retrieve currentLanguage from sessionStorage
//   const storedLanguage = sessionStorage.getItem('currentLanguage');

//   // Use storedLanguage if available, otherwise use currentLanguage
//   const languageToUse = storedLanguage || currentLanguage;

//   return (
//     <div>
//       {languageToUse === "ge" && (
//         <section className="section-xs bg-white">
//           <div className="container">
//             <ul className="breadcrumbs-custom-path">
//               <li hidden={product !== "realestate"}><Link to="/home" className="plain-link">უძრავი ქონება</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/vehicles" className="plain-link">ავტომობილები</Link></li>
//               <li hidden={product !== "service"}><Link to="/services" className="plain-link">სერვისები</Link></li>
//               <li hidden={product !== "demand"}><Link to="/demands" className="plain-link">მოთხოვნები</Link></li>
//               <li hidden={product !== "realestate"}><Link to="/favoratelistings" className="plain-link">რჩეული უძრავი ქონებები</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/favoratevehicles" className="plain-link">რჩეული ავტომობილები</Link></li>
//               <li hidden={product !== "service"}><Link to="/favorateservices" className="plain-link">რჩეული სერვისები</Link></li>
//               <li hidden={product !== "demand"}><Link to="/favoratedemands" className="plain-link">რჩეული მოთხოვნები</Link></li>
//               <li hidden={product !== "realestate"}><Link to="/listing" className="plain-link">განათავსე განცხადება უძრავ ქონებაზე</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/vehiclelisting" className="plain-link">განათავსე განცხადება ავტომობილზე</Link></li>
//               <li hidden={product !== "service"}><Link to="/servicelisting" className="plain-link">განათავსე განცხადება თქვენს სერვისებზე</Link></li>
//               <li hidden={product !== "demand"}><Link to="/demandlisting" className="plain-link">მოთხოვნაზე განცხადების დაპოსტვა</Link></li>
//               <li hidden={product !== "realestate"}><Link to="/mylistings" className="plain-link">ჩემი დაპოსტილი უძრავი ქონებები</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/mylistedvehicles" className="plain-link">ჩემი დაპოსტილი მანქანები</Link></li>
//               <li hidden={product !== "service"}><Link to="/mylistedservices" className="plain-link">ჩემი დაპოსტილი სერვისები</Link></li>
//               <li hidden={product !== "demand"}><Link to="/mylisteddemads" className="plain-link">ჩემი დაპოსტილი მოთხოვნები</Link></li>
//               <li><PopUpButton key={1} /></li>
//             </ul>
//           </div>
//         </section>
//       )}
//       {languageToUse === "en" && (
//         <section className="section-xs bg-white">
//           <div className="container">
//             <ul className="breadcrumbs-custom-path">
//               <li hidden={product !== "realestate"}><Link to="/home" className="plain-link">Find property</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/vehicles" className="plain-link">Find vehicles</Link></li>
//               <li hidden={product !== "service"}><Link to="/services" className="plain-link">Find services</Link></li>
//               <li hidden={product !== "demand"}><Link to="/demands" className="plain-link">Demand for something</Link></li>
//               <li hidden={product !== "realestate"}><Link to="/favoratelistings" className="plain-link">My favorate realestates</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/favoratevehicles" className="plain-link">My favorate vehicles</Link></li>
//               <li hidden={product !== "service"}><Link to="/favorateservices" className="plain-link">My favorate services</Link></li>
//               <li hidden={product !== "demand"}><Link to="/favoratedemands" className="plain-link">My favorite demands</Link></li>
//               <li hidden={product !== "realestate"}><Link to="/listing" className="plain-link">Post your property</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/vehiclelisting" className="plain-link">Post your vehicle</Link></li>
//               <li hidden={product !== "demand"}><Link to="/demandlisting" className="plain-link">post your demand for something</Link></li>
//               <li hidden={product !== "service"}><Link to="/servicelisting" className="plain-link">Post information about your services</Link></li>
//               <li hidden={product !== "realestate"}><Link to="/mylistings" className="plain-link">My posted properties</Link></li>
//               <li hidden={product !== "vehicle"}><Link to="/mylistedvehicles" className="plain-link">My posted vehicles</Link></li>
//               <li hidden={product !== "service"}><Link to="/mylistedservices" className="plain-link">My posted services</Link></li>
//               <li hidden={product !== "demand"}><Link to="/mylisteddemads" className="plain-link">My posted demands</Link></li>
//               <li><PopUpButton key={1} /></li>
//             </ul>
//           </div>
//         </section>
//       )}
//     </div>
//   )
// }

// export default SubMenue;


import React from "react";
import { Link } from 'react-router-dom';
import PopUpButton from "./popUpButton";
import { useLanguage } from './languageContext';

function SubMenue(props) {
  const { currentLanguage } = useLanguage();
  const product = props.product;

  // Retrieve currentLanguage from sessionStorage
  const storedLanguage = sessionStorage.getItem('currentLanguage');

  // Use storedLanguage if available, otherwise use currentLanguage
  const languageToUse = storedLanguage || currentLanguage;

  return (
    <div>
      {languageToUse === "ge" && (
        // <section className="section-xs bg-white">
        <section className="section-xs bg-white" style={{ paddingTop: '11px', paddingBottom: '0px' }}>
          <div className="container">
            <ul className="breadcrumbs-custom-path" style={{ fontWeight: '600' }}>
              <li hidden={product !== "realestate"}><Link to="/home" className="plain-link extra-bold">უძრავი ქონება</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/vehicles" className="plain-link extra-bold">ავტომობილები</Link></li>
              <li hidden={product !== "service"}><Link to="/services" className="plain-link extra-bold">სერვისები</Link></li>
              <li hidden={product !== "demand"}><Link to="/demands" className="plain-link extra-bold">მოთხოვნები</Link></li>
              <li hidden={product !== "realestate"}><Link to="/favoratelistings" className="plain-link extra-bold">რჩეულები</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/favoratevehicles" className="plain-link extra-bold">რჩეული ავტომობილები</Link></li>
              <li hidden={product !== "service"}><Link to="/favorateservices" className="plain-link extra-bold">რჩეული სერვისები</Link></li>
              <li hidden={product !== "demand"}><Link to="/favoratedemands" className="plain-link extra-bold">რჩეული მოთხოვნები</Link></li>
              <li hidden={product !== "realestate"}><Link to="/listing" className="plain-link extra-bold">განათავსე უძრავი ქონება</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/vehiclelisting" className="plain-link extra-bold">განათავსე ავტომობილი</Link></li>
              <li hidden={product !== "service"}><Link to="/servicelisting" className="plain-link extra-bold">განათავსე განცხადება სერვისებზე</Link></li>
              <li hidden={product !== "demand"}><Link to="/demandlisting" className="plain-link extra-bold">დაპოსტე მოთხოვნა</Link></li>
              <li hidden={product !== "realestate"}><Link to="/mylistings" className="plain-link extra-bold">ჩემი განცხადებები</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/mylistedvehicles" className="plain-link extra-bold">ჩემი განცხადებები</Link></li>
              <li hidden={product !== "service"}><Link to="/mylistedservices" className="plain-link extra-bold">ჩემი განცხადებები</Link></li>
              <li hidden={product !== "demand"}><Link to="/mylisteddemads" className="plain-link extra-bold">ჩემი განცხადებები</Link></li>
              <li><PopUpButton key={1} /></li>
            </ul>
          </div>
        </section>
      )}
      {languageToUse === "en" && (
        <section className="section-xs bg-white" style={{ paddingTop: '11px', paddingBottom: '0px' }}>
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li hidden={product !== "realestate"}><Link to="/home" className="plain-link">Find property</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/vehicles" className="plain-link">Find vehicles</Link></li>
              <li hidden={product !== "service"}><Link to="/services" className="plain-link">Find services</Link></li>
              <li hidden={product !== "demand"}><Link to="/demands" className="plain-link">Demand for something</Link></li>
              <li hidden={product !== "realestate"}><Link to="/favoratelistings" className="plain-link">My favorite realestates</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/favoratevehicles" className="plain-link">My favorite vehicles</Link></li>
              <li hidden={product !== "service"}><Link to="/favorateservices" className="plain-link">My favorite services</Link></li>
              <li hidden={product !== "demand"}><Link to="/favoratedemands" className="plain-link">My favorite demands</Link></li>
              <li hidden={product !== "realestate"}><Link to="/listing" className="plain-link">Post your property</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/vehiclelisting" className="plain-link">Post your vehicle</Link></li>
              <li hidden={product !== "demand"}><Link to="/demandlisting" className="plain-link">post your demand for something</Link></li>
              <li hidden={product !== "service"}><Link to="/servicelisting" className="plain-link">Post information about your services</Link></li>
              <li hidden={product !== "realestate"}><Link to="/mylistings" className="plain-link">My posted properties</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/mylistedvehicles" className="plain-link">My posted vehicles</Link></li>
              <li hidden={product !== "service"}><Link to="/mylistedservices" className="plain-link">My posted services</Link></li>
              <li hidden={product !== "demand"}><Link to="/mylisteddemads" className="plain-link">My posted demands</Link></li>
              <li><PopUpButton key={1} /></li>
            </ul>
          </div>
        </section>
      )}
      {languageToUse === "ru" && (
        <section className="section-xs bg-white" style={{ paddingTop: '11px', paddingBottom: '0px' }}>
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li hidden={product !== "realestate"}><Link to="/home" className="plain-link">Найти недвижимость</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/vehicles" className="plain-link">Найти машину</Link></li>
              <li hidden={product !== "service"}><Link to="/services" className="plain-link">Найти услуги</Link></li>
              <li hidden={product !== "demand"}><Link to="/demands" className="plain-link">Спрос на что-либо</Link></li>
              <li hidden={product !== "realestate"}><Link to="/favoratelistings" className="plain-link">Понравившиеся публикации</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/favoratevehicles" className="plain-link">Понравившиеся автомобили</Link></li>
              <li hidden={product !== "service"}><Link to="/favorateservices" className="plain-link">Понравившиеся услуги</Link></li>
              <li hidden={product !== "demand"}><Link to="/favoratedemands" className="plain-link">Понравившиеся спроси</Link></li>
              <li hidden={product !== "realestate"}><Link to="/listing" className="plain-link">Опубликовать недвижимость</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/vehiclelisting" className="plain-link">Разместить объявление</Link></li>
              <li hidden={product !== "demand"}><Link to="/demandlisting" className="plain-link">Опубликовать запрос</Link></li>
              <li hidden={product !== "service"}><Link to="/servicelisting" className="plain-link">Опубликовать услуги</Link></li>
              <li hidden={product !== "realestate"}><Link to="/mylistings" className="plain-link">Мои опубликованные квартиры</Link></li>
              <li hidden={product !== "vehicle"}><Link to="/mylistedvehicles" className="plain-link">Мои опубликованные автомобили</Link></li>
              <li hidden={product !== "service"}><Link to="/mylistedservices" className="plain-link">Мои размещенные услуги</Link></li>
              <li hidden={product !== "demand"}><Link to="/mylisteddemads" className="plain-link">Мои опубликованные спроси</Link></li>
              <li><PopUpButton key={1} /></li>
            </ul>
          </div>
        </section>
      )}
    </div>
  )
}

export default SubMenue;