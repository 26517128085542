import React, { useState, useEffect, useRef } from "react";
import Slider from 'react-slick';
import Loader from "./loader";
import ChatBot from "./chatBot";
import FavorateButton from "./favorateButton";
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import backendUrl from './config';
import { useAuth } from './authContext';
import { useNavigate } from "react-router-dom";
import { useLanguage } from './languageContext';
import SendEmailMainByUser from "./sendEmaiMainByUser";
import ImgSlider from "./imgSlider";


function VehicleDetailsPopUp({ onClick, listingId }) {
    const product = "vehicle";
    const itemId = listingId;
    const { userId } = useAuth();
    const { currentLanguage } = useLanguage();
    // const { itemId } = useParams();
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [loading, setLoading] = useState(true);

    const [imagePaths, setImagePaths] = useState([]);

    // const [userId, setUserId] = useState("");
    // const [responseMessageShow, setResponseMessageShow] = useState(false);


    const [responseMessageFetchPhotos, setResponseMessageFetchPhotos] = useState("");


    // Information toggle
    const [generalInformationAccordionOpened, setGeneralInformationAccordionOpened] = useState("true");
    const [vehicleInformationAccordionOpened, setVehicleInformationAccordionOpened] = useState("true");
    const [moreInformationAccordionOpened, setMoreInformationAccordionOpened] = useState("true");
    const [damagesAccordionOpened, setDamagesAccordionOpened] = useState("true");
    const [commentAccordionOpened, setCommentAccordionOpened] = useState("true");


    const { loggedIn } = useAuth();
    const navigate = useNavigate();

    const storedLanguage = sessionStorage.getItem('currentLanguage');
    const languageToUse = storedLanguage || currentLanguage;

    // deal summary 
    // let averageMortgageRate = 0.007;

    useEffect(() => {
        fetch(`${backendUrl}/item/${product}/${new Date().getTime()}/${itemId}`)
            .then((response) => response.json())
            .then((responseData) => {
                setSelectedVehicle(responseData);
                setLoading(false);
                fetchPhotos(responseData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [loggedIn, navigate, itemId]);

    // fetch photos 
    const fetchPhotos = async (responseData) => {
        setImagePaths([]);
        // Use a Set to store unique URLs
        const uniqueImagePaths = new Set();
        // Receive and add each image URL to the imagePaths array
        const numberOfPhotos = responseData.imgs.length;
        for (let i = 0; i < numberOfPhotos; i++) {
            try {
                // const response = await fetch(`${backendUrl}/api/vehiclephotos/${itemId}/${i}`);
                const response = await fetch(`${backendUrl}/api/photos/${product}/${itemId}/${i}`);
                if (response.ok) {
                    const contentType = response.headers.get('content-type');

                    if (contentType && contentType.includes('application/json')) {
                        const data = await response.json();
                        setResponseMessageFetchPhotos(data.message);
                    } else {
                        const blob = await response.blob();
                        const imgUrl = URL.createObjectURL(blob);

                        // Add the unique URL to the set
                        uniqueImagePaths.add(imgUrl);
                    }
                } else {
                    setResponseMessageFetchPhotos("Error fetching photos. Response failed.");
                }
            } catch (error) {
                console.error("Error fetching photos:", error);
                setResponseMessageFetchPhotos("Error fetching photos.");
            }
        }
        setImagePaths([...uniqueImagePaths]);
    };

    // fetching photo end


    if (loading) {
        return <Loader loading={loading} />;
    }

    const handleAccordionGeneralInformationToggle = () => {
        setGeneralInformationAccordionOpened(!generalInformationAccordionOpened);
    }

    const handleAccordionVehicleInformationToggle = () => {
        setVehicleInformationAccordionOpened(!vehicleInformationAccordionOpened);
    }

    const handleAccordionMoreDetalsToggle = () => {
        setMoreInformationAccordionOpened(!moreInformationAccordionOpened);
    }

    const handleAccordionDamagesToggle = () => {
        setDamagesAccordionOpened(!damagesAccordionOpened);
    }

    const handleAccordionCommentInformationToggle = () => {
        setCommentAccordionOpened(!commentAccordionOpened);
    }

    return (
        <div className="item-details-container container">
            {languageToUse === "ge" && (
                <div>
                    <section className="vehicleBackground context-dark" >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                            <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedVehicle.address} {selectedVehicle.city}</h3>
                        </div>
                    </section>
                    {/* <SubMenue product={product} /> */}
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list">
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>მწარმოებელი:</span><span className="" >{selectedVehicle.make}</span>
                                                    </li>
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>მოდელი:</span><span className="" >{selectedVehicle.model}</span>
                                                    </li>
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>წელი:</span><span className="" >{selectedVehicle.year}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="card-title"><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>მოკლე ინფორმაცია ავტომობილის შესახებ</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedVehicle.comment === "" ? "ინფორმაცია არ არის" : selectedVehicle.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>

                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${generalInformationAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionGeneralInformationToggle} className={`card-link ${generalInformationAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={generalInformationAccordionOpened}><span>{selectedVehicle.address} {selectedVehicle.city} {selectedVehicle.state} </span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${generalInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                <div className="card-body">
                                                    <div className="layout-1 text-margin">
                                                        <dl className="list-terms-inline">
                                                            <dt>ქალაქი:</dt>
                                                            <dd>{selectedVehicle.city}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>შტატი:</dt>
                                                            <dd>{selectedVehicle.state}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>ზიპ კოდი:</dt>
                                                            <dd>{selectedVehicle.zip}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>{selectedVehicle.dealtype === "forRent" ? "ქირავდება" : ""}</dt>
                                                            <dt>{selectedVehicle.dealtype === "forSale" ? "იყიდება" : ""}</dt>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>საიდენტიფიკაციო ნომერი:</dt>
                                                            <dd>{selectedVehicle.VIN}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        {selectedVehicle.dealtype === "forRent" && (
                                            <article className={`card card-custom card-corporate ${vehicleInformationAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionVehicleInformationToggle} className={`card-link ${vehicleInformationAccordionOpened ? '' : 'collapsed'}`}
                                                        role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={vehicleInformationAccordionOpened}><span hidden={selectedVehicle.dealtype !== "forRent"}>
                                                            ქირის ღირებულება {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "monthly" ? " /თვეში" : ""}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "weekly" ? " /კვირაში" : ""}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "daily" ? " /დღეში" : ""}</span>
                                                        <span hidden={selectedVehicle.dealtype !== "forSale"}>
                                                            ავტომობილის ღირებულება {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>

                                                <div className={`collapse ${vehicleInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                    <div className="card-body">
                                                        <div className="layout-1 text-margin">
                                                            <dl className="list-terms-inline">
                                                                <dt>მწარმოებელი:</dt>
                                                                <dd>{selectedVehicle.make}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>მოდელი:</dt>
                                                                <dd>{selectedVehicle.model} </dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>გამოშვების წელი:</dt>
                                                                <dd>{selectedVehicle.year} </dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>საწვავი:</dt>
                                                                <dd>{selectedVehicle.fuel === "gas" ? "ბენზინი" : ""}  {selectedVehicle.fuel === "diesel" ? "დიზელი" : ""}  </dd>
                                                            </dl>
                                                            {selectedVehicle.transmission !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>ტრანსმისია:</dt>
                                                                    <dd>{selectedVehicle.transmission} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.engine !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>ძრავი:</dt>
                                                                    <dd>{selectedVehicle.engine} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.milage !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>გარბენი:</dt>
                                                                    <dd>{selectedVehicle.mileage} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.exteriorcolor !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>ავტომანქანის ფერი:</dt>
                                                                    <dd>{selectedVehicle.exteriorcolor === "White" ? "თეთრი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Brown" ? "ყავისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Blue" ? "ლურჯი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Red" ? "წითელი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Green" ? "მწვანე" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Yellow" ? "ყვითელი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Orange" ? "სტაფილოსფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Purple" ? "იისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Pink" ? "იისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Aqua" ? "ღია ცისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Lime" ? "ღია მწვანე" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Silver" ? "ვერცხლისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Gray" ? "ნაცრისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.exteriorcolor === "Black" ? "შავი" : ""} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.interiorcolor !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>სალონის ფერი:</dt>
                                                                    <dd>{selectedVehicle.interiorcolor === "White" ? "თეთრი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Brown" ? "ყავისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Blue" ? "ლურჯი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Red" ? "წითელი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Green" ? "მწვანე" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Yellow" ? "ყვითელი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Orange" ? "სტაფილოსფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Purple" ? "იისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Pink" ? "იისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Aqua" ? "ღია ცისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Lime" ? "ღია მწვანე" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Silver" ? "ვერცხლისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Gray" ? "ნაცრისფერი" : ""} </dd>
                                                                    <dd>{selectedVehicle.interiorcolor === "Black" ? "შავი" : ""} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.interior !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>სალონი:</dt>
                                                                    <dd>{selectedVehicle.interior === "" ? "ინფორმაცია არ არის" : ""} </dd>
                                                                    <dd>{selectedVehicle.interior === "Leather" ? "ტყავის" : ""} </dd>
                                                                    <dd>{selectedVehicle.interior === "NoLeather" ? "ნაჭრის" : ""} </dd>
                                                                </dl>
                                                            )}
                                                            <dl className="list-terms-inline">
                                                                <dt>განცხადების ავტორი:</dt>
                                                                <dd>{selectedVehicle.name}</dd>, <dd> {selectedVehicle.email}</dd>, <dd> {selectedVehicle.phone}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        )}
                                    </div>
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${moreInformationAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionMoreDetalsToggle} className={`card-link ${moreInformationAccordionOpened ? '' : 'collapsed'}`}
                                                    role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={moreInformationAccordionOpened}>
                                                    <span>დამატებითი ინფორმაცია</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            {/* need to decide what detals should be dsiplayed in thsi block */}
                                            <div className={`collapse ${moreInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                <div className="card-body">
                                                    <div className="layout-1 text-margin">
                                                        {selectedVehicle.accident !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>არის თუ არა ნაავარიები:</dt>
                                                                <dd>{selectedVehicle.accident === "Any" ? "ინფორმაცია არ არის" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "noAccident" ? "ავარიაში არ ყოფილა" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "oneAccident" ? "ერთხელ არის ნაავარიები" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "moreAccidents" ? "მრავაჯერ არის ნაავარიები" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.condition !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>მანქანის მდგომარეობა:</dt>
                                                                <dd>{selectedVehicle.condition === "Any" ? "ინფორმაცია არ არის" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "amazing" ? "საუკეთესო" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "good" ? "კარგი" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "rough" ? "ცუდი" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.tire !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>საბურავების მდგომარეობა:</dt>
                                                                <dd>{selectedVehicle.tire === "Any" ? "ინფორმაცია არ არის" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "გood" ? "კარგი" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "mismatch" ? "ყველა საბურავი ერთნაირი არაა" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "replacementNeeded" ? "საჭიროებს შეცვლას" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.numberofkeys !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>გასაღებების რაოდენობა:</dt>
                                                                <dd>{selectedVehicle.numberofkeys}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.ownershipstatus !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>საკუთრების ფორმა:</dt>
                                                                <dd>{selectedVehicle.ownershipstatus === "Any" ? "ინფორმაცია არ არის" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "owned" ? "ინფორმაცია არ არის" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "leased" ? "ინფორმაცია არ არის" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "financed" ? "ინფორმაცია არ არის" : ""}</dd>
                                                            </dl>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion2" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${damagesAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionDamagesToggle} className={`card-link ${damagesAccordionOpened ? '' : 'collapsed'}`} role="button" aria-controls="accordion1-collapse-1" aria-expanded={damagesAccordionOpened}><span>ინფორმაცია დაზიანებებზე</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${damagesAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" style={{ minHeight: "fit-content" }}>
                                                <div className="col-12">
                                                    <div className="row row-10 features-container">
                                                        <div className="">
                                                            <ul className="list-sm text-margin">
                                                                <li hidden={!selectedVehicle.mechanicaldefects}>
                                                                    <p> სავალი ნაწილი დაზიანებულია </p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.damagespreventingdriving}>
                                                                    <p>მანქანა ვერ გადაადგილდება</p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.exteriordamage}>
                                                                    <p>ექსტერიერი დაზიანებულია</p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.crack}>
                                                                    <p>ნაკაწრები</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <!-- collapse--> */}
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    <div hidden={userId !== selectedVehicle.user._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editvehiclelisting/${selectedVehicle._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    განცხადებაში ცვლილებების შეტანა
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "დარწმუნებული ხართ, რომ გსურთ ამ განცხადების წაშლა? როგორც კი ამას გააკეთებთ, ვერ აღადგენთ წაშლილ განცხადებას და გამოწერას. თუ განცხადება იყო ფასიანი დაკარგავთ მისი ერთთვიანი გამოწერის დარჩენილ ვადასაც. ეს არ შეეხება სხვა განცხადებებს."
                                                    )
                                                        ? navigate(`/deletevehicle/${selectedVehicle._id}`)
                                                        : null
                                                }
                                            >
                                                განცხადების წაშლა
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    </div>
                                    {/* <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div> */}
                                    {/* <div className="block-group-item">
                                        <h3>მანქანის მდებარეობა რუქაზე</h3>
                                        <MapWrapper>
                                            <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedVehicle} defaultCenter={selectedVehicle}></Map>
                                        </MapWrapper>
                                    </div> */}
                                    {/* <div className="blog-post-solo-footer mt-20">
                                    </div> */}
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">სალომე ღურწკაია</h3>
                                                <p className="block-person-cite">კლიენტებთან ურთიერთობის ექსპერტი</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>5 წლის განმავლობაში დახმარება გაუწია უამრავ კლიენტს</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    დაუკავშირდით
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedVehicle.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )}
            {languageToUse === "en" && (
                <div>
                    <section className="vehicleBackground context-dark">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                            <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedVehicle.address} {selectedVehicle.city}</h3>
                        </div>
                    </section>
                    {/* <SubMenue product={product} /> */}
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list">
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>Make:</span><span className="" >{selectedVehicle.make}</span>
                                                    </li>
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>Model:</span><span className="" >{selectedVehicle.model}</span>
                                                    </li>
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>year:</span><span className="" >{selectedVehicle.year}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="card-title"><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>Short description</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedVehicle.comment === "" ? "Description is not provided" : selectedVehicle.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>

                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${generalInformationAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionGeneralInformationToggle} className={`card-link ${generalInformationAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={generalInformationAccordionOpened}><span>{selectedVehicle.address} {selectedVehicle.city} {selectedVehicle.state} </span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${generalInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                <div className="card-body">
                                                    <div className="layout-1 text-margin">
                                                        <dl className="list-terms-inline">
                                                            <dt>City:</dt>
                                                            <dd>{selectedVehicle.city}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>State:</dt>
                                                            <dd>{selectedVehicle.state}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>Zip:</dt>
                                                            <dd>{selectedVehicle.zip}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>{selectedVehicle.dealtype === "forRent" ? "For rent" : ""}</dt>
                                                            <dt>{selectedVehicle.dealtype === "forSale" ? "For sale" : ""}</dt>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>VIN:</dt>
                                                            <dd>{selectedVehicle.VIN}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        {selectedVehicle.dealtype === "forRent" && (
                                            <article className={`card card-custom card-corporate ${vehicleInformationAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionVehicleInformationToggle} className={`card-link ${vehicleInformationAccordionOpened ? '' : 'collapsed'}`}
                                                        role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={vehicleInformationAccordionOpened}><span hidden={selectedVehicle.dealtype !== "forRent"}>
                                                            Rent amount {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "monthly" ? " /monthly" : ""}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "weekly" ? " /weekly" : ""}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "daily" ? " /daily" : ""}</span>
                                                        <span hidden={selectedVehicle.dealtype !== "forSale"}>
                                                            Sales price {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>

                                                <div className={`collapse ${vehicleInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                    <div className="card-body">
                                                        <div className="layout-1 text-margin">
                                                            <dl className="list-terms-inline">
                                                                <dt>Make:</dt>
                                                                <dd>{selectedVehicle.make}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Model:</dt>
                                                                <dd>{selectedVehicle.model} </dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Year:</dt>
                                                                <dd>{selectedVehicle.fuel} </dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Fuel:</dt>
                                                                <dd>{selectedVehicle.fuel === "gas" ? "Gas" : ""}  {selectedVehicle.fuel === "diesel" ? "Diesel" : ""}  </dd>
                                                            </dl>
                                                            {selectedVehicle.transmission !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Transmission:</dt>
                                                                    <dd>{selectedVehicle.transmission} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.engine !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Engine:</dt>
                                                                    <dd>{selectedVehicle.engine} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.milage !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Mileage:</dt>
                                                                    <dd>{selectedVehicle.mileage} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.exteriorcolor !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Exterior color:</dt>
                                                                    <dd>{selectedVehicle.exteriorcolor} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.interiorcolor !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Interior color:</dt>
                                                                    <dd>{selectedVehicle.interiorcolor} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.interior !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Interior:</dt>
                                                                    <dd>{selectedVehicle.interior === "" ? "No information available" : ""} </dd>
                                                                    <dd>{selectedVehicle.interior === "Leather" ? "Leather" : ""} </dd>
                                                                    <dd>{selectedVehicle.interior === "NoLeather" ? "No leather" : ""} </dd>
                                                                </dl>
                                                            )}
                                                            <dl className="list-terms-inline">
                                                                <dt>Listed by:</dt>
                                                                <dd>{selectedVehicle.name}</dd>, <dd> {selectedVehicle.email}</dd>, <dd> {selectedVehicle.phone}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        )}
                                    </div>
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${moreInformationAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionMoreDetalsToggle} className={`card-link ${moreInformationAccordionOpened ? '' : 'collapsed'}`}
                                                    role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={moreInformationAccordionOpened}>
                                                    <span>More information</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            {/* need to decide what detals should be dsiplayed in thsi block */}
                                            <div className={`collapse ${moreInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                <div className="card-body">
                                                    <div className="layout-1 text-margin">
                                                        {selectedVehicle.accident !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Has the vehicle ever been in an accident:</dt>
                                                                <dd>{selectedVehicle.accident === "Any" ? "No info available" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "noAccident" ? "No" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "oneAccident" ? "Once" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "moreAccidents" ? "Multiple timesი" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.condition !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Vehicle condition:</dt>
                                                                <dd>{selectedVehicle.condition === "Any" ? "No info available" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "amazing" ? "Amazing" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "good" ? "Good" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "rough" ? "Rough" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.tire !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Tires:</dt>
                                                                <dd>{selectedVehicle.tire === "Any" ? "No info available" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "გood" ? "Good to go" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "mismatch" ? "Mismatched" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "replacementNeeded" ? "Requires replacement" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.numberofkeys !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Copy of keys:</dt>
                                                                <dd>{selectedVehicle.numberofkeys}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.ownershipstatus !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Ownership status:</dt>
                                                                <dd>{selectedVehicle.ownershipstatus === "Any" ? "No information available" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "owned" ? "Owned" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "leased" ? "Leased" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "financed" ? "Financed" : ""}</dd>
                                                            </dl>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion2" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${damagesAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionDamagesToggle} className={`card-link ${damagesAccordionOpened ? '' : 'collapsed'}`} role="button" aria-controls="accordion1-collapse-1" aria-expanded={damagesAccordionOpened}><span>Damages</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${damagesAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" style={{ minHeight: "fit-content" }}>
                                                <div className="col-12">
                                                    <div className="row row-10 features-container">
                                                        <div className="">
                                                            <ul className="list-sm text-margin">
                                                                <li hidden={!selectedVehicle.mechanicaldefects}>
                                                                    <p> Mechanical defects </p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.damagespreventingdriving}>
                                                                    <p>Vehicle has issues and cannot be driven</p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.exteriordamage}>
                                                                    <p>Exterior damages</p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.crack}>
                                                                    <p>Cracks and chips</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button> */}
                                    {/* <!-- collapse--> */}
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    {/* <div className="col-12">
                    <button className="button button-sm button-secondary"><RouterLink to={`/editlisting/` + selectedVehicle._id} style={{ textDecoration: "none", color: "black" }}>განცხადებაში ცვლილებების შეტანა</RouterLink></button>
                  </div> */}
                                    <div hidden={userId !== selectedVehicle.user._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editvehiclelisting/${selectedVehicle._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    Edit listings
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "Are you sure you want to remove this listing? Once you do this, you can't undo it. If you had a paid subscription for that listing, you'll lose it too."
                                                    )
                                                        ? navigate(`/deletevehicle/${selectedVehicle._id}`)
                                                        : null
                                                }
                                            >
                                                Delete listing
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    </div>
                                    {/* <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div> */}
                                    {/* <div className="block-group-item">
                                        <h3>Vehicle location</h3>
                                        <MapWrapper>
                                            <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedVehicle} defaultCenter={selectedVehicle}></Map>
                                        </MapWrapper>
                                    </div> */}
                                    {/* <div className="blog-post-solo-footer mt-20">
                                    </div> */}
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">Sally Gurtskaia</h3>
                                                <p className="block-person-cite">Customer success manager</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>Being a full time customer success manager for over 5 years has given Sally the opportunity to work with the most wonderful clients</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    Get in touch
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedVehicle.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )}
            {languageToUse === "ru" && (
                <div>
                    <section className="vehicleBackground context-dark">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="button-secondary-reverse" aria-label="Close" style={{ borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                            <h3 className="breadcrumbs-custom-title" style={{ fontSize: "16px", marginLeft: "10px" }}>{selectedVehicle.address} {selectedVehicle.city}</h3>
                        </div>
                    </section>
                    {/* <SubMenue product={product} /> */}
                    <div className="divider-section"></div>
                    <div className="section section-md bg-gray-12">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-7 col-xl-8">
                                    {/* <!-- Slick Carousel--> */}
                                    <div className="slick-slider-1">
                                        <div className="slick-slider-price">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</div>
                                        <div className="slick-slider carousel-parent" id="parent-carousel" data-arrows="true" data-loop="true" data-dots="false" data-swipe="true" data-fade="true" data-items="1" data-child="#child-carousel" data-for="#child-carousel">
                                            <ImgSlider imagePaths={imagePaths} />
                                        </div>
                                    </div>
                                    <div className="features-block">
                                        <div className="features-block-inner">
                                            <div className="features-block-item">
                                                <ul className="features-block-list">
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>Делать:</span><span className="" >{selectedVehicle.make}</span>
                                                    </li>
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>Модель:</span><span className="" >{selectedVehicle.model}</span>
                                                    </li>
                                                    <li>
                                                        <img src="" alt="" /><span style={{ fontWeight: '550', marginRight: '8px' }}>год:</span><span className="" >{selectedVehicle.year}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="features-block-item"><span className=""></span><FavorateButton listingId={itemId} product={product} isFavoriteBySelection={false} /></div>
                                        </div>
                                    </div>

                                    <div className="card-group-custom card-group-corporate" id="accordion1" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${commentAccordionOpened ? 'active' : ''}`} style={{ height: "fit-content" }}>
                                            <div className="card-header" id="accordion1-heading-1" >
                                                <div className="card-title"><a onClick={handleAccordionCommentInformationToggle} className={`card-link ${commentAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={commentAccordionOpened}><span>Краткое описание</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${commentAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" >
                                                <div className="card-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div className="layout">
                                                        <dl className="list-terms-inline text-margin">
                                                            <dd>{selectedVehicle.comment === "" ? "Описание не предоставлено" : selectedVehicle.comment}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>

                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${generalInformationAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionGeneralInformationToggle} className={`card-link ${generalInformationAccordionOpened ? '' : 'collapsed'}`} role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={generalInformationAccordionOpened}><span>{selectedVehicle.address} {selectedVehicle.city} {selectedVehicle.state} </span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${generalInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                <div className="card-body">
                                                    <div className="layout-1 text-margin">
                                                        <dl className="list-terms-inline">
                                                            <dt>Город:</dt>
                                                            <dd>{selectedVehicle.city}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>Штат:</dt>
                                                            <dd>{selectedVehicle.state}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>Почтовый индекс:</dt>
                                                            <dd>{selectedVehicle.zip}</dd>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>{selectedVehicle.dealtype === "forRent" ? "В аренду" : ""}</dt>
                                                            <dt>{selectedVehicle.dealtype === "forSale" ? "Продается" : ""}</dt>
                                                        </dl>
                                                        <dl className="list-terms-inline">
                                                            <dt>VIN - Идентификационный номер автомобиля:</dt>
                                                            <dd>{selectedVehicle.VIN}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        {selectedVehicle.dealtype === "forRent" && (
                                            <article className={`card card-custom card-corporate ${vehicleInformationAccordionOpened ? 'active' : ''}`}>
                                                <div className="card-header" id="accordion1-heading-1" role="tab">
                                                    <div className="card-title"><a onClick={handleAccordionVehicleInformationToggle} className={`card-link ${vehicleInformationAccordionOpened ? '' : 'collapsed'}`}
                                                        role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={vehicleInformationAccordionOpened}><span hidden={selectedVehicle.dealtype !== "forRent"}>
                                                            Арендная плата {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "monthly" ? " /ежемесячно" : ""}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "weekly" ? " /еженедельно" : ""}</span>
                                                        <span>{selectedVehicle.rentChargePeriod === "daily" ? " /ежедневно" : ""}</span>
                                                        <span hidden={selectedVehicle.dealtype !== "forSale"}>
                                                            Продажная цена {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3, useGrouping: true }).format(selectedVehicle.askingPrice)}</span>
                                                        <div className="card-arrow"></div></a></div>
                                                </div>

                                                <div className={`collapse ${vehicleInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                    <div className="card-body">
                                                        <div className="layout-1 text-margin">
                                                            <dl className="list-terms-inline">
                                                                <dt>Производитель:</dt>
                                                                <dd>{selectedVehicle.make}</dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Модель:</dt>
                                                                <dd>{selectedVehicle.model} </dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Год:</dt>
                                                                <dd>{selectedVehicle.fuel} </dd>
                                                            </dl>
                                                            <dl className="list-terms-inline">
                                                                <dt>Топливо:</dt>
                                                                <dd>{selectedVehicle.fuel === "gas" ? "Бензин" : ""}  {selectedVehicle.fuel === "diesel" ? "Дизель" : ""}  </dd>
                                                            </dl>
                                                            {selectedVehicle.transmission !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Каробка передача:</dt>
                                                                    <dd>{selectedVehicle.transmission} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.engine !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Двигатель:</dt>
                                                                    <dd>{selectedVehicle.engine} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.milage !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Пробег:</dt>
                                                                    <dd>{selectedVehicle.mileage} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.exteriorcolor !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Внешний цвет:</dt>
                                                                    <dd>{selectedVehicle.exteriorcolor} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.interiorcolor !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Цвет салона:</dt>
                                                                    <dd>{selectedVehicle.interiorcolor} </dd>
                                                                </dl>
                                                            )}
                                                            {selectedVehicle.interior !== "" && (
                                                                <dl className="list-terms-inline">
                                                                    <dt>Салон:</dt>
                                                                    <dd>{selectedVehicle.interior === "" ? "Нет доступной информации" : ""} </dd>
                                                                    <dd>{selectedVehicle.interior === "Leather" ? "Кожаный салон" : ""} </dd>
                                                                    <dd>{selectedVehicle.interior === "NoLeather" ? "Не Кожаный" : ""} </dd>
                                                                </dl>
                                                            )}
                                                            <dl className="list-terms-inline">
                                                                <dt>Публикователь:</dt>
                                                                <dd>{selectedVehicle.name}</dd>, <dd> {selectedVehicle.email}</dd>, <dd> {selectedVehicle.phone}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        )}
                                    </div>
                                    <div className="card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${moreInformationAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionMoreDetalsToggle} className={`card-link ${moreInformationAccordionOpened ? '' : 'collapsed'}`}
                                                    role="button" data-toggle="collapse" aria-controls="accordion1-collapse-1" aria-expanded={moreInformationAccordionOpened}>
                                                    <span>Больше информации</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            {/* need to decide what detals should be dsiplayed in thsi block */}
                                            <div className={`collapse ${moreInformationAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" role="tabpanel" aria-labelledby="accordion1-heading-1" data-parent="#accordion1">
                                                <div className="card-body">
                                                    <div className="layout-1 text-margin">
                                                        {selectedVehicle.accident !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Попадал ли автомобиль в аварию:</dt>
                                                                <dd>{selectedVehicle.accident === "Any" ? "Нет доступной информации" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "noAccident" ? "Нет" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "oneAccident" ? "Один раз" : ""}</dd>
                                                                <dd>{selectedVehicle.accident === "moreAccidents" ? "Много раз" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.condition !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Состояние автомобиля:</dt>
                                                                <dd>{selectedVehicle.condition === "Any" ? "Нет доступной информации" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "amazing" ? "В отличном состоянии" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "good" ? "В хорошем состоянии" : ""}</dd>
                                                                <dd>{selectedVehicle.condition === "rough" ? "В тяжелом состоянии" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.tire !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Шины:</dt>
                                                                <dd>{selectedVehicle.tire === "Any" ? "Нет доступной информации" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "good" ? "В хорошем состоянии" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "mismatch" ? "Несоответствие шин" : ""}</dd>
                                                                <dd>{selectedVehicle.tire === "replacementNeeded" ? "Требует замены" : ""}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.numberofkeys !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Копия ключей:</dt>
                                                                <dd>{selectedVehicle.numberofkeys}</dd>
                                                            </dl>
                                                        )}
                                                        {selectedVehicle.ownershipstatus !== "" && (
                                                            <dl className="list-terms-inline">
                                                                <dt>Статус собственности:</dt>
                                                                <dd>{selectedVehicle.ownershipstatus === "Any" ? "Нет доступной информации" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "owned" ? "В собственности" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "leased" ? "Арендаван" : ""}</dd>
                                                                <dd>{selectedVehicle.ownershipstatus === "financed" ? "Финансируется" : ""}</dd>
                                                            </dl>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <!-- collapse--> */}
                                    <div className="card-group-custom card-group-corporate" id="accordion2" >
                                        {/* <!-- card--> */}
                                        <article className={`card card-custom card-corporate ${damagesAccordionOpened ? 'active' : ''}`}>
                                            <div className="card-header" id="accordion1-heading-1" role="tab">
                                                <div className="card-title"><a onClick={handleAccordionDamagesToggle} className={`card-link ${damagesAccordionOpened ? '' : 'collapsed'}`} role="button" aria-controls="accordion1-collapse-1" aria-expanded={damagesAccordionOpened}><span>Повреждения</span>
                                                    <div className="card-arrow"></div></a></div>
                                            </div>
                                            <div className={`collapse ${damagesAccordionOpened ? 'show' : ''}`} id="accordion1-collapse-1" aria-labelledby="accordion1-heading-1" style={{ minHeight: "fit-content" }}>
                                                <div className="col-12">
                                                    <div className="row row-10 features-container">
                                                        <div className="">
                                                            <ul className="list-sm text-margin">
                                                                <li hidden={!selectedVehicle.mechanicaldefects}>
                                                                    <p> Механические дефекты</p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.damagespreventingdriving}>
                                                                    <p>Автомобиль имеет проблемы и не может управляться</p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.exteriordamage}>
                                                                    <p>Внешние повреждения</p>
                                                                </li>
                                                                <li hidden={!selectedVehicle.crack}>
                                                                    <p>Трещины и сколы</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button> */}
                                    {/* <!-- collapse--> */}
                                    <div className="divider"></div>
                                    <h2 className="heading-decoration-1"><span className="heading-inner"></span></h2>
                                    {/* <div className="col-12">
                    <button className="button button-sm button-secondary"><RouterLink to={`/editlisting/` + selectedVehicle._id} style={{ textDecoration: "none", color: "black" }}>განცხადებაში ცვლილებების შეტანა</RouterLink></button>
                  </div> */}
                                    <div hidden={userId !== selectedVehicle.user._id} className="row">
                                        <div className="col-6">
                                            <button className="button button-sm button-secondary">
                                                <RouterLink to={`/editvehiclelisting/${selectedVehicle._id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    Редактировать объявление
                                                </RouterLink>
                                            </button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <button
                                                className="button button-sm button-secondary"
                                                style={{ textDecoration: "none", color: "black" }}
                                                onClick={() =>
                                                    window.confirm(
                                                        "Вы уверены, что хотите удалить это объявление? Сделав это, вы не сможете отменить это. Если у вас была платная подписка на этот список, вы ее тоже потеряете."
                                                    )
                                                        ? navigate(`/deletevehicle/${selectedVehicle._id}`)
                                                        : null
                                                }
                                            >
                                                Удалить объявление
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="button-secondary-reverse" aria-label="Close" style={{ position: 'absolute', right: '10px', margin: '10px', borderRadius: "14px", height: "28px", width: "28px" }} onClick={onClick}>X</button>
                                    </div>
                                    {/* <div className="card-group-custom card-group-corporate" id="accordion3" role="tablist" aria-multiselectable="false">
                                    </div> */}
                                    {/* <div className="block-group-item">
                                        <h3>Vehicle location</h3>
                                        <MapWrapper>
                                            <Map mapStylesHeight="400px" mapSylesWidth="100%" favorateListings={selectedVehicle} defaultCenter={selectedVehicle}></Map>
                                        </MapWrapper>
                                    </div> */}
                                    {/* <div className="blog-post-solo-footer mt-20">
                                    </div> */}
                                    <div className="mt-35 mt-md-50 mt-lg-60">
                                        <article className="block-person">
                                            <div className="block-person-left"><img src="images/Agent_Sally_Gurtskaia.png" alt="" width="650" height="756" />
                                            </div>
                                            <div className="block-person-body">
                                                <h3 className="block-person-title">Салли Гурцкая</h3>
                                                <p className="block-person-cite">Менеджер по работе с клиентами</p>
                                                <ul className="block-person-list">
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-phone"></span><a href="tel:#">+ 1 929 497-8472</a></div>
                                                    </li>
                                                    <li>
                                                        <div className="block-person-link"><span className="icon mdi mdi-email-outline"></span><a className="text-spacing-50" href="mailto:#">service@superteamusa.com</a></div>
                                                    </li>
                                                </ul>
                                                <p>Будучи менеджером по работе с клиентами на протяжении более 5 лет, Салли получила возможность работать с самыми замечательными клиентами.</p>
                                                <Link
                                                    className="button button-primary"
                                                    to="sendEmailForm"
                                                    smooth={true}
                                                    duration={500}
                                                >
                                                    Связаться
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <div className="row row-50">
                                        <div className="col-md-6 col-lg-12">
                                            <SendEmailMainByUser
                                                itemId={itemId}
                                                address={selectedVehicle.address}
                                                product="Listing Vehicle"
                                                activity="Inquiry"
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-12">
                                            <div className="block-info bg-default">
                                                <div className="form-wrap chat-bot">
                                                    <div className="container"><ChatBot /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </div>
    );
}

export default VehicleDetailsPopUp;