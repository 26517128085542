import { useState, useEffect } from 'react';

const VinDecoder = ({ vin, onVinDecode }) => {
    const [decodedInfo, setDecodedInfo] = useState({
        make: 'N/A',
        model: 'N/A',
        year: 'N/A',
        transmission: 'N/A',
        engine: 'N/A',
    });

    useEffect(() => {
        if (vin) {
            // Perform the VIN decoding and update the state accordingly
            decodeVin(vin);
        }
    }, [vin]);

    const decodeVin = async (vin) => {
        try {
            const response = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`);
            const data = await response.json();

            if (data.Results && data.Results.length > 0) {
                // Extract make, model, year, transmission, and engine from the JSON response
                const decodedMake = data.Results.find((result) => result.Variable === 'Make')?.Value ?? 'N/A';
                const decodedModel = data.Results.find((result) => result.Variable === 'Model')?.Value ?? 'N/A';
                const decodedYear = data.Results.find((result) => result.Variable === 'Model Year')?.Value ?? 'N/A';
                // const decodedTransmission = data.Results.find((result) => result.Variable === 'Transmission Style')?.Value ?? 'N/A';
                // const decodedEngine = data.Results.find((result) => result.Variable === 'Engine Configuration')?.Value ?? 'N/A';

                setDecodedInfo({
                    make: decodedMake,
                    model: decodedModel,
                    year: decodedYear,
                    // transmission: decodedTransmission,
                    // engine: decodedEngine,
                });

                // Callback to parent with decoded information
                // onVinDecode(decodedMake, decodedModel, decodedYear, decodedTransmission, decodedEngine);
                onVinDecode(decodedMake, decodedModel, decodedYear);
            } else {
                console.error('Error decoding VIN: No results found');
                // Handle error, e.g., display an error message
            }
        } catch (error) {
            console.error('Error decoding VIN:', error);
            // Handle error, e.g., display an error message
        }
    };
};

export default VinDecoder;
